import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import objectId from '@src/lib/objectId'
import type EntityPhoneNumberStore from '@src/service/entity-phone-number-store'
import type {
  AvailabilityHours,
  PhoneNumberSettings,
  RoleName,
  UserPhoneNumber,
} from '@src/service/transport/account'
import type { IvrSettings } from '@src/service/transport/communication'

import { fullName } from '.'
import type { Model, PortRequestStatus } from '.'
import type MemberModel from './MemberModel'

export interface CodableEntityPhoneNumber {
  availabilityHours: AvailabilityHours | null
  awayVoicemailId: string | null
  awayVoicemailUrl: string | null
  color: string | null
  createdAt: number | null
  entityId: string | null
  entityName: string | null
  entityType: string | null
  formattedNumber: string | null
  forward: string | null
  groupId: string | null
  id: string
  ivr: IvrSettings | null
  mutedUntil: number | null
  name: string | null
  number: string | null
  orgId: string | null
  ownerId: string | null
  primary: boolean | null
  releasedAt: number | null
  role: RoleName | null
  settings: PhoneNumberSettings
  sid: string | null
  status: string | null
  symbol: string | null
  timeout: number | null
  updatedAt: number | null
  userId: string | null
  users: UserPhoneNumber[]
  voicemailId: string | null
  voicemailUrl: string | null
  portRequestId: string | null
  portingStatus: PortRequestStatus | null
}

class EntityPhoneNumberModel implements CodableEntityPhoneNumber, Model {
  availabilityHours: AvailabilityHours | null = null
  awayVoicemailId: string | null = null
  awayVoicemailUrl: string | null = null
  color: string | null = null
  createdAt: number | null = null
  entityId: string | null = null
  entityName: string | null = null
  entityType: string | null = null
  formattedNumber: string | null = null
  forward: string | null = null
  groupId: string | null = null
  id = objectId()
  ivr: IvrSettings | null = null
  mutedUntil: number | null = null
  name: string | null = null
  number: string | null = null
  orgId: string | null = null
  ownerId: string | null = null
  primary: boolean | null = null
  releasedAt: number | null = null
  role: RoleName | null = null
  settings: PhoneNumberSettings = {}
  sid: string | null = null
  status: string | null = null
  symbol: string | null = null
  timeout: number | null = null
  updatedAt: number | null = null
  userId: string | null = null
  users: UserPhoneNumber[] = []
  voicemailId: string | null = null
  voicemailUrl: string | null = null
  portRequestId: string | null = null
  portingStatus: PortRequestStatus | null = null

  constructor(
    private entityPhoneNumberStore: EntityPhoneNumberStore,
    attrs: CodableEntityPhoneNumber,
  ) {
    makeAutoObservable(this, {})

    this.deserialize(attrs)
  }

  get members() {
    return this.users
      .map((u) => (u.id ? this.entityPhoneNumberStore.getMember(u.id) : null))
      .filter(isNonNull)
  }

  get fullUserNames(): string {
    return this.users.map(fullName).join(', ')
  }

  get userNames(): string {
    if (!this.users) {
      return ''
    } else if (this.users.length === 1) {
      return fullName(this.users[0])
    }
    return this.users
      .map((u) => u.firstName)
      .filter((u) => u)
      .join(', ')
  }

  get isForwarding() {
    return Boolean(this.forward)
  }

  get isPorting() {
    return !!this.portingStatus && this.portingStatus !== 'completed'
  }

  get isTollFree() {
    const number = this.number

    if (!number) {
      return false
    }

    const tollFreeNumberPrefixes = ['800', '833', '844', '855', '866', '877', '888']

    // Currently checks only NANP toll free.
    // https://en.wikipedia.org/wiki/Toll-free_telephone_numbers_in_the_North_American_Numbering_Plan
    if (!number.startsWith('+1')) {
      return false
    }

    return tollFreeNumberPrefixes.includes(number.slice(2, 5))
  }

  update = (attributes: Partial<CodableEntityPhoneNumber>) => {
    Object.assign(this, attributes)

    return this.entityPhoneNumberStore.update(this)
  }

  updateSettings = (attributes: Partial<PhoneNumberSettings>) => {
    Object.assign(this.settings, attributes)

    return this.entityPhoneNumberStore.updateSettings(this)
  }

  makeOwner = (member: MemberModel) => {
    this.entityId = member.id
    this.ownerId = member.id
    this.users = this.users.map((u) => {
      if (u.id !== member.id && u.role == 'owner') {
        return { ...u, role: 'admin' }
      } else if (u.id === member.id) {
        return { ...u, role: 'owner' }
      }
      return u
    })

    return this.entityPhoneNumberStore.reassign(this, member.id)
  }

  addMember = (member: MemberModel, role: RoleName) => {
    this.users = this.users.filter((u) => u.id !== member.id)
    this.users.push({
      id: member.id,
      role,
      email: member.email,
      firstName: member.firstName,
      lastName: member.lastName,
    })

    return this.entityPhoneNumberStore.addUser(this, member.id)
  }

  removeMember = (member: MemberModel) => {
    this.users = this.users.filter((u) => u.id !== member.id)

    return this.entityPhoneNumberStore.removeUser(this, member.id)
  }

  toggleInternational = (enabled: boolean) => {
    this.settings.international = { ...this.settings.international, enabled }

    return this.entityPhoneNumberStore.toggleInternational(this)
  }

  fetchIvr = () => {
    return this.entityPhoneNumberStore.fetchIvr(this)
  }

  updateIvr = (ivr: IvrSettings) => {
    this.ivr = ivr

    return this.entityPhoneNumberStore.setIvr(this)
  }

  deleteIvr = () => {
    this.ivr = null

    return this.entityPhoneNumberStore.deleteIvr(this)
  }

  delete = () => {
    return this.entityPhoneNumberStore.delete(this)
  }

  deserialize = ({
    createdAt,
    mutedUntil,
    updatedAt,
    settings,
    ...json
  }: CodableEntityPhoneNumber) => {
    Object.assign(this, json)

    this.settings = settings ?? {}

    this.mutedUntil = mutedUntil ? parseDate(mutedUntil) : null

    this.createdAt = createdAt ? parseDate(createdAt) : null

    this.updatedAt = updatedAt ? parseDate(updatedAt) : null

    return this
  }

  setVoicemailUrl = (url: string) => {
    this.voicemailUrl = url

    return this.entityPhoneNumberStore.setVoicemail(this)
  }

  setAwayVoicemailUrl = (url: string) => {
    this.awayVoicemailUrl = url

    return this.entityPhoneNumberStore.setAwayVoicemail(this)
  }

  serialize = (): CodableEntityPhoneNumber => {
    return {
      availabilityHours: toJS(this.availabilityHours),
      awayVoicemailId: this.awayVoicemailId,
      awayVoicemailUrl: this.awayVoicemailUrl,
      color: this.color,
      createdAt: this.createdAt,
      entityId: this.entityId,
      entityName: this.entityName,
      entityType: this.entityType,
      formattedNumber: this.formattedNumber,
      forward: this.forward,
      groupId: this.groupId,
      id: this.id,
      ivr: toJS(this.ivr),
      mutedUntil: this.mutedUntil,
      name: this.name,
      number: this.number,
      orgId: this.orgId,
      ownerId: this.ownerId,
      primary: this.primary,
      releasedAt: this.releasedAt,
      role: this.role,
      settings: toJS(this.settings),
      sid: this.sid,
      status: this.status,
      symbol: this.symbol,
      timeout: this.timeout,
      updatedAt: this.updatedAt,
      userId: this.userId,
      users: toJS(this.users),
      voicemailId: this.voicemailId,
      voicemailUrl: this.voicemailUrl,
      portRequestId: this.portRequestId,
      portingStatus: this.portingStatus,
    }
  }
}

export default EntityPhoneNumberModel
