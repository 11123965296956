import { observer } from 'mobx-react-lite'

import { useAppStore } from '@src/app/context'
import MediaViewer from '@src/component/MediaViewer'

const AppMediaViewer = () => {
  const { closeMediaViewer, mediaViewer } = useAppStore()
  return mediaViewer ? (
    <MediaViewer closeMediaViewer={closeMediaViewer} mediaViewer={mediaViewer} />
  ) : null
}

export default observer(AppMediaViewer)
