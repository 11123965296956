import {
  PREMIUM_PLAN_MONTHLY_PRICE_ID,
  STANDARD_PLAN_MONTHLY_PRICE_ID,
  STANDARD_PLAN_MONTHLY_V3_PRICE_ID,
  STANDARD_PLAN_MONTHLY_V5_PRICE_ID,
  STANDARD_PLAN_MONTHLY_V6_PRICE_ID,
  PREMIUM_PLAN_ANNUAL_PRICE_ID,
  STANDARD_PLAN_ANNUAL_PRICE_ID,
  STANDARD_PLAN_ANNUAL_V5_PRICE_ID,
  STANDARD_PLAN_ANNUAL_V6_PRICE_ID,
  type PriceId,
  PREMIUM_PLAN_ANNUAL_V6_PRICE_ID,
  PREMIUM_PLAN_MONTHLY_V6_PRICE_ID,
} from '@src/app/billing/pricing/constants'

export const isPlanStandardMonthly = (priceId: PriceId) =>
  priceId === STANDARD_PLAN_MONTHLY_PRICE_ID ||
  priceId === STANDARD_PLAN_MONTHLY_V3_PRICE_ID ||
  priceId === STANDARD_PLAN_MONTHLY_V5_PRICE_ID ||
  priceId === STANDARD_PLAN_MONTHLY_V6_PRICE_ID

export const isPlanStandardAnnual = (priceId: PriceId) =>
  priceId === STANDARD_PLAN_ANNUAL_PRICE_ID ||
  priceId === STANDARD_PLAN_ANNUAL_V5_PRICE_ID ||
  priceId === STANDARD_PLAN_ANNUAL_V6_PRICE_ID

export const isPlanPremiumMonthly = (priceId: PriceId) =>
  priceId === PREMIUM_PLAN_MONTHLY_PRICE_ID ||
  priceId === PREMIUM_PLAN_MONTHLY_V6_PRICE_ID

export const isPlanPremiumAnnual = (priceId: PriceId) =>
  priceId === PREMIUM_PLAN_ANNUAL_PRICE_ID || priceId === PREMIUM_PLAN_ANNUAL_V6_PRICE_ID

const isPlan = (priceId: PriceId) =>
  isPlanStandardMonthly(priceId) ||
  isPlanStandardAnnual(priceId) ||
  isPlanPremiumMonthly(priceId) ||
  isPlanPremiumAnnual(priceId)

export default isPlan
