/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable } from 'mobx'

import Collection from '@src/service/collections/Collection'

import type Service from '.'
import type {
  ContactModel,
  ContactSuggestionModel,
  IntegrationContact,
  MemberModel,
  PhoneNumberModel,
} from './model'
import { ParticipantModel } from './model'

export default class ParticipantStore {
  readonly collection = new Collection<ParticipantModel>({
    idKey: 'phoneNumber',
    bindElements: true,
  })

  constructor(private root: Service) {
    makeAutoObservable(this, {})
  }

  get(phoneNumber: string): ParticipantModel | null {
    return this.collection.get(phoneNumber)
  }

  loadByNumber(phoneNumber: string) {
    this.root.contact.loadByNumber(phoneNumber)
    this.root.integration.loadByNumber(phoneNumber)
  }

  getContacts(phoneNumber: string): ContactModel[] {
    return (
      this.root.contact
        .getByNumber(phoneNumber)
        ?.slice()
        .sort((a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0)) ?? []
    )
  }

  getMember(phoneNumber: string): MemberModel | null {
    return (
      this.root.member.collection.list.find(
        (m) =>
          m.directNumber?.number === phoneNumber ||
          m.phoneNumbers.find((p) => p.number === phoneNumber),
      ) ?? null
    )
  }

  getContactSuggestion(participant: ParticipantModel): ContactSuggestionModel | null {
    if (participant.member) {
      return null
    }

    if (participant.contact) {
      return null
    }

    if (participant.integrationContacts.length !== 0) {
      return null
    }

    return this.root.contactSuggestion.getByNumber(participant.phoneNumber)
  }

  getOpenPhoneNumber(phoneNumber: string): PhoneNumberModel | null {
    return this.root.phoneNumber.byNumber[phoneNumber] ?? null
  }

  getIntegrationContacts(phoneNumber: string): IntegrationContact[] {
    return this.root.integration.getPeople(phoneNumber)
  }

  getOrCreate(phoneNumber: string): ParticipantModel {
    const participant = this.get(phoneNumber)

    if (participant) {
      return participant
    }

    const newParticipant = new ParticipantModel(this, phoneNumber)

    this.collection.put(newParticipant)

    return newParticipant
  }
}
