import { computed, makeObservable } from 'mobx'

import type Service from '@src/service'

import BaseAlertModel from './BaseAlertModel'

class CsvImportCompletedAlertModel extends BaseAlertModel<
  'contact-import-completed' | 'contact-import-errored',
  { contactImportId: string }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      csvImport: computed,
    })
  }

  get associatedObjectsLoaded() {
    return Boolean(this.csvImport)
  }

  get csvImport() {
    return this.service.contact.csvImportsV2.get(this.data.contactImportId)
  }
}

export default CsvImportCompletedAlertModel
