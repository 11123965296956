import type AppStore from '@src/app/AppStore'

import { URLRouter } from './URLRouter'

/**
 * Deals with URLs in respect to the OpenPhone app.
 */
export default class URLController {
  protected router: URLRouter
  protected readonly handlerUrlParam = 'handlerUrl'

  constructor(protected app: AppStore) {
    this.router = new URLRouter(this.app)

    this.app.electron?.on('open-url', (url) => {
      this.route(url)
    })
  }

  /**
   * Route a non-HTTP URL to its respective handler, if it exists.
   *
   * Examples:
   *  - `tel:+15555555555`
   *  - `sms:+15555555555,+15555555556,+15555555557?body=Hello%20World`
   *  - `openphone://conversation/CN12345`
   */
  route(url: URL | string) {
    this.router.route(url)
  }

  /**
   * Open a URL in the browser.
   *
   * If we're running in Electron, we always open the URL in a new window.
   */
  open(url: URL | string) {
    if (this.app.isElectron) {
      window.open(url)
    } else {
      window.location.href = url.toString()
    }
  }

  /**
   * Check for the `?handlerUrl=` query parameter and route it.
   *
   * The query parameter is added when web protocols are invoked from being
   * registered in `registerWebProtocols()`.
   */
  checkHandlerUrl() {
    const handlerUrl = this.app.history.consumeQueryParam(this.handlerUrlParam)

    if (handlerUrl) {
      this.route(handlerUrl)
    }
  }

  /**
   * Request permission to register our protocols on web.
   */
  registerWebProtocols(): void {
    if (this.app.electron) {
      return
    }
    const handlerUrl = this.getProtocolHandlerCallbackUrl()

    for (const protocol of this.router.webProtocols) {
      navigator.registerProtocolHandler(protocol, handlerUrl)
    }
  }

  /**
   * Associate OpenPhone with our protocols on desktop.
   */
  registerDesktopProtocols(): void {
    if (!this.app.electron?.app) {
      return
    }
    this.app.electron.app.registerTelProtocol?.()
  }

  /**
   * Disassociate OpenPhone from our protocols on desktop.
   */
  unregisterDesktopProtocols(): void {
    if (!this.app.electron?.app) {
      return
    }
    this.app.electron.app.unregisterTelProtocol?.()
  }

  /**
   * The URL intended to be the callback from `registerProtocolHandler()`.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/registerProtocolHandler
   */
  protected getProtocolHandlerCallbackUrl(): string {
    const url = new URL(window.location.origin)
    url.searchParams.append(this.handlerUrlParam, '%s')
    const decodedUrl = decodeURIComponent(url.toString())

    return decodedUrl
  }
}
