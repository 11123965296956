import { makeAutoObservable } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import type { ContactModel } from './model'
import { ContactSuggestionModel } from './model'
import type {
  ContactSuggestionStatus,
  RawContactSuggestion,
  SuggestedFields,
} from './model/ContactSuggestionModel'
import type { ContactSuggestionRepository } from './worker/repository/ContactSuggestionRepository'
import { CONTACT_SUGGESTION_TABLE_NAME } from './worker/repository/ContactSuggestionRepository'

export default class ContactSuggestionStore {
  readonly collection: PersistedCollection<
    ContactSuggestionModel,
    ContactSuggestionRepository
  >

  private readonly disposeBag = new DisposeBag()

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table(CONTACT_SUGGESTION_TABLE_NAME),
      classConstructor: (json: RawContactSuggestion) => new ContactSuggestionModel(json),
    })

    makeAutoObservable(this)

    this.disposeBag.add(this.subscribeToWebSocket())
    this.load()
  }

  private load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  async fetchPending() {
    const response = await this.root.transport.contacts.suggestions
      .for(this.root.organization.getCurrentOrganization().id)
      .fetchPending()

    this.collection.load(response.result, {
      deleteOthers: true,
    })
  }

  getByNumber(number: string): ContactSuggestionModel | null {
    return (
      this.collection.list.find(
        (s) => s.phoneNumber === number && s.status === 'pending',
      ) ?? null
    )
  }

  getByContact(contact: ContactModel): ContactSuggestionModel | null {
    if (!contact.local) {
      return null
    }

    const number = contact.phoneNumbers[0]?.value as string | undefined
    if (!number) {
      return null
    }

    return this.getByNumber(number)
  }

  getMergedSuggestedFields(
    contact: ContactModel,
    contactSuggestion: ContactSuggestionModel,
  ) {
    return {
      firstName: contact.firstName ?? contactSuggestion.suggestedFields.firstName,
      lastName: contact.lastName ?? contactSuggestion.suggestedFields.lastName,
      role: contact.role ?? contactSuggestion.suggestedFields.role,
      company: contact.company ?? contactSuggestion.suggestedFields.company,
    }
  }

  async update(
    contactSuggestion: ContactSuggestionModel,
    status: ContactSuggestionStatus,
    suggestedFields: SuggestedFields,
  ) {
    contactSuggestion.localUpdate({ status })

    return this.root.transport.contacts.suggestions.update(
      contactSuggestion.id,
      status,
      suggestedFields,
    )
  }

  toggleContactSuggestions = (enabled: boolean) => {
    return this.root.transport.contacts.settings.put({ suggestionsEnabled: enabled })
  }

  private putRawContactSuggestionToCollection(
    rawContactSuggestion: RawContactSuggestion | null,
  ) {
    if (!rawContactSuggestion) {
      return
    }

    const contactSuggestion = new ContactSuggestionModel(rawContactSuggestion)
    this.collection.put(contactSuggestion)
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'contact-suggestion-create':
        case 'contact-suggestion-update': {
          this.putRawContactSuggestionToCollection(data.contactSuggestion)
          break
        }
        case 'contact-suggestion-delete': {
          this.collection.delete(data.contactSuggestionId)
          break
        }
        case 'contact-suggestion-update-error': {
          this.putRawContactSuggestionToCollection(data.validData)
          break
        }
      }
    })
  }
}
