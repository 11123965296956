import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import { forwardRef } from 'react'

import { useFormField } from '@ui/FormField'
import type { InputBaseProps } from '@ui/InputBase'
import InputBase from '@ui/InputBase'

import * as styles from './TextInputControl.css'

export interface TextInputControlProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  inputBaseProps?: Omit<InputBaseProps, 'children'>
}

const TextInputControl = forwardRef<HTMLInputElement, TextInputControlProps>(
  ({ className, inputBaseProps = {}, ...props }, ref) => {
    const { htmlProps, fieldProps } = useFormField()

    return (
      <InputBase {...fieldProps} {...inputBaseProps}>
        <input
          type="text"
          className={cx(styles.root, className)}
          {...props}
          {...htmlProps}
          ref={ref}
          style={assignInlineVars({
            [styles.paddingLeft]: inputBaseProps.startAdornment ? '10px' : '16px',
            [styles.paddingRight]: inputBaseProps.endAdornment ? '10px' : '16px',
          })}
        />
      </InputBase>
    )
  },
)

TextInputControl.displayName = 'TextInputControl'

export default TextInputControl
