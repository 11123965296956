import { useComboboxList } from '@ui/Combobox/ComboboxList'

import * as styles from './ComboboxFocusTarget.css'

function ComboboxFocusTarget() {
  const { comboboxFocusTargetRef, focusTargetProps } = useComboboxList()

  return (
    <div
      className={styles.root}
      tabIndex={-1}
      ref={comboboxFocusTargetRef}
      {...focusTargetProps}
    />
  )
}

export default ComboboxFocusTarget
