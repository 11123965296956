import { observer } from 'mobx-react-lite'
import { useId } from 'react'

import { useAppStore } from '@src/app/context'
import type { PromptAction } from '@src/app/prompt'
import Button from '@src/component/ButtonV2'
import Modal from '@ui/Modal'
import Typography from '@ui/Typography'
import { LayerProvider } from '@ui/z-index'

import Action from './Action'
import * as styles from './Prompt.css'

const Prompt = () => {
  const titleId = useId()
  const descriptionId = useId()
  const { prompt } = useAppStore()

  const handleAlertAction = (action: PromptAction) => {
    action.onClick?.()
    prompt.hide()
  }

  if (!prompt.prompt) {
    return null
  }

  const { title, body, actions } = prompt.prompt

  return (
    <LayerProvider layer="prompt">
      <Modal
        onClose={prompt.hide}
        className={styles.root}
        underlayColor="rgb(26 25 38 / 40%)"
        isDismissable
        role="alertdialog"
        aria-labelledby={titleId}
        aria-describedby={descriptionId}
      >
        <Typography
          component="h2"
          id={titleId}
          fontWeight="medium"
          variant="headline"
          color="textPrimary"
        >
          {title}
        </Typography>

        <Typography
          id={descriptionId}
          variant="callout"
          color="textSecondary"
          className={styles.body}
        >
          {body}
        </Typography>

        <div className={styles.actions}>
          {actions && actions.length > 0 ? (
            actions
              .slice()
              .reverse()
              .map((action, index) => (
                <Action
                  key={`${titleId}-action-${index}`}
                  action={action}
                  onClick={handleAlertAction}
                />
              ))
          ) : (
            <Button variant="contained" height={36} color="primary" onClick={prompt.hide}>
              Okay
            </Button>
          )}
        </div>
      </Modal>
    </LayerProvider>
  )
}

export default observer(Prompt)
