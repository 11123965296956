import { isValidElement, Fragment, type ReactNode } from 'react'

export default function unwrapFragment(child: ReactNode): ReactNode[] | ReactNode {
  if (isValidElement(child) && child.type === Fragment) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    return Array.isArray(child.props.children)
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
        child.props.children?.map?.(unwrapFragment)?.flat?.() ?? []
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        unwrapFragment(child.props.children)
  }
  return child ?? []
}
