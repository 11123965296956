import { makeAutoObservable, toJS } from 'mobx'

import { getInitials, parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'
import type { Identity, IdentityPhone, Model } from '@src/service/model'
import type OrganizationStore from '@src/service/organization-store'
import type { Membership } from '@src/service/transport/account'

export type UseCase = 'personal' | 'business'
export type TeamSize = '1' | '2-5' | '6-9' | '10-49' | '50+'
export type Users = 'solo' | 'team'

type PersonalOrganizationAnalytics = {
  useCase: Extract<UseCase, 'personal'>
  industry: null
  teamSize: Extract<TeamSize, '1'>
  users: Extract<Users, 'solo'>
  teams: null
  need: string
}

type BusinessOrganizationAnalytics = {
  useCase: Extract<UseCase, 'business'>
  industry: string
  teamSize: TeamSize
  users: Users
  teams: string[]
  need: null
}

export type OrganizationAnalytics = (
  | PersonalOrganizationAnalytics
  | BusinessOrganizationAnalytics
) & {
  /**
   * Enrichment data from Clearbit.
   * No attributes are guaranteed to be present.
   *
   * @see https://dashboard.clearbit.com/docs#enrichment-api-company-api
   */
  enrichment?: {
    name?: string
    category?: { industry?: string; subIndustry?: string }
    metrics?: { employees?: number }
  }
}

export interface CodableOrganization {
  id?: string
  name?: string
  slug?: string | null
  employees?: string | null
  industry?: string | null
  externalId?: string | null
  membership?: Membership | null
  pictureUrl?: string | null
  analytics?: { [key: string]: any } | null
  createdAt?: number | null
  updatedAt?: number | null
}

class OrganizationModel implements Identity, Model, CodableOrganization {
  id: string = objectId()
  name = ''
  slug: string | null = null
  employees: string | null = null
  industry: string | null = null
  teams: string[] = []
  externalId: string | null = null
  membership: Membership | null = null
  pictureUrl: string | null = null
  analytics: OrganizationAnalytics | null = null
  createdAt: number | null = null
  updatedAt: number | null = null

  constructor(private organizationStore: OrganizationStore) {
    makeAutoObservable(this, {})
  }

  get phones(): IdentityPhone[] {
    return []
  }

  get emailAddresses(): string[] {
    return []
  }

  get shortName(): string {
    return this.name
  }

  get initials(): string {
    return getInitials(this.name)
  }

  get isAnonymous(): boolean {
    return Boolean(this.pictureUrl)
  }

  update = (org: Partial<this>): Promise<this> => {
    Object.assign(this, org)

    return this.organizationStore.update(this.serialize()).then(this.deserialize)
  }

  deserialize = (json: CodableOrganization): this => {
    Object.assign(this, json)

    if (json.createdAt) {
      this.createdAt = parseDate(json.createdAt)
    }

    if (json.updatedAt) {
      this.updatedAt = parseDate(json.updatedAt)
    }

    return this
  }

  serialize = (): CodableOrganization => {
    return {
      id: this.id,
      name: this.name,
      slug: this.slug,
      employees: this.employees,
      industry: this.industry,
      externalId: this.externalId,
      membership: toJS(this.membership),
      pictureUrl: this.pictureUrl,
      analytics: toJS(this.analytics),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }
}

export const isOrganizationModel = (a: any): a is OrganizationModel => {
  return a instanceof OrganizationModel
}

export default OrganizationModel
