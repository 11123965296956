import { useContext } from 'react'

import BillingSubscriptionContext from './BillingSubscriptionContext'

export default function useBillingSubscriptionContext() {
  const context = useContext(BillingSubscriptionContext)

  if (!context) {
    throw new Error(
      'useBillingSubscriptionContext() called outside of a BillingSubscriptionProvider',
    )
  }

  return context
}
