import {
  type ElectronClient,
  type UpdateEvent,
  type UpdateInfo,
} from '@openphone/desktop-client'
import { compare } from 'compare-versions'
import Debug from 'debug'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { nanoid } from 'nanoid'
import { Subject } from 'rxjs'

import { DisposeBag } from '@src/lib/dispose'

import type UpdateController from './UpdateController'

export default class ElectronUpdateController implements UpdateController {
  readonly id = nanoid()
  readonly debug = Debug(`op:updater:ElectronUpdateController:${this.id}`)
  readonly updateEvent$ = new Subject<UpdateEvent>()
  protected updateInfo: UpdateInfo | null = null

  /**
   * The percentage of the update download that has been completed.
   *
   * From 0 to 100, or null if no download is in progress.
   */
  downloadPercentage: number | null = null

  /**
   * Whether or not the update download has completed.
   */
  protected downloadComplete = false

  protected readonly disposeBag = new DisposeBag()

  constructor(readonly client: ElectronClient) {
    makeObservable<this, 'downloadComplete' | 'handleAppUpdateEvent'>(this, {
      downloadComplete: observable.ref,
      downloadPercentage: observable.ref,
      updateReady: computed,
      updateDownloading: computed,
      isUpdateAvailable: action,
      installAndRestart: action,
      handleAppUpdateEvent: action.bound,
    })

    this.client.on('app-update', this.handleAppUpdateEvent)

    this.disposeBag.add(() => {
      this.client.off('app-update', this.handleAppUpdateEvent)
    })
  }

  get updateReady(): boolean {
    return this.downloadComplete
  }

  get updateDownloading(): boolean {
    return (
      typeof this.downloadPercentage === 'number' &&
      this.downloadPercentage >= 0 &&
      this.downloadPercentage < 100
    )
  }

  protected get currentVersion(): string {
    return this.client.version
  }

  async isUpdateAvailable(): Promise<boolean> {
    try {
      if (this.updateReady) {
        return true
      }

      this.debug('checking for update...')

      const updateInfo = await this.client.updater.checkForUpdate?.()
      const isNewVersion = updateInfo
        ? compare(updateInfo.version, this.currentVersion, '>')
        : false

      if (!isNewVersion) {
        this.debug('no update available')
        return false
      }

      this.debug('update available: %O', updateInfo)
      this.debug('update available, downloading...')

      runInAction(() => {
        this.downloadPercentage = 0
      })

      this.client.updater.download?.()
      return true
    } catch (error) {
      this.debug('error checking for update: %O', error)
      return false
    }
  }

  installAndRestart(): void {
    if (this.updateReady) {
      this.debug('installing update...')
      this.downloadComplete = false
      this.downloadPercentage = null
      this.client.updater.installUpdatesAndRestart?.()
    }
  }

  protected handleAppUpdateEvent(event: UpdateEvent) {
    this.debug(`received 'app-update' event (type: %O, event: %O)`, event.type, event)

    this.updateEvent$.next(event)

    switch (event.type) {
      case 'downloading': {
        this.downloadPercentage = event.percent
        return
      }
      case 'downloaded': {
        this.downloadComplete = true
        this.downloadPercentage = null
        return
      }
      case 'error': {
        console.error(
          'An error occurred while attempting to update the desktop app. Error:',
          event.error,
        )
        this.downloadComplete = false
        this.downloadPercentage = null
        return
      }
    }
  }

  dispose(): void {
    this.disposeBag.dispose()
  }
}
