export default function omitNullish<T extends Record<string, unknown>>(object: T) {
  const clone = { ...object }

  for (const [key, value] of Object.entries(clone)) {
    if (value === undefined || value === null) {
      delete clone[key]
    }
  }

  return clone
}
