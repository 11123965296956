import useTransition from '@src/lib/hooks/useTransition'
import { LoadingIndicator } from '@ui/LoadingIndicator'

import * as styles from './SpinnerScreen.css'

export default function SpinnerScreen() {
  const state = useTransition(true, { enterDelay: 1000 })
  const hidden = state !== 'entered'

  return (
    <div className={styles.root}>
      {hidden ? null : <LoadingIndicator variant="circular" size={40} color="primary" />}
    </div>
  )
}
