import { computed, makeObservable } from 'mobx'

import { toE164 } from '@src/lib/phone-number'
import type Service from '@src/service'

import BaseAlertModel from './BaseAlertModel'

class PortRequestCompletedAlertModel extends BaseAlertModel<
  'port-request-completed',
  { phoneNumber: string; phoneNumberName: string; userId: string }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      phoneNumber: computed,
      phoneNumberName: computed,
    })
  }

  get associatedObjectsLoaded() {
    return Boolean(this.phoneNumber)
  }

  get phoneNumber() {
    return this.service.phoneNumber.collection.list.find((phoneNumber) => {
      return phoneNumber.number === this.data.phoneNumber
    })
  }

  get phoneNumberName() {
    return `${this.data.phoneNumberName} ${toE164(this.data.phoneNumber)}`
  }
}

export default PortRequestCompletedAlertModel
