import type { ListState } from '@react-stately/list'
import type { FocusableElement } from '@react-types/shared'
import { createContext } from 'react'
import type { Key, RefObject, DOMAttributes, Dispatch, SetStateAction } from 'react'

import type { Sensor } from '@ui/SensorProvider'

import type { FocusTargetProps } from './useFocusTarget'
import type { InputProps } from './useListFilter'

export interface ComboboxContextProps {
  /**
   * Reference to the list element of the combobox
   */
  comboboxListRef: RefObject<HTMLUListElement>

  /**
   * Reference to the input element of the combobox
   */
  comboboxInputRef: RefObject<HTMLInputElement>

  /**
   * Reference to the focus target element of comboboxes that aren't
   * inline and filterable and don't have an input to auto-focus on
   */
  comboboxFocusTargetRef: RefObject<HTMLDivElement>

  /**
   * Data state of the combobox: disabled items, actionable items or the selection manager.
   */
  state: ListState<object>

  /**
   * Which sensor is currently being used.
   */
  sensor: Sensor

  /**
   * Disable list items based on their disabled prop.
   */
  setDisabledKey: (key: Key) => void

  /**
   * Attributes passed to the list element
   */
  listBoxProps: DOMAttributes<FocusableElement>

  /**
   * Attributes passed to the input element
   */
  inputProps: InputProps

  /**
   * Attributes passed to the focus target element
   */
  focusTargetProps: FocusTargetProps

  /**
   * If the menuRef element is visible or not.
   */
  isVisible: boolean

  /**
   * Used to set the visibility status of the menuRef element.
   */
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

const ComboboxListContext = createContext<ComboboxContextProps | null>(null)

export default ComboboxListContext
