import { useLayoutEffect } from 'react'

export default function useTitle(title: string | undefined, badge = 0) {
  useLayoutEffect(() => {
    const badgeString = badge > 0 ? `(${badge}) ` : ''

    document.title =
      title === undefined && badgeString === ''
        ? `OpenPhone`
        : `${badgeString}${title} | OpenPhone`
  }, [title, badge])
}
