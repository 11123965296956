/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */

import { makeAutoObservable, remove } from 'mobx'

import type ById from '@src/lib/ById'
import { assertIsNotUndefined } from '@src/lib/isNonNull'
import PersistedCollection from '@src/service/collections/PersistedCollection'

import type Service from '.'
import type { Integration, LegacyIntegration } from './model'
import {
  buffer,
  EmailIntegrationModel,
  HubspotIntegrationModel,
  IntegrationContact,
  SlackIntegrationModel,
  WebhookIntegrationModel,
} from './model'
import GongIntegrationModel from './model/integration/GongIntegrationModel'
import SalesforceIntegrationModel from './model/integration/SalesforceIntegrationModel'
import type {
  CreateSlackAuthParams,
  IntegrationCreateParams,
} from './transport/integration'
import type {
  IntegrationContactRepository,
  IntegrationRepository,
} from './worker/repository'

export default class IntegrationStore {
  collection: PersistedCollection<Integration, IntegrationRepository>
  people: PersistedCollection<IntegrationContact, IntegrationContactRepository>

  private byNumber: ById<IntegrationContact[]> = {}
  private contactNumbers: ById<string[]> = {}

  constructor(private service: Service) {
    this.collection = new PersistedCollection({
      table: service.storage.table('integration'),
      classConstructor: (json) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        switch (json.type) {
          case 'slack':
            return new SlackIntegrationModel(service.integration)
          case 'email':
            return new EmailIntegrationModel(service.integration)
          case 'hubspot':
            return new HubspotIntegrationModel(service.integration)
          case 'salesforce':
            return new SalesforceIntegrationModel(service)
          case 'gong':
            return new GongIntegrationModel(service.integration)
          case 'webhook':
            return new WebhookIntegrationModel()
          default:
            return null
        }
      },
    })
    this.people = new PersistedCollection({
      table: service.storage.table('integrationContact'),
      classConstructor: () => new IntegrationContact(),
    })
    makeAutoObservable<this, 'contactNumbers' | 'phoneNumbersToLoad' | 'lastLoadedAt'>(
      this,
      {
        contactNumbers: false,
        phoneNumbersToLoad: false,
        lastLoadedAt: false,
      },
    )

    this.handleIndexByPhoneNumber()

    this.load()
  }

  loadByNumber = buffer(
    (phoneNumbers: string[]) => {
      this.service.transport.integration.person
        .get(
          phoneNumbers,
          this.collection.list.map((i) => i.id),
        )
        .then(this.people.load)
    },
    { duration: 1000, maxSize: 100 },
  )

  load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  fetchAll() {
    return this.service.transport.integration
      .getAll()
      .then((res) => this.collection.load(res, { deleteOthers: true }))
  }

  getApplication(clientId: string, redirectUrl: string) {
    return this.service.transport.account.application.get(clientId, redirectUrl)
  }

  getPeople(phoneNumber: string): IntegrationContact[] {
    return this.byNumber[phoneNumber] ?? []
  }

  fetchForPhoneNumber(phoneNumberId: string) {
    this.collection.performQuery((repo) => repo.all())
    return this.service.transport.integration.legacy
      .get(phoneNumberId)
      .then(this.collection.load)
      .then((objs) =>
        /**
         * delete other locally saved integrations for this phone number that
         * are not returned from the API
         */
        this.collection.deleteQuery((repo) =>
          repo.othersForPhoneNumber(
            objs.map((o) => o.id),
            phoneNumberId,
          ),
        ),
      )
  }

  createHubspot(params: IntegrationCreateParams) {
    return this.service.transport.integration
      .createHubspot(params)
      .then(this.collection.load)
  }

  createSalesforce(params: IntegrationCreateParams) {
    return this.service.transport.integration
      .createSalesforce(params)
      .then(this.collection.load)
  }

  createGong(params: IntegrationCreateParams) {
    return this.service.transport.integration
      .createGong(params)
      .then(this.collection.load)
  }

  createSlack(params: CreateSlackAuthParams) {
    return this.service.transport.integration.legacy
      .createSlack(params)
      .then(this.collection.load)
  }

  createEmail(integration: Partial<LegacyIntegration>) {
    return this.service.transport.integration.legacy
      .createEmail(integration)
      .then(this.collection.load)
  }

  update(integration: Integration) {
    this.collection.put(integration)
    return this.service.transport.integration
      .update(integration.serialize())
      .then(this.collection.load)
  }

  updateLegacy(integration: LegacyIntegration) {
    this.collection.put(integration)
    return this.service.transport.integration.legacy
      .update(integration.serialize())
      .then(this.collection.load)
  }

  delete(integration: Integration) {
    this.collection.delete(integration)
    return this.service.transport.integration.delete(integration.id)
  }

  deleteLegacy(integration: LegacyIntegration) {
    this.collection.delete(integration)
    return this.service.transport.integration.legacy.delete(integration.serialize())
  }

  getStatus(integration: Integration) {
    return this.service.transport.integration.status(integration.id)
  }

  /**
   * As contacts are added/updated/deleted from the collection, this
   * function keeps map of phone numbers to contacts for fast lookup
   */
  private handleIndexByPhoneNumber() {
    this.people.observe((event) => {
      if (event.type === 'put') {
        event.objects.forEach((person) => {
          if (!this.contactNumbers[person.id]) {
            this.contactNumbers[person.id] = []
          }
          const numbers = this.contactNumbers[person.id]
          // assertIsNotUndefined(numbers)
          numbers.forEach((number) => {
            this.byNumber[number] = this.byNumber[number]?.filter((c) => c !== person)
          })

          if (!person.phoneNumbers) {
            return
          }

          person.phoneNumbers.forEach((number) => {
            if (!this.byNumber[number]) {
              this.byNumber[number] = []
            }
            this.byNumber[number].push(person)
          })
          this.contactNumbers[person.id] = person.phoneNumbers.map((i) => i)
        })
      } else if (event.type == 'delete') {
        event.objects.forEach((object) => {
          const numbers = this.contactNumbers[object.id]
          numbers?.forEach((number) => {
            this.byNumber[number] = this.byNumber[number]?.filter(
              (c) => c.id !== object.id,
            )
          })
          remove(this.contactNumbers, object.id)
        })
      }
    })
  }
}
