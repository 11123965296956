import { random } from 'lodash'
import type { Observable } from 'rxjs'

type Options = {
  priority: 'high' | 'low'
  highMinMax: [number, number]
  lowMinMax: [number, number]
}

/**
 * Subscribes to the given observable and executes the given callback after
 * a random time span defined by the priority given or the defined min max
 * delay specified when an event gets emitted.
 *
 * The highMinMax and lowMinMax options represent a tuple of seconds used
 * to delay the execution of the callback. The tuple used is defined by the
 * priority set, which by default is `high`.
 *
 * @returns A tuple of disposers, the first is the subcription disposer and
 * the second is the abort controller disposer to avoid executing the callback
 * after the delay passes.
 *
 * @example
 * Given that a tuple is returned, you can spread the result of the function
 * in the dispose bag's add params.
 * ```ts
 *   this.disposeBag.add(...delayObservable(observable, () => thing(), { priority: 'low', lowMinMax: [40, 70]}))
 */
export default function delayObservable(
  observable: Observable<unknown>,
  callback: () => void,
  options?: Partial<Options> | (() => Partial<Options>),
) {
  const getMinMaxDelay = () => {
    const { priority, highMinMax, lowMinMax } = {
      priority: 'high',
      highMinMax: [0, 5],
      lowMinMax: [5, 15],
      ...(typeof options === 'function' ? options() : options),
    } satisfies typeof options

    return (priority === 'high' ? random(...highMinMax) : random(...lowMinMax)) * 1_000
  }

  const controllerRef = { current: new AbortController() }

  let delay: Promise<void> | null = null

  const subscription = observable.subscribe(async () => {
    delay = new Promise((resolve) => setTimeout(resolve, getMinMaxDelay()))
    await delay

    if (controllerRef.current.signal.aborted) {
      // reset the controller so we can re abort
      controllerRef.current = new AbortController()
      // stop execution because current signal was aborted
      return
    }

    callback()
  })

  const abort = () => {
    if (!delay) {
      return
    }

    controllerRef.current.abort()
    delay = null
  }

  return [subscription, abort] as const
}
