import config from '@src/config'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export interface PromptSettings {
  id: string
  version: number
  name: string
  template: {
    user: string
    options: string[]
  }
  aiProviderId: string
  orgId: string
  phoneNumberId: string
  useCase: string
  isActive: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export type PromptSettingsPayload = Pick<
  PromptSettings,
  'name' | 'template' | 'orgId' | 'phoneNumberId'
>

export default class AiClient {
  constructor(private transport: Transport) {}
  prompt = {
    toggle: (promptId: string, isActive: boolean): Promise<PromptSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}config/prompt/${promptId}/toggle`,
          method: 'PUT',
          body: {
            isActive,
          },
        }),
      )
    },

    get: (phoneNumberId: string): Promise<PromptSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}config/prompt/phone-number/${phoneNumberId}`,
          method: 'GET',
        }),
      )
    },

    create: (promptSettings: PromptSettingsPayload): Promise<PromptSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}config/prompt`,
          method: 'POST',
          body: promptSettings,
        }),
      )
    },

    update: (
      promptId: string,
      template: Pick<PromptSettingsPayload, 'template'>,
    ): Promise<PromptSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.AI_SERVICE_URL}config/prompt/${promptId}`,
          method: 'PUT',
          body: template,
        }),
      )
    },
  }
}
