import illustrationBase from './illustration-email.png'
import illustration2x from './illustration-email@2x.png'
import illustration3x from './illustration-email@3x.png'

export default {
  light: {
    base: illustrationBase,
    x2: illustration2x,
    x3: illustration3x,
  },
  dark: {
    base: illustrationBase,
    x2: illustration2x,
    x3: illustration3x,
  },
}
