import { useEffect, useMemo } from 'react'

import type IUiStore from '@src/app/IUiStore'

export default function useStore<T extends IUiStore>(
  initializer: () => T,
  deps: any[],
): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  const store = useMemo(initializer, deps)
  useEffect(() => {
    return () => store.tearDown?.()
  }, [store])

  return store
}
