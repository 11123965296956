import dayjs from 'dayjs'

import type { FilterType as CallsViewFilterType } from '@src/app/inbox/calls/filters/CallsFiltersController'
import type { InboxFilterType } from '@src/app/inbox/conversations/InboxFilters'
import type { InboxState } from '@src/app/inbox/conversations/InboxUiStore'
import { MONTH_LONG_DATE_SHORT_YEAR_TIME_FORMAT } from '@src/lib'

import type Heap from './Heap'

export default class InboxAnalyticsStore {
  constructor(private readonly heap: Heap) {}

  /**
   * Tracks when a user has sent a message
   *
   * Corresponds with Web - Sent - Message in Heap
   */
  messageSent() {
    window.dataLayer.push({
      event: 'message_sent',
    })

    this.heap.track('message_sent')
  }

  /**
   * Corresponds with Web - Hovered - Schedule Message in Heap
   */
  scheduleMessageButtonHovered() {
    this.heap.track('schedule_message_button_hovered')
  }

  /**
   * Corresponds with Web - Action - Scheduled Message in Heap
   */
  messageScheduled(action: 'click' | 'submit') {
    this.heap.track('message_scheduled', { action })
  }

  /**
   * Corresponds with Web - Received - Message in Heap
   */
  messageReceived() {
    this.heap.track('message_received')
  }

  /**
   * Corresponds with Global - Filtered - Inbox in Heap
   */
  inboxFiltered(filter: InboxState): void
  inboxFiltered(filter: InboxFilterType, totalFilters: number): void
  inboxFiltered(filter: string, totalFilters?: number): void {
    this.heap.track('inbox_filtered', { filter, total_filters: totalFilters })
  }

  /**
   * Corresponds with TBD - Cleared - Inbox in Heap
   */
  inboxFilterCleared(filter: InboxState | InboxFilterType, totalFilters: number) {
    this.heap.track('inbox_filter_cleared', { filter, total_filters: totalFilters })
  }

  /**
   * Corresponds with Web - Collapsed - Inbox Filters in Heap
   */
  inboxFiltersCollapsed(totalFilters: number) {
    this.heap.track('inbox_filters_collapsed', { total_filters: totalFilters })
  }

  /**
   * Corresponds with Web - Expanded - Inbox Filters in Heap
   */
  inboxFiltersExpanded(totalFilters: number) {
    this.heap.track('inbox_filters_expanded', { total_filters: totalFilters })
  }

  /**
   * Corresponds with Global - Pinned - Conversation in Heap
   */
  conversationPinned(pinCount: number, conversationId: string, inboxId: string | null) {
    this.heap.track('conversation_pinned', { pinCount, conversationId, inboxId })
  }

  /**
   * Corresponds with Global - Unpinned - Conversation in Heap
   */
  conversationUnpinned(pinCount: number, conversationId: string, inboxId: string | null) {
    this.heap.track('conversation_unpinned', { pinCount, conversationId, inboxId })
  }

  /**
   * Corresponds with Web - Renamed - Conversation in Heap
   */
  conversationRenamed() {
    this.heap.track('conversation_renamed')
  }

  /**
   * Corresponds with Web - Started - Conversation Renaming in Heap
   */
  conversationRenameInitiated(trigger: 'header_button' | 'action' | 'double_click_name') {
    this.heap.track('conversation_rename_initiated', { trigger })
  }

  /**
   * Based on the action:
   * `clicked` Corresponds with Web - Opened - AI Message Response
   * `selected` Corresponds with Web - Selected - AI Message Response
   */
  aiMessageResponseActioned(action: 'hovered' | 'clicked' | 'submitted' | 'selected') {
    this.heap.track('AI_message_response_actioned', { action })
  }

  /**
   * Corresponds with Web - Played - Audio
   */
  audioPlayed(type: 'voicemail' | 'recording', phoneNumberId: string): void {
    const timestamp = this.getTimestamp()
    this.heap.track('audio_played', {
      type,
      timestamp,
      phoneNumberId,
    })
  }

  /**
   * TODO: Create event in Heap
   */
  audioStopped(type: 'voicemail' | 'recording', phoneNumberId: string): void {
    const timestamp = this.getTimestamp()
    this.heap.track('audio_stopped', {
      type,
      timestamp,
      phoneNumberId,
    })
  }

  /**
   * TODO: Create event in Heap
   */
  audioDownloaded(type: 'voicemail' | 'recording', phoneNumberId: string): void {
    const timestamp = this.getTimestamp()
    this.heap.track('audio_downloaded', {
      type,
      timestamp,
      phoneNumberId,
    })
  }

  /**
   * TODO: Create event in Heap
   */
  callTranscriptOpened(): void {
    const timestamp = this.getTimestamp()
    this.heap.track('call_transcript_opened', {
      timestamp,
    })
  }

  /**
   * TODO: Create event in Heap
   */
  callAiSummaryExpanded(): void {
    const timestamp = this.getTimestamp()
    this.heap.track('call_ai_summary_expanded', {
      timestamp,
    })
  }

  /**
   * TODO: Create event in Heap
   */
  callAiSummaryCollapsed(): void {
    const timestamp = this.getTimestamp()
    this.heap.track('call_ai_summary_collapsed', {
      timestamp,
    })
  }

  private getTimestamp() {
    return dayjs(Date.now()).format(MONTH_LONG_DATE_SHORT_YEAR_TIME_FORMAT)
  }

  /**
   * Tracks when a user has downloaded an image
   */
  imageDownloaded() {
    // Corresponds with Web - Downloaded - Image
    this.heap.track('image_downloaded')
  }

  /**
   * Tracks when a user creates a new snippet
   */
  snippetCreated() {
    this.heap.track('snippet_created')
  }

  /**
   * Tracks when a user uses a new snippet
   */
  snippetUsed() {
    this.heap.track('snippet_used')
  }

  /**
   * Tracks when a direct message is sent
   */
  directMessageSent() {
    this.heap.track('direct_message_sent')
  }

  /**
   *
   * Tracks when a internal thread message is sent
   * @param phoneNumberId
   */
  internalThreadMessageSent(phoneNumberId: string) {
    this.heap.track('internal_thread_message_sent', { phoneNumberId })
  }

  /**
   * Tracks when the individual viewer avatars in a conversation are hovered
   * @param viewersCount
   */
  viewersHovered(viewersCount: number) {
    this.heap.track('viewers_hovered', { viewersCount })
  }

  /**
   * Tracks when the list of viewers in a conversation are hovered
   * @param viewersCount
   */
  viewersListHovered(viewersCount: number) {
    this.heap.track('viewers_list_hovered', { viewersCount })
  }

  /**
   * Tracks when a user views a conversation
   * @param viewersCount
   */
  conversationViewed(viewersCount: number) {
    this.heap.track('conversation_viewed', { viewersCount })
  }

  /**
   * Tracks when a user applies a filter in calls view
   * @param filter Filter applied from the set of filters that can be applied
   * @param count Total number of filters applied
   */
  callViewsFiltered(filter: CallsViewFilterType, count: number) {
    this.heap.track('call_views_filtered', { filter, filter_count: count })
  }

  /**
   * Tracks when a user removes a filter in calls view
   * @param filter Filter removed from the set of filters that are applied
   * @param count Total number of filters applied
   */
  callViewsCleared(filter: CallsViewFilterType, count: number) {
    this.heap.track('call_views_cleared', { filter, filter_count: count })
  }

  /**
   * Tracks when a user clicks on a row from the calls list
   */
  callViewsCallRowClicked() {
    this.heap.track('call_views_clicked_conversation')
  }

  /**
   * Tracks when a user clicks on the button to play a recording from a row
   */
  callViewsRecordingPlayed() {
    this.heap.track('call_views_played_recording')
  }
}
