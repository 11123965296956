import type { ReactNode } from 'react'

import Typography from '@ui/Typography'

import * as styles from './MenuListSectionLabel.css'

interface MenuListSectionLabelProps {
  role?: string
  children: ReactNode
}

const MenuListSectionLabel = ({ role, children }: MenuListSectionLabelProps) => {
  return (
    <Typography
      component="span"
      variant="caption1"
      color="textTertiary"
      className={styles.heading}
      role={role}
    >
      {children}
    </Typography>
  )
}

export default MenuListSectionLabel
