import type { LDFlagSet } from 'launchdarkly-js-client-sdk'

import type { Flags } from './FlagsTypes'

export default function launchDarklyAdapter(ldFlags: LDFlagSet): Flags {
  const flags: Flags = {}

  for (const [key, value] of Object.entries(ldFlags)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    flags[key] = value
  }

  return flags
}
