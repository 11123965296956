import { action, computed, makeObservable, observable } from 'mobx'
import { z } from 'zod'

import type IUiStore from '@src/app/IUiStore'
import log from '@src/lib/log'
import type StorageService from '@src/service/storage/StorageService'
import makePersistable from '@src/service/storage/makePersistable'

interface LegacyBannerConfig {
  banner: {
    'co-to-com': boolean
    notifications: boolean
  }
}

export type BannerKey = 'co-to-com' | 'notifications' | 'toll-free-registration'

interface BannerConfig {
  isDismissed: boolean
}

const LEGACY_USER_CONFIG_KEY = 'userconfig'

const defaultConfig: Record<BannerKey, BannerConfig> = {
  'co-to-com': { isDismissed: false },
  notifications: { isDismissed: false },
  'toll-free-registration': { isDismissed: false },
}

export default class BannerUiStore implements IUiStore {
  private openBannerCount = 0

  config = defaultConfig

  get hasBannersOpen() {
    return this.openBannerCount > 0
  }

  constructor(storage: StorageService) {
    makeObservable<this, 'openBannerCount'>(this, {
      openBannerCount: observable,
      config: observable.deep,
      hasBannersOpen: computed,
      mergeDefaultWithPersistedConfig: action.bound,
      migrateLegacyUserConfig: action.bound,
      incrementOpenBannerCount: action.bound,
      decrementOpenBannerCount: action.bound,
      dismiss: action.bound,
    })
    makePersistable(this, 'BannerUiStore', {
      config: storage.sync(),
    })

    this.migrateLegacyUserConfig()
    this.mergeDefaultWithPersistedConfig()
  }

  mergeDefaultWithPersistedConfig() {
    const keys = Object.keys(defaultConfig) as (keyof typeof defaultConfig)[]
    for (const property of keys) {
      if (!this.config[property]) {
        this.config[property] = defaultConfig[property]
      }
    }
  }

  // This is just for backwards compatibility
  migrateLegacyUserConfig() {
    const rawUnparsedLegacyConfig = localStorage.getItem(LEGACY_USER_CONFIG_KEY)

    if (rawUnparsedLegacyConfig === null) {
      return
    }

    let safeParsedLegacyConfig: LegacyBannerConfig | null = null

    try {
      const rawParsedLegacyConfig = JSON.parse(rawUnparsedLegacyConfig) as unknown
      const safeParsedLegacyConfigSchema = z.object({
        banner: z.object({
          'co-to-com': z.boolean(),
          notifications: z.boolean(),
          referral: z.boolean(),
          lightMode: z.boolean(),
        }),
      })
      safeParsedLegacyConfig = safeParsedLegacyConfigSchema.parse(rawParsedLegacyConfig)
    } catch {
      localStorage.removeItem(LEGACY_USER_CONFIG_KEY)
      log.error(`Unable to parse legacy user config: "${rawUnparsedLegacyConfig}"`)
    }

    if (safeParsedLegacyConfig) {
      // the legacy config value was the display status
      // so we have to translate that value to a dismissed status
      // which is the negation of the legacy value
      this.config['co-to-com'].isDismissed = !safeParsedLegacyConfig.banner['co-to-com']
      this.config.notifications.isDismissed = !safeParsedLegacyConfig.banner.notifications
      localStorage.removeItem(LEGACY_USER_CONFIG_KEY)
    }
  }

  incrementOpenBannerCount() {
    this.openBannerCount++
  }

  decrementOpenBannerCount() {
    this.openBannerCount--
  }

  dismiss(key: BannerKey) {
    this.config[key].isDismissed = true
  }

  tearDown(): void {
    // noop
  }
}
