import { useContext } from 'react'

import ComboboxListContext from './ComboboxListContext'

export default function useComboboxList() {
  const context = useContext(ComboboxListContext)

  if (!context) {
    throw new Error('useComboboxList has to be used within a <Combobox /> component')
  }

  return context
}
