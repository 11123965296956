import { useMenuTriggerState } from '@react-stately/menu'
import type { MutableRefObject, ReactNode } from 'react'
import { useCallback, useRef } from 'react'
import { useMenuTrigger } from 'react-aria'

import { MenuTriggerContext } from './context'

export interface MenuProviderProps {
  /**
   * The children of the menu provider. The common children are the `Menu.Trigger` and the `Menu.List`
   */
  children: ReactNode

  /**
   * Whether the Menu is opened or not, this makes the component controlled.
   *
   * If the `isInlined` property from the `Menu.List` component is true, this property won't have any effect.
   */
  isOpen?: boolean

  /**
   * Whether the Menu is opened by default, does not make the component controlled, it's still uncontrolled, although as soon as it's rendered it will be display opened. Once we click somewhere else or scroll the menu will get closed.
   *
   * If the `isInlined` property from the `Menu.List` component is true, this property won't have any effect.
   */
  defaultOpen?: boolean

  /**
   * The element that the Menu will use as reference to position itself.
   * This prop is used along with `isOpen` to make the `Menu` a controlled component.
   */
  targetRef?: MutableRefObject<HTMLElement | null>

  /**
   * A callback function that will be called once the menu gets opened.
   */
  onOpen?: () => void

  /**
   * A callback function that will be called once the menu gets closed.
   */
  onClose?: () => void
}

const MenuProvider = ({
  children,
  isOpen,
  defaultOpen,
  targetRef: controlledRef,
  onClose,
  onOpen,
}: MenuProviderProps) => {
  const state = useMenuTriggerState({
    isOpen,
    defaultOpen,
    onOpenChange: (isOpen) => {
      if (onOpen && isOpen) {
        onOpen()
      }

      if (onClose && !isOpen) {
        onClose()
      }
    },
  })

  const uncontrolledRef = useRef<HTMLElement | null>(null)

  const ref = controlledRef || uncontrolledRef

  const setNodeRef = useCallback((element: HTMLElement | null) => {
    uncontrolledRef.current = element
  }, [])

  const { menuTriggerProps, menuProps } = useMenuTrigger({ type: 'menu' }, state, ref)

  return (
    <MenuTriggerContext.Provider
      value={{ ref, setNodeRef, state, menuTriggerProps, menuProps }}
    >
      {children}
    </MenuTriggerContext.Provider>
  )
}

export default MenuProvider
