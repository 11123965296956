import { assignInlineVars } from '@vanilla-extract/dynamic'
import type { ElementType } from 'react'

import { isMacDesktop } from '@src/lib/device'
import type { TypographyProps } from '@ui/Typography'
import Typography from '@ui/Typography'

import * as styles from './Key.css'

export interface KeyProps {
  /**
   * The text to display inside the Key
   */
  label: string

  /**
   * The typography variant for the label
   *
   * @default 'caption3'
   */
  variant?: TypographyProps<ElementType>['variant']
}

const glyphs = ['⌘', '⏎', '⌥', '⌃', '⇧']

const Key = ({ label: labelProp, variant = 'caption3' }: KeyProps) => {
  const size = (() => {
    switch (variant) {
      case 'largeTitle': {
        return 40
      }
      case 'signature':
      case 'title1': {
        return 32
      }
      case 'title2': {
        return 28
      }
      case 'title3':
      case 'headline': {
        return 24
      }
      case 'emoji': {
        return 22
      }
      case 'subheadline':
      case 'body': {
        return 20
      }
      case 'callout': {
        return 18
      }
      case 'footnote':
      case 'caption1':
      case 'caption2':
      case 'caption3':
      case 'code':
      default: {
        return 16
      }
    }
  })()

  const label = (() => {
    const isMac = isMacDesktop()

    if (labelProp === '⌘') {
      return isMac ? '⌘' : 'ctrl'
    }

    if (labelProp === '⌥') {
      return isMac ? '⌥' : 'alt'
    }

    if (labelProp === '⌃') {
      // FIXME: we should remove '⌃' support
      // https://linear.app/openphone/issue/WEB-205/remove-support-for-shortcuts-that-use-ctrl-and-alt-and-only-support
      return isMac ? '⌃' : 'ctrl'
    }

    return labelProp
  })()

  return (
    <span
      className={styles.root({ isSingleCharacter: label.length === 1 })}
      style={assignInlineVars({ [styles.sizeVar]: `${size}px` })}
    >
      <Typography
        variant={variant}
        fontWeight={glyphs.includes(label) ? 'semibold' : 'medium'}
        color="textPrimary"
        className={variant === 'code' ? styles.code : undefined}
      >
        {label}
      </Typography>
    </span>
  )
}

export default Key
