import cx from 'classnames'

import DesktopDraggableArea from '@src/app/components/DesktopDraggableArea'

import * as styles from './Header.css'

const Header = function ({
  className,
  children,
}: {
  className?: string
  children?: React.ReactNode
}) {
  return (
    <DesktopDraggableArea className={cx(styles.header, className)}>
      {children}
    </DesktopDraggableArea>
  )
}

export default Header

export const HeaderSpacer = function () {
  return <div className={styles.spacer} />
}
