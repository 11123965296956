import { makeAutoObservable } from 'mobx'

import { parseDate } from '@src/lib'
import { formatted } from '@src/lib/phone-number'
import type BlocklistStore from '@src/service/BlocklistStore'

import type { Model } from '.'

export interface CodableBlocklist {
  createdAt: number
  id: string
  orgId: string
  phoneNumber: string
  updatedAt: number | null
  userId: string
}

export class BlocklistModel implements CodableBlocklist, Model {
  createdAt = 0
  id = ''
  orgId = ''
  phoneNumber = ''
  updatedAt: number | null = null
  userId = ''

  constructor(private blocklistStore: BlocklistStore) {
    makeAutoObservable(this, {})
  }

  get formatted() {
    return formatted(this.phoneNumber)
  }

  delete = () => {
    return this.blocklistStore.delete(this.id)
  }

  serialize = (): CodableBlocklist => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      orgId: this.orgId,
      phoneNumber: this.phoneNumber,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  deserialize = (json: Partial<CodableBlocklist>) => {
    Object.assign(this, json)

    if (json.updatedAt) {
      this.updatedAt = parseDate(json.updatedAt)
    }

    if (json.createdAt) {
      this.createdAt = parseDate(json.createdAt) ?? 0
    }

    return this
  }
}

export default BlocklistModel
