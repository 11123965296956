import type { MultipleSelectionState } from '@react-stately/selection'
import type { Key, KeyboardEvent } from 'react'
import { useCallback, useEffect } from 'react'
import type { AriaButtonProps } from 'react-aria'
import { useHover } from 'react-aria'

import MenuListItem from '@ui/Menu/MenuListItem'
import { useMenuTrigger } from '@ui/Menu/MenuProvider'
import type { MenuTriggerContextProps } from '@ui/Menu/MenuProvider/context/MenuTriggerContext'
import { useSubMenu } from '@ui/Menu/SubMenuProvider'

import { SubMenuTriggerContext } from './context'

export interface SubMenuTriggerContextProps {
  selectionManager: MultipleSelectionState
  ariaProps: AriaButtonProps<'button'>
  subMenuTriggerProps: {
    setNodeRef: MenuTriggerContextProps['setNodeRef']
    onClick: () => void
    onKeyDown: (event: KeyboardEvent) => void
    hoverProps: ReturnType<typeof useHover>['hoverProps']
  }
}

function SubMenuTrigger() {
  const { item, itemProps, parentMenu, selectionManager, menuListRef } = useSubMenu()
  const { subMenuMediator } = parentMenu

  const {
    setNodeRef,
    state: { open, close, isOpen },
    menuTriggerProps,
  } = useMenuTrigger()

  const { hoverProps } = useHover({
    onHoverChange(isHovering) {
      if (isHovering) {
        subMenuMediator.onTriggerHover(open)
      } else {
        subMenuMediator.onTriggerLeave(close)
      }
    },
  })

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ' || e.key === 'ArrowRight') {
        open('first')
      }
    },
    [open],
  )

  const handleClick = useCallback(() => {
    if (!isOpen) {
      subMenuMediator.onTriggerClick(open)
    } else {
      menuListRef.current?.focus()
    }
  }, [subMenuMediator, open, isOpen, menuListRef])

  useEffect(() => {
    if (isOpen) {
      subMenuMediator.emitOpen(item.key)
    }
  }, [isOpen, subMenuMediator, item.key])

  useEffect(() => {
    const unsubscribe = subMenuMediator.onOpen((openedSubMenu: Key) => {
      if (openedSubMenu !== item.key) {
        close()
      }
    })

    return () => {
      unsubscribe()
    }
  }, [subMenuMediator, item.key, close])

  return (
    <SubMenuTriggerContext.Provider
      value={{
        selectionManager,
        ariaProps: {
          id: menuTriggerProps.id,
          'aria-haspopup': menuTriggerProps['aria-haspopup'],
          'aria-expanded': menuTriggerProps['aria-expanded'],
        },
        subMenuTriggerProps: {
          setNodeRef,
          hoverProps,
          onKeyDown: handleKeyDown,
          onClick: handleClick,
        },
      }}
    >
      <MenuListItem item={item} {...itemProps} />
    </SubMenuTriggerContext.Provider>
  )
}

export default SubMenuTrigger
