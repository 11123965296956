/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { runInAction } from 'mobx'

import { getEmojiVariation, stripSkinVariation } from '@src/lib/emoji'
import type BaseReaction from '@src/service/model/reactions/BaseReaction'

import type Service from './index'

export default class EmojiService {
  constructor(private readonly root: Service) {}

  get skinTone() {
    return this.root.user.current?.settings?.preferences.defaultSkinTone
  }

  setSkinTone(value: string | undefined) {
    const settings = this.root.user.current?.settings

    if (!settings) {
      return
    }

    runInAction(() => {
      settings.preferences.defaultSkinTone = value
    })
  }

  getEmojiWithSkinTone(emoji: string): string {
    const variation = this.skinTone

    return variation ? getEmojiVariation(emoji, variation) : emoji
  }

  getReaction<T extends BaseReaction>(
    emoji: string,
    service: EmojiService,
    reactions: T[],
    currentUserId: string,
  ): T | undefined {
    const body = service.getEmojiWithSkinTone(emoji)
    return reactions.find(
      (r) =>
        r.body &&
        stripSkinVariation(r.body) === stripSkinVariation(body) &&
        r.userId === currentUserId,
    )
  }
}
