import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'
import type ContactStore from '@src/service/contact-store'
import type { ContactItemType } from '@src/service/transport/contacts'

import type { ContactModel, Model } from '.'

export interface CodableContactItem {
  createdAt: number | null
  deletedAt: number | null
  id: string
  name: string | null
  templateKey: string | null
  type: ContactItemType | null
  updatedAt: number | null
  value: any
}

export type LocalCodableContactItem = Partial<CodableContactItem & { isNew: boolean }>

export interface ContactItemPhoneNumber extends ContactItemModel {
  type: 'phone-number'
  value: string
}

class ContactItemModel implements CodableContactItem, Model {
  createdAt: number | null = Date.now()
  deletedAt: number | null = null
  id: string = objectId()
  name: string | null = null
  templateKey: string | null = null
  type: ContactItemType | null = null
  updatedAt: number | null = Date.now()
  value: any = null
  isNew?: boolean = false

  constructor(
    private contactStore: ContactStore,
    private contact: ContactModel,
    attrs: LocalCodableContactItem = {},
  ) {
    this.deserialize(attrs)

    makeAutoObservable(this, {})
  }

  get template() {
    return this.contactStore.template.list.find((t) => t.key === this.templateKey)
  }

  get isEmpty() {
    if (!this.value) {
      return true
    }
    if (Array.isArray(this.value) && this.value.length === 0) {
      return true
    }
    return false
  }

  update(attrs: LocalCodableContactItem) {
    this.deserialize(attrs)

    return this.contact.update()
  }

  delete() {
    this.contact.items = this.contact.items.filter((i) => i !== this)
    return this.contact.update()
  }

  deserialize({ deletedAt, createdAt, updatedAt, ...json }: LocalCodableContactItem) {
    if (json) {
      Object.assign(this, json)
    }

    if (deletedAt) {
      this.deletedAt = parseDate(deletedAt)
    }

    if (createdAt) {
      this.createdAt = parseDate(createdAt)
    }

    if (updatedAt) {
      this.updatedAt = parseDate(updatedAt)
    }

    return this
  }

  serialize(): CodableContactItem {
    return {
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      id: this.id,
      name: this.name,
      templateKey: this.templateKey,
      type: this.type,
      updatedAt: this.updatedAt,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      value: toJS(this.value),
    }
  }
}

export default ContactItemModel
