import type { StorageThemeKey } from '@src/theme'

import useMetaTag from './useMetaTag'

/**
 * Maintains a `<meta name="color-scheme">` tag in the document `<head>` based
 * on the app's current theme.
 *
 * The value `dark light` tells the browser that both themes are supported
 * and therefore the system can indicate what it prefers.
 * Similar to what the media query prefers-color-scheme does.
 */
export default function useMetaColorScheme(themeKey: StorageThemeKey) {
  useMetaTag(
    'color-scheme',
    themeKey === 'system' ? 'dark light' : themeKey === 'dark' ? 'dark' : 'light',
  )
}
