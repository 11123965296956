import { makeAutoObservable } from 'mobx'

import type IUiStore from '@src/app/IUiStore'
import assertNever from '@src/lib/assertNever'
import isNonNull from '@src/lib/isNonNull'
import type MemberModel from '@src/service/model/MemberModel'
import type PhoneNumberModel from '@src/service/model/phone-number/PhoneNumberModel'
import type { RoomInvitationType } from '@src/service/transport/voice'
import ActiveCall from '@src/service/voice/ActiveCall'
import type ActiveCallParticipants from '@src/service/voice/ActiveCallParticipants'

export type IncomingCallInvitation = InstanceType<
  typeof IncomingCallUiStore
>['invitation']

export type IncomingCallVia = MemberModel | PhoneNumberModel

export default class IncomingCallUiStore implements IUiStore {
  constructor(
    private readonly participants: ActiveCallParticipants,
    private readonly isListener: boolean = false,
    private readonly invitationType?: RoomInvitationType | null,
    readonly isDirectCall = false,
  ) {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  private get caller() {
    return this.invitationType === 'transferred'
      ? this.participants.transferrer
      : this.participants.from
  }

  private get callerPhoneNumber() {
    return this.caller?.isSuggestedContact ? this.caller.identifier : null
  }

  get phoneNumber() {
    return !this.isDirectCall ? this.participants.toPhoneNumber : null
  }

  get via() {
    return this.participants.from?.via ?? null
  }

  get invitation() {
    ActiveCall.assertInvitationType(this.invitationType)
    const callerString = this.callerPhoneNumber ? `${this.callerPhoneNumber} ` : ''

    switch (this.invitationType) {
      case 'added': {
        const targetString =
          isNonNull(this.participants.toMember) || !this.phoneNumber
            ? 'you'
            : `someone in ${this.phoneNumber.formattedName}`
        const typeString = this.isListener ? 'listen in on' : 'join'
        const count = this.participants.others.length - 1
        const countString =
          count > 0 ? ` with ${count} other ${count > 1 ? 'people' : 'person'}` : ''

        return {
          type: 'added',
          text: `${callerString}is inviting ${targetString} to ${typeString} a conference call ${countString}`,
        } as const
      }
      case 'called': {
        const targetString =
          !this.participants.toMember && this.phoneNumber?.isShared ? '' : 'you'

        return {
          type: 'called',
          text: `${callerString}is calling ${targetString}`,
        } as const
      }
      case 'transferred': {
        const transfereeString = this.participants.from
          ? ` with ${
              this.participants.from.isSuggestedContact
                ? this.participants.from.identifier
                : this.participants.from.name
            }`
          : ''
        const targetString =
          isNonNull(this.participants.toMember) || !this.phoneNumber
            ? 'you'
            : `someone in ${this.phoneNumber.formattedName}`

        return {
          type: 'transferred',
          text: `${callerString}would like to transfer a call${transfereeString} over to ${targetString}`,
        } as const
      }
      default:
        assertNever(
          this.invitationType,
          `Unhandled call invitation type: ${this.invitationType}`,
        )
    }
  }

  tearDown(): void {
    // noop
  }
}
