import { assignInlineVars } from '@vanilla-extract/dynamic'
import { Children, useRef, type ReactElement, cloneElement } from 'react'
import { mergeProps } from 'react-aria'
import { Transition } from 'react-transition-group'

import useTransition from '@src/lib/hooks/useTransition'
import type { PopoverProps } from '@ui/Popover'
import Popover from '@ui/Popover'
import { useLayer } from '@ui/z-index'

import * as styles from './FeatureTooltip.css'
import FeatureTooltipContent from './FeatureTooltipContent'

export type ActionType = 'primary' | 'secondary'

export interface FeatureTooltipAction<Type extends ActionType = ActionType> {
  /**
   * Action button variants
   */
  type: Type

  /**
   * Title for the action button
   */
  title?: string

  /**
   * Called when the action button is triggered.
   */
  onAction?: () => void

  /**
   * Should the onClose action be triggered in addition to the onAction trigger?
   */
  isOnCloseTriggered?: boolean
}

export interface FeatureTooltipProps {
  /**
   * Show the tooltip if true.
   */
  isOpen?: boolean

  /**
   * The title of the tooltip.
   */
  title: string

  /**
   * The description of the tooltip.
   */
  description: React.ReactNode

  /**
   * The footer of the tooltip. It's rendered to the left of the action button(s).
   */
  footer?: string

  /**
   * Called when the tooltip is closed by the user.
   */
  onClose?: () => void

  /**
   * Children to attach the Tooltip to.
   *
   * Should be a single element, and should be able to forward refs down to an HTML element.
   */
  children?: React.ReactChild

  /**
   * The placement of the element with respect to its anchor element.
   *
   * @default 'bottom'
   */
  placement?: PopoverProps['placement']

  /**
   * The additional offset applied along the main axis between the element and its
   * anchor element.
   *
   * @default 24
   */
  offset?: PopoverProps['offset']

  /**
   * Automatically focus the tooltip when it is opened.
   *
   * @default true
   */
  autoFocus?: boolean

  /**
   * Open delay in milliseconds.
   *
   * @default 0
   */
  openDelay?: number

  /**
   * Close delay in milliseconds.
   *
   * @default 0
   */
  closeDelay?: number

  /**
   * Variants to add one or two actions buttons
   */
  actions?:
    | [FeatureTooltipAction<'primary'>]
    | [FeatureTooltipAction<'secondary'>, FeatureTooltipAction<'primary'>]

  /**
   * Whether the overlay should close when the user scrolls the page.
   *
   * @default true
   */
  shouldCloseOnScroll?: boolean

  /**
   * Provides a way to customise the way the overlay is closed when the user interacts outside of it.
   * By default, onClose will always be called on an interaction with a link or an element with the `link` role.
   */
  shouldCloseOnInteractOutside?: false | ((element: Element) => boolean)

  /**
   * If `true`, hides the arrow pointing to the children component
   *
   * @default false
   */
  hideArrow?: boolean
}

const animationDuration = 100

export const FeatureTooltip = ({
  isOpen,
  children,
  placement,
  autoFocus = true,
  openDelay,
  closeDelay,
  offset = 24,
  shouldCloseOnScroll = true,
  shouldCloseOnInteractOutside,
  hideArrow = false,
  ...props
}: FeatureTooltipProps) => {
  const state = useTransition(isOpen ?? false, {
    enterDelay: openDelay,
    exitDelay: closeDelay,
    enterDuration: 0,
    exitDuration: 0,
  })

  const layer = useLayer()
  const zIndex = layer.zIndex + 1

  const popoverRef = useRef<HTMLDivElement>(null)
  const targetRef = useRef<HTMLElement>(null)
  let targetElement: ReactElement | null = null

  if (children) {
    const child = Children.only(children)

    if (isReactText(child)) {
      return (
        <span
          ref={targetRef}
          tabIndex={0}
          onClick={props.onClose}
          onFocus={() => popoverRef.current?.focus()}
        >
          {child}
        </span>
      )
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      targetElement = cloneElement(child, {
        ref: targetRef,
        ...mergeProps(child.props, {
          onClick: props.onClose,
          onFocus: () => popoverRef.current?.focus(),
        }),
      })
    }
  }

  if (targetElement) {
    return (
      <>
        {targetElement}

        <Transition
          in={state === 'entered'}
          timeout={{
            enter: animationDuration,
            exit: animationDuration,
          }}
          unmountOnExit={true}
        >
          {(status) => (
            <Popover
              ref={popoverRef}
              targetRef={targetRef}
              offset={offset}
              onClose={props.onClose}
              shouldCloseOnScroll={shouldCloseOnScroll}
              placement={placement}
              zIndex={zIndex}
              arrow={
                hideArrow ? null : (
                  <div className={styles.arrow}>
                    <div className={styles.arrowLine} />
                    <div className={styles.arrowDot} />
                  </div>
                )
              }
              autoFocus={autoFocus}
              isDismissable={true}
              withUnderlay={false}
              shouldCloseOnInteractOutside={
                shouldCloseOnInteractOutside === false
                  ? () => false
                  : shouldCloseOnInteractOutside ??
                    ((target) => {
                      let element: HTMLElement | null = target as HTMLElement

                      // Since there's no reliable way to determine if the user is navigating away
                      // from the current screen, we'll assume that they are if they click a link outside
                      // of the tooltip.
                      while (element) {
                        // Close when the user clicks on a link
                        if (element.tagName === 'A') {
                          return true
                        }

                        // Close when the user clicks on an element with the `link` role
                        if (element.getAttribute('role') === 'link') {
                          return true
                        }

                        element = element.parentElement
                      }

                      return false
                    })
              }
              className={styles.popover({ status })}
              style={assignInlineVars({
                [styles.animationDuration]: `${animationDuration}ms`,
              })}
            >
              <FeatureTooltipContent {...props} />
            </Popover>
          )}
        </Transition>
      </>
    )
  }

  if (!isOpen) {
    return null
  }

  return <FeatureTooltipContent {...props} />
}

export default FeatureTooltip

function isReactText(node: React.ReactChild): node is React.ReactText {
  return typeof node === 'string' || typeof node === 'number'
}
