import searchParamsWhitelist from './searchParamsWhitelist'

const whitelist = searchParamsWhitelist as readonly string[]

export default function stripUnknownSearchParams(params: URLSearchParams | string) {
  const clone = new URLSearchParams(params.toString())

  for (const [param] of clone) {
    if (!whitelist.includes(param)) {
      clone.delete(param)
    }
  }

  return clone
}
