import { makeAutoObservable, toJS } from 'mobx'

import type IntegrationStore from '@src/service/integration-store'
import type { Model } from '@src/service/model/base'

import type { BaseIntegration } from '.'
import type CallDeposition from './CallDeposition'
import type CodableIntegration from './CodableIntegration'
import type ContactImport from './ContactImport'
import type CreatedBy from './CreatedBy'
import type LoggableActivity from './LoggableActivity'
import { createdByUserName } from './utils'

interface CodableHubspotIntegration extends CodableIntegration {
  type: 'hubspot'
  /**
   * @deprecated
   */
  hubspotUser: string
}

class HubspotIntegrationModel
  implements Model, BaseIntegration, CodableHubspotIntegration
{
  id = ''
  readonly type = 'hubspot'
  /**
   * @deprecated
   *
   * Kept due to backwards compatibility
   * `createdBy` should be the property to access the information of the
   * user who created the integration
   */
  hubspotUser = ''
  resourceIds: string[] = []
  refreshToken: string | null = null
  accessToken: string | null = null
  logCalls: LoggableActivity = { enabled: false, activityType: null }
  logMessages: LoggableActivity = { enabled: false, activityType: null }
  accessTokenExpiresAt: Date | null = null
  contactProperties: string[] = []
  callDepositions: CallDeposition[] = []
  contactImport: ContactImport | null = null
  createdBy: CreatedBy | null = null

  constructor(private integrationStore: IntegrationStore) {
    makeAutoObservable(this, {})
  }

  get userName() {
    return createdByUserName(this.createdBy) ?? this.hubspotUser
  }

  update = (attrs: Partial<this>) => {
    Object.assign(this, attrs)
    return this.integrationStore.update(this)
  }

  delete = () => {
    return this.integrationStore.delete(this)
  }

  getStatus() {
    return this.integrationStore.getStatus(this)
  }

  deserialize = (json: CodableHubspotIntegration) => {
    Object.assign(this, json)
    return this
  }

  serialize = (): CodableHubspotIntegration => {
    return {
      id: this.id,
      type: this.type,
      hubspotUser: this.hubspotUser,
      resourceIds: toJS(this.resourceIds),
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      logCalls: toJS(this.logCalls),
      logMessages: toJS(this.logMessages),
      accessTokenExpiresAt: this.accessTokenExpiresAt,
      contactProperties: toJS(this.contactProperties),
      callDepositions: toJS(this.callDepositions),
      contactImport: toJS(this.contactImport),
      createdBy: toJS(this.createdBy),
    }
  }
}

export const isHubspotIntegrationModel = (a: unknown): a is HubspotIntegrationModel => {
  return a instanceof HubspotIntegrationModel
}

export default HubspotIntegrationModel
