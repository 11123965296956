class IgnoredError extends Error {
  /**
   * @param message The original error message
   * @param ignoreReason An explanation of why this error is ignored (this is meant for humans and is not used by the code)
   */
  constructor(
    message: string,
    // FIXME: `ignoreReason` will be removed from the bundled version: https://linear.app/openphone/issue/WEB-264/remove-unused-constructor-param-from-the-bundled-code
    private readonly ignoreReason: string,
  ) {
    super(message)
    this.name = 'IgnoredError'
  }
}

// When the Google Recaptcha script times out, it rejects with the string
// "Timeout" instead of something like Error("Timeout"), which means we
// need this custom handling to prevent sending the error to Sentry.
// See: https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
function isGoogleRecaptchaTimeoutError(error: unknown) {
  return error === 'Timeout'
}

export function isIgnoredError(error: unknown) {
  return (
    error instanceof IgnoredError ||
    (error && typeof error === 'object' && error['__op_ignored'] === true) ||
    isGoogleRecaptchaTimeoutError(error)
  )
}

/**
 *
 * @param error The error object
 * @param ignoreReason An explanation of why this error is ignored (this is meant for humans and is not used by the code)
 */
export function markErrorAsIgnored(
  error: unknown,
  // FIXME: `ignoreReason` will be removed from the bundled version: https://linear.app/openphone/issue/WEB-264/remove-unused-constructor-param-from-the-bundled-code
  ignoreReason: string,
): void {
  if (error && typeof error === 'object') {
    error['__op_ignored'] = true
  }
}
