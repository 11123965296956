/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { HTMLAttributes } from 'react'

const CheckboxDefault = ({ ...props }: HTMLAttributes<HTMLOrSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 1 18 18" {...props}>
    <title>Unchecked</title>
    <path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeOpacity="0.3"
      strokeWidth="1.25"
      d="M15 2.625c.656 0 1.25.266 1.68.696.43.43.695 1.023.695 1.679h0v10c0 .656-.266 1.25-.696 1.68-.43.43-1.023.695-1.679.695h0H5c-.656 0-1.25-.266-1.68-.696A2.368 2.368 0 012.626 15h0V5c0-.656.266-1.25.696-1.68A2.368 2.368 0 015 2.626h0z"
    ></path>
  </svg>
)

export default CheckboxDefault
