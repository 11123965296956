import BaseAlertModel from './BaseAlertModel'

class OrganizationExportCompleteAlertModel extends BaseAlertModel<
  'org-export-complete',
  { url: string }
> {
  override get associatedObjectsLoaded(): boolean {
    return true
  }
}

export default OrganizationExportCompleteAlertModel
