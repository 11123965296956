import isNonNull from '@src/lib/isNonNull'
import type CommonTextInputProps from '@ui/common/CommonTextInputProps'

import { base, typographySize, width, outlined } from './commonTextInputStyles.css'

const commonTextInputClasses = ({
  size,
  fullWidth,
  variant,
  hasError,
}: {
  size?: number
  fullWidth?: boolean
  variant?: CommonTextInputProps['variant']
  hasError?: boolean
}): string[] => {
  return [
    base,
    typographySize({ size: size && size > 40 ? 'body' : 'footnote' }),
    width({ fullWidth }),
    variant === 'outlined' ? outlined({ hasError }) : null,
  ].filter(isNonNull)
}

export default commonTextInputClasses
