import useAsyncScript from '@src/lib/hooks/useAsyncScript'

const src = 'https://accounts.google.com/gsi/client'

function createGoogleClientScript() {
  const script = document.createElement('script')
  script.async = true
  script.src = src
  return script
}

const isGoogleClientAvailable = () => 'google' in window && !!google

export default function useGoogleClientApi() {
  const { loaded } = useAsyncScript({
    scriptCreator: createGoogleClientScript,
    getInitialState: isGoogleClientAvailable,
  })

  return { loaded }
}
