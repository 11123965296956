import type { MutableRefObject, ReactNode } from 'react'
import { useCallback, useContext, createContext, useRef } from 'react'

interface TooltipContextProps {
  currentlyOpenedTooltipRef: MutableRefObject<string | null>
  setCurrentlyOpenedTooltip: (id: string) => void
  unsetCurrentlyOpenedTooltip: () => void
}

const TooltipContext = createContext<TooltipContextProps>({
  currentlyOpenedTooltipRef: { current: null },
  setCurrentlyOpenedTooltip: () => void {},
  unsetCurrentlyOpenedTooltip: () => void {},
})

export const useTooltip = () => {
  const context = useContext(TooltipContext)

  if (!context) {
    throw new Error('useTooltip has to be used within the <TooltipProvider /> component')
  }

  return context
}

interface TooltipProviderProps {
  children: ReactNode
}

const SEQUENTIAL_HOVER_TIMEOUT_DURATION = 500

export default function TooltipProvider({ children }: TooltipProviderProps) {
  const currentlyOpenedTooltipRef = useRef<string | null>(null)
  const sequentialHoverTimeoutRef = useRef<number | undefined>()

  const setCurrentlyOpenedTooltip = useCallback((id: string) => {
    clearTimeout(sequentialHoverTimeoutRef.current)
    currentlyOpenedTooltipRef.current = id
  }, [])

  const unsetCurrentlyOpenedTooltip = useCallback(() => {
    clearTimeout(sequentialHoverTimeoutRef.current)
    sequentialHoverTimeoutRef.current = window.setTimeout(() => {
      currentlyOpenedTooltipRef.current = null
    }, SEQUENTIAL_HOVER_TIMEOUT_DURATION)
  }, [])

  return (
    <TooltipContext.Provider
      value={{
        currentlyOpenedTooltipRef,
        setCurrentlyOpenedTooltip,
        unsetCurrentlyOpenedTooltip,
      }}
    >
      {children}
    </TooltipContext.Provider>
  )
}
