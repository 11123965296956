import { computed, makeObservable, observable, toJS } from 'mobx'
import type { MarkOptional } from 'ts-essentials'

import type { RingOrderFormSchemaType } from '@src/app/settings/phone-number/call-flow/RingOrder/RingOrderFormSchema'
import { strictOmit } from '@src/lib'
import uuid from '@src/lib/uuid'

import type { Model } from './base'

export type GetTotalRingDurationData =
  | {
      membersCount: number | null
      groupSize: number | null
      duration: number | null
    }
  | { groups: RawRingGroup[] | null }

/**
 * Returns the total ring duration of a ring order.
 */
export const getTotalRingDuration = (data: GetTotalRingDurationData): number => {
  if ('groups' in data) {
    return (data.groups ?? []).reduce((acc, curr) => acc + curr.duration, 0)
  }

  if (data.duration && data.membersCount && data.groupSize) {
    return data.duration * Math.ceil(data.membersCount / data.groupSize)
  }

  return 0
}

export type RingOrderType = 'ALL_AT_ONCE' | 'RANDOM' | 'CUSTOM'
export type RingGroupType = 'ALL_AT_ONCE' | 'RANDOM'

export interface RawRingGroup {
  id: string
  type: RingGroupType
  userIds: string[]
  duration: number
  groupSize: number | null
}

export interface RawRingOrder {
  id: string
  type: RingOrderType
  phoneNumberId: string
  duration: number | null
  groupSize: number | null
  /**
   * Represents the list of groups of the ring order, ordered in the order that the batch should be handled
   */
  groups: RawRingGroup[] | null
  isDefault: boolean
  updatedAt: string
  createdAt: string
  version: number
}

export type RawRingOrderFormFields = Pick<
  RawRingOrder,
  'type' | 'duration' | 'groupSize' | 'groups'
>

export default class RingOrderModel implements Model {
  private raw: RawRingOrder

  get id(): string {
    return this.raw.id
  }

  get type(): RingOrderType {
    return this.raw.type
  }

  get phoneNumberId(): string {
    return this.raw.phoneNumberId
  }

  get duration(): number | null {
    return this.raw.duration
  }

  get groupSize(): number | null {
    return this.raw.groupSize
  }

  get groups(): RawRingGroup[] | null {
    return this.raw.groups
  }

  get updatedAt(): string {
    return this.raw.updatedAt
  }

  get isDefault(): boolean {
    return this.raw.isDefault
  }

  get formFields(): RingOrderFormSchemaType {
    return strictOmit(this.raw, [
      'createdAt',
      'phoneNumberId',
      'updatedAt',
      'version',
    ]) as RingOrderFormSchemaType
  }

  constructor(attrs: MarkOptional<RawRingOrder, 'id'>) {
    this.raw = {
      ...attrs,
      id: attrs.id ?? `RO${uuid()}`.replace(/-/g, ''),
    }

    makeObservable<this, 'raw'>(this, {
      raw: observable.deep,
      id: computed,
      type: computed,
      phoneNumberId: computed,
      duration: computed,
      groupSize: computed,
      groups: computed,
      updatedAt: computed,
      formFields: computed,
    })
  }

  deserialize(json: RawRingOrder): this {
    this.raw = json
    return this
  }

  serialize(): RawRingOrder {
    return toJS(this.raw)
  }

  localUpdate(attrs: Partial<RawRingOrder>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
