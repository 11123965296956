import { makeAutoObservable } from 'mobx'

import type Service from '..'

import type { Model } from './base'

export interface CallTranscriptDialogueItem {
  start: number
  end: number
  userId?: string
  content: string
  /**
   * Phone number that was used during the call
   */
  identifier?: string
  speaker: number
}

export interface CodableCallTranscript {
  $version: string | null
  createdAt: string
  dialogue: CallTranscriptDialogueItem[]
  duration: number | null
  speakerCount: number | null
}

export class CallTranscriptModel implements CodableCallTranscript, Model {
  $version = null
  createdAt = ''
  dialogue: CallTranscriptDialogueItem[] = []
  duration = null
  speakerCount = null

  constructor(attrs?: Partial<CodableCallTranscript>) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  get id() {
    return this.createdAt
  }

  deserialize(attrs?: Partial<CodableCallTranscript>) {
    if (attrs) {
      Object.assign(this, attrs)
    }
    return this
  }

  serialize(): CodableCallTranscript {
    return {
      $version: this.$version,
      createdAt: this.createdAt,
      dialogue: this.dialogue.map((item) => ({ ...item })),
      duration: this.duration,
      speakerCount: this.speakerCount,
    }
  }
}
