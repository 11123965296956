import { makeAutoObservable } from 'mobx'

import { map } from '@src/lib'
import { DisposeBag } from '@src/lib/dispose'
import PersistedCollection from '@src/service/collections/PersistedCollection'

import type Service from '.'
import { BlocklistModel } from './model'
import type { BlocklistRepository } from './worker/repository/blocklist'

export default class BlocklistStore {
  private readonly disposeBag = new DisposeBag()
  collection: PersistedCollection<BlocklistModel, BlocklistRepository>

  constructor(private service: Service) {
    this.collection = new PersistedCollection({
      table: service.storage.table('blocklist'),
      classConstructor: () => new BlocklistModel(service.blocklist),
    })
    this.disposeBag.add(this.subscribeToWebSocket())

    makeAutoObservable(this, {})

    this.load()
  }

  get byPhoneNumber() {
    return map(this.collection.list, 'phoneNumber')
  }

  load = () => {
    return this.collection.performQuery((repo) => repo.all())
  }

  fetch = () => {
    return this.service.transport.account.blocklist
      .list()
      .then((items) => this.collection.load(items, { deleteOthers: true }))
  }

  block = (phoneNumber: string) => {
    return this.service.transport.account.blocklist
      .create(phoneNumber)
      .then((res) => this.collection.load(res))
  }

  unblock = async (phoneNumber: string) => {
    const obj = this.byPhoneNumber[phoneNumber]
    if (obj) {
      return this.delete(obj.id)
    }
  }

  delete = (id: string) => {
    this.collection.delete(id)
    return this.service.transport.account.blocklist.delete(id)
  }

  private subscribeToWebSocket() {
    return this.service.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'blocklist-update': {
          this.collection.load(data.block)
          break
        }
        case 'blocklist-delete': {
          this.collection.delete(data.block.id)
          break
        }
      }
    })
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
