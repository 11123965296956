import type { DataWorkerMeta } from '@openphone/data-worker/meta'
import { setDataWorkerMeta } from '@openphone/data-worker/meta'
import type { Socket } from '@openphone/workerql-core/common'

import workerUrl from './DataLayerWorker?worker&url'

const DATA_LAYER_WORKER_NAME = 'openphone:data-worker'

/**
 * Load the Data Layer Worker in a Shared Worker or Web Worker.
 *
 * For most environments, it's going to be a Shared Worker.
 *
 * Tip for debugging: If you're using Chrome, you can see the Shared Worker
 * in the `chrome://inspect/#workers` page.
 */
export default function loadDataLayerWorker(meta: DataWorkerMeta) {
  let socket: Socket
  let type: 'SharedWorker' | 'Worker'

  const url = new URL(workerUrl, window.location.origin)
  setDataWorkerMeta(url, meta)

  if ('SharedWorker' in window) {
    const worker = new SharedWorker(url, {
      name: DATA_LAYER_WORKER_NAME,
      type: 'module',
    })

    worker.port.start()
    socket = worker.port
    socket.close = () => worker.port.close()
    type = 'SharedWorker'
  } else {
    const worker = new Worker(url, {
      name: DATA_LAYER_WORKER_NAME,
      type: 'module',
    })
    socket = worker
    socket.close = () => worker.terminate()
    type = 'Worker'
  }

  return {
    socket,
    type,
  }
}
