import { computed, makeObservable, observable, reaction, action, toJS } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'
import shortId from '@src/lib/shortId'
import type { AvailabilityHours } from '@src/service/transport/account'
import type UserStore from '@src/service/user-store'

import type { Model } from './base'

export type ReactionEmojis = [string, string, string, string, string]
export type NotificationUnreadCount = 'conversations' | 'activities'

export type FeatureTooltipName =
  | 'porting'
  | 'participantHold1'
  | 'participantHold2'
  | 'participantHold3'
  | 'warmTransfer1'
  | 'warmTransfer2'
  /**
   *  Legacy feature tooltips
   *
   * Since feature tooltips are not removed from the user's settings, let's keep the already
   * used names here to prevent involuntarily re-using them for a new feature tooltip
   */
  | 'callFlow'
  | 'scheduledMessages'
  | 'ringOrderStep1'
  | 'ringOrderStep2'
  | 'helpAndSupport'
  | 'inboxFilters'
  | 'callViews1'
  | 'callFallbackStep1'
  | 'callFallbackStep2'
  | 'callFallbackStep3'

export type DismissibleCalloutName =
  | 'planUpdates'
  | 'blocklistUpdates'
  | 'tollFreeAbleToMessageUSSubmitted'
  | 'callViewsOnboarding'
  | 'callWorkflowBusinessHoursNotice'
  /**
   * Legacy dismissible callouts
   *
   * Since dismissible callouts are not removed from the user's settings, let's keep the already
   * used names here to prevent involuntarily re-using them for a new dismissible callout
   */
  | 'holidayPortingFreeze2023'

export interface UserPhoneNumberSharedSettings {
  id: string
  ringtone?: string | null
  conversations?: {
    pinnedIds?: string[] | null
  } | null
}

export interface CodableUserSettings {
  android: Record<string, unknown>
  ios: Record<string, unknown>
  web: {
    onboardingCompleted: boolean
    activationFlowCompleted: boolean
    newFeatureTooltips: Partial<Record<FeatureTooltipName, string>>
    dismissedCallouts: Partial<Record<DismissibleCalloutName, string>>
    playNotificationSounds?: boolean
  }
  shared: {
    phoneNumberOrder: string[]
    /**
     * Array of objects containing user specific settings for that number
     */
    phoneNumbers: UserPhoneNumberSharedSettings[]
  }
  notifications: {
    includeMessagePreview?: boolean
    snoozedDeliveryMethod?: 'silent' | 'none'
    schedule?: AvailabilityHours
    unreadCount?: NotificationUnreadCount
    playSounds?: 'all' | 'minimal' | 'none'
    defaultRingtone?: 'system' | 'primary' | 'secondary'
  }
  preferences: {
    reactionEmojis?: ReactionEmojis
    defaultSkinTone?: string
  }
}

export const defaultReactionEmojis: ReactionEmojis = ['😀', '😮', '❤️', '🎉', '👍']

class UserSettingsModel implements Model, CodableUserSettings {
  readonly id = shortId()
  ios = {}
  android = {}
  private raw: CodableUserSettings

  private disposeBag = new DisposeBag()

  get web() {
    return this.raw.web
  }

  get shared() {
    return this.raw.shared
  }

  get preferences() {
    return this.raw.preferences
  }

  get phoneNumbers() {
    return this.shared.phoneNumbers
  }

  get notifications() {
    return this.raw.notifications
  }

  constructor(
    private userStore: UserStore,
    attrs: Partial<CodableUserSettings>,
  ) {
    this.raw = this.applyDefaults(attrs)

    makeObservable<this, 'raw'>(this, {
      raw: observable.deep,
      web: computed.struct,
      shared: computed.struct,
      preferences: computed.struct,
      notifications: computed.struct,
      deserialize: action.bound,
    })

    this.disposeBag.add(
      reaction(
        () => this.serialize(),
        (settings) => {
          this.userStore.updateUserSettings(settings)
        },
      ),
    )
  }

  getPhoneNumberSettings(phoneNumberId: string): UserPhoneNumberSharedSettings {
    const settings = this.phoneNumbers.find((n) => n.id === phoneNumberId)
    if (settings) {
      if (!settings.conversations) {
        settings.conversations = {
          pinnedIds: [],
        }
      }
      return settings
    } else {
      const newSettings = {
        id: phoneNumberId,
        ringtone: null,
        conversations: {
          pinnedIds: [],
        },
      }
      this.phoneNumbers.push(newSettings)
      return newSettings
    }
  }

  deserialize(json: Partial<CodableUserSettings>) {
    this.raw = this.applyDefaults(json)

    return this
  }

  serialize(): CodableUserSettings {
    return toJS(this.raw)
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  private applyDefaults(attrs?: Partial<CodableUserSettings>): CodableUserSettings {
    return {
      ios: {
        ...attrs?.ios,
      },
      android: {
        ...attrs?.android,
      },
      web: {
        onboardingCompleted: false,
        activationFlowCompleted: false,
        playNotificationSounds: true,
        ...attrs?.web,
        newFeatureTooltips: {
          ...attrs?.web?.newFeatureTooltips,
        },
        dismissedCallouts: {
          ...attrs?.web?.dismissedCallouts,
        },
      },
      shared: {
        phoneNumberOrder: [],
        phoneNumbers: [],
        ...attrs?.shared,
      },
      notifications: {
        includeMessagePreview: true,
        ...attrs?.notifications,
      },
      preferences: {
        reactionEmojis: undefined,
        defaultSkinTone: undefined,
        ...attrs?.preferences,
      },
    }
  }
}

export default UserSettingsModel
