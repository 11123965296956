/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { HTMLAttributes } from 'react'

const CheckboxActiveFull = ({ id, ...props }: HTMLAttributes<HTMLOrSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 1 18 18" {...props}>
    <title>Checked</title>
    <defs>
      <filter id={`active${id}`} colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H20V20H0z"></path>
      <path
        fill="currentColor"
        d="M5 2h10a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3z"
      ></path>
      <g filter={`url(#active${id})`} transform="translate(2 2)">
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M12.112 4.88a.75.75 0 01.106.974l-.07.086-4.836 5.18-.056.047-.078.053-.048.025-.096.04-.103.025-.106.011-.1-.003-.056-.008-.103-.026-.096-.039-.093-.054-.079-.062-.046-.046-2.4-2.571a.75.75 0 011.015-1.1l.081.076L6.8 9.471l4.253-4.554a.75.75 0 011.06-.037z"
        ></path>
      </g>
    </g>
  </svg>
)

export default CheckboxActiveFull
