import Snackbar from '@material-ui/core/Snackbar'
import type { SnackbarContentProps as MuiSnackbarContentProps } from '@material-ui/core/SnackbarContent'
import MuiSnackbarContent from '@material-ui/core/SnackbarContent'

import * as styles from './Snackbar.css'

interface SnackbarContentProps extends MuiSnackbarContentProps {
  type: 'info' | 'error' | 'loading' | 'special'
}

const SnackbarContent = function ({ type, ...props }: SnackbarContentProps) {
  return (
    <MuiSnackbarContent
      classes={{
        root: styles.root({ type }),
        message: styles.message,
        action: styles.action({ isError: type === 'error' }),
      }}
      {...props}
    />
  )
}

export default Snackbar

export { SnackbarContent }
