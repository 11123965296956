import { useComboboxList } from '@ui/Combobox/ComboboxList'

import * as styles from './ComboboxListInput.css'

export default function ComboboxListInput() {
  const { comboboxInputRef, inputProps } = useComboboxList()

  return (
    <input type="text" ref={comboboxInputRef} {...inputProps} className={styles.root} />
  )
}
