import { runInAction } from 'mobx'
import { useCallback } from 'react'

import { useCurrentUser } from '@src/app/user'
import type { FeatureTooltipName } from '@src/service/model'

export default function useFeatureTooltipsActions() {
  const user = useCurrentUser()

  const dismiss = useCallback(
    (...names: FeatureTooltipName[]) => {
      runInAction(() => {
        names.forEach((name) => {
          if (user.settings.web.newFeatureTooltips[name]) {
            // Already dismissed, prevent resetting the date
            // to only save the first time the tooltip was dismissed
            return
          }

          user.settings.web.newFeatureTooltips[name] = new Date().toISOString()
        })
      })
    },
    [user],
  )

  const reset = useCallback(
    (...names: FeatureTooltipName[]) => {
      runInAction(() => {
        names.forEach((name) => {
          delete user.settings.web.newFeatureTooltips[name]
        })
      })
    },
    [user],
  )

  return {
    dismiss,
    reset,
  }
}
