import { useMenuTriggerState } from '@react-stately/menu'
import { useCallback, useRef } from 'react'
import type { ReactNode, MutableRefObject } from 'react'
import { useMenuTrigger } from 'react-aria'

import type { Sensor } from '@ui/SensorProvider'

import { ComboboxTriggerContext } from './context'

export interface ComboboxProviderProps {
  /**
   * The children of the combobox provider. The common children are the `Combobox.Trigger` and the `Combobox.List`
   */
  children: ReactNode

  /**
   * Whether the Combobox is opened or not, this makes the component controlled.
   *
   * If the `isInlined` property from the `Combobox.List` component is true, this property won't have any effect.
   */
  isOpen?: boolean

  /**
   * Whether the Combobox is opened by default, does not make the component controlled, it's still uncontrolled, although as soon as it's rendered it will be display opened. Once we click somewhere else or scroll the combobox will get closed.
   *
   * If the `isInlined` property from the `Combobox.List` component is true, this property won't have any effect.
   */
  defaultOpen?: boolean

  /**
   * The element that the Combobox will use as reference to position itself.
   * This prop is used along with `isOpen` to make the `Combobox` a controlled component.
   */
  targetRef?: MutableRefObject<HTMLElement | null>

  /**
   * A callback function that will be called once the menu gets closed.
   */
  onClose?: () => void
}

const ComboboxProvider = ({
  children,
  isOpen,
  defaultOpen,
  targetRef: controlledRef,
  onClose,
}: ComboboxProviderProps) => {
  const triggeredWithSensorRef = useRef<Sensor | null>(null)

  const state = useMenuTriggerState({
    isOpen,
    defaultOpen,
    onOpenChange: (isOpen) => {
      if (onClose && !isOpen) {
        onClose()
      }
    },
  })

  const uncontrolledRef = useRef<HTMLElement | null>(null)

  const ref = controlledRef || uncontrolledRef

  const setNodeRef = useCallback((element: HTMLElement | null) => {
    uncontrolledRef.current = element
  }, [])

  const { menuTriggerProps: comboboxTriggerProps, menuProps: comboboxProps } =
    useMenuTrigger({ type: 'listbox' }, state, ref)

  const setTriggeredWith = useCallback((sensor: Sensor) => {
    triggeredWithSensorRef.current = sensor
  }, [])

  return (
    <ComboboxTriggerContext.Provider
      value={{
        ref,
        state,
        defaultOpen,
        triggeredWithSensorRef,
        comboboxTriggerProps,
        comboboxProps,
        setNodeRef,
        setTriggeredWith,
      }}
    >
      {children}
    </ComboboxTriggerContext.Provider>
  )
}

export default ComboboxProvider
