import { observer } from 'mobx-react-lite'

import type { MessageMediaModel } from '@src/service/model'

import DocViewer from './DocViewer'
import ImageViewer from './ImageViewer'
import PdfViewer from './PdfViewer'
import VideoViewer from './VideoViewer'

interface MediaPreviewProps {
  media: MessageMediaModel
}

const MediaPreview = function ({ media }: MediaPreviewProps) {
  const type = media.fileType

  switch (type) {
    case 'image':
      return <ImageViewer media={media} />
    case 'video':
      return <VideoViewer media={media} />
    case 'doc':
    case 'spreadsheet':
    case 'presentation':
      return <DocViewer media={media} />
    case 'pdf':
      return <PdfViewer media={media} />
    default:
      return null
  }
}

export default observer(MediaPreview)
