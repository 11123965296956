import * as styles from './InputBase.css'

export interface InputBaseProps {
  /**
   * Passing true will make the component display with the error color scheme
   *
   * @default false
   */
  hasError?: boolean

  /**
   * Passing true will make the component display with the disabled style
   *
   * @default false
   */
  isDisabled?: boolean

  /**
   * The component that should appear at the start of the input
   */
  startAdornment?: React.ReactNode

  /**
   * The component that should appear at the end of the input
   */
  endAdornment?: React.ReactNode

  /**
   * Children to be rendered inside the input
   */
  children: React.ReactNode
}

const InputBase = ({
  hasError = false,
  isDisabled = false,
  startAdornment,
  endAdornment,
  children,
}: InputBaseProps) => {
  return (
    <div
      className={styles.root({
        state: isDisabled ? 'disabled' : hasError ? 'error' : 'default',
      })}
    >
      {startAdornment ? <div className={styles.adornment}>{startAdornment}</div> : null}

      <div className={styles.content({ disabled: isDisabled })}>{children}</div>

      {endAdornment ? <div className={styles.adornment}>{endAdornment}</div> : null}
    </div>
  )
}

export default InputBase
