import { useContext } from 'react'

import BannersContext from './BannersContext'

export default function useBannersContext() {
  const context = useContext(BannersContext)

  if (!context) {
    throw new Error('`useBanners` has to be used under `BannersProvider`')
  }

  return context
}
