import type { Key, Dispatch, SetStateAction, RefObject, AriaAttributes } from 'react'
import { createContext } from 'react'
import type { AriaMenuOptions, MenuAria } from 'react-aria'

import type SubMenuMediator from '@ui/Menu/MenuList/SubMenuMediator'
import type { PopoverProps } from '@ui/Popover'
import type { Sensor } from '@ui/SensorProvider'

import type { MenuTreeState } from './useMenuTreeState'

/**
 * HTML attributes to meet accessibility requirements.
 */
type AccessiblityProps = AriaAttributes &
  Pick<MenuAria['menuProps'], 'id' | 'role' | 'tabIndex'>

/**
 * React aria specific configuration props.
 */
type MenuOptions = Omit<AriaMenuOptions<object>, 'onClose'>

/**
 * Props related to the visual aspect of the menu.
 */
type StyleProps = Pick<PopoverProps, 'placement'>

type MenuProps = AccessiblityProps & MenuOptions & StyleProps

export interface MenuContextProps {
  /**
   * Reference to the list element of the menu
   */
  menuListRef: RefObject<HTMLUListElement>

  /**
   * Data state of the menu: disabled items, actionable items or the selection manager.
   */
  state: MenuTreeState<object>

  /**
   * Accessibility, configuration, and style props.
   */
  props: MenuProps

  /**
   * Which sensor is currently being used.
   */
  sensor: Sensor

  /**
   * Disable list items based on their disabled prop.
   */
  setDisabledKey: (key: Key) => void

  /**
   * If the menuRef element is visible or not.
   */
  isVisible: boolean

  /**
   * Used to set the visibility status of the menuRef element.
   */
  setIsVisible: Dispatch<SetStateAction<boolean>>

  /**
   * Instance of the sub menu mediator that controls how mouse
   * interactions open or close a sub menu and how two same
   * level sub menus interact with each other.
   */
  subMenuMediator: SubMenuMediator
}

const MenuListContext = createContext<MenuContextProps | null>(null)

export default MenuListContext
