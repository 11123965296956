import { alpha } from '@material-ui/core/styles'

import { black, white } from '@src/theme/themes/colors'
import { createThemeMatch } from '@src/theme/themes/common'
import type { OpenPhonePalette } from '@src/theme/themes/common'
import {
  borderRadius,
  callModal,
  contactTags,
  legacy,
} from '@src/theme/themes/shared/palette'

const primary = {
  1: '#874FFF',
  2: '#A36EFF',
  3: '#A27AFF',
  4: '#26223B',
}

const secondary = {
  green1: '#00B94D',
  green2: '#00DB72',
  blue: '#00A9FE',
  yellow: '#FFC648',
  orange: '#FFAD2A',
  red1: '#F03A14',
  red2: '#FF6042',
}

const gray = {
  1: '#EEEEF0',
  2: '#9797B2',
  3: '#6C6C87',
  4: '#505062',
  5: '#242434',
  6: '#1D1D2A',
}

const note = {
  text: '#FFF8E4',
  label: '#C29F51',
  highlight: '#FCB200',
  bg: '#2D292A',
}

const mentionTheirName = '#0B9FFF'
const mentionMyName = '#FFCD4F'

const mention = {
  theirName: mentionTheirName,
  theirBg: alpha(mentionTheirName, 0.14),
  myName: mentionMyName,
  myBg: alpha(secondary.yellow, 0.15),
  chatBubbleBg: alpha(white, 0.16),
}

const hoverPrimaryBase = '#4B4B68'
const tagPurpleBgBase = '#A178FF'
const tagGreenBgBase = '#56F5A9'
const tagBlueBgBase = '#35A9F3'
const tagYellowBgBase = '#BEA457'
const tagOrangeBgBase = '#FFC465'
const tagRedBgBase = '#FF8871'

const palette: OpenPhonePalette = {
  black,
  white,
  titleBar: {
    background: '#232333',
    closeHover: '#C43F1C',
  },
  background: {
    primary: '#1A1925',
    popover: '#212130',
    deepPopover: '#4C4A6F',
    error: '#39272F',
    highlight: (a = 0.06) => alpha(white, a),
    callModeBg: callModal.background,
    callModeBgBlurred: callModal.backgroundBlurred,
    callModeHighlight: callModal.highlight,
  },
  primary,
  secondary,
  gray,
  mention,
  tag: {
    purple: {
      bg: alpha(tagPurpleBgBase, 0.15),
      bgHover: alpha(tagPurpleBgBase, 0.21),
      text: '#D1B7FF',
    },
    green: {
      bg: alpha(tagGreenBgBase, 0.13),
      bgHover: alpha(tagGreenBgBase, 0.19),
      text: '#55E787',
    },
    blue: {
      bg: alpha(tagBlueBgBase, 0.13),
      bgHover: alpha(tagBlueBgBase, 0.19),
      text: '#31AEFF',
    },
    yellow: {
      bg: alpha(tagYellowBgBase, 0.13),
      bgHover: alpha(tagYellowBgBase, 0.19),
      text: '#FFEDBA',
    },
    orange: {
      bg: alpha(tagOrangeBgBase, 0.13),
      bgHover: alpha(tagOrangeBgBase, 0.19),
      text: '#FFB745',
    },
    red: {
      bg: alpha(tagRedBgBase, 0.13),
      bgHover: alpha(tagRedBgBase, 0.19),
      text: '#FF7358',
    },
  },
  note,
  text: {
    primary: gray[1],
    secondary: gray[2],
    tertiary: gray[3],
    placeholder: alpha(gray[1], 0.4),
    subtitle: '#C3CEDD',
  },
  hover: {
    primary: alpha(hoverPrimaryBase, 0.23),
    darker: alpha(hoverPrimaryBase, 0.5),
    darker2: alpha(hoverPrimaryBase, 0.75),
    thread: alpha(note.highlight, 0.06),
    selected: alpha(tagPurpleBgBase, 0.08),
  },
  border: {
    common: alpha(gray[1], 0.06),
    focus: alpha(primary[2], 0.8),
  },
  shadow: {
    tooltip: `0 1px 3px 0 ${alpha(black, 0.3)}, 0 0 0 1px ${alpha(gray[4], 0.5)}`,
    popup: `0 2px 20px 0 ${alpha(black, 0.35)}, 0 0 0 1px ${alpha(gray[4], 0.55)}`,
    modal: `0 8px 104px 0 ${alpha(black, 0.6)}, 0 0 0 1px ${alpha(gray[4], 0.55)}`,
    textField: {
      inactive: `inset 0 0 0 1.5px ${alpha(gray[1], 0.18)}`,
      hoverPurple:
        `0 0 0 4px ${alpha(primary[2], 0.18)},` +
        `inset 0 0 0 1.5px ${alpha(primary[2], 0.8)}`,
      hoverNoteThread:
        `0 0 0 4px ${alpha(note.highlight, 0.11)},` +
        `inset 0 0 0 1.5px ${alpha(note.highlight, 0.6)}`,
    },
    dropdown: {
      inactive: `inset 0 0 0 1.5px ${alpha(gray[1], 0.18)}`,
      hover: `inset 0 0 0 1.5px ${alpha(gray[1], 0.2)}`,
      active: `inset 0 0 0 1.5px ${alpha(primary[2], 0.8)}`,
      disabled: `inset 0 0 0 1.5px ${alpha(gray[1], 0.1)}`,
    },
    comments: `0 0 0 1.5px ${alpha(gray[1], 0.09)}`,
    card: `0 0 0 1.5px ${alpha(gray[1], 0.09)}`,
  },
  brands: {
    google: '#2481FF',
  },
  tooltip: {
    background: '#FBFBFC',
    text: '#222224',
    textSecondary: '#646466',
  },
  borderRadius,
  contactTags,
  legacy,

  match: createThemeMatch('dark'),
}

export default palette
