import { computed, makeObservable } from 'mobx'

import type Service from '@src/service'

import ThreadAlertBaseModel from './ThreadAlertBaseModel'

class ThreadCommentReactionAlertModel extends ThreadAlertBaseModel<
  'reaction-on-activity-comment',
  {
    activityId: string
    body: string
    commentId: string
    conversationId: string
    reactionId: string
    userId: string
  }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      reaction: computed,
    })
  }

  get reaction() {
    return this.comment?.reactions?.find(
      (reaction) => reaction.id === this.data.reactionId,
    )
  }
}

export default ThreadCommentReactionAlertModel
