import { observer } from 'mobx-react-lite'
import { useLayoutEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { useAppStore } from '@src/app/context'

const UniversalLoginCouponRoute = () => {
  const { couponId } = useParams()
  const store = useAppStore()

  useLayoutEffect(() => {
    if (couponId) {
      store.service.billing.fetchCoupon(couponId)
    }
  }, [couponId, store.service.billing, store.service.billing.fetchCoupon])

  return <Navigate to="/signup" />
}

export default observer(UniversalLoginCouponRoute)
