import Typography from '@material-ui/core/Typography'
import AccountIcon from '@material-ui/icons/BusinessCenter'
import ErrorIcon from '@material-ui/icons/Error'
import MessageIcon from '@material-ui/icons/Message'
import ContactIcon from '@material-ui/icons/PermContactCalendar'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import Header, { HeaderSpacer } from '@src/app/Header'
import { useAppStore } from '@src/app/context'
import Button from '@src/component/button'
import Logo from '@src/component/logo'
import config from '@src/config'
import { unique } from '@src/lib'
import useStatefulPromise from '@src/lib/hooks/useStatefulPromise'
import { IntegrationsIcon } from '@ui/icons/custom'
import { fontWeights } from '@ui/theme/css'

import * as styles from './ThirdPartyAuthorize.css'

export interface Application {
  clientId: string
  description: string
  id: string
  logoUrl: string
  name: string
  verified: boolean
}

function scopeList(s: string): { icon: React.FC<any>; desc: string }[] {
  const delimiter = s.indexOf(',') === -1 ? ' ' : ','
  const scopes = unique(
    s
      .split(delimiter)
      .map((s) => s.trim())
      .map((s) => (s === 'profile' ? 'read:account' : s)),
  )

  const conversationScopes = scopes.filter((s) => s.endsWith(':conversations'))
  const contactsScopes = scopes.filter((s) => s.endsWith(':contacts'))
  const accountScopes = scopes.filter((s) => s.endsWith(':account'))

  const list: { icon: React.FC; desc: string }[] = []

  if (conversationScopes.length > 0) {
    if (conversationScopes.length === 2) {
      list.push({
        icon: MessageIcon,
        desc: 'Read your conversations and send messages.',
      })
    } else {
      const perm = conversationScopes[0]
      list.push({
        icon: MessageIcon,
        desc: perm?.startsWith('read:')
          ? 'Read your conversations'
          : 'Send messages on your behalf',
      })
    }
  }

  if (contactsScopes.length > 0) {
    if (contactsScopes.length === 2) {
      list.push({
        icon: ContactIcon,
        desc: 'See, edit, create and delete your contacts',
      })
    } else {
      const perm = contactsScopes[0]
      list.push({
        icon: ContactIcon,
        desc: perm?.startsWith('read:')
          ? 'See your contacts'
          : 'Edit, create and delete your contacts',
      })
    }
  }

  if (accountScopes.length > 0) {
    if (accountScopes.length === 2) {
      list.push({
        icon: AccountIcon,
        desc: 'See, edit, create and delete your contacts',
      })
    } else {
      const perm = accountScopes[0]
      list.push({
        icon: AccountIcon,
        desc: perm?.startsWith('read:')
          ? 'See your account and workspace information'
          : 'Edit your account and workspace information',
      })
    }
  }

  return list
}

interface OAuthParams {
  scope: string
  client_id: string
  redirect_uri: string
  response_type: string
  state: string
}

function isOAuthParams(params: any): params is OAuthParams {
  return (
    !!params &&
    typeof params === 'object' &&
    'scope' in params &&
    'client_id' in params &&
    'redirect_uri' in params &&
    'response_type' in params
  )
}

const ThirdPartyAuthorize = function () {
  const { service, history } = useAppStore()
  const [oauthParams, setOAuthParams] = useState<OAuthParams | null>(null)
  const org = service.organization.current

  const [appResponse, findApp] = useStatefulPromise<Application>(() => {
    const params = history.query

    if (isOAuthParams(params)) {
      setOAuthParams({
        scope: params.scope,
        client_id: params.client_id,
        redirect_uri: params.redirect_uri,
        response_type: params.response_type,
        state: params.state,
      })

      return service.integration.getApplication(params.client_id, params.redirect_uri)
    }

    return Promise.reject(new Error('Could not find the application'))
  })

  useEffect(() => {
    findApp()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, [])

  const handleBack = () => {
    history.goBack()
  }

  const handleCancel = () => {
    history.push('/')
  }

  return (
    <div className={styles.root}>
      <Header className={styles.header}>
        <Logo />
        <HeaderSpacer />
      </Header>
      <div className={styles.card}>
        {appResponse.error ? (
          <div style={{ textAlign: 'center' }}>
            <ErrorIcon className={styles.errorIcon} />
            <Typography variant="h6" color="textPrimary" style={{ marginBottom: 30 }}>
              {appResponse.error.message}
            </Typography>
            <Button variant="text" color="default" onClick={handleBack}>
              Go back
            </Button>
          </div>
        ) : appResponse.data && oauthParams ? (
          <form method="post" action={`${config.AUTH_SERVICE_URL}oauth`}>
            {Object.keys(oauthParams).map((key) => (
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
              <input type="hidden" name={key} value={oauthParams[key]} />
            ))}
            <input type="hidden" name="grant_type" value="authorization_code" />
            <input
              type="hidden"
              name="authorization"
              value={service.auth.session?.idToken}
            />

            <Typography
              variant="h5"
              color="textPrimary"
              style={{ textAlign: 'center', fontWeight: fontWeights.default.medium }}
            >
              {appResponse.data.name} is requesting permission to access
              {org?.name ? ` the ${org.name} ` : ' your '}
              OpenPhone workspace
            </Typography>
            <div className={styles.logos}>
              <div className={styles.logo}>
                <img src={org?.pictureUrl ?? undefined} />
              </div>
              <IntegrationsIcon style={{ margin: '0 30px', opacity: 0.4 }} />
              <div className={styles.logo}>
                <img src={appResponse.data.logoUrl} />
              </div>
            </div>
            <div className={styles.scopes}>
              <Typography
                variant="h6"
                color="textPrimary"
                style={{
                  fontWeight: fontWeights.default.bold,
                  fontSize: '1rem',
                  marginBottom: 10,
                }}
              >
                {appResponse.data.name} will be able to:
              </Typography>

              {scopeList(oauthParams.scope).map((scope, index) => (
                <div className={styles.scope} key={index}>
                  <div className={styles.scopeIcon}>
                    <scope.icon fontSize="small" />
                  </div>
                  <div className={styles.scopeDescription}>{scope.desc}</div>
                </div>
              ))}
            </div>
            <div className={styles.actions}>
              <Button
                type="button"
                variant="outlined"
                color="default"
                style={{ marginRight: 20 }}
                className={styles.action}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className={styles.action}
                color="secondary"
              >
                Allow
              </Button>
            </div>
          </form>
        ) : null}
      </div>
    </div>
  )
}

export default observer(ThirdPartyAuthorize)
