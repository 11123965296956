import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'

import * as styles from './LoadingIndicator.css'

export const dotSizes = [4, 5, 8] as const
export const circularSizes = [12, 14, 16, 20, 30, 40, 48] as const

type DottedVariantProps = {
  variant: 'dotted'
  dotSize?: (typeof dotSizes)[number]
}

type CircularVariantProps = {
  variant: 'circular'
  size?: (typeof circularSizes)[number]
}

type LoadingIndicatorProps = (DottedVariantProps | CircularVariantProps) & {
  className?: string
  'aria-label'?: string
  color?: 'gray' | 'primary' | 'inherit'
}

const LoadingIndicator = ({
  className,
  'aria-label': ariaLabel = 'Loading...',
  color = 'inherit',
  ...props
}: LoadingIndicatorProps) => {
  const size = (() => {
    if (props.variant === 'dotted') {
      return props.dotSize ?? 8
    }

    return props.size ?? 20
  })()

  return (
    <div
      className={cx(styles.root({ color }), className)}
      role="progressbar"
      aria-label={ariaLabel}
      aria-hidden={!ariaLabel}
      style={assignInlineVars({
        [styles.size]: `${size}px`,
      })}
    >
      {props.variant === 'dotted' ? (
        <>
          <span className={styles.dot} />
          <span className={styles.dot} />
          <span className={styles.dot} />
        </>
      ) : (
        <svg viewBox="22 22 44 44" className={styles.circle}>
          <circle
            className={styles.circleDash}
            cx="44"
            cy="44"
            r="20"
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      )}
    </div>
  )
}

export default LoadingIndicator
