import { action, computed, makeObservable, observable, toJS } from 'mobx'

import type {
  RawCompleteTollFreeRegistration,
  RawTollFreeRegistrationFormFields,
  RegistrationRejection,
} from '@src/app/settings/trust/trustTypes'
import { strictOmit } from '@src/lib'

import type { Model } from './base'

export default class TollFreeRegistrationModel implements Model {
  private raw: RawCompleteTollFreeRegistration

  get id(): string {
    return this.raw.id
  }

  get isReadOnly(): boolean {
    if (this.isDraft) {
      return false
    }

    if (!this.isRejected) {
      return true
    }

    return !this.resubmitAllowed
  }

  get registrationStatus() {
    return this.raw.registrationStatus
  }

  get updatedAt() {
    return this.raw.updatedAt
  }

  get submittedAt() {
    return this.raw.submittedAt
  }

  get formFields(): RawTollFreeRegistrationFormFields {
    return strictOmit(this.raw, [
      'id',
      'object',
      'twilioSid',
      'registrationStatus',
      'twilioStatus',
      'canMessageCANumbers',
      'canMessageUSNumbers',
      'createdAt',
      'updatedAt',
      'submittedAt',
      'registrationRejection',
      'isPersonalUse',
      'additionalInformation',
      'notificationEmail',
    ])
  }

  get isDraft(): boolean {
    return this.raw.registrationStatus === 'draft'
  }

  get isPersonalUse(): boolean {
    return this.raw.isPersonalUse
  }

  get canMessageCANumbers(): boolean {
    return this.raw.canMessageCANumbers
  }

  get canMessageUSNumbers(): boolean {
    return this.raw.canMessageUSNumbers
  }

  get isSubmitted(): boolean {
    return this.raw.registrationStatus === 'submitted'
  }

  /**
   * Returns true if the current registrationStatus is 'rejected'.
   * This is not the same as `isTwilioRejected` which returns true if the registration was rejected by Twilio following a registration being submitted.
   */
  get isRejected(): boolean {
    return this.raw.registrationStatus === 'rejected'
  }

  /**
   * Returns true if the registration was rejected by Twilio following a registration being submitted.
   * This is a separate status from `registrationStatus`
   */
  get isTwilioRejected(): boolean {
    return this.raw.twilioStatus === 'TWILIO_REJECTED'
  }

  get resubmitAllowed(): RegistrationRejection['resubmitAllowed'] {
    return this.raw.registrationRejection?.resubmitAllowed ?? false
  }

  get priorityResubmitBy(): RegistrationRejection['priorityResubmitBy'] {
    const date = this.raw.registrationRejection?.priorityResubmitBy

    return date ? new Date(date) : null
  }

  get rejectionReason(): RegistrationRejection['reason'] {
    return this.raw.registrationRejection?.reason ?? null
  }

  get isRegistered(): boolean {
    return this.registrationStatus === 'approved'
  }

  constructor(raw: RawCompleteTollFreeRegistration) {
    this.raw = raw

    makeObservable<this, 'raw'>(this, {
      raw: observable.deep,
      id: computed,
      isReadOnly: computed,
      registrationStatus: computed,
      updatedAt: computed,
      submittedAt: computed,
      formFields: computed,
      isDraft: computed,
      isPersonalUse: computed,
      canMessageCANumbers: computed,
      canMessageUSNumbers: computed,
      isSubmitted: computed,
      isRejected: computed,
      isTwilioRejected: computed,
      isRegistered: computed,
      deserialize: action,
      serialize: action,
      localUpdate: action,
      resubmitAllowed: computed,
      priorityResubmitBy: computed,
      rejectionReason: computed,
    })
  }

  deserialize(json: RawCompleteTollFreeRegistration): this {
    this.raw = json
    return this
  }

  serialize(): RawCompleteTollFreeRegistration {
    return toJS(this.raw)
  }

  localUpdate(attrs: Partial<RawCompleteTollFreeRegistration>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
