import cx from 'classnames'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

import * as styles from './MenuListItemBase.css'

export type MenuListItemBaseProps = React.LiHTMLAttributes<HTMLLIElement> & {
  children: ReactNode

  /**
   * Classname to apply to the item
   */
  className?: string

  /**
   * The variant to apply to the item:
   *
   * - `default` displays an ordinary item
   * - `danger` displays the item with more use of the red color to convey a desctuctive action. For example, it can be used in a "Delete" item.
   * - `selected` displays the item with a purpleish background
   *
   * @default `default`
   */
  variant?: 'default' | 'danger' | 'selected'

  /**
   * Whether the item is disabled
   */
  disabled?: boolean
}

const MenuListItemBase = forwardRef<HTMLLIElement, MenuListItemBaseProps>(
  ({ children, className, variant = 'default', disabled = false, ...props }, ref) => {
    return (
      <li
        {...props}
        ref={ref}
        className={cx(
          styles.root({
            selected: variant === 'selected',
            danger: variant === 'danger',
            disabled,
          }),
          className,
        )}
      >
        {children}
      </li>
    )
  },
)

export default MenuListItemBase
