import { makeObservable } from 'mobx'

import type Service from '.'

export default class AiMessageResponseStore {
  constructor(private root: Service) {
    makeObservable<this>(this, {})
  }

  suggestions(conversationId: string, prompt: string) {
    return this.root.transport.communication.messaging
      .suggestions(conversationId, prompt)
      .then((res) => res.suggestions)
  }
}
