/**
 * Exponential delay function that decays over time. Values start at 250ms and grow by a factor of 2 until
 * they reach `maxDelay`. Additionally, the return values are randomized by 50% to avoid a thundering herd
 * problem if a server recovers and many clients attempt reconnection at exactly the same time. We want the
 * decay so that we don't restart the clock if we end up in a crash loop.
 *
 * @returns A promise that resolves after the delay.
 */
export type ExponentialDelay = () => Promise<unknown>

const MIN_DELAY = 250

const exponentialDelay = (maxDelay: number): ExponentialDelay => {
  const calculateDelay = exponentialDelayCalculator(maxDelay)
  return async () => {
    const delay = calculateDelay()
    // randomize by +/- 50%
    const randomized = delay / 2 + delay * Math.random()
    return await new Promise((resolve) => setTimeout(resolve, randomized))
  }
}

export const exponentialDelayCalculator = (maxDelay: number): (() => number) => {
  let currentDelay = MIN_DELAY
  let lastInvocationTimestamp = 0

  return () => {
    const now = Date.now()
    let elapsed = now - lastInvocationTimestamp

    if (elapsed > currentDelay) {
      while (elapsed > currentDelay && currentDelay > MIN_DELAY) {
        elapsed -= currentDelay
        currentDelay /= 2
      }
    } else {
      if (currentDelay < maxDelay) {
        currentDelay = currentDelay * 2
      }
    }

    lastInvocationTimestamp = now

    return Math.min(currentDelay, maxDelay)
  }
}

export default exponentialDelay
