import { useContext } from 'react'

import type { RecaptchaContextProps } from '@src/app/recaptcha/RecaptchaContext'
import RecaptchaContext from '@src/app/recaptcha/RecaptchaContext'

const useRecaptcha = (): RecaptchaContextProps => {
  const context = useContext(RecaptchaContext)
  if (!context) {
    throw new Error('`useRecaptcha` has to be used in a child of `RecaptchaProvider`')
  }
  return context
}

export default useRecaptcha
