/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import config from '@src/config'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export interface Snippet {
  id?: string
  userId?: string | null
  name?: string
  text?: string
  sharedWith?: string[]
  createdAt?: number
  updatedAt?: number
}

export default class SnippetsClient {
  constructor(private transport: Transport) {}

  list(): Promise<Snippet[]> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.COMMUNICATION_SERVICE_URL}cannedMessage`,
      }),
    )
  }

  put(snippet: Snippet): Promise<Snippet> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.COMMUNICATION_SERVICE_URL}cannedMessage`,
        body: snippet,
      }),
    )
  }

  delete(snippet: Snippet): Promise<Snippet[]> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'delete',
        url: `${config.COMMUNICATION_SERVICE_URL}cannedMessage/${snippet.id}`,
      }),
    )
  }
}
