import dayjs from 'dayjs'
import { computed, makeObservable, observable, action, toJS } from 'mobx'

import type { Model } from '.'

export type CnamProfileStatus = 'approved' | 'pending' | 'rejected' | 'failed'

export interface DecodableCnamProfile {
  id: string
  object: 'cnam'
  displayName: string
  useAsDefault: boolean
  resourceIds: string[]
  status: CnamProfileStatus
  apiVersion: string
  createdAt: string
  updatedAt: string | null
  etag: string
}

export interface CodableCnamProfile {
  id: string
  object: 'cnam'
  displayName: string
  useAsDefault: boolean
  resourceIds: string[]
  status: CnamProfileStatus
  apiVersion: string
  createdAt: string
  updatedAt: string | null
  etag: string
  updating: boolean
  isLegacy: boolean
}

const LEGACY_CNAM_CUT_OFF_DATE = dayjs(new Date('2024-01-01'))
export const FORMATTED_LEGACY_CNAM_CUT_OFF_DATE =
  LEGACY_CNAM_CUT_OFF_DATE.format('MMMM D, YYYY')

export class CnamProfile implements Model {
  private raw: CodableCnamProfile

  get id(): string {
    return this.raw.id
  }

  get displayName(): string {
    return this.raw.displayName
  }

  get useAsDefault(): boolean {
    return this.raw.useAsDefault
  }

  get resourceIds(): string[] {
    return this.raw.resourceIds
  }

  get status(): CnamProfileStatus {
    return this.raw.status
  }

  get createdAt(): string {
    return this.raw.createdAt
  }

  get etag(): string {
    return this.raw.etag
  }

  get updating(): boolean {
    return this.raw.updating
  }

  get isLegacy(): boolean {
    return dayjs(this.createdAt).isBefore(LEGACY_CNAM_CUT_OFF_DATE)
  }

  constructor(attrs: CodableCnamProfile) {
    this.raw = attrs

    makeObservable<this, 'raw' | 'localUpdate'>(this, {
      raw: observable.deep,
      id: computed,
      displayName: computed,
      useAsDefault: computed,
      resourceIds: computed,
      status: computed,
      createdAt: computed,
      etag: computed,
      updating: computed,
      isLegacy: computed,
      localUpdate: action.bound,
    })
  }

  localUpdate(partial: Partial<CodableCnamProfile>): this {
    this.raw = { ...this.raw, ...partial }
    return this
  }

  deserialize(json: CodableCnamProfile): this {
    this.raw = json
    return this
  }

  serialize(): CodableCnamProfile {
    return toJS(this.raw)
  }
}
