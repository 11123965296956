import { useEffect } from 'react'

import useBannersContext from './useBannersContext'

/**
 * Increments the banner count when the component mounts and decrements it when it unmounts.
 */
export default function useBannerCounter(show = false) {
  const banners = useBannersContext()

  useEffect(() => {
    if (!show) {
      return
    }

    banners.incrementOpenBannerCount()

    return () => {
      banners.decrementOpenBannerCount()
    }
  }, [banners, show])
}
