import { useContext } from 'react'

import type { FormFieldContextType } from './FormFieldContext'
import FormFieldContext from './FormFieldContext'

// IMPORTANT: Make sure to update the `useFormField.stories.mdx` file if you change
// something that requires a documentation change.

/**
 * Hook that allows to build form inputs, selects, and other form elements.
 */
export default function useFormField(
  overrides: Partial<FormFieldContextType> | null = null,
): FormFieldContextType {
  const context = useContext(FormFieldContext)

  return {
    htmlProps: {
      ...context.htmlProps,
      ...overrides?.htmlProps,
    },
    fieldProps: {
      ...context.fieldProps,
      ...overrides?.fieldProps,
    },
  }
}
