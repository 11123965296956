import { useId } from '@react-aria/utils'
import cx from 'classnames'
import type { AriaAttributes, ChangeEvent } from 'react'
import { forwardRef, useEffect, useRef } from 'react'

import * as styles from './Checkbox.css'
import {
  Checkbox_ActiveFullIcon,
  Checkbox_ActivePartIcon,
  Checkbox_DefaultIcon,
} from './icons'

export interface CheckboxPropsBase
  extends Omit<React.HTMLProps<HTMLLabelElement>, 'onChange' | 'onFocus' | 'onBlur'> {
  className?: string
  /**
   * If `true`, the component is checked.
   */
  checked: boolean
  /**
   * If `true`, the component appears indeterminate, regardless of the value of `checked`
   */
  indeterminate?: boolean
  /**
   * Callback fired when the state is changed.
   * @param {boolean} checked The new value of the checkbox.
   * @param {ChangeEvent<HTMLInputElement>} event The HTML element's event
   */
  onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void
  /**
   * If `true`, the checkbox is disabled.
   */
  disabled?: boolean

  onFocus?: React.FocusEventHandler<HTMLInputElement>

  onBlur?: React.FocusEventHandler<HTMLInputElement>
}

type RequireUniqueIdOrLabel =
  | { id: string }
  | { label: string }
  | Required<Pick<AriaAttributes, 'aria-labelledby'>>
  | Required<Pick<AriaAttributes, 'aria-hidden'>>
type CheckboxProps = CheckboxPropsBase & RequireUniqueIdOrLabel

const Checkbox = forwardRef<HTMLLabelElement, CheckboxProps>(
  (
    {
      checked = false,
      indeterminate = false,
      className,
      onChange,
      disabled = false,
      id,
      onFocus,
      onBlur,
      label,
      ...props
    }: CheckboxProps,
    ref,
  ) => {
    const internalId = useId(id)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.indeterminate = indeterminate
      }
    }, [indeterminate])

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.checked = checked
      }
    }, [checked])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.checked, e)
    }

    return (
      <label ref={ref} {...props} className={cx(styles.root({ disabled }), className)}>
        <input
          ref={inputRef}
          className={styles.input}
          type="checkbox"
          onChange={handleChange}
          checked={checked}
          aria-checked={indeterminate ? 'mixed' : checked ? 'true' : 'false'}
          aria-disabled={disabled ? 'true' : 'false'}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          aria-label={label}
          id={id}
        />
        {indeterminate ? (
          <Checkbox_ActivePartIcon
            id={internalId}
            className={cx(styles.icon, styles.activeIcon)}
          />
        ) : checked ? (
          <Checkbox_ActiveFullIcon
            id={internalId}
            className={cx(styles.icon, styles.activeIcon)}
          />
        ) : (
          <Checkbox_DefaultIcon className={cx(styles.icon, styles.defaultIcon)} />
        )}
      </label>
    )
  },
)

export default Checkbox
