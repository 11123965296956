import type { PriceId } from '@src/app/billing/pricing/constants'
import {
  CHARITY_ANNUAL_PRICE_ID,
  CHARITY_PRICE_ID,
  SOLE_PROP_ANNUAL_PRICE_ID,
  SOLE_PROP_PRICE_ID,
  LOW_VOLUME_STANDARD_ANNUAL_PRICE_ID,
  LOW_VOLUME_STANDARD_MONTHLY_PRICE_ID,
} from '@src/app/billing/pricing/constants'

export const isA2PLowVolumeStandardMonthly = (priceId: PriceId) =>
  priceId === LOW_VOLUME_STANDARD_MONTHLY_PRICE_ID

export const isA2PLowVolumeStandardAnnual = (priceId: PriceId) =>
  priceId === LOW_VOLUME_STANDARD_ANNUAL_PRICE_ID

export const isA2PLowVolumeStandard = (priceId: PriceId) =>
  isA2PLowVolumeStandardMonthly(priceId) || isA2PLowVolumeStandardAnnual(priceId)

export const isA2PSolePropMonthly = (priceId: PriceId) => priceId === SOLE_PROP_PRICE_ID

export const isA2PSolePropAnnual = (priceId: PriceId) =>
  priceId === SOLE_PROP_ANNUAL_PRICE_ID

export const isA2PSoleProp = (priceId: PriceId) =>
  isA2PSolePropMonthly(priceId) || isA2PSolePropAnnual(priceId)

export const isA2PCharityMonthly = (priceId: PriceId) => priceId === CHARITY_PRICE_ID

export const isA2PCharityAnnual = (priceId: PriceId) =>
  priceId === CHARITY_ANNUAL_PRICE_ID

export const isA2PCharity = (priceId: PriceId) =>
  isA2PCharityMonthly(priceId) || isA2PCharityAnnual(priceId)

const isA2P = (priceId: PriceId) =>
  isA2PLowVolumeStandard(priceId) || isA2PSoleProp(priceId) || isA2PCharity(priceId)

export default isA2P
