import type { AriaMenuOptions } from 'react-aria'

import MenuPopover from '@ui/Menu/MenuPopover'
import { useMenuTrigger } from '@ui/Menu/MenuProvider'
import { useMaybeSubMenu } from '@ui/Menu/SubMenuProvider'
import type { MenuChild } from '@ui/Menu/types'
import type { PopoverProps } from '@ui/Popover'
import type { ThemeKey } from '@ui/theme'
import { themeClassNames } from '@ui/theme'

import { MenuListContent } from './MenuListContent'
import { MenuListProvider } from './context'

interface MenuPopoverProps {
  /**
   * {@link PopoverProps.placement}
   * @default 'bottom left'
   */
  placement?: PopoverProps['placement']

  /**
   * {@link PopoverProps.offset}
   *
   * @default 6
   */
  offset?: PopoverProps['offset']

  /** {@link PopoverProps.crossOffset} */
  crossOffset?: PopoverProps['crossOffset']
}

export interface MenuListProps<T>
  extends Omit<AriaMenuOptions<T>, 'onClose'>,
    MenuPopoverProps {
  /**
   * A list of items to render. If specified a render function has to be specified as children.
   */
  items?: T[]

  /**
   * The children of the list which can be: Items, Sections, Separators, and Sub Menus.
   */
  children: MenuChild<T>

  /**
   * Enforce themeKey
   */
  themeKey?: ThemeKey
}

export default function MenuList<T extends object>({
  children,
  placement = 'bottom left',
  offset = 6,
  crossOffset,
  themeKey,
  ...props
}: MenuListProps<T>) {
  const {
    state: { isOpen, close },
    ref,
  } = useMenuTrigger()

  const isSubMenu = useMaybeSubMenu()

  if (!isOpen) {
    return null
  }

  if (isSubMenu) {
    placement = 'right top'
    offset = -4
    crossOffset = -6
  }

  return (
    <MenuListProvider outerChildren={children} {...props}>
      <MenuPopover
        placement={placement}
        offset={offset}
        crossOffset={crossOffset}
        targetRef={ref}
        onClose={close}
        className={themeKey ? themeClassNames[themeKey] : undefined}
        // Submenus don't have an underlay to allow hovering back over the parent menu
        // to quickly continue interacting with it, rather than requiring a click away
        // or pressing ESC to exit out of the submenu (both of which still trigger the
        // submenu to close as well)
        withUnderlay={!isSubMenu}
      >
        <MenuListContent />
      </MenuPopover>
    </MenuListProvider>
  )
}
