import { makeAutoObservable } from 'mobx'

import type { PromptSettingsPayload } from '@src/service/transport/AiClient'

import type Service from '..'

export default class AiStore {
  constructor(private root: Service) {
    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )
  }

  togglePrompt(promptId: string, isActive: boolean) {
    return this.root.transport.ai.prompt.toggle(promptId, isActive)
  }

  getPromptSettings(phoneNumberId: string) {
    return this.root.transport.ai.prompt.get(phoneNumberId)
  }

  createPromptSettings(userPrompt: PromptSettingsPayload) {
    return this.root.transport.ai.prompt.create(userPrompt)
  }

  updatePromptSettings(
    promptId: string,
    template: Pick<PromptSettingsPayload, 'template'>,
  ) {
    return this.root.transport.ai.prompt.update(promptId, template)
  }
}
