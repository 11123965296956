import { Routes, Route } from 'react-router-dom'

import { BillingSubscriptionProvider } from '@src/app/billing/subscription'
import SpinnerScreen from '@src/app/components/SpinnerScreen'
import { Flagged } from '@src/app/verification'

const VerificationRoute = () => {
  return (
    <BillingSubscriptionProvider fallback={<SpinnerScreen />}>
      <Routes>
        <Route path="flagged" element={<Flagged />} />
      </Routes>
    </BillingSubscriptionProvider>
  )
}

export default VerificationRoute
