import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

/**
 * Reads the value of the given search param name from the current location
 * and performs a navigation replacement of the current location to remove
 * the param from the location.
 *
 * The value that was initially read is stored and returned as the first item
 * of the tuple. The second item of the tuple is a function that updates the
 * stored value with the newest value of the param from the location. Given that
 * a replace happened it will probably `null` after rereading the value.
 * Therefore, rereading is a useful way to clearing the stored value.
 */
export default function useConsumeSearchParam(param: string) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [consumedParam, setConsumedParam] = useState(() => searchParams.get(param))
  const reread = useCallback(
    () => setConsumedParam(searchParams.get(param)),
    [param, searchParams],
  )

  useEffect(() => {
    if (searchParams.get(param) === null) {
      return
    }

    if (consumedParam === null) {
      reread()
    }

    setSearchParams(
      (prev) => {
        prev.delete(param)
        return prev
      },
      { replace: true },
    )
  }, [searchParams, param, consumedParam, reread, setSearchParams])

  return [consumedParam, reread] as const
}
