import { getTimeZones } from '@vvo/tzdb'
import { nanoid } from 'nanoid'

import formatUTCOffset from './formatUTCOffset'

const rawTimezones = getTimeZones()

export const timezones = rawTimezones.map((timezone) => {
  const offset = timezone.currentTimeOffsetInMinutes
  const formattedOffset = formatUTCOffset(offset)
  const includeMainCity =
    rawTimezones.filter(
      (t) =>
        t.countryName === timezone.countryName &&
        t.alternativeName === timezone.alternativeName,
    ).length > 1

  return {
    id: nanoid(6),
    name: timezone.name,
    displayName: `${formattedOffset} ${timezone.alternativeName}${
      includeMainCity ? ` - ${timezone.mainCities[0]}` : ''
    } (${timezone.countryName})`,
    group: timezone.group,
    offset: formattedOffset,
    offsetInMinutes: offset,
  }
})

/* export const languages = [
  { name: 'Afrikaans (South Africa)', code: 'af-ZA' },
  { name: 'Albanian (Albania)', code: 'sq-AL' },
  { name: 'Amharic (Ethiopia)', code: 'am-ET' },
  { name: 'Arabic (Algeria)', code: 'ar-DZ' },
  { name: 'Arabic (Bahrain)', code: 'ar-BH' },
  { name: 'Arabic (Egypt)', code: 'ar-EG' },
  { name: 'Arabic (Iraq)', code: 'ar-IQ' },
  { name: 'Arabic (Israel)', code: 'ar-IL' },
  { name: 'Arabic (Jordan)', code: 'ar-JO' },
  { name: 'Arabic (Kuwait)', code: 'ar-KW' },
  { name: 'Arabic (Lebanon)', code: 'ar-LB' },
  { name: 'Arabic (Morocco)', code: 'ar-MA' },
  { name: 'Arabic (Oman)', code: 'ar-OM' },
  { name: 'Arabic (Qatar)', code: 'ar-QA' },
  { name: 'Arabic (Saudi Arabia)', code: 'ar-SA' },
  { name: 'Arabic (State of Palestine)', code: 'ar-PS' },
  { name: 'Arabic (Tunisia)', code: 'ar-TN' },
  { name: 'Arabic (United Arab Emirates)', code: 'ar-AE' },
  { name: 'Arabic (Yemen)', code: 'ar-YE' },
  { name: 'Armenian (Armenia)', code: 'hy-AM' },
  { name: 'Azerbaijani (Azerbaijan)', code: 'az-AZ' },
  { name: 'Basque (Spain)', code: 'eu-ES' },
  { name: 'Bengali (Bangladesh)', code: 'bn-BD' },
  { name: 'Bengali (India)', code: 'bn-IN' },
  { name: 'Bosnian (Bosnia and Herzegovina)', code: 'bs-BA' },
  { name: 'Bulgarian (Bulgaria)', code: 'bg-BG' },
  { name: 'Burmese (Myanmar)', code: 'my-MM' },
  { name: 'Catalan (Spain)', code: 'ca-ES' },
  { name: 'Chinese, Cantonese (Traditional Hong Kong)', code: 'yue-Hant-HK' },
  { name: 'Chinese, Mandarin (Simplified, China)', code: 'zh (cmn-Hans-CN)' },
  { name: 'Chinese, Mandarin (Traditional, Taiwan)', code: 'zh-TW (cmn-Hant-TW)' },
  { name: 'Croatian (Croatia)', code: 'hr-HR' },
  { name: 'Czech (Czech Republic)', code: 'cs-CZ' },
  { name: 'Danish (Denmark)', code: 'da-DK' },
  { name: 'Dutch (Belgium)', code: 'nl-BE' },
  { name: 'Dutch (Netherlands)', code: 'nl-NL' },
  { name: 'English (Australia)', code: 'en-AU' },
  { name: 'English (Canada)', code: 'en-CA' },
  { name: 'English (Ghana)', code: 'en-GH' },
  { name: 'English (Hong Kong)', code: 'en-HK' },
  { name: 'English (India)', code: 'en-IN' },
  { name: 'English (Ireland)', code: 'en-IE' },
  { name: 'English (Kenya)', code: 'en-KE' },
  { name: 'English (New Zealand)', code: 'en-NZ' },
  { name: 'English (Nigeria)', code: 'en-NG' },
  { name: 'English (Pakistan)', code: 'en-PK' },
  { name: 'English (Philippines)', code: 'en-PH' },
  { name: 'English (Singapore)', code: 'en-SG' },
  { name: 'English (South Africa)', code: 'en-ZA' },
  { name: 'English (Tanzania)', code: 'en-TZ' },
  { name: 'English (United Kingdom)', code: 'en-GB' },
  { name: 'English (United States)', code: 'en-US' },
  { name: 'Estonian (Estonia)', code: 'et-EE' },
  { name: 'Filipino (Philippines)', code: 'fil-PH' },
  { name: 'Finnish (Finland)', code: 'fi-FI' },
  { name: 'French (Belgium)', code: 'fr-BE' },
  { name: 'French (Canada)', code: 'fr-CA' },
  { name: 'French (France)', code: 'fr-FR' },
  { name: 'French (Switzerland)', code: 'fr-CH' },
  { name: 'Galician (Spain)', code: 'gl-ES' },
  { name: 'Georgian (Georgia)', code: 'ka-GE' },
  { name: 'German (Austria)', code: 'de-AT' },
  { name: 'German (Germany)', code: 'de-DE' },
  { name: 'German (Switzerland)', code: 'de-CH' },
  { name: 'Greek (Greece)', code: 'el-GR' },
  { name: 'Gujarati (India)', code: 'gu-IN' },
  { name: 'Hebrew (Israel)', code: 'iw-IL' },
  { name: 'Hindi (India)', code: 'hi-IN' },
  { name: 'Hungarian (Hungary)', code: 'hu-HU' },
  { name: 'Icelandic (Iceland)', code: 'is-IS' },
  { name: 'Indonesian (Indonesia)', code: 'id-ID' },
  { name: 'Italian (Italy)', code: 'it-IT' },
  { name: 'Italian (Switzerland)', code: 'it-CH' },
  { name: 'Japanese (Japan)', code: 'ja-JP' },
  { name: 'Javanese (Indonesia)', code: 'jv-ID' },
  { name: 'Kannada (India)', code: 'kn-IN' },
  { name: 'Kazakh (Kazakhstan)', code: 'kk-KZ' },
  { name: 'Khmer (Cambodia)', code: 'km-KH' },
  { name: 'Korean (South Korea)', code: 'ko-KR' },
  { name: 'Lao (Laos)', code: 'lo-LA' },
  { name: 'Latvian (Latvia)', code: 'lv-LV' },
  { name: 'Lithuanian (Lithuania)', code: 'lt-LT' },
  { name: 'Macedonian (North Macedonia)', code: 'mk-MK' },
  { name: 'Malay (Malaysia)', code: 'ms-MY' },
  { name: 'Malayalam (India)', code: 'ml-IN' },
  { name: 'Marathi (India)', code: 'mr-IN' },
  { name: 'Mongolian (Mongolia)', code: 'mn-MN' },
  { name: 'Nepali (Nepal)', code: 'ne-NP' },
  { name: 'Norwegian Bokmål (Norway)', code: 'no-NO' },
  { name: 'Persian (Iran)', code: 'fa-IR' },
  { name: 'Polish (Poland)', code: 'pl-PL' },
  { name: 'Portuguese (Brazil)', code: 'pt-BR' },
  { name: 'Portuguese (Portugal)', code: 'pt-PT' },
  { name: 'Punjabi (Gurmukhi India)', code: 'pa-Guru-IN' },
  { name: 'Romanian (Romania)', code: 'ro-RO' },
  { name: 'Russian (Russia)', code: 'ru-RU' },
  { name: 'Serbian (Serbia)', code: 'sr-RS' },
  { name: 'Sinhala (Sri Lanka)', code: 'si-LK' },
  { name: 'Slovak (Slovakia)', code: 'sk-SK' },
  { name: 'Slovenian (Slovenia)', code: 'sl-SI' },
  { name: 'Spanish (Argentina)', code: 'es-AR' },
  { name: 'Spanish (Bolivia)', code: 'es-BO' },
  { name: 'Spanish (Chile)', code: 'es-CL' },
  { name: 'Spanish (Colombia)', code: 'es-CO' },
  { name: 'Spanish (Costa Rica)', code: 'es-CR' },
  { name: 'Spanish (Dominican Republic)', code: 'es-DO' },
  { name: 'Spanish (Ecuador)', code: 'es-EC' },
  { name: 'Spanish (El Salvador)', code: 'es-SV' },
  { name: 'Spanish (Guatemala)', code: 'es-GT' },
  { name: 'Spanish (Honduras)', code: 'es-HN' },
  { name: 'Spanish (Mexico)', code: 'es-MX' },
  { name: 'Spanish (Nicaragua)', code: 'es-NI' },
  { name: 'Spanish (Panama)', code: 'es-PA' },
  { name: 'Spanish (Paraguay)', code: 'es-PY' },
  { name: 'Spanish (Peru)', code: 'es-PE' },
  { name: 'Spanish (Puerto Rico)', code: 'es-PR' },
  { name: 'Spanish (Spain)', code: 'es-ES' },
  { name: 'Spanish (United States)', code: 'es-US' },
  { name: 'Spanish (Uruguay)', code: 'es-UY' },
  { name: 'Spanish (Venezuela)', code: 'es-VE' },
  { name: 'Sundanese (Indonesia)', code: 'su-ID' },
  { name: 'Swahili (Kenya)', code: 'sw-KE' },
  { name: 'Swahili (Tanzania)', code: 'sw-TZ' },
  { name: 'Swedish (Sweden)', code: 'sv-SE' },
  { name: 'Tamil (India)', code: 'ta-IN' },
  { name: 'Tamil (Malaysia)', code: 'ta-MY' },
  { name: 'Tamil (Singapore)', code: 'ta-SG' },
  { name: 'Tamil (Sri Lanka)', code: 'ta-LK' },
  { name: 'Telugu (India)', code: 'te-IN' },
  { name: 'Thai (Thailand)', code: 'th-TH' },
  { name: 'Turkish (Turkey)', code: 'tr-TR' },
  { name: 'Ukrainian (Ukraine)', code: 'uk-UA' },
  { name: 'Urdu (India)', code: 'ur-IN' },
  { name: 'Urdu (Pakistan)', code: 'ur-PK' },
  { name: 'Uzbek (Uzbekistan)', code: 'uz-UZ' },
  { name: 'Vietnamese (Vietnam)', code: 'vi-VN' },
  { name: 'Zulu (South Africa)', code: 'zu-ZA' },
] */

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const timeOptions = [
  { value: '0000', label: '12:00 AM' },
  { value: '0030', label: '12:30 AM' },
  { value: '0100', label: '01:00 AM' },
  { value: '0130', label: '01:30 AM' },
  { value: '0200', label: '02:00 AM' },
  { value: '0230', label: '02:30 AM' },
  { value: '0300', label: '03:00 AM' },
  { value: '0330', label: '03:30 AM' },
  { value: '0400', label: '04:00 AM' },
  { value: '0430', label: '04:30 AM' },
  { value: '0500', label: '05:00 AM' },
  { value: '0530', label: '05:30 AM' },
  { value: '0600', label: '06:00 AM' },
  { value: '0630', label: '06:30 AM' },
  { value: '0700', label: '07:00 AM' },
  { value: '0730', label: '07:30 AM' },
  { value: '0800', label: '08:00 AM' },
  { value: '0830', label: '08:30 AM' },
  { value: '0900', label: '09:00 AM' },
  { value: '0930', label: '09:30 AM' },
  { value: '1000', label: '10:00 AM' },
  { value: '1030', label: '10:30 AM' },
  { value: '1100', label: '11:00 AM' },
  { value: '1130', label: '11:30 AM' },
  { value: '1200', label: '12:00 PM' },
  { value: '1230', label: '12:30 PM' },
  { value: '1300', label: '01:00 PM' },
  { value: '1330', label: '01:30 PM' },
  { value: '1400', label: '02:00 PM' },
  { value: '1430', label: '02:30 PM' },
  { value: '1500', label: '03:00 PM' },
  { value: '1530', label: '03:30 PM' },
  { value: '1600', label: '04:00 PM' },
  { value: '1630', label: '04:30 PM' },
  { value: '1700', label: '05:00 PM' },
  { value: '1730', label: '05:30 PM' },
  { value: '1800', label: '06:00 PM' },
  { value: '1830', label: '06:30 PM' },
  { value: '1900', label: '07:00 PM' },
  { value: '1930', label: '07:30 PM' },
  { value: '2000', label: '08:00 PM' },
  { value: '2030', label: '08:30 PM' },
  { value: '2100', label: '09:00 PM' },
  { value: '2130', label: '09:30 PM' },
  { value: '2200', label: '10:00 PM' },
  { value: '2230', label: '10:30 PM' },
  { value: '2300', label: '11:00 PM' },
  { value: '2330', label: '11:30 PM' },
]

// Extracted from https://compass.greatdata.com/areacode/currenttime
const usAreaCodeTimezone = {
  '201': 'America/New_York',
  '202': 'America/New_York',
  '203': 'America/New_York',
  '205': 'America/Chicago',
  '206': 'America/Los_Angeles',
  '207': 'America/New_York',
  '208': 'America/Boise',
  '209': 'America/Los_Angeles',
  '210': 'America/Chicago',
  '212': 'America/New_York',
  '213': 'America/Los_Angeles',
  '214': 'America/Chicago',
  '215': 'America/New_York',
  '216': 'America/New_York',
  '217': 'America/Chicago',
  '218': 'America/Chicago',
  '219': 'America/Chicago',
  '220': 'America/New_York',
  '223': 'America/New_York',
  '224': 'America/Chicago',
  '225': 'America/Chicago',
  '228': 'America/Chicago',
  '229': 'America/New_York',
  '231': 'America/New_York',
  '234': 'America/New_York',
  '239': 'America/New_York',
  '240': 'America/New_York',
  '248': 'America/New_York',
  '251': 'America/Chicago',
  '252': 'America/New_York',
  '253': 'America/Los_Angeles',
  '254': 'America/Chicago',
  '256': 'America/Chicago',
  '260': 'America/New_York',
  '262': 'America/Chicago',
  '267': 'America/New_York',
  '269': 'America/New_York',
  '270': 'America/Chicago',
  '272': 'America/New_York',
  '276': 'America/New_York',
  '279': 'America/Los_Angeles',
  '281': 'America/Chicago',
  '301': 'America/New_York',
  '302': 'America/New_York',
  '303': 'America/Denver',
  '304': 'America/New_York',
  '305': 'America/New_York',
  '307': 'America/Denver',
  '308': 'America/Chicago',
  '309': 'America/Chicago',
  '310': 'America/Los_Angeles',
  '312': 'America/Chicago',
  '313': 'America/New_York',
  '314': 'America/Chicago',
  '315': 'America/New_York',
  '316': 'America/Chicago',
  '317': 'America/New_York',
  '318': 'America/Chicago',
  '319': 'America/Chicago',
  '320': 'America/Chicago',
  '321': 'America/New_York',
  '323': 'America/Los_Angeles',
  '325': 'America/Chicago',
  '326': 'America/New_York',
  '330': 'America/New_York',
  '331': 'America/Chicago',
  '332': 'America/New_York',
  '334': 'America/Chicago',
  '336': 'America/New_York',
  '337': 'America/Chicago',
  '339': 'America/New_York',
  '341': 'America/Los_Angeles',
  '346': 'America/Chicago',
  '347': 'America/New_York',
  '351': 'America/New_York',
  '352': 'America/New_York',
  '360': 'America/Los_Angeles',
  '361': 'America/Chicago',
  '364': 'America/Chicago',
  '380': 'America/New_York',
  '385': 'America/Denver',
  '386': 'America/New_York',
  '401': 'America/New_York',
  '402': 'America/Chicago',
  '404': 'America/New_York',
  '405': 'America/Chicago',
  '406': 'America/Denver',
  '407': 'America/New_York',
  '408': 'America/Los_Angeles',
  '409': 'America/Chicago',
  '410': 'America/New_York',
  '412': 'America/New_York',
  '413': 'America/New_York',
  '414': 'America/Chicago',
  '415': 'America/Los_Angeles',
  '417': 'America/Chicago',
  '419': 'America/New_York',
  '423': 'America/New_York',
  '424': 'America/Los_Angeles',
  '425': 'America/Los_Angeles',
  '430': 'America/Chicago',
  '432': 'America/Chicago',
  '434': 'America/New_York',
  '435': 'America/Denver',
  '440': 'America/New_York',
  '442': 'America/Los_Angeles',
  '443': 'America/New_York',
  '445': 'America/New_York',
  '456': null,
  '458': 'America/Los_Angeles',
  '463': 'America/New_York',
  '469': 'America/Chicago',
  '470': 'America/New_York',
  '475': 'America/New_York',
  '478': 'America/New_York',
  '479': 'America/Chicago',
  '480': 'America/Phoenix',
  '484': 'America/New_York',
  '500': null,
  '501': 'America/Chicago',
  '502': 'America/New_York',
  '503': 'America/Los_Angeles',
  '504': 'America/Chicago',
  '505': 'America/Denver',
  '507': 'America/Chicago',
  '508': 'America/New_York',
  '509': 'America/Los_Angeles',
  '510': 'America/Los_Angeles',
  '512': 'America/Chicago',
  '513': 'America/New_York',
  '515': 'America/Chicago',
  '516': 'America/New_York',
  '517': 'America/New_York',
  '518': 'America/New_York',
  '520': 'America/Phoenix',
  '521': null,
  '522': null,
  '523': null,
  '524': null,
  '530': 'America/Los_Angeles',
  '531': 'America/Chicago',
  '533': null,
  '534': 'America/Chicago',
  '539': 'America/Chicago',
  '540': 'America/New_York',
  '541': 'America/Los_Angeles',
  '544': null,
  '551': 'America/New_York',
  '559': 'America/Los_Angeles',
  '561': 'America/New_York',
  '562': 'America/Los_Angeles',
  '563': 'America/Chicago',
  '564': 'America/Los_Angeles',
  '566': null,
  '567': 'America/New_York',
  '570': 'America/New_York',
  '571': 'America/New_York',
  '573': 'America/Chicago',
  '574': 'America/New_York',
  '575': 'America/Denver',
  '580': 'America/Chicago',
  '585': 'America/New_York',
  '586': 'America/New_York',
  '588': null,
  '601': 'America/Chicago',
  '602': 'America/Phoenix',
  '603': 'America/New_York',
  '605': 'America/Chicago',
  '606': 'America/New_York',
  '607': 'America/New_York',
  '608': 'America/Chicago',
  '609': 'America/New_York',
  '610': 'America/New_York',
  '611': null,
  '612': 'America/Chicago',
  '614': 'America/New_York',
  '615': 'America/Chicago',
  '616': 'America/New_York',
  '617': 'America/New_York',
  '618': 'America/Chicago',
  '619': 'America/Los_Angeles',
  '620': 'America/Chicago',
  '623': 'America/Phoenix',
  '626': 'America/Los_Angeles',
  '628': 'America/Los_Angeles',
  '629': 'America/Chicago',
  '630': 'America/Chicago',
  '631': 'America/New_York',
  '636': 'America/Chicago',
  '640': 'America/New_York',
  '641': 'America/Chicago',
  '646': 'America/New_York',
  '650': 'America/Los_Angeles',
  '651': 'America/Chicago',
  '657': 'America/Los_Angeles',
  '659': 'America/Chicago',
  '660': 'America/Chicago',
  '661': 'America/Los_Angeles',
  '662': 'America/Chicago',
  '667': 'America/New_York',
  '669': 'America/Los_Angeles',
  '678': 'America/New_York',
  '680': 'America/New_York',
  '681': 'America/New_York',
  '682': 'America/Chicago',
  '689': 'America/New_York',
  '700': null,
  '701': 'America/Chicago',
  '702': 'America/Los_Angeles',
  '703': 'America/New_York',
  '704': 'America/New_York',
  '706': 'America/New_York',
  '707': 'America/Los_Angeles',
  '708': 'America/Chicago',
  '710': null,
  '712': 'America/Chicago',
  '713': 'America/Chicago',
  '714': 'America/Los_Angeles',
  '715': 'America/Chicago',
  '716': 'America/New_York',
  '717': 'America/New_York',
  '718': 'America/New_York',
  '719': 'America/Denver',
  '720': 'America/Denver',
  '724': 'America/New_York',
  '725': 'America/Los_Angeles',
  '726': 'America/Chicago',
  '727': 'America/New_York',
  '731': 'America/Chicago',
  '732': 'America/New_York',
  '734': 'America/New_York',
  '737': 'America/Chicago',
  '740': 'America/New_York',
  '743': 'America/New_York',
  '747': 'America/Los_Angeles',
  '754': 'America/New_York',
  '757': 'America/New_York',
  '760': 'America/Los_Angeles',
  '762': 'America/New_York',
  '763': 'America/Chicago',
  '765': 'America/New_York',
  '769': 'America/Chicago',
  '770': 'America/New_York',
  '772': 'America/New_York',
  '773': 'America/Chicago',
  '774': 'America/New_York',
  '775': 'America/Los_Angeles',
  '779': 'America/Chicago',
  '781': 'America/New_York',
  '785': 'America/Chicago',
  '786': 'America/New_York',
  '800': null,
  '801': 'America/Denver',
  '802': 'America/New_York',
  '803': 'America/New_York',
  '804': 'America/New_York',
  '805': 'America/Los_Angeles',
  '806': 'America/Chicago',
  '808': 'Pacific/Honolulu',
  '809': null,
  '810': 'America/New_York',
  '812': 'America/New_York',
  '813': 'America/New_York',
  '814': 'America/New_York',
  '815': 'America/Chicago',
  '816': 'America/Chicago',
  '817': 'America/Chicago',
  '818': 'America/Los_Angeles',
  '820': 'America/Los_Angeles',
  '828': 'America/New_York',
  '830': 'America/Chicago',
  '831': 'America/Los_Angeles',
  '832': 'America/Chicago',
  '833': null,
  '838': 'America/New_York',
  '843': 'America/New_York',
  '844': null,
  '845': 'America/New_York',
  '847': 'America/Chicago',
  '848': 'America/New_York',
  '850': 'America/Chicago',
  '854': 'America/New_York',
  '855': null,
  '856': 'America/New_York',
  '857': 'America/New_York',
  '858': 'America/Los_Angeles',
  '859': 'America/New_York',
  '860': 'America/New_York',
  '862': 'America/New_York',
  '863': 'America/New_York',
  '864': 'America/New_York',
  '865': 'America/New_York',
  '866': null,
  '870': 'America/Chicago',
  '872': 'America/Chicago',
  '877': null,
  '878': 'America/New_York',
  '880': null,
  '881': null,
  '888': null,
  '900': null,
  '901': 'America/Chicago',
  '903': 'America/Chicago',
  '904': 'America/New_York',
  '906': 'America/New_York',
  '907': 'America/Anchorage',
  '908': 'America/New_York',
  '909': 'America/Los_Angeles',
  '910': 'America/New_York',
  '912': 'America/New_York',
  '913': 'America/Chicago',
  '914': 'America/New_York',
  '915': 'America/Denver',
  '916': 'America/Los_Angeles',
  '917': 'America/New_York',
  '918': 'America/Chicago',
  '919': 'America/New_York',
  '920': 'America/Chicago',
  '925': 'America/Los_Angeles',
  '928': 'America/Phoenix',
  '929': 'America/New_York',
  '930': 'America/New_York',
  '931': 'America/Chicago',
  '934': 'America/New_York',
  '936': 'America/Chicago',
  '937': 'America/New_York',
  '938': 'America/Chicago',
  '940': 'America/Chicago',
  '941': 'America/New_York',
  '947': 'America/New_York',
  '949': 'America/Los_Angeles',
  '951': 'America/Los_Angeles',
  '952': 'America/Chicago',
  '954': 'America/New_York',
  '956': 'America/Chicago',
  '959': 'America/New_York',
  '970': 'America/Denver',
  '971': 'America/Los_Angeles',
  '972': 'America/Chicago',
  '973': 'America/New_York',
  '978': 'America/New_York',
  '979': 'America/Chicago',
  '980': 'America/New_York',
  '983': 'America/Denver',
  '984': 'America/New_York',
  '985': 'America/Chicago',
  '986': 'America/Boise',
  '989': 'America/New_York',
}

// Extracted from https://compass.greatdata.com/areacode/currenttime
const canadaAreaCodeTimezone = {
  '204': 'America/Winnipeg',
  '226': 'America/Toronto',
  '236': 'America/Vancouver',
  '249': 'America/Toronto',
  '250': 'America/Vancouver',
  '263': 'America/Toronto',
  '289': 'America/Toronto',
  '306': 'America/Regina',
  '343': 'America/Toronto',
  '354': 'America/Toronto',
  '365': 'America/Toronto',
  '367': 'America/Toronto',
  '368': 'America/Edmonton',
  '403': 'America/Edmonton',
  '416': 'America/Toronto',
  '418': 'America/Toronto',
  '431': 'America/Winnipeg',
  '437': 'America/Toronto',
  '438': 'America/Toronto',
  '450': 'America/Toronto',
  '468': 'America/Toronto',
  '474': 'America/Regina',
  '506': 'America/Halifax',
  '514': 'America/Toronto',
  '519': 'America/Toronto',
  '548': 'America/Toronto',
  '579': 'America/Toronto',
  '581': 'America/Toronto',
  '587': 'America/Edmonton',
  '604': 'America/Vancouver',
  '613': 'America/Toronto',
  '622': null,
  '639': 'America/Regina',
  '647': 'America/Toronto',
  '672': 'America/Vancouver',
  '683': 'America/Toronto',
  '705': 'America/Toronto',
  '709': 'America/St_Johns',
  '742': 'America/Toronto',
  '778': 'America/Vancouver',
  '780': 'America/Edmonton',
  '782': 'America/Halifax',
  '807': 'America/Toronto',
  '819': 'America/Toronto',
  '825': 'America/Edmonton',
  '867': 'America/Whitehorse',
  '873': 'America/Toronto',
  '902': 'America/Halifax',
  '905': 'America/Toronto',
}

export const usAndCanadaAreaCodeTimezone = {
  ...usAreaCodeTimezone,
  ...canadaAreaCodeTimezone,
}

export const ringDurationOptions = [
  { name: '5 seconds', value: '5' },
  { name: '10 seconds', value: '10' },
  { name: '15 seconds', value: '15' },
  { name: '20 seconds', value: '20' },
  { name: '25 seconds', value: '25' },
  { name: '30 seconds', value: '30' },
  { name: '45 seconds', value: '45' },
  { name: '60 seconds', value: '60' },
  { name: '90 seconds', value: '90' },
]
