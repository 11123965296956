import type { PhoneNumberSelection } from '@src/component/phone-number-selector/PhoneNumberSelectorController'
import { isDirectNumber } from '@src/service/model/DirectNumberModel'
import type { VoiceTarget } from '@src/service/transport/voice'

export default function convertSelectionToVoiceTarget(
  selection: PhoneNumberSelection,
): VoiceTarget {
  switch (selection.type) {
    case 'inbox':
      return { phoneNumber: selection.to.number }
    case 'inbox-member':
    case 'member':
      return {
        userId: selection.to.id,
        ...(isDirectNumber(selection.via)
          ? { directNumber: selection.via }
          : { phoneNumber: selection.via }),
      }
    case 'contact':
      return { phoneNumber: selection.via }
    case 'add-number':
    case 'number':
      return { phoneNumber: selection.to }
  }
}
