import * as styles from './AnimatingLogo.css'

function AnimatingLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"
      viewBox="0 0 648 471"
      role="img"
      aria-label="animating logo"
      aria-hidden
      className={styles.logo}
    >
      <g fill="none" fillRule="evenodd">
        <defs>
          <mask id="left">
            <rect width="100%" height="100%" fill="white" />
            <circle cx="412.5" cy="235.5" r="235.5" fill="black" />
          </mask>
          <mask id="right">
            <rect width="100%" height="100%" fill="white" />
            <circle cx="176" cy="236" r="176" fill="black" />
          </mask>
        </defs>
        <circle className={styles.circle} cx="176" cy="236" r="176" mask="url(#left)" />
        <circle
          cx="412.5"
          cy="235.5"
          r="235.5"
          fillRule="nonzero"
          mask="url(#right)"
          className={styles.circle}
        />
      </g>
    </svg>
  )
}

export default AnimatingLogo
