import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib/date'
import uuid from '@src/lib/uuid'
import { ActivityReaction } from '@src/service/model/reactions'
import type { ActivityCodableReaction } from '@src/service/model/reactions'

import type { CodableMessageMedia } from './MessageMediaModel'
import MessageMediaModel from './MessageMediaModel'
import type { ActivityModel, Enrichment } from './activity'
import type { Model } from './base'

export interface CodableComment {
  id: string
  body: string | null
  enrichment: Enrichment | null
  media: CodableMessageMedia[]
  reactions: ActivityCodableReaction[]
  createdAt: number | null
  updatedAt: number | null
  userId: string | null
}

export interface EncodableComment {
  id: string
  activityId: string
  body: string | null
  media?: CodableMessageMedia[]
}

export interface DecodableComment extends CodableComment {
  activityId?: string
  conversationId?: string
}

export class Comment implements Model, CodableComment {
  readonly type = 'message'

  id: string = `CM${uuid()}`.replace(/-/g, '')
  body: string | null = null
  enrichment: Enrichment | null = null
  media: MessageMediaModel[] = []
  createdAt: number | null = Date.now()
  updatedAt: number | null = Date.now()
  userId: string | null = null

  // Relations
  reactions: ActivityReaction[] = []

  constructor(
    readonly activity: ActivityModel,
    attrs: Partial<CodableComment> | Partial<DecodableComment> = {},
  ) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
  }

  deserialize(attrs?: Partial<CodableComment> | Partial<DecodableComment>) {
    if (attrs) {
      const { media, reactions, ...json } = attrs
      Object.assign(this, json)
      this.media = media?.map((m) => new MessageMediaModel().deserialize(m)) ?? []
      this.reactions = reactions?.map?.((r) => new ActivityReaction(this, r)) ?? []
      const createdAt = json.createdAt || this.createdAt
      this.createdAt = createdAt ? parseDate(createdAt) : null
      const updatedAt = json.updatedAt || this.updatedAt
      this.updatedAt = updatedAt ? parseDate(updatedAt) : null
    }
    return this
  }

  serialize(): CodableComment {
    return {
      id: this.id,
      body: this.body,
      media: this.media?.map((m) => m.serialize()),
      reactions: this.reactions?.map((r) => r.serialize()),
      enrichment: toJS(this.enrichment),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }

  toJSON(): EncodableComment {
    return {
      id: this.id,
      activityId: this.activity.id,
      body: this.body,
      media: this.media?.map((m) => m.serialize()),
    }
  }
}
