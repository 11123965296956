import { computed, makeObservable } from 'mobx'

import type {
  LiteTollFreeRegistration,
  TollFreeRegistrationStatus,
} from '@src/app/settings/trust/trustTypes'

import type { Model } from './base'

export default class TollFreeRegistrationLiteModel implements Model {
  id = ''
  canMessageCANumbers = false
  canMessageUSNumbers = false
  object = 'TFNRegistration'
  registrationStatus: TollFreeRegistrationStatus = 'draft'

  constructor(attrs: LiteTollFreeRegistration) {
    this.deserialize(attrs)
    makeObservable(this, {
      isSubmitted: computed,
    })
  }

  deserialize(json: LiteTollFreeRegistration): this {
    Object.assign(this, json)
    return this
  }

  serialize(): LiteTollFreeRegistration {
    return {
      id: this.id,
      canMessageCANumbers: this.canMessageCANumbers,
      canMessageUSNumbers: this.canMessageUSNumbers,
      object: 'TFNRegistration',
      registrationStatus: this.registrationStatus,
    }
  }

  get isSubmitted(): boolean {
    return this.registrationStatus === 'submitted'
  }
}
