export type EventMap = { [key: string]: (...args: any[]) => void }

export default class EventEmitter<Events extends EventMap> {
  private readonly _listeners: { [E in keyof Events]?: Events[E][] } = {}

  on<E extends keyof Events>(event: E, fn: Events[E]): void {
    const listeners: Events[E][] = this._listeners[event] ?? []
    listeners.push(fn)
    this._listeners[event] = listeners
  }

  off<E extends keyof Events>(event: E, fn?: Events[E]): void {
    const listeners = this._listeners[event] ?? []
    this._listeners[event] = fn ? listeners.filter((listener) => listener !== fn) : []
  }

  emit<E extends keyof Events>(event: E, ...args: Parameters<Events[E]>): void {
    const listeners = this._listeners[event] ?? []

    for (const listener of listeners) {
      listener(...args)
    }
  }
}
