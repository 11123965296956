import { observer } from 'mobx-react-lite'

import { AppAvatar } from '@src/app/components/app-avatar'
import type { Identity } from '@src/service/model'
import AvatarGroup, { type AvatarGroupProps } from '@ui/AvatarGroup'

export interface AppAvatarGroupProps extends Omit<AvatarGroupProps, 'children'> {
  identities: Identity[]
  classNameForAvatar?: (identity: Identity) => string
  classNameForCircle?: (identity: Identity) => string
}

const AppAvatarGroup = observer(
  ({
    identities,
    classNameForAvatar,
    classNameForCircle,
    ...props
  }: AppAvatarGroupProps) => {
    return (
      <AvatarGroup {...props}>
        {identities.map((identity, index) => (
          <AppAvatar
            // The index is part of the key in case the list contains
            // multiple of the same identity
            key={`${identity['phoneNumber'] ?? identity.id}-${index}`}
            className={classNameForAvatar?.(identity)}
            circleClassName={classNameForCircle?.(identity)}
            identity={identity}
            hideStatus={true}
          />
        ))}
      </AvatarGroup>
    )
  },
)

AppAvatarGroup.displayName = 'AppAvatarGroup'

export default AppAvatarGroup
