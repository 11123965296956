import type { ListState } from '@react-stately/list'
import type { FocusableElement } from '@react-types/shared'
import type { KeyboardEvent, RefObject } from 'react'
import type { AriaGridListOptions, GridListAria } from 'react-aria'
import { useGridList as useDefaultGridList } from 'react-aria'

export interface GridProps<T> extends AriaGridListOptions<T> {
  /**
   * Overrides the default implementation of the escape handler.
   * The {@link AriaGridListOptions.disallowEmptySelection `disallowEmptySelection`}
   * property is respected in every case we clear the selection.
   * As it does in the default implementation.
   *
   * The possible values are:
   * - `clear-and-exit`: Clears the selection and closes the combobox
   * - `clear`: Clears the selection
   * - `exit`: Closes the combobox without modifying the selection
   *
   * @default `exit`
   */
  escapeBehavior?: 'clear-and-exit' | 'clear' | 'exit'
}

/**
 * The default behavior of the grid list is to clear the selection and close
 * There's this property called `disallowEmptySelection` which if its enabled
 * it won't clear the selection but the combobox will be kept opened.
 *
 * Therefore, this custom hook overrides the escape key handler from react
 * aria and adds more options to it using the
 * {@link GridProps.escapeBehavior `escapeBehavior`} prop.
 */
export default function useGridList<T>(
  props: GridProps<T>,
  state: ListState<T>,
  ref: RefObject<HTMLElement>,
): GridListAria {
  const { escapeBehavior = 'exit', disallowEmptySelection } = props
  const { gridProps } = useDefaultGridList(props, state, ref)
  const defaultKeyDownHandler = gridProps.onKeyDown

  const onKeyDown = (event: KeyboardEvent<FocusableElement>) => {
    if (event.key === 'Escape') {
      event.preventDefault()
      switch (escapeBehavior) {
        case 'clear': {
          if (!disallowEmptySelection) {
            state.selectionManager.clearSelection()
            event.stopPropagation()
          }
          break
        }
        case 'clear-and-exit': {
          if (!disallowEmptySelection) {
            state.selectionManager.clearSelection()
          }
          break
        }
      }

      return
    }

    if (defaultKeyDownHandler) {
      defaultKeyDownHandler(event)
    }
  }

  gridProps.onKeyDown = onKeyDown

  return { gridProps }
}
