import classNames from 'classnames'
import { createContext } from 'react'

import type { ThemeMatch } from './createThemeMatch'
import createThemeMatch from './createThemeMatch'
import type { ThemeKey } from './themes/common'
import { themeClassNames } from './themes/common'
import { typographyThemeClassNames } from './typography/themes/common'
import type { TypographyThemeKey } from './typography/themes/common'

export interface ThemeContextType {
  themeKey: ThemeKey
  typographyThemeKey: TypographyThemeKey
  themeClassName: string
  match: ThemeMatch
}

const ThemeContext = createContext(createThemeContextValue('light', 'default'))

export default ThemeContext

export function createThemeContextValue(
  themeKey: ThemeKey,
  typographyThemeKey: TypographyThemeKey,
) {
  return {
    themeKey,
    typographyThemeKey,
    themeClassName: classNames(
      themeClassNames[themeKey],
      typographyThemeClassNames[typographyThemeKey],
    ),
    match: createThemeMatch(themeKey),
  }
}
