import { zodResolver } from '@hookform/resolvers/zod'
import { observer } from 'mobx-react-lite'
import { useForm } from 'react-hook-form'
import { Navigate, useMatch, useSearchParams } from 'react-router-dom'
import { z } from 'zod'

import { useAppStore } from '@src/app/context'
import Header from '@src/app/onboarding/Header'
import { useRecaptcha } from '@src/app/recaptcha'
import Button from '@src/component/ButtonV2'
import Illustration from '@src/component/illustrations'
import CheckEmail from '@src/component/illustrations/CheckEmail'
import TextField from '@ui/TextField/TextField'
import Typography from '@ui/Typography'

import * as styles from './EmailCode.css'

interface EmailCodeForm {
  code: string
}

const emailCodeSchema = z.object({
  code: z.string().nonempty('Code is required'),
})

function EmailCode() {
  const { login: loginStore, history } = useAppStore()
  const isSignupCodePage = useMatch('/signup/code')
  const [searchParams] = useSearchParams()

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<EmailCodeForm>({
    mode: 'onChange',
    resolver: zodResolver(emailCodeSchema),
    defaultValues: {
      code: '',
    },
  })

  const { isReady: isGrecaptchaReady, generateToken } = useRecaptcha()

  const disabled = !isValid || !isGrecaptchaReady

  const onSubmit = handleSubmit(async ({ code }) => {
    let recaptcha_token: string | undefined
    try {
      recaptcha_token = await generateToken(
        loginStore.mode === 'login' ? 'login_code' : 'signup_code',
      )
    } finally {
      loginStore.loginWithCode(code, recaptcha_token)
    }
  })

  if (isSignupCodePage && !searchParams.get('email')) {
    return <Navigate to="/signup" />
  }

  return (
    <form onSubmit={onSubmit}>
      <Header />
      <div className={styles.card}>
        <Illustration illustration={CheckEmail} className={styles.image} />
        <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
          Check your email
        </Typography>
        <Typography variant="body" color="textSecondary">
          Enter the 6-digit code we just sent to{' '}
          <Typography component="span" variant="body" color="textPrimary">
            {loginStore.email}
          </Typography>{' '}
          to continue.
        </Typography>
        <TextField
          {...register('code')}
          errorText={errors.code?.message}
          autoComplete="off"
          autoFocus
          inputMode="numeric"
          placeholder="6-digit code"
          maxLength={6}
          height={45}
          textInputProps={{
            style: {
              textAlign: 'center',
            },
          }}
          style={{ marginTop: 48 }}
        />
        <Button
          type="submit"
          fullWidth
          disabled={disabled}
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          loading={isSubmitting || loginStore.loading === 'logging_in'}
        >
          Continue
        </Button>
        <Button
          type="button"
          fullWidth
          variant="text"
          color="textSecondary"
          fontWeight="regular"
          height={36}
          style={{ marginTop: 8 }}
          onClick={history.goBack}
        >
          Go back
        </Button>
      </div>
    </form>
  )
}

export default observer(EmailCode)
