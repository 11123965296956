import { computed, makeObservable, observable, toJS } from 'mobx'

import type { Model } from './base'

export type CallFallbackConfigType = 'forward' | 'playAudio' | 'voicemail'

export interface CallFallbackConfigDestination {
  type: CallFallbackConfigType
  destination: string // the user's selected option, similar to how IVR destinations are formatted (@, http, etc) aside from voicemail, which should be the voicemail's URL
  destinationId?: string | null // the ID of the destination resource, if applicable (such as voicemail ID)
}

export interface RawCallFallbackConfig {
  id: string // random string prefixed by 'CF'
  phoneNumberId: string
  outsideBusinessHours: CallFallbackConfigDestination
  duringBusinessHours: CallFallbackConfigDestination
  previousSettings: {
    [K in CallFallbackConfigType]: {
      outsideBusinessHours: {
        destination: string | null
        destinationId: string | null
      }
      duringBusinessHours: {
        destination: string | null
        destinationId: string | null
      }
    } | null
  }
}

export default class CallFallbackConfigModel implements Model {
  private raw: RawCallFallbackConfig

  get id(): string {
    return this.raw.id
  }

  get phoneNumberId(): string {
    return this.raw.phoneNumberId
  }

  get outsideBusinessHours(): CallFallbackConfigDestination {
    return this.raw.outsideBusinessHours
  }

  get duringBusinessHours(): CallFallbackConfigDestination {
    return this.raw.duringBusinessHours
  }

  get previousSettings(): RawCallFallbackConfig['previousSettings'] {
    return this.raw.previousSettings
  }

  constructor(attrs: RawCallFallbackConfig) {
    this.raw = { ...attrs }

    makeObservable<this, 'raw'>(this, {
      raw: observable.deep,
      id: computed,
      phoneNumberId: computed,
    })
  }

  deserialize(json: RawCallFallbackConfig): this {
    this.raw = json
    return this
  }

  serialize(): RawCallFallbackConfig {
    return toJS(this.raw)
  }

  localUpdate(attrs: Partial<RawCallFallbackConfig>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
