import type { MultipleSelectionState } from '@react-stately/selection'
import type { Node } from '@react-types/shared'
import type { RefObject } from 'react'
import { createContext } from 'react'

import type { MenuContextProps } from '@ui/Menu/MenuList'
import type { MenuItemProps } from '@ui/Menu/MenuListItem'
import type { MenuTriggerContextProps } from '@ui/Menu/MenuProvider/context/MenuTriggerContext'

export interface SubMenuContextProps {
  item: Node<object>
  itemProps: Omit<MenuItemProps<object>, 'item'>
  parentMenu: MenuContextProps
  parentTrigger: MenuTriggerContextProps
  selectionManager: MultipleSelectionState
  menuListRef: RefObject<HTMLUListElement>
}

const SubMenuContext = createContext<SubMenuContextProps | null>(null)

export default SubMenuContext
