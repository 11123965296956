import Typography from '@ui/Typography'

import * as styles from './UniversalLoginLegalFooter.css'

const UniversalLoginLegalFooter = () => {
  return (
    <>
      <Typography
        variant="caption1"
        color="textTertiary"
        fontWeight="regular"
        className={styles.terms}
      >
        By continuing, you acknowledge and accept our
      </Typography>
      <Typography
        variant="caption1"
        color="textTertiary"
        fontWeight="regular"
        className={styles.terms}
      >
        <a href="https://www.openphone.com/terms" target="_blank" className={styles.link}>
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href="https://www.openphone.com/privacy"
          target="_blank"
          className={styles.link}
        >
          Privacy Policy
        </a>
        .
      </Typography>
    </>
  )
}

export default UniversalLoginLegalFooter
