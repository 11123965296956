import type { ThemeKey } from './themes/common'

export type ThemeMatch = <T = unknown>(match: Record<ThemeKey, T>) => T

const createThemeMatch =
  (key: ThemeKey): ThemeMatch =>
  (match) =>
    match[key]

export default createThemeMatch
