import { computed, makeObservable } from 'mobx'

import type Service from '@src/service'

import BaseAlertModel from './BaseAlertModel'

class ActivityAlertBaseModel<
  T extends string,
  D extends {
    activityId: string
    conversationId: string
    userId: string
  },
> extends BaseAlertModel<T, D> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      user: computed,
      activity: computed,
      conversation: computed,
    })
  }

  get associatedObjectsLoaded() {
    return Boolean(this.activity)
  }

  get user() {
    return this.service.member.get(this.data.userId)
  }

  get activity() {
    return this.service.activity.get(this.data.activityId)
  }

  get conversation() {
    return this.service.conversation.collection.get(this.data.conversationId)
  }
}

export default ActivityAlertBaseModel
