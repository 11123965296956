import emojiRegex from 'emoji-regex'
import fuzzysort from 'fuzzysort'

export function capitalize(s: null): null
export function capitalize(s: string): string
export function capitalize(s: string | null): string | null {
  if (!s) {
    return s
  }
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function stripSpecials(str: string): string {
  return str.replace(/[*#+()\-\s]/g, '')
}

export function highlight(str: string, query?: string): string {
  if (!query) {
    return str
  }
  const result = fuzzysort.single(stripSpecials(query), str)
  if (!result) {
    return str
  }
  return fuzzysort.highlight(result, '<em>', '</em>') || str
}

export function compareString(
  a: string,
  b: string,
  opts: { caseInsensitive?: boolean } = {},
): -1 | 0 | 1 {
  if (opts.caseInsensitive) {
    a = a?.toLocaleLowerCase()
    b = b?.toLocaleLowerCase()
  }
  if (a === b) {
    return 0
  }
  if (a < b) {
    return -1
  }
  return 1
}

/**
 * Extracts and returns the first parts from given first and last names.
 * This is to handle the middle name that could be part of either the first name or last name.
 *
 * @param {string|null} [firstName] - The full first name with or without middle name.
 * @param {string|null} [lastName] - The full last name with or without middle name.
 * @returns {string} A string containing the first word from both the first and last names.
 */
export const getSingleFirstAndLastName = (
  firstName?: string | null,
  lastName?: string | null,
) => {
  const first = firstName?.split(' ')[0] || ''
  const last = lastName?.split(' ')[0] || ''
  return `${first} ${last}`.trim()
}

const getFirstCharOrEmoji = (text: string): string => {
  const regex = emojiRegex()
  return regex.test(text) ? text : text[0]
}

export function getInitials(text?: string | null): string {
  if (!text || text.trim().length === 0) {
    return '-'
  }

  // slice to ensure at most 2 characters in the initials
  const initials = text.split(/\s+/).slice(0, 2).map(getFirstCharOrEmoji)

  return initials.join('')
}

export function isTruthy(value: any): boolean {
  if (!value) {
    return false
  }
  if (typeof value === 'string') {
    value = value.toLowerCase()
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
  return ['false', 'off', null, 0, '0', 'no'].indexOf(value) === -1
}

export function isFalsy(value: any): boolean {
  return !isTruthy(value)
}

/**
 * @param amount in cents
 * @param options Optional formatting options
 * @param options.hideCentsIfZero If true, displays $x instead of $x.00
 * @param options.hideSign If true, the "hyphen" character indicating a negative value won't show
 */
export const toFormattedDollars = (
  amount: number,
  options?: {
    hideCentsIfZero?: boolean
    hideSign?: boolean
  },
): string => {
  const dollarAmount = amount / 100
  return dollarAmount.toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'narrowSymbol',
    ...(options?.hideCentsIfZero &&
      dollarAmount % 1 === 0 && {
        maximumFractionDigits: 0,
      }),
    ...(options?.hideSign && {
      signDisplay: 'never',
    }),
  })
}

/**
 * @param amount in cents
 * @param options Optional formatting options
 * @param options.hideCentsIfZero If true, displays $x instead of $x.00
 */
export const toAccessibleFormattedDollars = (
  amount: number,
  options?: {
    hideCentsIfZero?: boolean
  },
): string => {
  const formatted = toFormattedDollars(amount, { ...options, hideSign: true })
  return amount < 0 ? `–${formatted}` : `${formatted}`
}

export const joinPretty = (names: string[], join = 'and', oxford = true) => {
  switch (names.length) {
    case 0: {
      return names
    }
    case 1: {
      return names[0]
    }
    case 2: {
      return `${names[0]} and ${names[1]}`
    }
    default: {
      let returnString = names[0]
      for (let i = 1; i < names.length; i++) {
        if (i < names.length - 1) {
          returnString += `, ${names[i]}`
        } else {
          returnString += oxford ? ',' : ''
          returnString += ` ${join} ${names[i]}`
        }
      }
      return returnString
    }
  }
}
