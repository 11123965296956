import classNames from 'classnames'
import shuffle from 'lodash/fp/shuffle'
import type { AriaAttributes } from 'react'

import RotatingText from '@src/app/components/RotatingText'
import Typography from '@ui/Typography'

import * as styles from './RotatingTips.css'

const tips = shuffle([
  <>
    Use{' '}
    <Typography variant="caption3" color="textPrimary" className={styles.shortcuts}>
      <kbd>⌘</kbd>
      <kbd>K</kbd>
    </Typography>
    at any time to bring up the Command Menu
  </>,
  <>
    Use{' '}
    <Typography variant="caption3" color="textPrimary" className={styles.shortcuts}>
      <kbd>⌘</kbd>
      <kbd>/</kbd>
    </Typography>
    at any time to bring up the search window
  </>,
  <>
    When writing a message, press{' '}
    <Typography variant="caption3" color="textPrimary" className={styles.shortcuts}>
      <kbd>/</kbd>
    </Typography>{' '}
    to bring up commands
  </>,
  <>Use snippets to save and send frequently written messages</>,
  <>Head to phone number settings to manage your call flow</>,
  <>You can schedule any message to be sent at a later date</>,
  <>Connect to HubSpot or Salesforce to sync contact and activity data</>,
  <>Create webhooks to send real-time notifications of events to your apps</>,
  <>Invite a teammate to your workspace to start sharing phone numbers</>,
  <>Use auto-replies to automatically respond to missed calls</>,
  <>Connect your number to Slack to receive text notifications</>,
  <>Configure business hours to conditionally send calls to voicemail</>,
])

export const RotatingTips = ({
  'aria-hidden': ariaHidden,
}: Pick<AriaAttributes, 'aria-hidden'>) => {
  return (
    <RotatingText
      texts={tips}
      height={22}
      wait={5000}
      animationDuration={700}
      initiallyHidden
      aria-hidden={ariaHidden}
      renderFunction={({ className, children }) => (
        <Typography
          variant="callout"
          color="textSecondary"
          className={classNames(styles.tip, className)}
        >
          {children}
        </Typography>
      )}
    />
  )
}

export default RotatingTips
