/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import dayjs from 'dayjs'
import type { DateRange as RangeModifier } from 'react-day-picker'

import type {
  AnalyticsDailyActivitiesResult,
  AnalyticsQueryResultV2,
  AnalyticsUsersActivitiesResult,
} from '@src/app/analytics/AnalyticsQueryResult'
import type NonNullableRangeModifier from '@src/app/analytics/NonNullableRangeModifier'
import config from '@src/config'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export type ReportGranularity = 'day' | 'week'

export default class ReportClient {
  constructor(private transport: Transport) {}

  usersV2(
    phoneNumberId: string[],
    range: RangeModifier,
    granularity: ReportGranularity = 'day',
  ): Promise<AnalyticsQueryResultV2> {
    const coercedRange = this.coerceRange(range)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.REPORT_SERVICE_URL}analytics/dashboard/chartData`,
        body: {
          granularity,
          range: coercedRange,
          phoneNumberId,
          timezone,
        },
      }),
    )
  }

  userActivities({
    phoneNumberIds,
    range,
    prevRange,
    userIds,
  }: {
    phoneNumberIds: string[]
    range: RangeModifier
    prevRange: NonNullableRangeModifier
    userIds?: string[]
  }): Promise<AnalyticsUsersActivitiesResult> {
    const coercedRange = this.coerceRange(range)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.REPORT_SERVICE_URL}analytics/dashboard/userActivities`,
        body: {
          phoneNumberIds,
          userIds,
          range: coercedRange,
          prevRange,
          timezone,
        },
      }),
    )
  }

  dailyActivities(
    phoneNumberIds: string[],
    range: RangeModifier,
  ): Promise<AnalyticsDailyActivitiesResult> {
    const coercedRange = this.coerceRange(range)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.REPORT_SERVICE_URL}analytics/dashboard/dailyActivities`,
        body: {
          phoneNumberIds,
          range: coercedRange,
          timezone,
        },
      }),
    )
  }

  export = {
    list: () => {
      return this.transport.queue<ListExportsResponse>(
        new HttpTransaction({
          method: 'get',
          url: `${config.REPORT_SERVICE_URL}exports`,
        }),
      )
    },

    queue: (phoneNumberIds: string[], range: RangeModifier, exportType?: ExportType) => {
      range = this.coerceRange(range)
      exportType = exportType || 'custom'

      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.REPORT_SERVICE_URL}export`,
          body: {
            from: range.from?.toISOString(),
            to: range.to?.toISOString(),
            phoneNumberIds,
            toDownloadLocation: true,
            exportType,
          },
        }),
      )
    },

    clearUserExports: () => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.REPORT_SERVICE_URL}exports/user`,
        }),
      )
    },

    exportEstimate: (phoneNumberIds: string[], range: RangeModifier) => {
      range = this.coerceRange(range)
      return this.transport.queue<ExportEstimate>(
        new HttpTransaction({
          method: 'post',
          url: `${config.REPORT_SERVICE_URL}export-estimate`,
          body: {
            from: range.from?.toISOString(),
            to: range.to?.toISOString(),
            phoneNumberIds,
          },
        }),
      )
    },

    workspaceData: (exportTypes: string[]) => {
      return this.transport.queue<ExportEstimate>(
        new HttpTransaction({
          method: 'post',
          url: `${config.REPORT_SERVICE_URL}exports/org`,
          body: {
            exportTypes,
          },
        }),
      )
    },
  }

  protected coerceRange(range: RangeModifier): NonNullableRangeModifier {
    return range.from && range.to
      ? (range as NonNullableRangeModifier)
      : {
          from: dayjs().startOf('month').toDate(),
          to: new Date(),
        }
  }
}

export interface BaseExport {
  id: string
  orgId: string
  userId: string
  status: 'pending' | 'processing' | 'completed' | 'failed'
  query: {
    to: string
    from: string
    phoneNumberIds: string[]
    toDownloadLocation: boolean
    exportType?: ExportType
  }
  filename: string
  requestedAt: string
  createdAt: string
  updatedAt: string
}

interface PendingExport extends BaseExport {
  status: 'pending'
}

interface ProcessingExport extends BaseExport {
  status: 'processing'
}

interface CompletedExport extends BaseExport {
  status: 'completed'
  url: string
}

interface FailedExport extends BaseExport {
  status: 'failed'
  errorMessage: string
}

export type ReportExport =
  | BaseExport
  | PendingExport
  | ProcessingExport
  | CompletedExport
  | FailedExport

export type ListExportsResponse = ReportExport[]

export interface ExportEstimate {
  records: number
  totalPages: number
}

export type ExportType =
  | 'today'
  | 'yesterday'
  | 'last-7-days'
  | 'last-4-weeks'
  | 'last-3-months'
  | 'last-12-months'
  | 'month-to-date'
  | 'quarter-to-date'
  | 'year-to-date'
  | 'custom'
