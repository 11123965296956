import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'

import PricingController from '@src/app/billing/pricing/PricingController'
import { useAppStore } from '@src/app/context'
import useInstance from '@src/lib/hooks/useInstance'

const PricingContext = createContext<PricingController | null>(null)

export function usePricing() {
  const context = useContext(PricingContext)

  if (!context) {
    throw new Error(`usePricing has to be used under PricingProvider`)
  }

  return context
}

export interface PricingProviderProps {
  children: ReactNode
}

export default function PricingProvider({ children }: PricingProviderProps) {
  const app = useAppStore()
  const pricingControllerInstance = useInstance(() => new PricingController(app), [app])
  return (
    <PricingContext.Provider value={pricingControllerInstance}>
      {children}
    </PricingContext.Provider>
  )
}
