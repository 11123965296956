import { makeAutoObservable } from 'mobx'

import { getInitials } from '@src/lib'
import type { Identity, IdentityPhone } from '@src/service/model/base'

import type PhoneNumberModel from './PhoneNumberModel'

export default class PhoneNumberGroup implements Identity {
  readonly pictureUrl = undefined
  readonly emailAddresses = []
  readonly isAnonymous = false

  constructor(
    public readonly id: string,
    readonly phoneNumber: PhoneNumberModel,
  ) {
    makeAutoObservable(this, {})
  }

  get name(): string {
    return this.phoneNumber.name
  }

  get shortName(): string {
    return this.phoneNumber.name
  }

  get initials(): string {
    return getInitials(this.name)
  }

  get pictureSymbol(): string {
    return this.phoneNumber.symbol
  }

  get members() {
    return this.phoneNumber.members
  }

  get phones(): IdentityPhone[] {
    return [
      {
        id: this.phoneNumber.id,
        name: this.phoneNumber.name,
        symbol: this.phoneNumber.symbol,
        number: this.phoneNumber.number,
        isOffHours: this.phoneNumber.isOffHours,
        isShared: this.phoneNumber.isShared,
      },
    ]
  }
}
