import { useContext } from 'react'

import ThemeContext, { createThemeContextValue } from './ThemeContext'
import type { ThemeKey } from './themes/common'
import type { TypographyThemeKey } from './typography/themes/common'

/**
 * Returns the current theme key and theme class name.
 *
 * @param themeKey Overrides the current theme with a specific theme.
 */
export default function useTheme(
  themeKey?: ThemeKey,
  typographyThemeKey?: TypographyThemeKey,
) {
  const context = useContext(ThemeContext)

  if (themeKey || typographyThemeKey) {
    return createThemeContextValue(
      themeKey ? themeKey : context.themeKey,
      typographyThemeKey ? typographyThemeKey : context.typographyThemeKey,
    )
  }

  return context
}
