import { Item as DefaultItem } from '@react-stately/collections'
import type { ReactNode } from 'react'

import type { LoosenedItemProps } from '@ui/Menu/Item/Item'
import type { GetCollectionNodeFunction } from '@ui/Menu/types'

export interface SubMenuFacadeProps<T> extends LoosenedItemProps<T> {
  children: ReactNode
}

/**
 * Public component for users. This will be mapped internally to SubMenuProvider
 */
function SubMenuFacade<T>(_: SubMenuFacadeProps<T>) {
  return null
}

SubMenuFacade.getCollectionNode = function* <T extends object>(
  props: SubMenuFacadeProps<T>,
) {
  // @ts-expect-error not `getCollectionNode` is not exposed in types
  // https://github.com/adobe/react-spectrum/blob/600bff46f77558d68aff7ea2c002873653f0330d/packages/%40react-stately/collections/src/Item.ts#L78
  const nodeBuilder = DefaultItem.getCollectionNode as GetCollectionNodeFunction<
    T,
    SubMenuFacadeProps<T>
  >
  const node = nodeBuilder(props).next().value
  yield {
    ...node,
    props: {
      ...node.props,
      // the only way to add custom data into the node
      // to differentiate collection components (Item, Section, Separator)
      // from custom components is using the props slot
      // because the collection builder strips any custom
      // properties passed in the root of the node
      // https://github.com/adobe/react-spectrum/blob/600bff46f77558d68aff7ea2c002873653f0330d/packages/%40react-stately/collections/src/CollectionBuilder.ts#L168-L206
      isSubMenu: true,
    },
  }
}

export default SubMenuFacade
