import type { Node } from '@react-types/shared'
import { useMenuSection } from 'react-aria'

import { useComboboxList } from '@ui/Combobox/ComboboxList/context'
import ComboboxListItem from '@ui/Combobox/ComboboxListItem'
import MenuListSectionLabel from '@ui/Menu/MenuListSectionLabel'
import MenuSeparator from '@ui/Menu/MenuSeparator'

import * as styles from './ComboboxListSection.css'

export interface MenuListSectionProps<T> {
  section: Node<T>
}

const ComboboxListSection = <T extends object>({ section }: MenuListSectionProps<T>) => {
  const { state } = useComboboxList()
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  })

  const children = [...section.childNodes]

  const isAtTopOfCollection = state.collection.getFirstKey() === section.key

  return (
    <>
      {isAtTopOfCollection ? null : <MenuSeparator />}
      <li {...itemProps} className={styles.root}>
        {section.rendered ? (
          <MenuListSectionLabel role={headingProps.role}>
            {section.rendered}
          </MenuListSectionLabel>
        ) : null}
        <ul {...groupProps} className={styles.group}>
          {children.map((node) => {
            return <ComboboxListItem key={`${section.key}-${node.key}`} item={node} />
          })}
        </ul>
      </li>
    </>
  )
}

export default ComboboxListSection
