import { mergeProps } from '@react-aria/utils'
import type { MenuTriggerProps as DefaultMenuTriggerProps } from '@react-stately/menu'
import type { ReactNode, ReactElement } from 'react'
import { isValidElement, Children, cloneElement } from 'react'
import type { AriaButtonProps, KeyboardResult } from 'react-aria'
import { useKeyboard } from 'react-aria'
import { isFragment } from 'react-is'

import { useMenuTrigger } from '@ui/Menu/MenuProvider'
import type { MenuTriggerContextProps } from '@ui/Menu/MenuProvider/context/MenuTriggerContext'

type NonPropagatedKeyProps = KeyboardResult['keyboardProps']

type RenderFunctionProps = {
  state: { isOpen: boolean }
  props: Omit<AriaButtonProps<'button'>, 'onPress' | 'onPressStart'> &
    Pick<NonPropagatedKeyProps, 'onKeyDown' | 'onKeyUp'> & {
      onClick: () => void
      ref: MenuTriggerContextProps['setNodeRef']
    }
}

export type RenderFunction = (props: RenderFunctionProps) => JSX.Element

export interface MenuTriggerProps
  extends Omit<AriaButtonProps, 'children'>,
    DefaultMenuTriggerProps {
  /**
   * The children of the MenuTrigger, if given a single child it will clone the element and assign a few properties and handlers to convert that child to a menu trigger.
   *
   * @example
   * ```
   * <Menu.Trigger>
   *  <button>Open menu</button>
   * </Menu.Trigger>
   * ```
   *
   * A render function can also be used to provide the props to an element more deeply nested in the children tree.
   *
   * The props are necessary to open the menu by clicking on the trigger, or by pressing the arrow down key on the element. Furthermore, it provides of aria attributes that convey that the trigger will open a menu.
   *
   * @example
   * ```
   * <Menu.Trigger>
   *  {(props) => (
   *    <Tooltip title="Create thing">
   *      <button {...props}><PlusIcon /></button>
   *    </Tooltip>
   *  )}
   * </Menu.Trigger>
   * ```
   */
  children: ReactNode | RenderFunction
}

const getFirstChild = (children: ReactNode): ReactElement<Record<string, any>> => {
  const child = Children.toArray(children)[0]

  if (!isValidElement<Record<string, any>>(child) || isFragment(child)) {
    throw new Error('MenuTrigger child is invalid')
  }

  return child
}

const isRenderFunction = (
  children: MenuTriggerProps['children'],
): children is RenderFunction => typeof children === 'function'

const MenuTrigger = ({ children, ...props }: MenuTriggerProps) => {
  const {
    ref,
    setNodeRef,
    state: { toggle, isOpen },
    menuTriggerProps,
  } = useMenuTrigger()

  const { keyboardProps } = useKeyboard(menuTriggerProps)

  if (isRenderFunction(children)) {
    return children({
      state: { isOpen },
      props: {
        ...removeUnusedHandlers(menuTriggerProps),
        onKeyDown: keyboardProps.onKeyDown,
        onKeyUp: keyboardProps.onKeyUp,
        ref: setNodeRef,
        onClick: toggle,
      },
    })
  }

  const child = getFirstChild(children)

  const mergedProps = mergeProps(
    child.props,
    props,
    removeUnusedHandlers(menuTriggerProps),
    {
      ref,
      onClick: toggle,
    },
  )
  return cloneElement(child, mergedProps)
}

export default MenuTrigger

function removeUnusedHandlers(menuTriggerProps: AriaButtonProps) {
  delete menuTriggerProps.onPress
  delete menuTriggerProps.onPressStart
  return menuTriggerProps
}
