import { useContext } from 'react'

import MenuListContext from './MenuListContext'

export default function useMenuList() {
  const context = useContext(MenuListContext)

  if (!context) {
    throw new Error('useMenuList has to be used within a <Menu /> component')
  }

  return context
}
