import { makeAutoObservable } from 'mobx'

import { parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'
import Collection from '@src/service/collections/Collection'
import type { Conversation } from '@src/service/conversation-store'

import type { Model } from './base'

export interface CodableDirectNumber {
  id: string
  createdAt: number | null
  number: string | null
  orgId: string | null
  updatedAt: number | null
  userId: string | null
}

class DirectNumberModel implements Model {
  id: string = objectId()
  createdAt: number | null = null
  number: string | null = null
  orgId: string | null = null
  updatedAt: number | null = null
  userId: string | null = null

  conversations = new Collection<Conversation>()

  constructor(attrs: CodableDirectNumber) {
    this.deserialize(attrs)

    makeAutoObservable(this)
  }

  deserialize = (json: Partial<CodableDirectNumber>) => {
    Object.assign(this, json)

    if (json.createdAt) {
      this.createdAt = parseDate(json.createdAt)
    }

    if (json.updatedAt) {
      this.updatedAt = parseDate(json.updatedAt)
    }

    return this
  }

  serialize = (): CodableDirectNumber => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      number: this.number,
      orgId: this.orgId,
      updatedAt: this.updatedAt,
      userId: this.userId,
    }
  }
}

// TODO: maybe it's worth to move it to the class?
// still need to wait untill member obj will use DirectNumber objects
export const isDirectNumber = (value: string | null): boolean =>
  value?.startsWith('@') ?? false

export default DirectNumberModel
