import { useContext } from 'react'

import type Service from '@src/service'

import ServiceContext from './ServiceContext'

export default function useService(): Service {
  const context = useContext(ServiceContext)

  if (context === null) {
    throw new Error('`useService` hook should be used under a `ServiceContext` provider')
  }

  return context
}
