import { observer } from 'mobx-react-lite'

import { useAppStore } from '@src/app/context'

import BillingSubscriptionContext from './BillingSubscriptionContext'

interface BillingSubscriptionProviderProps {
  children: React.ReactNode
  fallback?: React.ReactNode
}

function BillingSubscriptionProvider({
  children,
  fallback = null,
}: BillingSubscriptionProviderProps) {
  const app = useAppStore()
  const subscription = app.service.billing.subscription

  if (!subscription) {
    return <>{fallback}</>
  }

  return (
    <BillingSubscriptionContext.Provider value={{ subscription }}>
      {children}
    </BillingSubscriptionContext.Provider>
  )
}

export default observer(BillingSubscriptionProvider)
