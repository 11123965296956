import { mergeProps } from '@react-aria/utils'

import { useComboboxList } from '@ui/Combobox/ComboboxList/context'
import ComboboxListItem from '@ui/Combobox/ComboboxListItem'
import ComboboxListSection from '@ui/Combobox/ComboboxListSection'
import { useComboboxTrigger } from '@ui/Combobox/ComboboxProvider'
import ComboboxSeparator from '@ui/Combobox/ComboboxSeparator'
import { useIsVisible } from '@ui/Menu/MenuList/utils/useIsVisible'
import Typography from '@ui/Typography'

import * as styles from './ComboboxListContent.css'

export default function ComboboxListContent() {
  const { state, comboboxListRef, listBoxProps, setIsVisible } = useComboboxList()

  const { comboboxProps } = useComboboxTrigger()

  const mergedProps = mergeProps(listBoxProps, comboboxProps)

  useIsVisible(comboboxListRef, setIsVisible)

  if (!state.collection.size) {
    return (
      <ul {...mergedProps} ref={comboboxListRef} className={styles.root}>
        <Typography
          component="li"
          variant="caption1"
          color="textTertiary"
          className={styles.empty}
        >
          No results found
        </Typography>
      </ul>
    )
  }

  return (
    <ul {...mergedProps} ref={comboboxListRef} className={styles.root}>
      {[...state.collection].map((item) => {
        if (item.type === 'item') {
          return <ComboboxListItem item={item} key={item.key} />
        }

        if (item.type === 'separator') {
          return <ComboboxSeparator key={item.key} />
        }

        if (item.type === 'section') {
          return <ComboboxListSection section={item} key={item.key} />
        }
      })}
    </ul>
  )
}
