import DeepProxy from 'proxy-deep';
export function createClient() {
    if (typeof window === 'undefined' || !window._e)
        return undefined;
    const bridge = window._e;
    const target = {
        platform: bridge.platform,
        version: bridge.version,
        send: window._e.send,
        on: (name, handler) => bridge.on(name, handler),
        off: (name, handler) => bridge.off(name, handler),
    };
    return new DeepProxy(target, {
        get(target, key, receiver) {
            const val = Reflect.get(target, key, receiver);
            if (val) {
                if (typeof val === 'function' && ['call', 'apply'].includes(key)) {
                    return val;
                }
                return typeof val === 'function' ? val.bind(target) : val;
            }
            const exists = checkIfMethodExists(window._e.schema, [...this.path, key]);
            if (exists) {
                return this.nest(function () { });
            }
            return undefined;
        },
        apply(_target, _thisArg, args) {
            return window._e.invoke('command', { path: this.path, args });
        },
    });
}
function checkIfMethodExists(schema, path) {
    if (path.length === 0)
        return true;
    path = [...path];
    let prop;
    let obj = schema;
    while ((prop = path.shift())) {
        if (!obj[prop])
            return false;
        obj = obj[prop];
    }
    return true;
}
