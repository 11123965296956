import type { DBTypes } from '@openphone/dexie-database/types'
import { makeAutoObservable, toJS } from 'mobx'
import type { MarkOptional } from 'ts-essentials'

import type { TriggerDefinitionId } from '@src/app/components/workflow/builder/WorkflowBuilderController'
import uuid from '@src/lib/uuid'
import type { Model } from '@src/service/model/base'

export type RawWorkflowTriggerDefinition = DBTypes.WorkflowTriggerDefinitionRow

export default class WorkflowTriggerDefinitionModel implements Model {
  private raw: RawWorkflowTriggerDefinition

  get id() {
    return this.raw.id
  }

  get category() {
    return this.raw.category
  }

  constructor(attrs: MarkOptional<RawWorkflowTriggerDefinition, 'id'>) {
    this.raw = {
      ...attrs,
      id: (attrs.id as TriggerDefinitionId) ?? `WTD${uuid()}`.replace(/-/g, ''),
    }

    makeAutoObservable<this>(this)
  }

  deserialize(json: RawWorkflowTriggerDefinition): this {
    this.raw = json
    return this
  }

  serialize(): RawWorkflowTriggerDefinition {
    return toJS(this.raw)
  }

  localUpdate(attrs: Partial<RawWorkflowTriggerDefinition>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
