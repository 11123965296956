import { createContext } from 'react'

interface ModalContextType {
  elementRef: React.RefObject<HTMLDivElement>
}

const ModalContext = createContext<ModalContextType>({
  elementRef: { current: null },
})

export default ModalContext
