import type { ReactNode } from 'react'

import { BannerUiStore } from '@src/app/banner'
import useStore from '@src/lib/hooks/useStore'
import { useService } from '@src/service/context'

import BannersContext from './BannersContext'

export default function BannersProvider({ children }: { children: ReactNode }) {
  const { storage } = useService()
  const store = useStore(() => new BannerUiStore(storage), [])
  return <BannersContext.Provider value={store}>{children}</BannersContext.Provider>
}
