import { computed, makeObservable } from 'mobx'

import type Service from '@src/service'

import ActivityAlertBaseModel from './ActivityAlertBaseModel'

abstract class ThreadAlertBaseModel<
  T extends string,
  D extends {
    activityId: string
    commentId: string
    conversationId: string
    userId: string
  },
> extends ActivityAlertBaseModel<T, D> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      comment: computed,
    })
  }

  override get associatedObjectsLoaded() {
    return Boolean(this.comment)
  }

  get comment() {
    return this.activity?.comments?.find((comment) => comment.id === this.data.commentId)
  }
}

export default ThreadAlertBaseModel
