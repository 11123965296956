import { makeAutoObservable, toJS } from 'mobx'

import type IntegrationStore from '@src/service/integration-store'
import type { Model } from '@src/service/model/base'

import type { BaseIntegration } from '.'
import type CallDeposition from './CallDeposition'
import type CodableIntegration from './CodableIntegration'
import type ContactImport from './ContactImport'
import type CreatedBy from './CreatedBy'
import type LoggableActivity from './LoggableActivity'
import { createdByUserName } from './utils'

export interface CodableGongIntegration extends CodableIntegration {
  type: 'gong'
}

class GongIntegrationModel implements Model, BaseIntegration, CodableGongIntegration {
  id = ''
  readonly type = 'gong'
  resourceIds: string[] = []
  refreshToken: string | null = null
  accessToken: string | null = null
  logCalls: LoggableActivity = { enabled: false, activityType: null }
  logMessages: LoggableActivity = { enabled: false, activityType: null }
  accessTokenExpiresAt: Date | null = null
  contactProperties: string[] = []
  callDepositions: CallDeposition[] = []
  contactImport: ContactImport | null = null
  createdBy: CreatedBy | null = null

  constructor(private integrationStore: IntegrationStore) {
    makeAutoObservable(this, {})
  }

  get userName(): string {
    return createdByUserName(this.createdBy) ?? ''
  }

  update = (attrs: Partial<this>) => {
    Object.assign(this, attrs)
    return this.integrationStore.update(this)
  }

  delete = () => {
    return this.integrationStore.delete(this)
  }

  deserialize = (json: CodableGongIntegration) => {
    Object.assign(this, json)

    return this
  }

  serialize = (): CodableGongIntegration => {
    return {
      id: this.id,
      type: this.type,
      resourceIds: toJS(this.resourceIds),
      refreshToken: this.refreshToken,
      accessToken: this.accessToken,
      logCalls: toJS(this.logCalls),
      logMessages: toJS(this.logMessages),
      accessTokenExpiresAt: this.accessTokenExpiresAt,
      contactProperties: toJS(this.contactProperties),
      callDepositions: toJS(this.callDepositions),
      contactImport: toJS(this.contactImport),
      createdBy: toJS(this.createdBy),
    }
  }
}

export const isGongIntegrationModel = (a: unknown): a is GongIntegrationModel => {
  return a instanceof GongIntegrationModel
}

export default GongIntegrationModel
