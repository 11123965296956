import cx from 'classnames'
import { observer } from 'mobx-react-lite'

import type AppStore from '@src/app/AppStore'
import useStore from '@src/lib/hooks/useStore'

import * as styles from './WindowsTitleBar.css'
import WindowsTitleBarController from './WindowsTitleBarController'
import CloseIcon from './icons/close.svg'
import MaximizeIcon from './icons/maximize.svg'
import MinimizeIcon from './icons/minimize.svg'
import UnmaximizeIcon from './icons/unmaximize.svg'

interface WindowsTitleBarProps extends React.HTMLProps<HTMLDivElement> {
  app: AppStore
}

function WindowsTitleBar({ app, className, ...props }: WindowsTitleBarProps) {
  const controller = useStore(() => new WindowsTitleBarController(app), [])

  if (controller.fullScreen) {
    return null
  }

  return (
    <div {...props} className={cx(styles.root, className)}>
      <div className={styles.buttons}>
        <button className={styles.button} onClick={controller.minimize} title="Minimize">
          <MinimizeIcon />
        </button>
        {controller.maximized ? (
          <button
            className={styles.button}
            onClick={controller.unmaximize}
            title="Unmaximize"
          >
            <UnmaximizeIcon />
          </button>
        ) : (
          <button
            className={styles.button}
            onClick={controller.maximize}
            title="Maximize"
          >
            <MaximizeIcon />
          </button>
        )}
        <button className={styles.closeButton} onClick={controller.close} title="Close">
          <CloseIcon />
        </button>
      </div>
    </div>
  )
}

export default observer(WindowsTitleBar)
