import { forwardRef, useState } from 'react'

import type { TextInputControlProps } from '@ui/TextInputControl'
import TextInputControl from '@ui/TextInputControl'
import { HideIcon, ShowIcon } from '@ui/icons/tint/20/general'

import * as styles from './PasswordInputControl.css'

export interface PasswordInputControlProps extends TextInputControlProps {
  defaultHidden?: boolean
}

const PasswordInputControl = forwardRef<HTMLInputElement, PasswordInputControlProps>(
  ({ defaultHidden = true, inputBaseProps, ...props }, ref) => {
    const [hidden, setHidden] = useState(defaultHidden)

    const Icon = hidden ? HideIcon : ShowIcon

    const endAdornment = (
      <button
        type="button"
        className={styles.button}
        onClick={() => setHidden((current) => !current)}
      >
        <Icon
          className={styles.icon}
          role="img"
          aria-label={hidden ? 'Show value' : 'Hide value'}
        />
      </button>
    )

    return (
      <TextInputControl
        type={hidden ? 'password' : 'text'}
        inputBaseProps={{
          endAdornment,
          ...inputBaseProps,
        }}
        {...props}
        ref={ref}
      />
    )
  },
)

PasswordInputControl.displayName = 'PasswordInputControl'

export default PasswordInputControl
