import defaultThemeClass from './default/theme.css'
import dyslexiaThemeClass from './dyslexia/theme.css'

export const typographyThemeClassNames = {
  default: defaultThemeClass,
  dyslexia: dyslexiaThemeClass,
}

export type TypographyThemeKey = keyof typeof typographyThemeClassNames

export function isTypographyThemeKey(key: string): key is TypographyThemeKey {
  return key in typographyThemeClassNames
}
