import { useEffect } from 'react'

import { useAppStore } from '@src/app/context'
import useConsumeSearchParam from '@src/lib/hooks/useConsumeSearchParam/useConsumeSearchParam'

export const DISPLAY_SUPPORT_CHAT_PARAM = 'support-chat'

/**
 * Displays the ada embed live chat if the `support-chat` param is present in the location.
 * The param will be removed after the chat is displayed.
 */
export default function useDisplayAdaLiveChatFromUrl({ enabled }: { enabled: boolean }) {
  const { helpAndSupport } = useAppStore()

  const [chatParam, reread] = useConsumeSearchParam(
    enabled ? DISPLAY_SUPPORT_CHAT_PARAM : '',
  )

  useEffect(() => {
    if (chatParam === null) {
      return
    }

    helpAndSupport?.showChatBot()

    reread()
  }, [chatParam, helpAndSupport, reread])
}
