export default function stripSearchParam(
  params: URLSearchParams | string,
  param: string,
) {
  const clone = new URLSearchParams(params.toString())

  clone.delete(param)

  return clone
}
