import cx from 'classnames'
import type { CSSProperties, ReactElement } from 'react'
import { cloneElement, forwardRef } from 'react'

import assertNever from '@src/lib/assertNever'
import type { TypographyVariant } from '@src/theme'
import Typography from '@ui/Typography'

import * as styles from './Badge.css'
import type {
  BadgeVariant,
  BadgeSize,
  BadgeColor,
  BadgeIconPlacement,
} from './Badge.types'

export interface BadgeProps {
  variant?: BadgeVariant
  color?: BadgeColor
  size?: BadgeSize
  icon?: ReactElement
  label: string
  style?: CSSProperties
  className?: string
  iconPlacement?: BadgeIconPlacement
}

const Badge = (
  {
    variant = 'default',
    color = 'primary',
    size = 'default',
    icon: iconProp,
    label,
    style,
    className,
    iconPlacement = 'start',
  }: BadgeProps,
  ref: React.ForwardedRef<HTMLSpanElement>,
) => {
  const iconPropClassName: string | null =
    iconProp &&
    typeof iconProp.props === 'object' &&
    'className' in iconProp.props &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    typeof iconProp.props.className === 'string'
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (iconProp.props.className as string)
      : null

  const icon = iconProp
    ? cloneElement(iconProp, {
        className: cx(iconPropClassName, styles.icon({ size })),
      })
    : null

  const typographyVariant = ((): TypographyVariant => {
    switch (size) {
      case 'xsmall': {
        return 'caption3'
      }
      case 'small':
      case 'default': {
        return 'caption2'
      }
      case 'large': {
        return 'caption1'
      }
      default: {
        assertNever(size, `Unhandled Badge typographyVariant size: ${size}`)
      }
    }
  })()

  return (
    <Typography
      ref={ref}
      component="span"
      variant={typographyVariant}
      fontWeight="medium"
      className={cx(
        styles.root({
          variant,
          size,
          color,
          iconPlacement: icon ? iconPlacement : undefined,
        }),
        className,
      )}
      style={style}
    >
      {iconPlacement === 'start' && icon}
      {label}
      {iconPlacement === 'end' && icon}
    </Typography>
  )
}

export default forwardRef(Badge)
