import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'

import Bone from '@ui/Bone'

import * as styles from './AppSkeleton.css'

interface AppSkeletonProps {
  animated?: boolean
}

export const AppSkeleton = ({ animated = false }: AppSkeletonProps) => {
  return (
    <div className={styles.root}>
      <div className={cx(styles.sideMenu, { [styles.animated]: animated })}>
        <div className={styles.accountMenu}>
          <Bone className={styles.avatar} />
          <Bone className={styles.name} />
        </div>
        <div
          className={styles.navMenu}
          style={assignInlineVars({ [styles.delayVar]: '1' })}
        >
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <div key={`navItem-${index}`} className={styles.navItem}>
                <Bone className={styles.navIcon} />
                <Bone className={styles.navText} />
              </div>
            ))}
        </div>
        <div
          className={styles.inboxes}
          style={assignInlineVars({ [styles.delayVar]: '2' })}
        >
          <Bone className={styles.inboxesTitle} />
          {Array(3)
            .fill(0)
            .map((_, index) => (
              <div key={`inbox-${index}`} className={styles.inbox}>
                <Bone className={styles.inboxIcon}></Bone>
                <Bone className={styles.inboxName}></Bone>
                <Bone className={styles.inboxPhone}></Bone>
              </div>
            ))}
        </div>
        <div className={styles.team} style={assignInlineVars({ [styles.delayVar]: '3' })}>
          <Bone className={styles.teamTitle} />
          {Array(5)
            .fill(0)
            .map((_, index) => (
              <div key={`teamItem-${index}`} className={styles.teamItem}>
                <Bone className={styles.teamIcon}></Bone>
                <Bone className={styles.teamName}></Bone>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default AppSkeleton
