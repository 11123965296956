import { useAppStore } from '@src/app/context'

export default function useSuspendedCurrentUser() {
  const app = useAppStore()
  const user = app.service.user.current
  const member = user?.asMember

  if (!user || !member) {
    throw app.service.user.currentUserPromise
  }

  return { user, member }
}
