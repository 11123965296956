import { Section } from '@react-stately/collections'
import type { SectionProps } from '@react-types/shared'

import type { MenuChild } from '@ui/Menu/types'

interface MenuSectionProps<T> extends Omit<SectionProps<T>, 'children'> {
  children: MenuChild<T>
}

export default function MenuSection<T>(props: MenuSectionProps<T>) {
  // @ts-expect-error a nullable element is not allowed in typings but it works fine with it
  return <Section {...props} />
}
// @ts-expect-error `getCollectionNode` doesn't get exposed in typings
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
MenuSection.getCollectionNode = Section.getCollectionNode
