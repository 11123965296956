export default class SyncStorage<T = unknown> {
  get(key: string): T | null {
    const value = localStorage.getItem(key)
    if (!value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      return value as any
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      return JSON.parse(value)
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      return value as any
    }
  }

  set(key: string, item: any) {
    if (typeof item === 'string') {
      localStorage.setItem(key, item)
    } else {
      localStorage.setItem(key, JSON.stringify(item))
    }
  }

  delete(key: string) {
    localStorage.removeItem(key)
  }
}
