import { nanoid } from 'nanoid'
import { useSeparator } from 'react-aria'

import * as styles from './MenuSeparator.css'

export const MenuSeparator = () => {
  const { separatorProps } = useSeparator({ elementType: 'hr' })
  return <hr {...separatorProps} className={styles.root} />
}

MenuSeparator.getCollectionNode = function* () {
  const id = nanoid()
  yield {
    type: 'separator',
    key: id,
  }
}

export default MenuSeparator
