import { RnnoiseWorkletNode, loadRnnoise } from '@sapphi-red/web-noise-suppressor'
import rnnoiseWasmPath from '@sapphi-red/web-noise-suppressor/rnnoise.wasm?url'
import rnnoiseWorkletPath from '@sapphi-red/web-noise-suppressor/rnnoiseWorklet.js?url'
import rnnoiseWasmSimdPath from '@sapphi-red/web-noise-suppressor/rnnoise_simd.wasm?url'
import type { AudioProcessor } from '@twilio/voice-sdk'

export default class NoiseSuppressionAudioProcessor implements AudioProcessor {
  private audioContext: AudioContext
  private destination: MediaStreamAudioDestinationNode

  constructor() {
    this.audioContext = new AudioContext({
      latencyHint: 0,
    })
    this.destination = this.audioContext.createMediaStreamDestination()
  }

  async createProcessedStream(stream: MediaStream): Promise<MediaStream> {
    const source = this.audioContext.createMediaStreamSource(stream)

    const rnnoiseWasmBinary = await loadRnnoise({
      url: rnnoiseWasmPath,
      simdUrl: rnnoiseWasmSimdPath,
    })
    await this.audioContext.audioWorklet.addModule(rnnoiseWorkletPath)
    const rnnoiseNode = new RnnoiseWorkletNode(this.audioContext, {
      wasmBinary: rnnoiseWasmBinary,
      maxChannels: 2,
    })

    // Highpass filters are typically used in voice mixing to take out the muddiness,
    // which is more evident when speaking on the phone due to the line encoding/decoding.
    // The 350Hz is just an arbitrary value that sounded good without sacrificing too much of the bass.
    const biquadFilter = this.audioContext.createBiquadFilter()
    biquadFilter.type = 'highpass'
    biquadFilter.frequency.value = 350

    // This flattens the volume and helps avoiding volume oscillations since
    // it's common to raise the voice volume when speaking in a loud environment
    const compressor = this.audioContext.createDynamicsCompressor()

    source.connect(rnnoiseNode)
    rnnoiseNode.connect(biquadFilter)
    biquadFilter.connect(compressor)
    compressor.connect(this.destination)

    return this.destination.stream
  }

  async destroyProcessedStream(): Promise<void> {
    this.destination?.disconnect()
  }
}
