import type { MultipleSelectionStateProps } from '@react-stately/selection'
import { useMultipleSelectionState } from '@react-stately/selection'
import type { Node } from '@react-types/shared'
import type { ReactNode } from 'react'
import { useRef, useMemo, Children, isValidElement } from 'react'

import MenuList, { useMenuList } from '@ui/Menu/MenuList'
import type { MenuItemProps } from '@ui/Menu/MenuListItem'
import MenuProvider, { useMenuTrigger } from '@ui/Menu/MenuProvider'
import SubMenuTrigger from '@ui/Menu/SubMenuTrigger/SubMenuTrigger'

import { SubMenuContext } from './context'

export interface SubMenuProviderProps<T> extends MenuItemProps<T> {
  item: Node<T>
}

/**
 * Used as an item on a parent list. It accepts the same props as an item.
 * It will render the MenuListItem with those props and make that item a
 * SubMenuTrigger.
 *
 * A SubMenuProvider has to be used with two specific children:
 * 1. Any react node, which will be injected into the MenuListItem.
 * 2. A Menu.List component which represents the sub menu.
 */
const SubMenuProvider = <T extends object>({
  item,
  ...itemProps
}: SubMenuProviderProps<T>) => {
  const parentMenu = useMenuList()
  const parentTrigger = useMenuTrigger()
  const [trigger, menu] = useMemo(() => validateChildren(item.rendered), [item])
  const strippedItem = useMemo(() => ({ ...item, rendered: trigger }), [item, trigger])

  const selectionManager = useSelectionStateFromChildren(menu)

  const menuListRef = useRef<HTMLUListElement>(null)

  return (
    <SubMenuContext.Provider
      value={{
        item: strippedItem,
        itemProps,
        parentMenu,
        parentTrigger,
        selectionManager,
        menuListRef,
      }}
    >
      <MenuProvider>
        <SubMenuTrigger />
        {menu}
      </MenuProvider>
    </SubMenuContext.Provider>
  )
}

export default SubMenuProvider

function useSelectionStateFromChildren(menu: ReactNode) {
  const defaultProps: MultipleSelectionStateProps = {
    selectionMode: undefined,
    selectedKeys: undefined,
    defaultSelectedKeys: undefined,
  }
  const { selectionMode, defaultSelectedKeys, selectedKeys } =
    isValidElement(menu) && hasMultipleSelectionProps(menu.props)
      ? menu.props
      : defaultProps

  return useMultipleSelectionState({
    selectionMode,
    selectedKeys,
    defaultSelectedKeys,
  })
}

function hasMultipleSelectionProps(props: unknown): props is MultipleSelectionStateProps {
  return (
    typeof props === 'object' &&
    props !== null &&
    ('selectionMode' in props ||
      'defaultSelectedKeys' in props ||
      'selectedKeys' in props)
  )
}

/**
 * Enforces what children the SubMenuProvider should have.
 * It has to have an initial first element that can be anything or text,
 * and a second element that has to be of type MenuList.
 */
function validateChildren(nodes: ReactNode): [ReactNode, ReactNode] {
  const items: ReactNode[] = []

  Children.forEach(nodes, (child) => items.push(child))

  const firstItemIsTriggerContent =
    typeof items[0] === 'string' || isValidElement(items[0])
  const secondItemIsMenu = isValidElement(items[1]) && items[1].type === MenuList

  if (!firstItemIsTriggerContent || !secondItemIsMenu) {
    throw new Error('Invalid sub menu children')
  }

  return [items[0], items[1]]
}
