import config from '@src/config'
import { compareString, toQueryString } from '@src/lib'
import { formatted } from '@src/lib/phone-number'

import type { ParticipantModel } from '.'

export function fullName(entity: { firstName: string; lastName: string }): string {
  if (!entity) {
    return ''
  }
  return [entity.firstName, entity.lastName].filter((name) => name).join(' ')
}
;``
export const thumbnailUrl = (
  url: string,
  opts: { width?: number; height?: number; quality?: number },
): string => {
  if (url?.startsWith(config.STATIC_URL)) {
    const query = toQueryString(opts)
    return `${config.FILES_URL}${url.substr(config.STATIC_URL.length)}${query}`
  }
  return url
}

export const buffer = <T>(
  handler: (objs: T[]) => void,
  opts: { duration: number; maxSize?: number } = { duration: 0 },
) => {
  let timeout: number | null = null
  let objects: T[] = []
  return function (obj: T) {
    if (timeout) {
      window.clearTimeout(timeout)
    }
    objects.push(obj)
    if (opts.maxSize && objects.length >= opts.maxSize) {
      handler(objects)
      objects = []
    } else {
      timeout = window.setTimeout(() => {
        handler(objects)
        objects = []
      }, opts.duration)
    }
  }
}

export const compareByName = (
  a: { name: string; sortName?: string },
  b: { name: string; sortName?: string },
): number =>
  compareString(a.sortName ?? a.name, b.sortName ?? b.name, { caseInsensitive: true })

export const participantsFriendlyName = (participants: ParticipantModel[]): string => {
  return participants
    .map((participant) => {
      const name = participants.length === 1 ? participant.name : participant.shortName
      return name || formatted(participant.phoneNumber)
    })
    .join(', ')
}
