import type { MenuItemAria } from 'react-aria'

export default function removeUnusedHandlers(
  menuItemProps: MenuItemAria['menuItemProps'],
) {
  delete menuItemProps.onClick
  delete menuItemProps.onDoubleClick
  delete menuItemProps.onDragStart
  delete menuItemProps.onKeyDown
  delete menuItemProps.onKeyUp
  delete menuItemProps.onMouseDown
  delete menuItemProps.onPointerDown
  delete menuItemProps.onPointerUp
  return menuItemProps
}
