import cx from 'classnames'

import type { RenderOptionProps } from '@src/component'
import { MenuItem } from '@src/component/popover-menu'
import useIsOverflowing from '@src/lib/hooks/useIsOverflowing'
import Checkbox from '@ui/Checkbox'
import Tooltip from '@ui/Tooltip'
import Typography from '@ui/Typography'
import { CheckBoxTickIcon } from '@ui/icons/tint/16/general'
import { AddIcon } from '@ui/icons/tint/20/general'
import { theme } from '@ui/theme'

import * as styles from './SelectSearchPhoneNumberControl.css'
import type { SelectPhoneNumberOptionType } from './types'

function SelectSearchOptionHoC(multiSelect: boolean) {
  const CheckMark: (props: { checked: boolean }) => JSX.Element | null = multiSelect
    ? ({ checked }) => (
        <Checkbox className={styles.checkmark} checked={checked} aria-hidden="true" />
      )
    : ({ checked }) =>
        checked ? <CheckBoxTickIcon aria-hidden="true" className={styles.tick} /> : null

  const Component = ({
    option,
    index,
    rootOptionProps,
  }: RenderOptionProps<SelectPhoneNumberOptionType>) => {
    const isSelected = Boolean(rootOptionProps['aria-selected'])
    const { isOverflowing, ref: optionRef } = useIsOverflowing()

    let nameNode = (
      <Typography
        variant="footnote"
        nowrap
        ref={optionRef}
        color={isSelected ? `rgb(${theme.palette.primary.strong})` : undefined}
      >
        <span className={styles.itemIcon}>{option.icon}</span> {option.name}
      </Typography>
    )
    let icon: React.ReactNode = null

    if (option.__isTyped && !isSelected) {
      icon = <AddIcon />
      nameNode = (
        <Typography component="span" variant="footnote" nowrap ref={optionRef}>
          Set to{' '}
          <Typography component="span" variant="inherit" fontWeight="medium">
            {option.name}
          </Typography>
        </Typography>
      )
    }

    return (
      <MenuItem
        {...rootOptionProps}
        key={index}
        className={cx(styles.option, {
          [styles.selected]: isSelected,
        })}
        icon={icon}
      >
        <div className={styles.menuItemContent}>
          {isOverflowing ? <Tooltip title={option.name}>{nameNode}</Tooltip> : nameNode}

          <Typography
            variant="caption1"
            color="textTertiary"
            nowrap
            className={styles.optionRightLabel}
          >
            {option.formattedNumber}
          </Typography>

          <div className={styles.checkmarkContainer}>
            <CheckMark checked={isSelected} />
          </div>
        </div>
      </MenuItem>
    )
  }
  Component.displayName = multiSelect ? 'SelectSearchOptionMulti' : 'SelectSearchOption'

  return Component
}

export const SelectSearchOption = SelectSearchOptionHoC(false)
export const SelectSearchOptionMulti = SelectSearchOptionHoC(true)
