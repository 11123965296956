import type { MenuTriggerState } from '@react-stately/menu'
import type React from 'react'
import { createContext } from 'react'
import type { AriaButtonProps, AriaMenuOptions } from 'react-aria'

export interface MenuTriggerContextProps {
  state: MenuTriggerState
  menuTriggerProps: AriaButtonProps
  menuProps: AriaMenuOptions<unknown>
  ref: React.MutableRefObject<HTMLElement | null>
  setNodeRef: (element: HTMLElement | null) => void
}

const MenuTriggerContext = createContext<MenuTriggerContextProps | null>(null)

export default MenuTriggerContext
