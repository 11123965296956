/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { NotFoundError } from '@openphone/internal-api-client'
import { action, makeAutoObservable } from 'mobx'

import { formatDate } from '@src/lib'
import { DisposeBag } from '@src/lib/dispose'
import { logError } from '@src/lib/log'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import type {
  AvailablePhoneNumber,
  SearchPhoneNumberParams,
} from '@src/service/transport/account'

import type Service from '.'
import type { CodableEntityPhoneNumber, MemberModel } from './model'
import { EntityPhoneNumberModel } from './model'
import type { EntityPhoneNumberRepository } from './worker/repository'

export default class EntityPhoneNumberStore {
  collection: PersistedCollection<EntityPhoneNumberModel, EntityPhoneNumberRepository>

  private readonly disposeBag = new DisposeBag()

  constructor(private service: Service) {
    this.collection = new PersistedCollection({
      table: service.storage.table('entityPhoneNumber'),
      classConstructor: (json: CodableEntityPhoneNumber) =>
        new EntityPhoneNumberModel(service.organization.phoneNumber, json),
    })

    makeAutoObservable(this, {})

    this.disposeBag.add(this.subscribeToWebSocket())
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  getMember(id: string): MemberModel | null {
    return this.service.member.collection.get(id)
  }

  fetch() {
    this.collection.performQuery((repo) => repo.all())

    return this.service.transport.account.admin
      .phoneNumbers()
      .then((res) => this.collection.load(res, { deleteOthers: true }))
  }

  update(phoneNumber: EntityPhoneNumberModel) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .updatePhoneNumber(phoneNumber.serialize())
      .then(this.collection.load)
  }

  updateSettings(phoneNumber: EntityPhoneNumberModel) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .updatePhoneNumberSettings(phoneNumber.id, phoneNumber.serialize().settings)
      .then(this.collection.load)
  }

  toggleInternational(
    phoneNumber: EntityPhoneNumberModel,
  ): Promise<CodableEntityPhoneNumber> {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .toggleInternational(
        phoneNumber.id,
        phoneNumber.settings.international?.enabled ?? false,
      )
      .then(this.collection.load)
      .then((objs) => objs[0])
  }

  async addUser(phoneNumber: EntityPhoneNumberModel, userId: string) {
    this.collection.put(phoneNumber)
    const user = phoneNumber.users.find((u) => u.id === userId)
    if (!user) {
      return
    }
    return this.service.transport.account.admin
      .addPhoneNumberUser(phoneNumber.id, userId, user.role)
      .then(this.collection.load)
  }

  removeUser(phoneNumber: EntityPhoneNumberModel, userId: string) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .removePhoneNumberUser(phoneNumber.id, userId)
      .then(this.collection.load)
  }

  reassign(phoneNumber: EntityPhoneNumberModel, ownerId: string) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.admin
      .reassignPhoneNumber(phoneNumber.id, ownerId)
      .then(this.collection.load)
  }

  search(params: SearchPhoneNumberParams): Promise<AvailablePhoneNumber[]> {
    return this.service.transport.account.phoneNumbers.available(params)
  }

  buy(number: string, assignTo?: string): Promise<EntityPhoneNumberModel> {
    return this.service.transport.account.admin
      .buyPhoneNumber(number, assignTo)
      .then(this.collection.load)
      .then((phoneNumbers) => {
        const phoneNumber = phoneNumbers[0]
        this.service.analytics.workspace.numberCreated(
          this.service.organization.current?.id ?? '',
          phoneNumber?.number ?? '',
          'workspace',
        )
        this.service.analytics.workspace.userAddedToInbox(
          phoneNumber?.id,
          phoneNumber?.users.length,
          'owner',
        )
        return phoneNumbers[0]
      })
  }

  fetchIvr(phoneNumber: EntityPhoneNumberModel) {
    return this.service.transport.communication.ivr
      .get(phoneNumber.id)
      .then(
        action((res) => {
          phoneNumber.ivr = res
          this.collection.put(phoneNumber)
        }),
      )
      .catch(
        action((e) => {
          if (e instanceof NotFoundError) {
            phoneNumber.ivr = null
            this.collection.put(phoneNumber)
            return
          }
          throw e
        }),
      )
  }

  async setIvr(phoneNumber: EntityPhoneNumberModel) {
    this.collection.put(phoneNumber)
    const ivr = phoneNumber.serialize().ivr
    if (!ivr) {
      return
    }
    return this.service.transport.communication.ivr.set(ivr)
  }

  deleteIvr(phoneNumber: EntityPhoneNumberModel) {
    this.collection.put(phoneNumber)
    return this.service.transport.communication.ivr.delete(phoneNumber.id)
  }

  setVoicemail(phoneNumber: EntityPhoneNumberModel) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.recording
      .create({
        url: phoneNumber.voicemailUrl ?? undefined,
        type: 'voicemail',
        name: `Recording ${formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss')}`,
      })
      .then(
        action((recording) => {
          phoneNumber.voicemailId = recording.id ?? null
          return this.update(phoneNumber)
        }),
      )
  }

  setAwayVoicemail(phoneNumber: EntityPhoneNumberModel) {
    this.collection.put(phoneNumber)
    return this.service.transport.account.recording
      .create({
        url: phoneNumber.awayVoicemailUrl ?? undefined,
        type: 'away-voicemail',
        name: `Recording ${formatDate(new Date(), 'YYYY-MM-DD HH:mm:ss')}`,
      })
      .then(
        action((recording) => {
          phoneNumber.awayVoicemailId = recording.id ?? null
          return this.update(phoneNumber)
        }),
      )
  }

  delete(phoneNumber: EntityPhoneNumberModel) {
    this.collection.delete(phoneNumber)
    this.service.analytics.workspace.numberDeleted(
      this.service.organization.current?.id ?? '',
      phoneNumber.number ?? '',
    )
    return this.service.transport.account.admin.deletePhoneNumber(phoneNumber.id)
  }

  private subscribeToWebSocket() {
    return this.service.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'phone-number-update':
          return this.fetch().catch(logError)
        case 'phone-number-delete':
          return this.collection.delete(data.phoneNumberId)
      }
    })
  }
}
