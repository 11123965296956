import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'

import type { MessageMediaModel } from '@src/service/model'

import * as styles from './VideoViewer.css'
import * as sharedStyles from './shared.css'

interface VideoViewerProps {
  media: MessageMediaModel
}

const VideoViewer = function ({ media }: VideoViewerProps) {
  const rootRef = useRef<HTMLDivElement>(null)
  const mediaRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const mediaElement = mediaRef.current

    if (!mediaElement) {
      return
    }

    if (media.url) {
      mediaElement.src = media.url
    } else if (media.file) {
      mediaElement.src = URL.createObjectURL(media.file)
    }
  }, [media])

  return (
    <div ref={rootRef} className={sharedStyles.root}>
      <video autoPlay ref={mediaRef} controls tabIndex={-1} className={styles.video} />
    </div>
  )
}

export default observer(VideoViewer)
