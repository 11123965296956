import ActivityAlertBaseModel from './ActivityAlertBaseModel'

class ThreadResolutionAlertModel extends ActivityAlertBaseModel<
  'activity-resolved' | 'activity-unresolved',
  { activityId: string; conversationId: string; userId: string }
> {
  get isResolved() {
    return this.type === 'activity-resolved'
  }
}

export default ThreadResolutionAlertModel
