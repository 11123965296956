/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */
import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import type { HTMLProps, ReactNode } from 'react'

import Typography from '@ui/Typography'

import * as styles from './Card.css'

interface CardProps extends HTMLProps<HTMLDivElement> {
  emoji?: string
  illustration?: ReactNode
  title: string
  description?: ReactNode
  headerClassName?: string
  contentClassName?: string
  callout?: ReactNode
  headerWidth?: 320 | 472
  contentWidth?: 320 | 432 | 472 | 800
}

export const Card = function ({
  emoji,
  illustration,
  title,
  description,
  children,
  className,
  headerClassName,
  contentClassName,
  callout,
  headerWidth = 320,
  contentWidth = 320,
  ...props
}: CardProps) {
  return (
    <div {...props} className={cx(styles.root({ hasTopMargin: !!emoji }), className)}>
      <div
        className={cx(styles.header, headerClassName)}
        style={assignInlineVars({ [styles.headerWidth]: `${headerWidth}px` })}
      >
        {callout}
        {emoji ? (
          <Typography variant="largeTitle" style={{ marginBottom: 8 }}>
            {emoji}
          </Typography>
        ) : (
          illustration
        )}
        {title && (
          <Typography variant="largeTitle" style={{ marginBottom: 10 }}>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body" color="textSecondary">
            {description}
          </Typography>
        )}
      </div>
      <div
        className={cx(styles.content, contentClassName)}
        style={assignInlineVars({ [styles.contentWidth]: `${contentWidth}px` })}
      >
        {children}
      </div>
    </div>
  )
}
