import { observer } from 'mobx-react-lite'

import type { FlagKey, FlagValue } from '@src/service/feature-flags'

import useFlag from './useFlag'

export interface FlagProps<Key extends FlagKey> {
  flag: Key
  defaultValue?: FlagValue<Key>
  children: React.ReactNode
  fallback?: React.ReactNode
}

function Flag<Key extends FlagKey>({
  flag,
  defaultValue,
  children,
  fallback = null,
}: FlagProps<Key>) {
  const value = useFlag(flag, defaultValue)

  if (!value) {
    return <>{fallback}</>
  }

  // FIXME: https://linear.app/openphone/issue/ENG-4160
  return <>{children}</>
}

export default observer(Flag)
