import Key from '@ui/Key'

import * as styles from './Shortcut.css'

interface ShortcutProps {
  id?: string
  keys: string[]
}

function Shortcut({ id, keys }: ShortcutProps) {
  return (
    <div id={id} className={styles.root}>
      {keys.map((key) => (
        <Key key={key} label={key} variant="caption1" />
      ))}
    </div>
  )
}

export default Shortcut
