import config from '@src/config'
import type { SearchConversationsRequest } from '@src/service/dto/request/search/SearchConversationsRequest'
import type { SearchConversationsResponse } from '@src/service/dto/response/search/SearchConversationsResponse'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export default class SearchClient {
  constructor(private transport: Transport) {}

  conversation(body: SearchConversationsRequest): Promise<SearchConversationsResponse> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'POST',
        url: `${config.SEARCH_SERVICE_URL}search/conversation`,
        body,
      }),
    )
  }
}
