import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import { Children, cloneElement, forwardRef, isValidElement } from 'react'

import assertNever from '@src/lib/assertNever'
import type { AvatarSize, AvatarProps } from '@ui/Avatar'

import * as styles from './AvatarRow.css'

export interface AvatarRowProps {
  size?: AvatarSize
  maxAvatars?: number
  className?: string
  children: React.ReactNode
  reversed?: boolean
  onClick?: () => void
}

const AvatarRow = forwardRef<HTMLDivElement, AvatarRowProps>(function (
  { children, className, size = 26, maxAvatars = 4, reversed = false, ...props },
  ref,
) {
  const childrenLength = Children.count(children)
  const childrenArray = Children.toArray(children)

  const avatarsCount = childrenLength > maxAvatars ? maxAvatars - 1 : childrenLength
  const extra = childrenLength - avatarsCount
  const avatars = childrenArray.slice(0, avatarsCount)

  return (
    <div
      ref={ref}
      {...props}
      className={cx(styles.root({ reversed }), className)}
      style={assignInlineVars({
        [styles.marginVar]: `${getMargin(size)}px`,
      })}
    >
      {avatars.map((element) => {
        if (!isValidElement<AvatarProps>(element)) {
          throw new Error('AvatarRow children must be valid Avatar elements')
        }

        return cloneElement(element, {
          className: cx(styles.avatar({ reversed }), element.props.className),
          size,
        })
      })}
      {extra > 0 && (
        <div
          className={cx(styles.avatar({ reversed }), styles.more)}
          style={assignInlineVars({
            [styles.sizeVar]: `${size}px`,
            [styles.fontSizeVar]: `${getMoreFontSize(size)}px`,
            [styles.paddingVar]: `${getMorePadding(size)}px`,
          })}
        >
          {extra}
        </div>
      )}
    </div>
  )
})

export default AvatarRow

const getMargin = (size: AvatarSize): number => {
  switch (size) {
    case 14:
    case 16:
      return -5
    case 18:
    case 20:
    case 22:
      return -7
    case 24:
    case 26:
      return -9
    case 30:
      return -11
    case 34:
    case 36:
      return -13
    case 40:
      return -15
    case 48:
      return -18
    case 56:
      return -21
    case 80:
      return -31
    case 96:
      return -37
    default:
      assertNever(size, `Unhandled avatar size: ${size}`)
  }
}

const getMoreFontSize = (size: AvatarSize): number => {
  switch (size) {
    case 14:
    case 16:
    case 18:
      return 9
    case 20:
      return 10
    case 22:
      return 11
    case 24:
      return 12
    case 26:
      return 13
    case 30:
      return 14
    case 34:
      return 15
    case 36:
      return 16
    case 40:
      return 17
    case 48:
      return 18
    case 56:
      return 20
    case 80:
      return 21
    case 96:
      return 24
    default:
      assertNever(size, `Unhandled avatar size: ${size}`)
  }
}

const getMorePadding = (size: AvatarSize): number => {
  switch (size) {
    case 14:
      return 4
    case 16:
    case 18:
    case 20:
      return 5
    case 22:
    case 24:
      return 6
    case 26:
      return 7
    case 30:
      return 8
    case 34:
    case 36:
      return 9
    case 40:
    case 48:
      return 10
    case 56:
      return 12
    case 80:
    case 96:
      return 20
    default:
      assertNever(size, `Unhandled avatar size: ${size}`)
  }
}
