import type { Selection } from '@react-types/shared'

import Typography from '@ui/Typography'

interface SelectedOptionsProps {
  selection: Selection
}

export default function SelectedOption({ selection }: SelectedOptionsProps) {
  const optionName = (() => {
    if (selection === 'all') {
      return 'All'
    }

    if (selection.size > 1) {
      return [...selection].join(', ')
    }

    if (selection.size === 1) {
      return [...selection][0]
    }

    return null
  })()

  if (optionName === null) {
    return null
  }

  return (
    <Typography
      variant="footnote"
      color="textSecondary"
      aria-label={`Option checked: ${optionName}`}
    >
      {optionName}
    </Typography>
  )
}
