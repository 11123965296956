import { useMenuSection } from 'react-aria'

import { useMenuList } from '@ui/Menu/MenuList/context'
import type { MenuNode } from '@ui/Menu/MenuList/context/useMenuTreeState'
import MenuListItem from '@ui/Menu/MenuListItem'
import MenuListSectionLabel from '@ui/Menu/MenuListSectionLabel'
import MenuSeparator from '@ui/Menu/MenuSeparator'

import * as styles from './MenuListSection.css'

export interface MenuListSectionProps<T> {
  section: MenuNode<T>
}

const MenuListSection = <T extends object>({ section }: MenuListSectionProps<T>) => {
  const { state } = useMenuList()
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: section.rendered,
    'aria-label': section['aria-label'],
  })

  const children = [...section.childNodes]

  const isAtTopOfCollection = state.collection.getFirstKey() === section.key

  return (
    <>
      {isAtTopOfCollection ? null : <MenuSeparator />}
      <li {...itemProps} className={styles.root}>
        {section.rendered ? (
          <MenuListSectionLabel role={headingProps.role}>
            {section.rendered}
          </MenuListSectionLabel>
        ) : null}
        <ul {...groupProps} className={styles.group}>
          {children.map((node) => {
            if (node.type === 'item') {
              return <MenuListItem key={`${section.key}-${node.key}`} item={node} />
            }

            return null
          })}
        </ul>
      </li>
    </>
  )
}

export default MenuListSection
