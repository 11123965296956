import type { Location, NavigateOptions } from 'react-router-dom'

type LocationLike =
  | Location
  | {
      url: URL
      options?: NavigateOptions
    }

const getSearchParams = (locationOrUrl: Location | URL) => {
  if (locationOrUrl instanceof URL) {
    return locationOrUrl.searchParams
  }

  return new URLSearchParams(locationOrUrl.search)
}

const getState = (item: LocationLike) => {
  if ('url' in item) {
    // Default state is null in react-router-dom
    return (item.options?.state as unknown) ?? null
  }

  return item.state as unknown
}

const isSameLocation = (itemA: LocationLike, itemB: LocationLike) => {
  const a = 'url' in itemA ? itemA.url : itemA
  const b = 'url' in itemB ? itemB.url : itemB

  const isSamePath = a.pathname === b.pathname

  const isSameHash = a.hash === b.hash

  const isSameSearch = (() => {
    // If previous search exists but new search doesn't, or vice versa,
    // then they are not the same
    if ((!a.search && b.search) || (a.search && !b.search)) {
      return false
    }

    const searchParamsA = getSearchParams(a)
    const searchParamsB = getSearchParams(b)

    const searchKeysA = Array.from(searchParamsA.keys())
    const searchKeysB = Array.from(searchParamsB.keys())

    // If the number of search params are different, then they are not the same
    if (searchKeysA.length !== searchKeysB.length) {
      return false
    }

    // Compare search by key/value pairs.
    // Keep in mind the order of the search params can be different, but we
    // should still consider them the same
    for (const [key, value] of searchParamsA.entries()) {
      if (searchParamsB.get(key) !== value) {
        return false
      }
    }

    return true
  })()

  const isSameState = (() => {
    const stateA = getState(itemA)
    const stateB = getState(itemB)
    if (stateA === undefined && stateB === undefined) {
      return true
    }

    if (stateA === null && stateB === null) {
      return true
    }

    if (typeof stateA === 'object' && typeof stateB === 'object') {
      return JSON.stringify(stateA) === JSON.stringify(stateB)
    }

    return stateA === stateB
  })()

  return isSamePath && isSameSearch && isSameHash && isSameState
}

export default isSameLocation
