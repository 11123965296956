import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjsIsToday from 'dayjs/plugin/isToday'
import dayjsIsTomorrow from 'dayjs/plugin/isTomorrow'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

import { timezones } from '@src/lib/constants'
import { isTruthy } from '@src/lib/string'

import { chrono } from './chrono'

type D = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 0

type OneToNine = Exclude<D, 0>
export type YYYY = `20${D}${D}`
export type MM = `0${OneToNine}` | `1${0 | 1 | 2}`
export type DD = `0${OneToNine}` | `${1 | 2}${D}` | `3${0 | 1}`

/**
 * Extends `dayjs` with the needed plugins so that the are available throughout the whole app.
 * This gets only called once on app initialisation.
 */
export const extendDayJs = () => {
  dayjs.extend(customParseFormat)
  dayjs.extend(quarterOfYear)
  dayjs.extend(relativeTime, {
    thresholds: [
      { l: 's', r: 1 },
      { l: 'ss', r: 59, d: 'second' },
      { l: 'm', r: 1 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 1 },
      { l: 'hh', r: 23, d: 'hour' },
      { l: 'd', r: 1 },
      { l: 'dd', r: 29, d: 'day' },
      { l: 'M', r: 1 },
      { l: 'MM', r: 11, d: 'month' },
      { l: 'y', r: 1 },
      { l: 'yy', d: 'year' },
    ],
    rounding: (number) => Math.floor(Math.max(1, number)),
  })
  dayjs.extend(utc)
  dayjs.extend(dayjsIsToday)
  dayjs.extend(dayjsIsTomorrow)
  dayjs.extend(advancedFormat)
  dayjs.extend(updateLocale)
  dayjs.updateLocale('en', {
    relativeTime: {
      ...dayjs.Ls.en.relativeTime,
      s: '%d second',
      ss: '%d seconds',
      m: '%d minute',
      h: '%d hour',
      d: '%d day',
      M: '%d month',
      y: '%d year',
    },
  })
}

export const MONTH_LONG_DATE_SHORT_YEAR_TIME_FORMAT = 'MMMM D, YYYY, h:mm A'
export const MONTH_LONG_DATE_TIME_FORMAT = 'MMMM D, h:mm A'

const nth = function (d: number) {
  if (d > 3 && d < 21) {
    return 'th'
  }
  switch (d % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

const getDayjs = (date: string | number | Date | Dayjs): Dayjs => {
  if (dayjs.isDayjs(date)) {
    return date
  }
  return dayjs(date)
}

export const isDate = (date: unknown): date is Date => {
  return !!date && date instanceof Date
}

export const fromNow = (
  date: string | number | Date | Dayjs,
  withoutSuffix = false,
): string => {
  return getDayjs(date).fromNow(withoutSuffix)
}

export const sameDay = (
  timestamp1: number | Date | Dayjs,
  timestamp2: number | Date | Dayjs,
): boolean => {
  return getDayjs(timestamp1).isSame(getDayjs(timestamp2), 'date')
}

const sameWeek = (
  timestamp1: number | Date | Dayjs,
  timestamp2: number | Date | Dayjs,
): boolean => {
  return getDayjs(timestamp1).isSame(getDayjs(timestamp2), 'week')
}

const sameYear = (
  timestamp1: number | Date | Dayjs,
  timestamp2: number | Date | Dayjs,
): boolean => {
  return getDayjs(timestamp1).isSame(getDayjs(timestamp2), 'year')
}

const isYesterday = (timestamp: number | Date | Dayjs): boolean => {
  return sameDay(timestamp, Date.now() - 24 * 3600 * 1000)
}

const isTomorrow = (timestamp: number | Date | Dayjs): boolean => {
  return sameDay(timestamp, Date.now() + 24 * 3600 * 1000)
}

const isThisWeek = (timestamp: number | Date | Dayjs): boolean => {
  return sameWeek(timestamp, Date.now())
}

export const isToday = (timestamp: number | Date | Dayjs): boolean => {
  return sameDay(timestamp, Date.now())
}

export const isThisYear = (timestamp: number | Date | Dayjs): boolean => {
  return sameYear(timestamp, Date.now())
}

export const isFromPreviousYear = (
  timestamp: string | number | Date | Dayjs,
): boolean => {
  return dayjs(timestamp).year() < dayjs().year()
}

export interface FriendlyDateOptions {
  upperFirst?: boolean
  useTimeForToday?: boolean
}

export const friendlyDate = (
  timestamp: number | Date | Dayjs,
  { upperFirst = true, useTimeForToday = false }: FriendlyDateOptions = {},
): string => {
  if (isToday(timestamp)) {
    if (useTimeForToday) {
      return getDayjs(timestamp).format('h:mm A')
    }
    return (upperFirst ? 'T' : 't') + 'oday'
  } else if (isYesterday(timestamp)) {
    return (upperFirst ? 'Y' : 'y') + 'esterday'
  } else if (isThisYear(timestamp)) {
    return getDayjs(timestamp).format('MMM D')
  } else {
    return getDayjs(timestamp).format('MMM D, YYYY')
  }
}

export type FriendlyDateTimeOptions = Omit<FriendlyDateOptions, 'useTimeForToday'>

export const friendlyDateTime = (
  timestamp: number | Date | Dayjs,
  { upperFirst = true }: FriendlyDateTimeOptions = {},
): string => {
  if (isToday(timestamp)) {
    return (upperFirst ? 'T' : 't') + `oday, ${getDayjs(timestamp).format('h:mm a')}`
  } else if (isYesterday(timestamp)) {
    return (upperFirst ? 'Y' : 'y') + `esterday ${getDayjs(timestamp).format('h:mm a')}`
  } else if (isThisYear(timestamp)) {
    return getDayjs(timestamp).format('MMM D, h:mm a')
  } else {
    return getDayjs(timestamp).format('MMM D, YYYY, h:mm a')
  }
}

export const fromNowV2 = (timestamp: number | Date): string => {
  const [secondsPerMinute, secondsPerHour] = [60, 3600] as const
  const date = getDayjs(timestamp).startOf('second')

  // We want to round to the nearest minute to show "for 1 minute" when it's actually 59 seconds
  // or "for 1 hour" when the time is actually 59 minutes and 59 seconds.
  // The trade-off is that we disregard seconds entirely, losing time precision.
  // This can result in a time for example 31 seconds being rounded to "for 1 minute".
  const diffInMinutesRounded = Math.round(
    getDayjs(timestamp).diff(dayjs(), 'minutes', true),
  )
  const diffInSecondsRounded = diffInMinutesRounded * secondsPerMinute

  /**
   * If the date is within 60 mins show e.g. "for 45 minutes."
   */
  if (diffInMinutesRounded < secondsPerMinute) {
    return `for ${diffInMinutesRounded} ${
      diffInMinutesRounded === 1 ? 'minute' : 'minutes'
    }`
  }

  /**
   * If it's within 3 hours, show e.g. "for 2 hours".
   */
  if (diffInSecondsRounded < 3 * secondsPerHour) {
    const hours = Math.floor(diffInSecondsRounded / secondsPerHour)
    const parts = [
      hours,
      Math.round((diffInSecondsRounded % secondsPerHour) / secondsPerMinute),
    ]
    return [
      'for',
      ...parts
        .map((p, i) =>
          i === 0
            ? `${p} ${p === 1 ? 'hour' : 'hours'}`
            : p > 0
            ? `and ${p} ${p === 1 ? 'minute' : 'minutes'}`
            : null,
        )
        .filter(isTruthy),
    ].join(' ')
  }

  /**
   * If it's today show e.g. "until 5pm"
   */
  if (isToday(date)) {
    return `until ${date.format('h:mma')}`
  }

  /**
   * If it's tomorrow show e.g. "until tomorrow at 5pm"
   */
  if (isTomorrow(date)) {
    return `until tomorrow at ${date.format('h:mma')}`
  }

  /**
   * If it's this week show e.g. "until Wednesday at 5pm"
   */
  if (isThisWeek(date)) {
    return `until ${date.format('dddd')} at ${date.format('h:mma')}`
  }

  /**
   * If it's this year show e.g. "until April 16th at 5pm"
   */
  if (isThisYear(date)) {
    return `until ${date.format('MMMM D')}${nth(date.date())} at ${date.format('h:mma')}`
  }

  /**
   * If it's in distant future, say "until further notice"
   */
  if (date.year() - dayjs().year() > 20) {
    return `until further notice`
  }

  /**
   * Return e.g. "on January 7th 2021 at 3pm"
   */
  return `on ${date.format('MMMM D')}${nth(date.date())} ${date.year()} at ${date.format(
    'h:mma',
  )}`
}

export const friendlyTime = (timestamp: number | Date): string => {
  return dayjs(timestamp).format('h:mm a')
}

export const formatDate = (
  date: Date | number | string,
  format = 'MMM D, YYYY',
  offset?: number,
): string => {
  if (offset !== undefined) {
    return dayjs(date).utcOffset(offset).format(format)
  }

  return dayjs(date).format(format)
}

/**
 *  Takes a duration in seconds and returns a string formatted as HH:MM:SS
 *
 * @param duration The duration in seconds.
 * @param padMinutes Whether to pad the minutes with a leading zero.
 * @param padHours Whether to pad the hours with a leading zero.
 */
export const toHHMMSS = (
  duration: number,
  padMinutes = false,
  padHours = false,
): string => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - hours * 3600) / 60)
  const seconds = Math.floor(duration - hours * 3600 - minutes * 60)

  const formattedHours: string = padHours
    ? hours.toString().padStart(2, '0')
    : hours.toString()

  const formattedMinutes: string =
    padMinutes || padHours || hours > 0
      ? minutes.toString().padStart(2, '0')
      : minutes.toString()

  const formattedSeconds: string = seconds.toString().padStart(2, '0')

  const formattedMinutesAndSeconds = `${formattedMinutes}:${formattedSeconds}`

  if (hours > 0 || padHours) {
    return `${formattedHours}:${formattedMinutesAndSeconds}`
  }

  return formattedMinutesAndSeconds
}

export const parse = (input: string | number): Date | null => {
  if (!input) {
    return null
  }
  return dayjs(input).toDate()
}

export function minutes(number: number): number {
  return number * 60000
}

export function hours(number: number): number {
  return number * minutes(60)
}

export function parseFromString(
  text: string,
  opts?: { forwardDate: boolean },
): Date | null {
  return (
    chrono.parseDate(text, undefined, opts) ||
    chrono.parseDate(`in ${text}`, undefined, opts)
  )
}

export function parseDate(date: string | number | Date): number | null {
  if (typeof date === 'string') {
    return Date.parse(date)
  } else if (typeof date === 'number') {
    return date
  } else if (date instanceof Date) {
    return date.getTime()
  }
  return null
}

export function daysBefore(date: string | number | Date | dayjs.Dayjs): number {
  return dayjs(date).diff(new Date(), 'day')
}

/**
 * Returns if the formatted date string should be preceeded by a
 * preposition such as "at", "in", or "on".
 *
 * E.g. it's correct to say "on May 1" but incorrect to say "on today".
 *
 * @param formattedDate The date formatted as a string from a function
 * such as friendlyDate.
 */
export function hasTimePreposition(formattedDate: string) {
  return !['today', 'yesterday', 'tomorrow'].includes(formattedDate.toLowerCase())
}

/**
 * Returns an ISO 8601 string.
 *
 * @param date
 */
export function formatDateToISO(date: number) {
  return dayjs(date).toISOString()
}

export function isLiveSupportBusinessHours(): boolean {
  const currentUtcTime = dayjs().utc()
  const pacificOffset =
    timezones.find((timezone) => timezone.name === 'America/Los_Angeles')
      ?.offsetInMinutes ?? 0
  const pacificTime = currentUtcTime.add(pacificOffset, 'minute')

  const startOfBusiness = pacificTime.hour(8).minute(0).second(0)
  const endOfBusiness = pacificTime.hour(17).minute(0).second(0)

  return pacificTime.isAfter(startOfBusiness) && pacificTime.isBefore(endOfBusiness)
}
