/* eslint-disable import/prefer-default-export -- FIXME: Fix this ESLint violation! */

import type CreatedBy from './CreatedBy'

export function createdByUserName(createdBy: CreatedBy | null): string | null {
  if (!createdBy) {
    return null
  }
  return `${createdBy.firstName} ${createdBy.lastName}`
}
