import { useEffect, useState } from 'react'

import { useAppStore } from '@src/app/context'

const LOCAL_STORAGE_PARTNER_STACK_KEY = 'PartnerStack.key'

export function useStorePartnerStackKey() {
  const { history } = useAppStore()

  useEffect(() => {
    const partnerStackKey =
      history.consumeQueryParam('ps_partner_key') ?? history.consumeQueryParam('gspk')

    if (!partnerStackKey) {
      return
    }

    localStorage.setItem(LOCAL_STORAGE_PARTNER_STACK_KEY, partnerStackKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, [history.consumeQueryParam])
}

export function useRestorePartnerStackKey() {
  const [partnerStackKey] = useState<string | null>(() =>
    localStorage.getItem(LOCAL_STORAGE_PARTNER_STACK_KEY),
  )

  function clear() {
    localStorage.removeItem(LOCAL_STORAGE_PARTNER_STACK_KEY)
  }

  return [partnerStackKey, clear] as const
}
