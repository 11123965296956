import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { fromEvent } from 'rxjs'

import Image from '@src/component/image'
import type { MessageMediaModel } from '@src/service/model'

import * as styles from './ImageViewer.css'
import * as sharedStyles from './shared.css'

interface ImageViewerProps {
  media: MessageMediaModel
}

const ImageViewer = function ({ media }: ImageViewerProps) {
  const rootRef = useRef<HTMLDivElement | null>(null)
  const imageRef = useRef<HTMLImageElement | null>(null)
  const optimalScaleRef = useRef<number>(0)
  const transformRef = useRef({ scale: 1, x: 0, y: 0 })

  function setTransform(scale: number, x = 0, y = 0, animate?: boolean) {
    transformRef.current = { scale, x, y }
    if (imageRef.current) {
      imageRef.current.style.transform = `translate3d(${x}px, ${y}px, 0) scale(${scale}, ${scale})`
      if (animate) {
        imageRef.current.style.transition = 'transform 200ms ease 0s'
      } else {
        imageRef.current.style.transition = 'none'
      }
    }
  }

  useEffect(() => {
    function adjustSize(initial: boolean) {
      if (!rootRef.current || !imageRef.current) {
        return
      }
      const { clientHeight, clientWidth } = rootRef.current
      const { naturalHeight, naturalWidth } = imageRef.current
      const overflow = naturalWidth > clientWidth || naturalHeight > clientHeight

      if (overflow) {
        optimalScaleRef.current = Math.min(
          (clientWidth - 175) / naturalWidth,
          clientHeight / naturalHeight,
        )
        if (initial) {
          setTransform(optimalScaleRef.current, 0, 0)
        } else {
          setTransform(
            optimalScaleRef.current,
            transformRef.current.x,
            transformRef.current.y,
          )
        }
      } else {
        optimalScaleRef.current = 1
      }
    }

    const rootCurrent = rootRef.current
    const imageCurrent = imageRef.current
    const resizeObserver = new ResizeObserver(() => adjustSize(false))
    if (rootCurrent) {
      resizeObserver.observe(rootCurrent)
    }

    const subs = [
      ...(imageCurrent
        ? [
            fromEvent(imageCurrent, 'load').subscribe(() => {
              imageCurrent.style.display = 'block'
              adjustSize(true)
            }),
          ]
        : []),
      fromEvent(window, 'resize').subscribe(() => adjustSize(false)),
    ]

    return () => {
      subs.forEach((s) => s.unsubscribe())
      if (rootCurrent) {
        resizeObserver.unobserve(rootCurrent)
      }
    }
  }, [])

  useEffect(() => {
    const rootCurrent = rootRef.current
    const imageCurrent = imageRef.current
    let active = false
    let initialX: number, initialY: number, lastX: number, lastY: number

    imageRef.current?.addEventListener('touchstart', dragStart, false)
    imageRef.current?.addEventListener('mousedown', dragStart, false)
    imageRef.current?.addEventListener('touchend', dragEnd, false)
    imageRef.current?.addEventListener('mouseup', dragEnd, false)
    rootRef.current?.addEventListener('touchend', dragEnd, false)
    rootRef.current?.addEventListener('mouseup', dragEnd, false)
    document.addEventListener('touchmove', drag, false)
    document.addEventListener('mousemove', drag, false)
    document.addEventListener('mouseleave', dragEnd, false)

    function handleZoom(offsetX: number, offsetY: number) {
      if (optimalScaleRef.current < transformRef.current.scale) {
        setTransform(optimalScaleRef.current, 0, 0, true)
      } else if (
        optimalScaleRef.current === transformRef.current.scale &&
        imageRef.current
      ) {
        const scale = optimalScaleRef.current < 1 ? 1 : 2
        const { naturalWidth, naturalHeight } = imageRef.current
        const deltaX = naturalWidth / 2 - offsetX
        const deltaY = naturalHeight / 2 - offsetY
        setTransform(scale, deltaX, deltaY, true)
      }
    }

    function dragStart(e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      if (e.type === 'touchstart') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        initialX = e.touches[0].clientX
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        initialY = e.touches[0].clientY
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        initialX = e.clientX
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        initialY = e.clientY
      }
      lastX = initialX
      lastY = initialY
      active = true
    }

    function dragEnd(e) {
      active = false

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      const t = e.type === 'touchmove' ? e.touches[0] : e
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      const currentX = t.clientX
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      const currentY = t.clientY
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      const offsetX = t.offsetX
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      const offsetY = t.offsetY
      const deltaX = currentX - initialX
      const deltaY = currentY - initialY
      const delta = Math.abs(Math.max(deltaX, deltaY))

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      if (e.target === imageRef.current && delta < 5) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        handleZoom(offsetX, offsetY)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
        e.stopPropagation()
        return
      }
    }

    function drag(e) {
      if (active) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
        e.preventDefault()

        let currentX,
          currentY = 0

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        if (e.type === 'touchmove') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
          currentX = e.touches[0].clientX
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
          currentY = e.touches[0].clientY
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
          currentX = e.clientX
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
          currentY = e.clientY
        }
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands -- FIXME: Fix this ESLint violation!
        const deltaX = transformRef.current.x + currentX - lastX
        const deltaY = transformRef.current.y + currentY - lastY
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        lastX = currentX
        lastY = currentY

        setTransform(transformRef.current.scale, deltaX, deltaY)
      }
    }

    return () => {
      imageCurrent?.removeEventListener('touchstart', dragStart, false)
      imageCurrent?.removeEventListener('mouseup', dragEnd, false)
      imageCurrent?.removeEventListener('touchend', dragEnd, false)
      imageCurrent?.removeEventListener('mousedown', dragStart, false)
      rootCurrent?.removeEventListener('touchend', dragEnd, false)
      rootCurrent?.removeEventListener('mousedown', dragStart, false)
      document.removeEventListener('touchmove', drag, false)
      document.removeEventListener('mousemove', drag, false)
      document.removeEventListener('mouseleave', dragEnd, false)
    }
  }, [])

  return (
    <div ref={rootRef} className={sharedStyles.root}>
      <Image ref={imageRef} media={media} tabIndex={-1} className={styles.image} />
    </div>
  )
}

export default observer(ImageViewer)
