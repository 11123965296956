import { action, makeObservable, observable } from 'mobx'

export type PromiseState = 'pending' | 'fulfilled' | 'rejected'

type TimeoutState = 'active' | 'reached' | 'cleared'

export interface DeferredOptions {
  timeout?: number
}

class TimeoutError extends Error {
  constructor(message?: string) {
    super(message)
    this.name = 'TimeoutError'
  }
}

export default class Deferred<T> {
  private _resolve!: (value: T | PromiseLike<T>) => void
  private _reject!: (reason?: any) => void
  private _timeoutId?: number

  readonly promise: Promise<T> = new Promise((resolve, reject) => {
    this._resolve = resolve
    this._reject = reject
  })

  state: PromiseState = 'pending'
  timeoutState: TimeoutState | null = null

  constructor(protected _options: DeferredOptions = {}) {
    if (typeof _options.timeout === 'number') {
      this.timeoutState = 'active'
      this._timeoutId = window.setTimeout(
        action(() => {
          this.timeoutState = 'reached'
          this.reject(new TimeoutError('Timeout reached.'))
        }),
        _options.timeout,
      )
    }
    makeObservable(this, {
      state: observable.ref,
      timeoutState: observable.ref,
      resolve: action,
      reject: action,
      clearTimeout: action,
    })
  }

  resolve(value: T | PromiseLike<T>): void {
    if (this.state === 'pending') {
      this.state = 'fulfilled'
      this._resolve(value)
      this._clearTimeout()
    }
  }

  reject(reason?: any): void {
    if (this.state === 'pending') {
      this.state = 'rejected'
      this._reject(reason)
      this._clearTimeout()
    }
  }

  clearTimeout(): void {
    this.timeoutState = 'cleared'
    this._clearTimeout()
  }

  private _clearTimeout(): void {
    window.clearTimeout(this._timeoutId)
  }
}
