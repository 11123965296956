import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

import type { MessageMediaModel } from '@src/service/model'

import * as styles from './PdfViewer.css'
import * as sharedStyles from './shared.css'

interface PdfViewerProps {
  media: MessageMediaModel
}

const PdfViewer = function ({ media }: PdfViewerProps) {
  const rootRef = useRef<HTMLDivElement>(null)

  if (media.file || !media.url) {
    return null
  }

  return (
    <div ref={rootRef} className={sharedStyles.root}>
      <iframe frameBorder={0} className={styles.iframe} src={media.url} />
    </div>
  )
}

export default observer(PdfViewer)
