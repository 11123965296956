import Popover from '@ui/Popover'
import type { PopoverProps } from '@ui/Popover'
import { LayerProvider } from '@ui/z-index'

import * as styles from './ComboboxPopover.css'

export default function ComboboxPopover({ children, ...props }: PopoverProps) {
  return (
    <LayerProvider layer="menu">
      <Popover
        {...props}
        isDismissable
        className={styles.popover}
        containFocus
        shouldCloseOnInteractOutside={(element) => {
          const isOutsideComboboxAndTrigger = !props.targetRef.current?.contains(element)

          return isOutsideComboboxAndTrigger
        }}
      >
        {children}
      </Popover>
    </LayerProvider>
  )
}
