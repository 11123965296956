import type { ListState } from '@react-stately/list'
import { ListCollection } from '@react-stately/list'
import type { Collection, Node } from '@react-types/shared'
import type { ChangeEvent, KeyboardEvent } from 'react'
import { useCallback, useState } from 'react'
import { useFilter } from 'react-aria'

import { getFirstValidItemKey } from '@ui/Combobox/ComboboxList/getNextValidItemKey'
import { useComboboxTrigger } from '@ui/Combobox/ComboboxProvider'

export type InputProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
  placeholder: string
}

interface ListFilter<T> {
  collection: Collection<Node<T>>
  inputProps: InputProps
}

interface FilterOptions {
  enabled: boolean
  inputPlaceholder?: string
}

export default function useListFilter<T extends object>(
  state: ListState<T>,
  options: FilterOptions,
): ListFilter<T> {
  const {
    state: { close },
  } = useComboboxTrigger()
  const { contains } = useFilter({ sensitivity: 'base' })
  const [originalCollection, setOriginalCollection] = useState(state.collection)
  const [inputValue, setInputValue] = useState('')
  const [filteredCollection, setFilteredCollection] = useState(state.collection)

  if (state.collection !== originalCollection) {
    setOriginalCollection(state.collection)
    setFilteredCollection(filterCollection(state.collection, inputValue, contains))
  }

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const key = event.key

      if (
        key === 'ArrowDown' &&
        event.currentTarget.selectionEnd === event.currentTarget.value.length
      ) {
        const firstKey = state.collection.getFirstKey()
        const keyToFocus = getFirstValidItemKey(state, firstKey)

        if (!keyToFocus) {
          return
        }

        state.selectionManager.setFocused(true)
        state.selectionManager.setFocusedKey(keyToFocus)
      }

      if (key === 'Escape') {
        close()
      }
    },
    [state, close],
  )

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value

      if (value === '') {
        setFilteredCollection(originalCollection)
      } else {
        setFilteredCollection(filterCollection(originalCollection, value, contains))
      }

      setInputValue(value)
    },
    [contains, originalCollection],
  )

  return {
    inputProps: {
      onChange,
      onKeyDown,
      placeholder: options.inputPlaceholder ?? 'Search...',
    },
    collection: options.enabled ? filteredCollection : state.collection,
  }
}

type FilterFn = (textValue: string, inputValue: string) => boolean

function filterCollection<T extends object>(
  collection: Collection<Node<T>>,
  inputValue: string,
  filter: FilterFn,
): Collection<Node<T>> {
  return new ListCollection(filterNodes(collection, inputValue, filter))
}

function filterNodes<T>(
  nodes: Iterable<Node<T>>,
  inputValue: string,
  filter: FilterFn,
): Iterable<Node<T>> {
  const filteredNodes: Node<T>[] = []
  for (const node of nodes) {
    if (node.type === 'separator') {
      // Prevent a leading separator or two consecutive separators
      const prevNode = filteredNodes[filteredNodes.length - 1]
      const hasValidPrevNode = !!prevNode && prevNode.type !== 'separator'
      if (hasValidPrevNode) {
        filteredNodes.push({ ...node })
      }
    } else if (node.type === 'section' && node.hasChildNodes) {
      const filteredChildNodes = filterNodes(node.childNodes, inputValue, filter)
      if ([...filteredChildNodes].length > 0) {
        filteredNodes.push({ ...node, childNodes: filteredChildNodes })
      }
    } else if (node.type !== 'section' && filter(node.textValue, inputValue)) {
      filteredNodes.push({ ...node })
    }
  }

  // Prevent a trailing separator
  if (
    filteredNodes.length > 0 &&
    filteredNodes[filteredNodes.length - 1]?.type === 'separator'
  ) {
    filteredNodes.pop()
  }

  return filteredNodes
}
