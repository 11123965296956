import { useEffect, useState } from 'react'

/**
 * Listens for changes in media queries.
 * Returns a boolean value that indicates whether the document matches the media query string.
 *
 * @param {string} query - The media query string.
 * @returns {boolean} - Returns true if the document matches the media query string, otherwise false.
 *
 * @example
 * const isMobile = useMediaQuery(`(max-width: ${breakpoints.mobile}px)`);
 *
 * useEffect(() => {
 *   if (isMobile) {
 *     // Do something if mobile
 *   } else {
 *     // Do something if not mobile
 *   }
 * }, [isMobile]);
 */
const useMediaQuery = (query: string): boolean => {
  const mediaQueryList = window.matchMedia(query)
  const [matches, setMatches] = useState(mediaQueryList.matches)

  useEffect(() => {
    const onChange = (event: MediaQueryListEvent) => setMatches(event.matches)

    mediaQueryList.addEventListener('change', onChange)

    return () => {
      mediaQueryList.removeEventListener('change', onChange)
    }
  }, [mediaQueryList])

  return matches
}

export default useMediaQuery
