import { action, makeObservable, observable } from 'mobx'

type StatefulPromiseOptions<T> = Partial<{
  defaultValue: T
  observable: boolean
}>

/**
 * A wrapper around a promise that keeps track of the network state.
 * Can be made observable and used inside controllers.
 *
 * @see useStatefulPromise if you want to use it inside a component
 */
class StatefulPromise<T = unknown, K extends unknown[] = unknown[]> {
  data: T | null
  status: 'idle' | 'loading' | 'success' | 'failed' = 'idle'
  error: Error | null = null

  constructor(
    private promise: (...args: [...K]) => Promise<T>,
    options: StatefulPromiseOptions<T> = { observable: true },
  ) {
    this.data = options?.defaultValue ?? null

    if (options?.observable) {
      makeObservable<this, 'promise'>(this, {
        data: observable.ref,
        status: observable.ref,
        error: observable.ref,
        run: action.bound,
        resetStatus: action.bound,
        promise: observable.ref,
      })
    }
  }

  async run(...args: [...K]) {
    this.status = 'loading'
    this.error = null
    this.data = null

    return this.promise(...args)
      .then(
        action((response) => {
          this.data = response
          this.status = 'success'

          return response
        }),
      )
      .catch(
        action((error: Error) => {
          this.error = error
          this.status = 'failed'

          throw error
        }),
      )
  }

  resetStatus() {
    this.status = 'idle'
  }
}

export default StatefulPromise
