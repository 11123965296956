/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import * as chrono from 'chrono-node'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'

class CustomChrono {
  chrono = chrono.casual.clone()

  constructor() {
    this.chrono.parsers.push(this.shortMinuteParser())
  }

  shortMinuteParser(): chrono.Parser {
    return {
      pattern: () => /\b(\d+)m\b/,
      extract: (context, match) => {
        let instant = dayjs(context.reference.instant).utcOffset(
          context.reference.timezoneOffset ?? 0,
        )
        instant = instant.add(Number(match[1]), 'minute')
        return this.createParsingComponents(context, instant)
      },
    }
  }

  private createParsingComponents(
    context: Parameters<chrono.Parser['extract']>[0],
    date: Dayjs,
  ) {
    const components = context.createParsingComponents()
    components.assign('year', date.year())
    components.assign('month', date.month() + 1)
    components.assign('day', date.date())
    components.assign('hour', date.hour())
    components.assign('minute', date.minute())
    components.assign('second', date.second())
    components.assign('millisecond', date.millisecond())
    return components
  }
}

const customChrono = new CustomChrono().chrono
export default customChrono
