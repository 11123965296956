import { AnalyticsBrowser } from '@dreamdata/analytics-next'
import Cookies from 'js-cookie'

import config from '@src/config'
import { toCamelCase } from '@src/lib/collections'
import type Service from '@src/service'
import Heap from '@src/service/analytics/Heap'
import type {
  MemberModel,
  OrganizationModel,
  OrganizationAnalytics,
  SubscriptionModel,
  UserModel,
  UserAnalytics,
} from '@src/service/model'
import type { StorageThemeKey } from '@src/theme'

import InboxAnalyticsStore from './InboxAnalyticsStore'
import TrustAnalyticsStore from './TrustAnalyticsStore'
import VoiceAnalyticsStore from './VoiceAnalyticsStore'
import WorkflowAnalyticsStore from './WorkflowAnalyticsStore'
import WorkspaceAnalyticsStore from './WorkspaceAnalyticsStore'

interface AnalyticsData {
  orgId: string | undefined
  orgAutoCharge: boolean
  orgPlan: string | undefined
  orgIndustry: string | undefined
  orgSubIndustry: string | undefined
  orgSize: string | undefined
  email: string | undefined
  firstName: string | undefined
  lastName: string | undefined
  useCase?: OrganizationAnalytics['useCase']
  selfIndustry?: OrganizationAnalytics['industry']
  selfSize?: OrganizationAnalytics['teamSize']
  users?: OrganizationAnalytics['users']
  teams?: OrganizationAnalytics['teams']
  need?: OrganizationAnalytics['need']
  currentSize: string | undefined
  role: string | undefined
  subRole: string | undefined
}

export interface IdentifyData {
  user: UserModel
  member: MemberModel
  organization: OrganizationModel
  subscription: SubscriptionModel
  themeKey: StorageThemeKey
  workspaceSize: number
}

class AnalyticsStore {
  public readonly inbox: InboxAnalyticsStore
  public readonly voice: VoiceAnalyticsStore
  public readonly trust: TrustAnalyticsStore
  public readonly workflow: WorkflowAnalyticsStore
  public readonly workspace: WorkspaceAnalyticsStore

  private userId?: string
  private segmentIo: any
  private analytics?: AnalyticsData
  private heap: Heap
  private dreamdata: AnalyticsBrowser

  constructor(
    private root: Service,
    private desktopVersion?: string,
  ) {
    window.dataLayer ??= []
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    this.segmentIo = (window as any).analytics
    this.heap = new Heap(root, desktopVersion)

    this.dreamdata = new AnalyticsBrowser()
    this.dreamdata.load(
      {
        writeKey: config.DREAMDATA_WRITE_KEY,
      },
      {
        intentSources: {
          clearbit: {
            token: config.CLEARBIT_TOKEN,
          },
        },
      },
    )

    this.inbox = new InboxAnalyticsStore(this.heap)
    this.voice = new VoiceAnalyticsStore(this.heap)
    this.trust = new TrustAnalyticsStore(this.heap)
    this.workflow = new WorkflowAnalyticsStore(this.heap)
    this.workspace = new WorkspaceAnalyticsStore(this.heap, this.dreamdata)
  }

  private updateAnalyticsCookie({
    analyticsData,
    savedAnalytics,
    userId,
  }: {
    analyticsData: AnalyticsData
    savedAnalytics: { [key: string]: any }
    userId: string
  }) {
    // Update the cookie if it is missing user data
    const missingAnalytics: { [key: string]: any } = {}
    for (const key in analyticsData) {
      if (!savedAnalytics[key]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        missingAnalytics[key] = analyticsData[key]
      }
    }

    if (!savedAnalytics.userId) {
      missingAnalytics.userId = userId
    }

    if (!savedAnalytics.referrer) {
      missingAnalytics.referrer = document.referrer
    }

    const isCookieMissingAnalytics = Object.keys(missingAnalytics).length > 0
    if (isCookieMissingAnalytics) {
      Cookies.set(
        'op_analytics',
        JSON.stringify({ ...savedAnalytics, ...missingAnalytics }),
      )
    }
  }

  private updateUserAnalytics({
    savedAnalytics,
    user,
    gaId,
  }: {
    savedAnalytics: { [key: string]: any }
    user: UserModel
    gaId?: string
  }) {
    // Look for differences between our cookie data and our backend data
    const updatedAnalytics: { [key: string]: any } = {}
    for (const key in savedAnalytics) {
      if (savedAnalytics[key] && user.analytics?.[key] !== savedAnalytics[key]) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        updatedAnalytics[key] = savedAnalytics[key]
      }
    }

    if (user.analytics && !user.analytics.ga) {
      updatedAnalytics.ga = gaId
    }

    // Update the backend
    const isUserMissingAnalytics = Object.keys(updatedAnalytics).length > 0
    if (isUserMissingAnalytics) {
      user.update({
        analytics: { ...user.analytics, ...updatedAnalytics } as UserAnalytics,
      })
    }
  }

  /**
   * Tracks when a user has been identified
   */
  async identify({
    user,
    member,
    organization,
    subscription,
    workspaceSize,
    themeKey,
  }: IdentifyData) {
    const analyticsData: AnalyticsData = {
      orgId: organization.id,
      orgPlan: subscription.type ?? undefined,
      orgAutoCharge: subscription.autochargeAmount > 0,
      orgIndustry: organization.analytics?.enrichment?.category?.industry,
      orgSubIndustry: organization.analytics?.enrichment?.category?.subIndustry,
      orgSize: organization.analytics?.enrichment?.metrics?.employees?.toString(),
      email: user.email ?? undefined,
      firstName: member.firstName,
      lastName: member.lastName,
      useCase: organization.analytics?.useCase,
      selfIndustry: organization.analytics?.industry,
      selfSize: organization.analytics?.teamSize,
      users: organization.analytics?.users,
      teams: organization.analytics?.teams,
      need: organization.analytics?.need,
      currentSize: workspaceSize.toString(),
      role: user.analytics?.enrichment?.employment?.role,
      subRole: user.analytics?.enrichment?.employment?.subRole,
    }

    this.userId = user.id
    this.analytics = analyticsData

    // Obtain data that needs to be tracked

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.identify(user.id, {
      ...analyticsData,
    })

    window.dataLayer.push({
      userId: user.id,
      orgId: analyticsData.orgId,
    })

    this.dreamdata.identify(user.id, {
      name: `${analyticsData.firstName} ${analyticsData.lastName}`,
      email: analyticsData.email,
      orgId: analyticsData.orgId,
    })

    // Identify the user with Heap
    this.heap.identify({
      user,
      member,
      organization,
      subscription,
      themeKey,
      workspaceSize,
    })

    // Get analytics cookies
    const gaId = Cookies.get('_ga')
    const analyticsCookie = Cookies.get('op_analytics')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    const savedAnalytics = analyticsCookie ? toCamelCase(JSON.parse(analyticsCookie)) : {}

    // Sync analytics cookie and user analytics
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    this.updateAnalyticsCookie({ analyticsData, savedAnalytics, userId: user.id })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    this.updateUserAnalytics({ savedAnalytics, user, gaId })
  }

  page(path: string) {
    this.googlePageView(path)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.page()
  }

  opened() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.track('App Opened')
  }

  referred() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.track('Contact Referred')
  }

  reset() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    this.segmentIo?.reset()
  }

  private googlePageView(path: string) {
    window.dataLayer.push({
      event: 'pageview',
      page: path,
      title: window.document.title,
      environment: config.ENV,
      ...this.analytics,
    })
  }

  /**
   * Tracks click events
   *
   * @param analyticsId - Describes the goal of the clickable element
   * @param redirect - Full URL (if external) or path (if internal) to which the clickable element redirects the user
   */
  click(analyticsId: string, redirect?: URL | string) {
    const url: URL | undefined =
      typeof redirect === 'string' ? new URL(redirect, window.location.origin) : redirect
    window.dataLayer.push({
      event: 'click',
      analyticsId,
      url: url?.toString(),
    })
  }

  tearDown() {
    this.heap.tearDown()
  }
}

export default AnalyticsStore
