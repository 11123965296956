/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { HTMLAttributes } from 'react'

const CheckboxActivePart = ({ id, ...props }: HTMLAttributes<HTMLOrSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="1 1 18 18" {...props}>
    <title>Checked indeterminate</title>
    <defs>
      <filter id={`active-part${id}`} colorInterpolationFilters="auto">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0H20V20H0z"></path>
      <path
        fill="currentColor"
        d="M5 2h10a3 3 0 013 3v10a3 3 0 01-3 3H5a3 3 0 01-3-3V5a3 3 0 013-3z"
      ></path>
      <g filter={`url(#active-part${id})`} transform="translate(2 2)">
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M11.5 7.125a.875.875 0 01.11 1.743l-.11.007h-7a.875.875 0 01-.11-1.743l.11-.007h7z"
        ></path>
      </g>
    </g>
  </svg>
)

export default CheckboxActivePart
