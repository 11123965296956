import Typography from '@ui/Typography'

import * as styles from './ReCaptchaLegalInfo.css'

interface ReCaptchaLegalInfoProps {
  className?: string
}

const ReCaptchaLegalInfo = ({ className }: ReCaptchaLegalInfoProps) => {
  return (
    <div className={className}>
      <Typography
        variant="caption1"
        color="textTertiary"
        fontWeight="regular"
        className={styles.terms}
      >
        This site is protected by reCAPTCHA and the Google{' '}
        <a
          className={styles.link}
          href="https://policies.google.com/privacy"
          target="_blank"
        >
          Privacy Policy
        </a>{' '}
        and{' '}
        <a
          className={styles.link}
          href="https://policies.google.com/terms"
          target="_blank"
        >
          Terms of Service
        </a>{' '}
        apply.
      </Typography>
    </div>
  )
}

export default ReCaptchaLegalInfo
