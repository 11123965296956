/**
 * Get the UTC offset formatted as `UTC[+-]hh:mm` given minutes
 * as the parameter to get the offset from.
 *
 * Example: given -60 it outputs UTC-01:00
 *
 * @param offsetInMinutes Can be negative or positive indicating
 * if it's behind UTC or ahead.
 */
function formatUTCOffset(offsetInMinutes: number) {
  if (offsetInMinutes === 0) {
    return 'UTC'
  }

  const sign = offsetInMinutes < 0 ? '-' : '+'

  const hours = Math.abs(Math.trunc(offsetInMinutes / 60))
    .toString()
    .padStart(2, '0')

  const minutes = Math.abs(Math.trunc(offsetInMinutes % 60))
    .toString()
    .padStart(2, '0')

  return `UTC${sign}${hours}:${minutes}`
}

export default formatUTCOffset
