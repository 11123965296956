import { createCallbackState } from '@src/app/Callback'
import config from '@src/config'

export const googleLogin = async (): Promise<{ accessToken: string }> => {
  return new Promise((resolve, reject) => {
    const client = google.accounts.oauth2.initTokenClient({
      client_id: config.GOOGLE_CLIENT_ID,
      scope: 'profile https://www.googleapis.com/auth/userinfo.email',
      callback: (response) => {
        if (response.error) {
          return reject(response.error)
        }

        return resolve({ accessToken: response.access_token })
      },
      error_callback: (error) => {
        return reject(error)
      },
    })

    client.requestAccessToken()
  })
}

export const redirectGoogleLogin = () => {
  // Google's OAuth 2.0 endpoint for requesting an access token
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement('form')
  form.setAttribute('method', 'GET') // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint)

  // Parameters to pass to OAuth 2.0 endpoint.
  const params = {
    client_id: config.GOOGLE_CLIENT_ID,
    redirect_uri: `${window.location.origin}/native/login`,
    response_type: 'token',
    scope: 'email profile',
    prompt: 'select_account',
    include_granted_scopes: 'false',
  }

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    input.setAttribute('value', params[p])
    form.appendChild(input)
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form)
  form.submit()
}

export const redirectGoogleContactLogin = (isElectron = false) => {
  // Google's OAuth 2.0 endpoint for requesting an access token
  const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement('form')
  form.setAttribute('method', 'GET') // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint)

  if (isElectron) {
    form.setAttribute('target', '_blank')
  }

  // Parameters to pass to OAuth 2.0 endpoint.
  const params = {
    client_id: config.GOOGLE_CLIENT_ID,
    redirect_uri: `${window.location.origin}/callback/google-contacts`,
    response_type: 'code',
    scope:
      'profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/contacts.readonly',
    prompt: 'select_account consent',
    include_granted_scopes: 'false',
    access_type: 'offline',
    state: createCallbackState({
      desktop: isElectron,
      redirectTo: `/contacts`,
    }),
  }

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', p)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    input.setAttribute('value', params[p])
    form.appendChild(input)
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form)
  form.submit()
}
