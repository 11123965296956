import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import { Children, cloneElement, isValidElement } from 'react'

import Avatar, {
  type AvatarProps,
  type AvatarSize,
  fontSizesByAvatarSize,
} from '@ui/Avatar'

import * as styles from './AvatarGroup.css'

export interface AvatarGroupProps {
  size?: AvatarSize
  className?: string
  children: React.ReactNode
}

const AvatarGroup = ({ children, className, size = 40 }: AvatarGroupProps) => {
  const childrenLength = Children.count(children)
  const childrenArray = Children.toArray(children)

  const count = Math.min(childrenLength, 4)
  const avatarsCount = childrenLength > 4 ? 3 : childrenLength
  const extra = childrenLength - avatarsCount
  const avatars = childrenArray.slice(0, avatarsCount)

  return (
    <div
      className={cx(styles.root, className, {
        [styles.two]: count === 2,
        [styles.three]: count === 3,
        [styles.four]: count === 4,
      })}
      style={assignInlineVars({
        [styles.sizeVar]: `${size}px`,
      })}
    >
      {avatars.length === 0 ? (
        <Avatar size={size} className={styles.avatar} />
      ) : (
        avatars.map((element) => {
          if (!isValidElement<AvatarProps>(element)) {
            throw new Error('AvatarGroup children must be valid Avatar elements')
          }

          return cloneElement(element, {
            className: cx(styles.avatar, element.props.className),
            size,
          })
        })
      )}
      {extra > 0 && (
        <div
          className={styles.more}
          style={assignInlineVars({
            [styles.fontSizeVar]: `${fontSizesByAvatarSize[size]}px`,
          })}
        >
          {extra}
        </div>
      )}
    </div>
  )
}

export default AvatarGroup
