import { makeAutoObservable, toJS } from 'mobx'

import objectId from '@src/lib/objectId'

import type { Model } from './base'

export interface CsvImportV2MappingValue {
  type: string
  name: string
  value: string
  isLocalItem: boolean
}

interface CsvImportV2Mapping {
  [key: string]: CsvImportV2MappingValue
}

export type CsvImportStatus =
  | 'new'
  | 'uploaded'
  | 'processing'
  | 'completed'
  | 'errored'
  | 'incomplete'

export interface StatusMetadata {
  updated?: number
  total?: number
  remaining?: number
  errored?: number
  batch?: boolean
}

interface CsvImportV2Error {
  error: string
}

export interface CodableCsvImportV2 {
  createdAt: string | null
  deletedAt: string | null
  errors: CsvImportV2Error[] | null
  id: string
  initiatedAt: string | null
  locked: boolean
  mapping: CsvImportV2Mapping | null
  meta: {
    status?: StatusMetadata
  } | null
  name: string | null
  originalName: string | null
  retries: number
  s3Location: string | null
  status: CsvImportStatus
  type: 'csv'
  updatedAt: string | null
  uploadingUserId: string | null
  uploadingUserOrgId: string | null
}

class CsvImportV2Model implements Model, CodableCsvImportV2 {
  createdAt: string | null = null
  deletedAt: string | null = null
  errors: CsvImportV2Error[] | null = null
  id: string = objectId()
  initiatedAt: string | null = null
  locked = false
  mapping: CsvImportV2Mapping | null = null
  meta: {
    status?: StatusMetadata
  } | null = null
  name: string | null = null
  originalName: string | null = null
  retries = 0
  s3Location: string | null = null
  status: CsvImportStatus = 'new'
  type = 'csv' as const
  updatedAt: string | null = null
  uploadingUserId: string | null = null
  uploadingUserOrgId: string | null = null

  constructor(attrs: CodableCsvImportV2) {
    this.deserialize(attrs)

    makeAutoObservable(this, {})
  }
  deserialize(json: CodableCsvImportV2) {
    Object.assign(this, json)
    return this
  }
  serialize(): CodableCsvImportV2 {
    return toJS(this)
  }
}

export default CsvImportV2Model
