/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { TooltipProps as MuiTooltipProps } from '@material-ui/core/Tooltip'
import MuiTooltip from '@material-ui/core/Tooltip'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { useMemo } from 'react'

import { mergeClasses } from '@src/lib/util'
import type { ThemeKey } from '@src/theme'
import { darkTheme, lightTheme } from '@src/theme'
import Typography from '@ui/Typography'

interface TooltipProps extends Omit<MuiTooltipProps, 'classes'> {
  shortcut?: string
  disabled?: boolean
  nowrap?: boolean
  /**
   * Determines which theme the tooltip should use. If none is provided it will use the default theme for tooltips (dark when the app is in light mode and light when the app is in dark mode)
   */
  mode?: ThemeKey
  popperClassName?: string
}

const Tooltip = function ({
  shortcut,
  disabled,
  nowrap,
  title,
  mode,
  className,
  popperClassName,
  ...props
}: TooltipProps) {
  const tooltipStyles = useTooltipStyles({ nowrap, mode })
  const isDisabled = disabled || !title
  const classes = useMemo(
    () => mergeClasses(tooltipStyles, { tooltip: className, popper: popperClassName }),
    [tooltipStyles, className, popperClassName],
  )
  return (
    <MuiTooltip
      {...props}
      disableFocusListener={isDisabled}
      disableHoverListener={isDisabled}
      disableTouchListener={isDisabled}
      classes={classes}
      enterDelay={props.enterDelay ?? 150}
      title={
        typeof title === 'string' ? (
          <TooltipContent title={title} shortcut={shortcut} nowrap={nowrap} mode={mode} />
        ) : (
          title
        )
      }
    />
  )
}

interface TooltipContentProps {
  title: string
  shortcut?: string
  nowrap?: boolean
  mode?: ThemeKey
}

const TooltipContent = function ({ title, shortcut, nowrap, mode }: TooltipContentProps) {
  const styles = useStyles({ nowrap, mode })
  const keys = (shortcut?.split('+') || []).map((key) => {
    if (key.toLowerCase() === 'shift') {
      return '⇧'
    } else if (key.toLowerCase() === 'meta') {
      return '⌘'
    }
    return key
  })

  return (
    <div className={styles.content}>
      <Typography
        variant="caption1"
        color="inherit"
        fontWeight="regular"
        style={{ margin: '8px 0', fontSize: 'inherit', fontWeight: 'inherit' }}
      >
        {title}
      </Typography>
      {shortcut && (
        <div className={styles.shortcut}>
          {keys.map((key) => (
            <Typography
              key={key}
              variant="caption3"
              fontWeight="semibold"
              className={styles.key}
            >
              {key}
            </Typography>
          ))}
        </div>
      )}
    </div>
  )
}

export default Tooltip

const useStyles = makeStyles<Theme, Partial<TooltipContentProps>>((theme) => ({
  content: ({ nowrap, mode }) => ({
    fontSize: '11px',
    // INTERNAL: when refactoring this away from MaterialUI, use fontWeights.default.medium
    fontWeight: 500,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: nowrap ? 'none' : 312,
    minHeight: 30,
    padding: '0 10px',
    color:
      mode === 'light'
        ? lightTheme.palette.op.gray[1]
        : mode === 'dark'
        ? darkTheme.palette.op.gray[1]
        : theme.palette.op.match({
            light: darkTheme.palette.op.gray[1],
            dark: lightTheme.palette.op.gray[1],
          }),
  }),
  shortcut: {
    marginLeft: 12,
    display: 'flex',
    alignItems: 'center',
  },
  key: ({ mode }) => ({
    alignItems: 'center',
    background:
      mode === 'light'
        ? lightTheme.palette.op.hover.darker
        : mode === 'dark'
        ? darkTheme.palette.op.hover.darker
        : theme.palette.op.match({
            light: darkTheme.palette.op.hover.darker,
            dark: lightTheme.palette.op.hover.darker,
          }),
    color: 'inherit',
    opacity: 0.8,
    borderRadius: 3,
    display: 'flex',
    // INTERNAL: when refactoring this away from MaterialUI, use fontWeights.default.semibold
    fontWeight: 600,
    justifyContent: 'center',
    marginLeft: 4,
    minWidth: 16,
    height: 16,
    padding: '0 4px',
  }),
}))

const useTooltipStyles = makeStyles<Theme, Partial<TooltipProps>>((theme) => ({
  tooltip: ({ nowrap, mode }) => ({
    backgroundColor:
      mode === 'light'
        ? lightTheme.palette.op.gray[6]
        : mode === 'dark'
        ? darkTheme.palette.op.gray[6]
        : theme.palette.op.match({
            light: darkTheme.palette.op.gray[6],
            dark: lightTheme.palette.op.gray[6],
          }),
    boxShadow: theme.palette.op.shadow.tooltip,
    borderRadius: theme.palette.op.borderRadius.tooltip,
    margin: 8,
    maxWidth: nowrap ? 'none' : 312,
    padding: 0,
  }),
  popper: {
    // Same z-index as the new @ui/Popover component.
    zIndex: 100000,
  },
}))
