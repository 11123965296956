import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import type { ReactNode, ElementType, AriaAttributes } from 'react'

import * as styles from './Stack.css'

export interface StackProps {
  /**
   * The space between the stack items.
   *
   * @default 0
   */
  gap?: number

  /**
   * Defines how elements should be aligned along the main-axis
   *
   * @default "flex-start"
   */
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around'

  /**
   * Defines how elements should be aligned along the cross-axis
   *
   * @default "center"
   */
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'

  /**
   * The direction the stack will take.
   * `row` will be horizontal and `column` will be vertical.
   *
   * @default 'row'
   */
  direction?: 'row' | 'column'

  /**
   * The flex display type of the stack either a block or inline box.
   *
   * @default 'flex'
   */
  display?: 'flex' | 'inline-flex'

  /**
   * The underlying element of the stack.
   *
   * @default 'div'
   */
  component?: ElementType

  /**
   * The extra classes to pass into the Stack.
   */
  className?: string

  /**
   * The items of the stack
   */
  children: ReactNode
}

const Stack = ({
  direction = 'row',
  component = 'div',
  display = 'flex',
  justifyContent = 'flex-start',
  alignItems = 'center',
  gap = 0,
  className,
  children,
  ...props
}: StackProps & AriaAttributes) => {
  const Component = component

  return (
    <Component
      className={cx(styles.root({ display, direction }), className)}
      style={assignInlineVars({
        [styles.gapVar]: `${gap}px`,
        [styles.alignItemsVar]: alignItems,
        [styles.justifyContentVar]: justifyContent,
      })}
      {...props}
    >
      {children}
    </Component>
  )
}

export default Stack

export const HStack = (props: Omit<StackProps, 'direction'>) => (
  <Stack {...props} direction="row" />
)

export const VStack = (props: Omit<StackProps, 'direction'>) => (
  <Stack {...props} direction="column" />
)
