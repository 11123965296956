import type { DragMoveEvent } from '@dnd-kit/core'
import type { Debugger } from 'debug'
import Debug from 'debug'
import clamp from 'lodash/fp/clamp'
import { action, computed, makeObservable, observable, reaction } from 'mobx'

import type IUiStore from '@src/app/IUiStore'
import { DisposeBag } from '@src/lib/dispose'

export interface Size {
  width: number
  height: number
}

interface Option {
  name?: string
  hideScroll?: boolean
  onResize?: (size: Size) => void
  onPassiveScroll?: (point: { left: number; top: number }) => void
}

export class ScrollViewStore implements IUiStore {
  /** Height of the scrollable viewport */
  private height = 0

  /** Height of the scroll content */
  private contentHeight = 0

  /** Holds the scroll offset of the element as reported by the element itself */
  private scrollOffset = 0

  /** Signals to the view to change its scroll offset */
  scrollTo = { top: 0 }

  /** The offset of the knob */
  knobOffset = 0

  /** The offset of the knob when dragging begins */
  knobOffsetOriginal: number | null = null

  /** Is the mouse currently inside the scrollable area */
  isMouseIn = false

  private debug: Debugger
  private disposeBag: DisposeBag

  /** Is the knob being dragged by mouse */
  get isDragging() {
    return this.knobOffsetOriginal !== null
  }

  /** Calculate the height of the knob */
  get knobHeight() {
    if (this.height >= this.contentHeight) {
      return 0
    }
    return Math.max(60, (this.height / this.contentHeight) * this.height - 6)
  }

  get knobHidden() {
    return !!this.opt.hideScroll || (!this.isDragging && !this.isMouseIn)
  }

  get name() {
    return this.opt.name ?? 'scrollview'
  }

  constructor(private opt: Option) {
    makeObservable<this, 'scrollOffset' | 'height' | 'contentHeight'>(this, {
      height: observable.ref,
      contentHeight: observable.ref,
      scrollTo: observable.ref,
      scrollOffset: observable.ref,
      knobOffset: observable.ref,
      knobOffsetOriginal: observable.ref,
      isMouseIn: observable.ref,
      isDragging: computed,
      knobHeight: computed,
      knobHidden: computed,
      setSize: action.bound,
      setContentSize: action.bound,
      setScrollOffset: action.bound,
      onKnobDrag: action.bound,
      onKnobDragStart: action.bound,
      onKnobDragEnd: action.bound,
      onMouseEnter: action.bound,
      onMouseLeave: action.bound,
      onScroll: action.bound,
    })

    this.debug = Debug(`op:scrollview:store:@${opt.name}`)
    this.debug('instantiated')

    this.disposeBag = new DisposeBag(
      reaction(
        () => ({
          height: this.height,
          contentHeight: this.contentHeight,
          scrollOffset: this.scrollOffset,
        }),
        ({ height, contentHeight, scrollOffset }) => {
          if (this.isDragging) {
            return
          }
          const availableContentScroll = contentHeight - height
          const availableKnobScroll = height - this.knobHeight - 6
          this.knobOffset = (scrollOffset / availableContentScroll) * availableKnobScroll
        },
      ),
    )
  }

  setSize(size: Size) {
    this.height = size.height
    this.opt.onResize?.(size)
  }

  setContentSize(size: Size) {
    this.contentHeight = size.height
  }

  setScrollOffset(top: number) {
    this.scrollTo = { top }
    this.debug(`set offset to ${top}`)
  }

  onKnobDragStart() {
    this.knobOffsetOriginal = this.knobOffset
  }

  onKnobDragEnd() {
    this.knobOffsetOriginal = null
  }

  onKnobDrag(event: DragMoveEvent) {
    if (this.knobOffsetOriginal === null) {
      return
    }
    const clampTop = clamp(0, this.height - this.knobHeight - 6)
    const top = clampTop(this.knobOffsetOriginal + event.delta.y)

    if (this.knobOffset !== top) {
      this.debug(`onKnobDrag ${top}px`)
      this.knobOffset = top
      this.scrollTo = { top: (top / this.height) * this.contentHeight }
    }
  }

  onScroll(left: number, top: number) {
    this.debug(`onScroll ${top}px`)
    this.scrollOffset = top
    this.opt?.onPassiveScroll?.({ left, top })
  }

  onMouseEnter() {
    this.isMouseIn = true
  }

  onMouseLeave() {
    this.isMouseIn = false
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
