import { useContext } from 'react'

import type AppStore from '@src/app/AppStore'

import AppContext from './AppContext'

export default function useAppStore(): AppStore {
  const context = useContext(AppContext)

  if (context === null) {
    throw new Error('`useAppStore` hook should be used under an `AppContext` provider')
  }

  return context
}
