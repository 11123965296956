import { makeAutoObservable, toJS } from 'mobx'

import objectId from '@src/lib/objectId'

import type { Model } from './base'

export interface CodableWorkspace extends Model {
  id: string
  name: string
  slug: string | null
  pictureUrl: string | null
  createdAt: string | null
  updatedAt: string | null
  memberCount: number
}

export default class WorkspaceModel implements CodableWorkspace {
  id: string = objectId()
  name = ''
  slug: string | null = null
  pictureUrl: string | null = null
  createdAt: string | null = null
  updatedAt: string | null = null
  memberCount = 0

  constructor(attrs: CodableWorkspace) {
    this.deserialize(attrs)
    makeAutoObservable(this)
  }

  deserialize(json: Partial<CodableWorkspace>) {
    Object.assign(this, json)

    return this
  }

  serialize(): CodableWorkspace {
    return toJS(this)
  }
}
