import Header from '@src/app/onboarding/Header'

import * as styles from './VerificationLayout.css'

export default function VerificationLayout({ children }) {
  return (
    <div className={styles.root}>
      <Header />
      <div className={styles.main}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}
