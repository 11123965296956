import { action, makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'
import type ContactStore from '@src/service/contact-store'
import type { GooglePeopleSyncProgressState } from '@src/service/transport/websocket'

import type BaseCodableContactSettings from './BaseCodableContactSettings'
import type { Model } from './base'

export interface CodableGoogleContactSettings extends BaseCodableContactSettings {
  hasRefreshToken: boolean | null
  source: string | null
  sourceName: string | null
  type: string | null
  resyncStatus: GooglePeopleSyncProgressState | null
}

class GoogleContactSettingsModel implements CodableGoogleContactSettings, Model {
  createdAt: number | null = null
  id: string = objectId()
  meta: { hasRefreshToken: boolean } | null = null
  hasRefreshToken: boolean | null = null
  source: string | null = null
  sourceName: string | null = null
  type: string | null = null
  updatedAt: number | null = null
  userId: string | null = null
  resyncStatus: GooglePeopleSyncProgressState | null = null

  constructor(private contactStore: ContactStore) {
    makeAutoObservable(this, {})
  }

  resync() {
    this.resyncStatus = 'started'

    return this.contactStore.resyncGoogleContactSettings(this.serialize()).catch(
      action((error) => {
        this.resyncStatus = null
        throw error
      }),
    )
  }

  deserialize = ({ updatedAt, createdAt, ...json }: CodableGoogleContactSettings) => {
    Object.assign(this, json)

    this.updatedAt = updatedAt ? parseDate(updatedAt) : null

    this.createdAt = createdAt ? parseDate(createdAt) : null

    return this
  }

  serialize = (): CodableGoogleContactSettings => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      meta: toJS(this.meta),
      hasRefreshToken: this.hasRefreshToken,
      source: this.source,
      sourceName: this.sourceName,
      type: this.type,
      updatedAt: this.updatedAt,
      userId: this.userId,
      resyncStatus: this.resyncStatus,
    }
  }
}

export default GoogleContactSettingsModel
