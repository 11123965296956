import config from '@src/config'
import type { RawWorkflowDefinition } from '@src/service/model/workflow/WorkflowDefinitionModel'
import type { RawWorkflowStepDefinition } from '@src/service/model/workflow/WorkflowStepDefinitionModel'
import type { RawWorkflowTriggerDefinition } from '@src/service/model/workflow/WorkflowTriggerDefinitionModel'

import type Transport from '.'
import type Paginated from './lib/Paginated'
import { HttpTransaction } from './transaction'

export interface Validation {
  valid: boolean
  errors: {
    path: string
    message: string
  }[]
}

export default class WorkflowClient {
  constructor(private transport: Transport) {}

  definitions = {
    list: ({
      orgId,
      userId,
      maxResults,
      pageToken,
      entityIds,
    }: {
      orgId: string
      userId: string
      maxResults: number
      pageToken?: string
      entityIds?: string[]
    }): Promise<Paginated<RawWorkflowDefinition>> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'GET',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-definitions`,
          query: {
            orgId,
            userId,
            maxResults,
            pageToken,
            entityIds: entityIds?.join(','),
          },
        }),
      )
    },

    post: (definition: RawWorkflowDefinition): Promise<RawWorkflowDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'POST',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-definitions`,
          body: definition,
        }),
      )
    },

    get: (id: string, version?: number): Promise<RawWorkflowDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'GET',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-definitions/${id}`,
          query: { version },
        }),
      )
    },

    put: (
      id: string,
      definition: RawWorkflowDefinition,
    ): Promise<RawWorkflowDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'PUT',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-definitions/${id}`,
          body: definition,
        }),
      )
    },

    delete: (id: string): Promise<null> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'DELETE',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-definitions/${id}`,
        }),
      )
    },

    validate: (id: string, definition: RawWorkflowDefinition): Promise<Validation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'POST',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-definitions/${id}/validate`,
          body: definition,
        }),
      )
    },
  }

  stepDefinitions = {
    list: (
      maxResults: number,
      pageToken?: string,
    ): Promise<Paginated<RawWorkflowStepDefinition>> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'GET',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-step-definitions`,
          query: {
            maxResults,
            pageToken,
          },
        }),
      )
    },

    get: (id: string, version?: number): Promise<RawWorkflowStepDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'GET',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-step-definitions/${id}`,
          query: { version },
        }),
      )
    },

    validate: (definition: RawWorkflowStepDefinition): Promise<Validation> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'POST',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-step-definitions/validate`,
          body: definition,
        }),
      )
    },
  }

  triggerDefinitions = {
    list: (
      maxResults: number,
      pageToken?: string,
    ): Promise<Paginated<RawWorkflowTriggerDefinition>> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'GET',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-trigger-definitions`,
          query: {
            maxResults,
            pageToken,
          },
        }),
      )
    },

    get: (id: string, version?: number): Promise<RawWorkflowTriggerDefinition> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'GET',
          url: `${config.WORKFLOW_SERVICE_URL}workflow-trigger-definitions/${id}`,
          query: { version },
        }),
      )
    },
  }
}
