import type { Selection } from '@react-types/shared'
import type { Key, ReactNode } from 'react'
import { useRef, useCallback, useState } from 'react'

import useInstance from '@src/lib/hooks/useInstance'
import type { MenuListProps } from '@ui/Menu/MenuList'
import SubMenuMediator from '@ui/Menu/MenuList/SubMenuMediator'
import { useMaybeSubMenu } from '@ui/Menu/SubMenuProvider'
import { useSensor } from '@ui/SensorProvider'

import { MenuListContext } from '.'
import useMenuTreeState from './useMenuTreeState'

type MenuListProviderProps<T> = MenuListProps<T> & {
  outerChildren: MenuListProps<T>['children']
  children: ReactNode
}

export default function MenuListProvider<T extends object>({
  children,
  outerChildren,
  items,
  defaultSelectedKeys,
  selectedKeys,
  selectionMode,
  onAction: onOuterAction,
  onSelectionChange: outerOnSelectionChange,
  disallowEmptySelection = true,
  ...props
}: MenuListProviderProps<T>) {
  const [disabledKeys, setDisabledKeys] = useState(new Set<Key>())
  const [isVisible, setIsVisible] = useState(false)
  const subMenu = useMaybeSubMenu()
  const subMenuMediator = useInstance(() => new SubMenuMediator())

  const onSelectionChange = useCallback(
    (selection: Selection) => {
      outerOnSelectionChange?.(selection)
      if (subMenu && selectionMode !== 'none') {
        subMenu.selectionManager.setSelectedKeys(selection)
      }
    },
    [outerOnSelectionChange, subMenu, selectionMode],
  )

  const treeState = useMenuTreeState({
    items,
    children: outerChildren,
    disabledKeys,
    defaultSelectedKeys,
    selectedKeys,
    selectionMode,
    onSelectionChange,
  })

  const setDisabledKey = useCallback((key: Key) => {
    setDisabledKeys((prev) => new Set([...prev, key]))
  }, [])

  const onAction = useCallback(
    (key: Key) => {
      onOuterAction?.(key)

      // Close parent menu if the menu is not selectable.
      // The reason why we allow selectable menus to not
      // close the parent is to improve the user experience
      // around selection. This way a user can perform
      // multiple selection operations without reoping the
      // menu.
      if (subMenu && selectionMode === undefined) {
        subMenu.parentTrigger.state.close()
      }
    },
    [onOuterAction, subMenu, selectionMode],
  )

  const menuListRef = useRef<HTMLUListElement>(null)

  // for provider under a sub menu we use the ref from the sub menu provider so that
  // focus management works
  const exposedMenuListRef = subMenu ? subMenu.menuListRef : menuListRef

  const sensor = useSensor()

  return (
    <MenuListContext.Provider
      value={{
        menuListRef: exposedMenuListRef,
        state: treeState,
        props: {
          ...props,
          defaultSelectedKeys,
          selectedKeys,
          selectionMode,
          onSelectionChange,
          disallowEmptySelection,
          onAction,
        },
        sensor,
        setDisabledKey,
        isVisible,
        setIsVisible,
        subMenuMediator,
      }}
    >
      {children}
    </MenuListContext.Provider>
  )
}
