import type { MutableRefObject } from 'react'
import { createRef, useState } from 'react'

import useSize from '@src/lib/hooks/useSize'

const EMPTY_REF = createRef<HTMLElement>()

export default function useTriggerWidth({
  ref,
  enabled,
}: {
  ref: MutableRefObject<HTMLElement | null>
  enabled: boolean
}) {
  const [resizedWidth, setResizedWidth] = useState(0)

  useSize({
    // give a null ref so the hook doesn't run unnecessarily
    ref: enabled ? ref : EMPTY_REF,
    onResize: (size) => setResizedWidth(size.width),
  })

  return enabled ? resizedWidth : null
}
