import { useContext } from 'react'

import SubMenuContext from './SubMenuContext'

export default function useSubMenu() {
  const context = useContext(SubMenuContext)

  if (context === null) {
    throw new Error('`useSubMenu` has to be used under SubMenuProvider')
  }

  return context
}
