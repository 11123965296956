import { useEffect } from 'react'

import { LoadingIndicator } from '@ui/LoadingIndicator'

import * as styles from './StripeCallback.css'

const StripeCallback = () => {
  useEffect(() => {
    window.top?.postMessage('3DS-authentication-complete')
  }, [])

  return (
    <div className={styles.root}>
      <LoadingIndicator variant="circular" size={40} color="primary" />
    </div>
  )
}

export default StripeCallback
