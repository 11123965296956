import { action, reaction, runInAction, toJS } from 'mobx'

import isNonNull from '@src/lib/isNonNull'

import AsyncStorage from './AsyncStorage'
import type { StorageEngine } from './StorageService'

export type AnnotationsMap<T, AdditionalFields extends PropertyKey> = {
  [P in Exclude<keyof T, 'toString'>]?: StorageEngine
} & Record<AdditionalFields, StorageEngine>

export default function makePersistable<T, AdditionalKeys extends PropertyKey = never>(
  target: T,
  name: string,
  properties: AnnotationsMap<T, AdditionalKeys>,
) {
  Object.keys(properties).forEach((property) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const storage: StorageEngine = properties[property]
    const key = [name, property].filter(isNonNull).join('.')

    if (storage instanceof AsyncStorage) {
      storage.get(key).then(
        action((value) => {
          // Dexie returns undefined if the key doesn't exist
          // in the AsyncStorage database
          if (value !== undefined) {
            target[property] = value
          }
        }),
      )
      reaction(
        () => {
          if (!target[property]) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
            return target[property]
          }
          if (typeof target[property] === 'object' && 'serialize' in target[property]) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
            return target[property].serialize()
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
          return toJS(target[property])
        },
        (value) => {
          storage.set(value, key)
        },
        { name: `MakePersistable.${key}` },
      )
    } else {
      runInAction(() => {
        const value = storage.get(key)
        if (isNonNull(value)) {
          target[property] = value
        }
      })
      reaction(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
        () => toJS(target[property]),
        (value) => {
          storage.set(key, value)
        },
        { name: `MakePersistable.${key}` },
      )
    }
  })
}
