import type { Key } from 'react'

export default class SubMenuMediator {
  openOnTriggerHoverTimeout: NodeJS.Timeout | null = null
  closeOnTriggerLeaveTimeout: NodeJS.Timeout | null = null
  closeOnSubMenuLeaveTimeout: NodeJS.Timeout | null = null

  onOpenListeners: Set<(openedMenu: Key) => void> = new Set()

  emitOpen(key: Key) {
    for (const onOpenListener of this.onOpenListeners) {
      onOpenListener(key)
    }
  }

  onOpen(callback: (openedMenu: Key) => void) {
    this.onOpenListeners.add(callback)

    return () => this.onOpenListeners.delete(callback)
  }

  onTriggerHover(callback: () => void) {
    if (this.closeOnSubMenuLeaveTimeout) {
      clearTimeout(this.closeOnSubMenuLeaveTimeout)
    }

    this.openOnTriggerHoverTimeout = setTimeout(callback, 250)
  }

  onTriggerLeave(callback: () => void) {
    if (this.openOnTriggerHoverTimeout) {
      clearTimeout(this.openOnTriggerHoverTimeout)
    }

    if (this.closeOnTriggerLeaveTimeout) {
      clearTimeout(this.closeOnTriggerLeaveTimeout)
    }

    this.closeOnTriggerLeaveTimeout = setTimeout(callback, 250)
  }

  onTriggerClick(callback: () => void) {
    if (this.openOnTriggerHoverTimeout) {
      clearTimeout(this.openOnTriggerHoverTimeout)
    }

    if (this.closeOnTriggerLeaveTimeout) {
      clearTimeout(this.closeOnTriggerLeaveTimeout)
    }

    callback()
  }

  onSubMenuHover() {
    if (this.closeOnTriggerLeaveTimeout) {
      clearTimeout(this.closeOnTriggerLeaveTimeout)
    }

    if (this.closeOnSubMenuLeaveTimeout) {
      clearTimeout(this.closeOnSubMenuLeaveTimeout)
    }
  }

  onSubMenuLeave(callback: () => void) {
    this.closeOnSubMenuLeaveTimeout = setTimeout(callback, 1000)
  }
}
