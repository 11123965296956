import { useContext } from 'react'

import MenuTriggerContext from './MenuTriggerContext'

export default function useMenuTrigger() {
  const context = useContext(MenuTriggerContext)

  if (!context) {
    throw new Error('useMenuTrigger has to be used within a <Menu /> component')
  }

  return context
}
