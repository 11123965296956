import { makeAutoObservable } from 'mobx'

import type { Model } from '@src/service/model/base'

class WebhookIntegrationModel implements Model {
  id = ''
  type: 'webhook' = 'webhook' as const
  orgId: string | null = null
  userId: string | null = null
  url: string | null = null
  method: 'post' | 'get' | 'put' | null = null

  constructor() {
    makeAutoObservable(this, {})
  }

  deserialize = (json) => {
    Object.assign(this, json)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      type: this.type,
      orgId: this.orgId,
      userId: this.userId,
      url: this.url,
      method: this.method,
    }
  }
}

export default WebhookIntegrationModel
