import type { ListState } from '@react-stately/list'
import type { Key, KeyboardEvent } from 'react'
import { useCallback } from 'react'

import {
  getFirstValidItemKey,
  getLastValidItemKey,
} from '@ui/Combobox/ComboboxList/getNextValidItemKey'
import { useComboboxTrigger } from '@ui/Combobox/ComboboxProvider'

export type FocusTargetProps = {
  onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void
}

interface FocusTarget {
  focusTargetProps: FocusTargetProps
}

export default function useFocusTarget<T extends object>(
  state: ListState<T>,
): FocusTarget {
  const {
    state: { close },
  } = useComboboxTrigger()

  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      event.preventDefault()
      const key = event.key

      const focusOnKey = (key: Key | null) => {
        if (key) {
          state.selectionManager.setFocused(true)
          state.selectionManager.setFocusedKey(key)
        }
      }

      switch (key) {
        case 'ArrowDown': {
          const firstKey = state.collection.getFirstKey()
          const keyToFocus = getFirstValidItemKey(state, firstKey)
          focusOnKey(keyToFocus)
          break
        }
        case 'ArrowUp': {
          const lastKey = state.collection.getLastKey()
          const keyToFocus = getLastValidItemKey(state, lastKey)
          focusOnKey(keyToFocus)
          break
        }
        case 'Escape': {
          close()
          break
        }
      }
    },
    [state, close],
  )
  return {
    focusTargetProps: {
      onKeyDown,
    },
  }
}
