import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

import type { MessageMediaModel } from '@src/service/model'

import * as styles from './DocViewer.css'
import * as sharedStyles from './shared.css'

interface DocViewerProps {
  media: MessageMediaModel
}

const DocViewer = function ({ media }: DocViewerProps) {
  const rootRef = useRef<HTMLDivElement>(null)

  if (media.file) {
    return null
  }

  return (
    <div ref={rootRef} className={sharedStyles.root}>
      <iframe
        frameBorder={0}
        className={styles.iframe}
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${media.url}`}
      />
    </div>
  )
}

export default observer(DocViewer)
