import { action, makeObservable, observable } from 'mobx'

import { logError } from '@src/lib/log'

export type AugmentedPermissionName = PermissionName | 'microphone'

const isPermissionName = (name: string): name is PermissionName =>
  ['geolocation', 'notifications', 'push'].includes(name)

type PermissionStatesMap = { [P in AugmentedPermissionName]?: PermissionState }

export default class Permissions {
  readonly states: PermissionStatesMap = {}

  constructor(permissions: readonly AugmentedPermissionName[] = []) {
    makeObservable(this, {
      states: observable.shallow,
    })

    for (const permission of permissions) {
      this.subscribeToPermissionChange(permission as PermissionName).catch(
        (err: unknown) => {
          // Only log errors for permissions that are supported by all
          // browsers. (For example, 'microphone' is not supported by Firefox.)
          // @see https://developer.mozilla.org/en-US/docs/Web/API/Permissions/query#name
          if (!isPermissionName(permission)) {
            logError(err)
          }
        },
      )
    }
  }

  protected async subscribeToPermissionChange(name: PermissionName) {
    // Not supported in SSR or jsdom
    if (typeof navigator !== 'undefined' && navigator.permissions) {
      return navigator.permissions.query({ name }).then((permissionStatus) => {
        this.setPermissionStatus(name, permissionStatus)
        permissionStatus.onchange = action(() => {
          this.setPermissionStatus(name, permissionStatus)
        })
      })
    }
  }

  private setPermissionStatus(name: PermissionName, status: PermissionStatus): void {
    this.states[name] = status.state
  }

  /**
   * Returns the permission state of the given permission name from the cache
   * if it exists, if not it will query the Permission API about it, caches it,
   * and returns it.
   */
  async query(name: AugmentedPermissionName): Promise<PermissionState | undefined> {
    const currentState = this.states[name]

    if (currentState !== undefined) {
      return currentState
    }

    // Not supported in SSR or jsdom
    if (typeof navigator !== 'undefined' && navigator.permissions) {
      const { state } = await navigator.permissions.query({
        name: name as PermissionName,
      })
      this.states[name] = state
      return state
    }

    return undefined
  }
}
