import { errorTitle } from '@openphone/internal-api-client'
import { makeAutoObservable } from 'mobx'

import type IUiStore from '@src/app/IUiStore'
import type { SoundUiStore } from '@src/app/sound'

export interface PromptAction {
  title: string
  type?: 'primary' | 'secondary' | 'secondary-outlined' | 'destructive'
  onClick?: () => void
}

export interface Prompt {
  title?: string
  body?: React.ReactNode
  actions?: PromptAction[]
}

export default class PromptUiStore implements IUiStore {
  prompt: Prompt | null = null

  constructor(private sound: SoundUiStore) {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  get isShown() {
    return this.prompt !== null
  }

  show(prompt: Prompt) {
    this.prompt = prompt
  }

  showError(error: Error) {
    this.sound.play('error')
    this.prompt = {
      title: errorTitle(error) ?? undefined,
      body: error.message,
    }
  }

  hide() {
    this.prompt = null
  }

  tearDown(): void {
    this.hide()
  }
}
