import type { Selection } from '@react-types/shared'
import { useRef, useState } from 'react'
import type { Location } from 'react-router-dom'

import IconButton from '@src/component/IconButton'
import useKeyboardShortcuts from '@src/lib/hooks/useKeyboardShortcuts'
import Combobox from '@ui/Combobox'
import Typography from '@ui/Typography'
import { ClockIcon } from '@ui/icons/tint/16/general'

import * as styles from './RecentHistoryBase.css'

export interface HistoryItem {
  id: string
  icon: React.ReactNode
  label: string
  secondaryLabel?: string
  location: Location
}

interface RecentHistoryProps {
  historyItems: HistoryItem[]
  onSelection: (location: Location) => void
}

const RecentHistoryBase = ({ historyItems, onSelection }: RecentHistoryProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const triggerRef = useRef<HTMLButtonElement>(null)

  const isEmpty = historyItems.length === 0

  const openCombobox = () => setIsOpen(true)
  const closeCombobox = () => setIsOpen(false)

  const handleSelectionChange = (selection: Selection) => {
    if (selection === 'all') {
      return
    }

    const key = Array.from(selection)[0]

    const historyItem = historyItems.find((item) => item.id === key)

    if (historyItem) {
      onSelection(historyItem.location)
    }
  }

  useKeyboardShortcuts({
    name: 'RecentHistoryBase',
    node: document,
    filter: (_shortcut, event) => !event.defaultPrevented,
    handler: (shortcut, event) => {
      if (isEmpty || isOpen || shortcut !== 'Meta+KeyY') {
        return
      }

      event.preventDefault()
      openCombobox()
    },
    dep: [isEmpty, isOpen],
  })

  return (
    <>
      <IconButton
        icon={<ClockIcon />}
        size={24}
        title="Recently viewed"
        shortcut="Meta+Y"
        disabled={isEmpty}
        onClick={openCombobox}
        ref={triggerRef}
        className={styles.noDrag}
      />
      <Combobox.Provider targetRef={triggerRef} isOpen={isOpen} onClose={closeCombobox}>
        <Combobox.List
          aria-label="Recently viewed"
          width={376}
          placement="bottom left"
          selectedKeys={[]}
          selectionMode="single"
          onSelectionChange={handleSelectionChange}
          closeOnSelect
        >
          <Combobox.Section title="Recently viewed" items={historyItems}>
            {(item) => (
              <Combobox.ListItem
                key={item.id}
                textValue={`${item.label} ${item.secondaryLabel}`.trim()}
                icon={item.icon}
                hideSelectionIndicator
              >
                {item.label}
                {item.secondaryLabel ? (
                  <>
                    &nbsp;&nbsp;
                    <Typography component="span" color="textTertiary">
                      {item.secondaryLabel}
                    </Typography>
                  </>
                ) : null}
              </Combobox.ListItem>
            )}
          </Combobox.Section>
        </Combobox.List>
      </Combobox.Provider>
    </>
  )
}

export default RecentHistoryBase
