import { action, computed, makeObservable, observable, reaction } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'
import UserIdleDetector, { type UserState } from '@src/lib/idle/UserIdleDetector'
import type Service from '@src/service'

export default class PresenceService {
  protected readonly idleDetector = new UserIdleDetector()
  protected readonly disposeBag = new DisposeBag()

  _visibilityState: DocumentVisibilityState = 'visible'

  constructor(private readonly root: Service) {
    makeObservable<this, 'handleVisibilityChange'>(this, {
      _visibilityState: observable.ref,
      activityState: computed,
      visibilityState: computed,
      handleVisibilityChange: action.bound,
    })

    this.disposeBag.add(
      reaction(
        () => this.root.flags.flags.userIdleTimeout,
        (threshold) => {
          this.idleDetector.stop()

          if (typeof threshold === 'number' && threshold > 0) {
            this.idleDetector.start({ threshold })
          }
        },
      ),
    )

    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    this.disposeBag.add(() => {
      document.removeEventListener('visibilitychange', this.handleVisibilityChange)
    })
  }

  get activityState(): UserState {
    return this.idleDetector.userState
  }

  get visibilityState(): DocumentVisibilityState {
    return this._visibilityState
  }

  protected handleVisibilityChange() {
    this._visibilityState = document.visibilityState
  }

  tearDown(): void {
    this.disposeBag.dispose()
    this.idleDetector.stop()
  }
}
