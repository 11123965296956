/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'
import { mergeRefs } from '@react-aria/utils'
import cx from 'classnames'
import { forwardRef, useLayoutEffect, useRef } from 'react'

import type { AppAvatarProps } from '@src/app/components/app-avatar'
import { AppAvatar } from '@src/app/components/app-avatar'
import { formatted } from '@src/lib/phone-number'
import type { Identity } from '@src/service/model'
import { typography } from '@src/theme'
import Typography from '@ui/Typography'

import { Link } from './link'

export interface MenuProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'target'> {
  size?: 'small' | 'medium' | 'large'
}

export type MenuItemHeight = 'small' | 'medium' | 'large' | undefined

export interface MenuItemProps
  extends Omit<
    React.HTMLProps<HTMLAnchorElement | HTMLButtonElement>,
    'size' | 'target' | 'ref'
  > {
  icon?: React.ReactNode
  selected?: boolean
  highlighted?: boolean
  height?: MenuItemHeight
  applyHoverStyles?: boolean
  disabled?: boolean
  href?: string
  type?: 'button' | 'reset' | 'submit'
  target?: string
}

export interface MenuContentProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size' | 'target' | 'ref'> {
  height?: MenuItemHeight
}

export interface IdentityItemProps extends Omit<MenuItemProps, 'icon'> {
  identity?: Identity | null
  showPhoneNumbers?: boolean
}

export const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ className, size = 'small', ...props }, ref) => {
    const styles = useStyles({ size })
    return (
      <div ref={ref} {...props} className={cx(className, styles[size], styles.root)} />
    )
  },
)

export const MenuContent = forwardRef<HTMLDivElement, MenuContentProps>(
  ({ children, className, height, style, ...props }, ref) => {
    const styles = useStyles({})

    if (height) {
      children = <div className={styles.body}>{children}</div>
    }

    return (
      <div
        ref={ref}
        {...props}
        style={{ minHeight: getHeight(height), ...style }}
        className={cx(className, styles.content)}
      >
        {children}
      </div>
    )
  },
)

export const MenuItem = forwardRef<HTMLAnchorElement | HTMLButtonElement, MenuItemProps>(
  (
    {
      children,
      className,
      icon,
      highlighted,
      selected,
      height,
      applyHoverStyles = true,
      style,
      disabled,
      href,
      type,
      ...props
    },
    outerRef,
  ) => {
    const styles = useStyles({ applyHoverStyles, disabled })
    const internalRef = useRef<HTMLAnchorElement | HTMLButtonElement>(null)
    const ref = mergeRefs<HTMLAnchorElement | HTMLButtonElement>(outerRef, internalRef)

    useLayoutEffect(() => {
      if (highlighted) {
        internalRef.current?.scrollIntoView({ block: 'nearest', inline: 'nearest' })
      }
    }, [highlighted])

    if (typeof children === 'string') {
      children = (
        <Typography
          nowrap
          variant="inherit"
          color={selected || disabled ? 'inherit' : 'textPrimary'}
        >
          {children}
        </Typography>
      )
    } else {
      children = <div className={styles.body}>{children}</div>
    }

    const DynamicElement = href ? Link : 'button'

    // FIXME - See tech debt ticket to refactor all of menu-v2 components: ENG-5474
    return (
      <DynamicElement
        {...props}
        disabled={disabled}
        href={href as string}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        ref={ref as any}
        type={!href ? type ?? 'button' : undefined}
        style={{ minHeight: getHeight(height), ...style }}
        className={cx(className, styles.content, styles.item, {
          [styles.selected]: selected,
          [styles.highlighted]: applyHoverStyles && highlighted,
          [styles.disabled]: disabled,
        })}
        tabIndex={disabled ? -1 : undefined}
        aria-disabled={disabled ? 'true' : 'false'}
        data-menuitem
      >
        {icon && <div className={styles.icon}>{icon}</div>}
        {children}
      </DynamicElement>
    )
  },
)

export const SeparatorItem = ({ ...props }: React.HTMLProps<HTMLDivElement>) => {
  const styles = useStyles({})
  return <div {...props} className={styles.separator} />
}

export const HeaderItem = function ({
  children,
  height = 'small',
  ...props
}: MenuContentProps) {
  const styles = useStyles({})
  return (
    <MenuContent {...props} height={height}>
      <Typography variant="caption2" color="textTertiary" className={styles.header}>
        {children}
      </Typography>
    </MenuContent>
  )
}

export const IdentityItem = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  IdentityItemProps
>(({ selected, identity, showPhoneNumbers = true, height, ...props }, outerRef) => {
  const avatarHeight = getAvatarHeight(height)
  const styles = useStyles({})

  return (
    <MenuItem
      ref={outerRef}
      {...props}
      selected={selected}
      icon={<AppAvatar identity={identity} size={avatarHeight} />}
    >
      <div className={styles.info}>
        <Typography
          nowrap
          variant="footnote"
          fontWeight="medium"
          color={selected ? 'inherit' : 'textPrimary'}
          style={{ flex: 1 }}
        >
          {identity?.name}
        </Typography>
        {showPhoneNumbers && identity?.phones && identity.phones.length > 0 ? (
          <Typography
            nowrap
            variant="caption2"
            color={selected ? 'inherit' : 'textSecondary'}
          >
            {identity.phones.length > 1
              ? `${identity.phones.length} numbers`
              : formatted(identity.phones[0]?.number ?? '')}
          </Typography>
        ) : null}
      </div>
    </MenuItem>
  )
})

export default Menu

const useStyles = makeStyles<Theme, MenuProps & { applyHoverStyles?: boolean }>(
  // @ts-expect-error -- This should get fixed when the component gets migrated to Vanilla Extract
  (theme: Theme) => ({
    root: {
      minWidth: 146,
      padding: '4px 0',

      '& > $content:last-child': {
        marginBottom: 0,
      },
    },
    medium: {
      padding: '8px 0',

      '& $content': {
        marginLeft: 8,
        marginRight: 8,
        width: 'calc(100% - 16px)',
        ...typography.callout,
      },

      '& $item': {
        minHeight: 40,
        padding: '0 12px',
      },

      '& $header': {
        padding: '0 14px',
      },

      '& $separator': {
        marginTop: 8,
        marginBottom: 8,
      },
    },
    content: {
      ...typography.footnote,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.op.text.primary,
      margin: '0 4px 2px',
    },
    item: ({ applyHoverStyles, disabled }) => ({
      cursor: disabled ? 'inherit' : 'pointer',
      borderRadius: 5,
      padding: '0 10px',
      textDecoration: 'none',
      border: 'none',
      backgroundColor: 'transparent',
      width: 'calc(100% - 8px)',
      textAlign: 'left',
      '&:hover': {
        background:
          applyHoverStyles && !disabled ? theme.palette.op.hover.primary : undefined,
      },
    }),
    selected: {
      color: theme.palette.op.primary[2],
    },
    highlighted: () => ({
      background: theme.palette.op.hover.primary,
    }),
    icon: {
      color: theme.palette.op.text.secondary,
      marginRight: 12,
      lineHeight: 0,
    },
    disabled: {
      color: theme.palette.op.gray[4],

      '& $icon': {
        color: theme.palette.op.gray[4],
      },
    },
    body: {
      flex: 1,
    },
    separator: {
      borderTop: `1.5px solid ${theme.palette.op.border.common}`,
      margin: '4px 0',
    },
    header: {
      padding: '0 10px',
    },
    footer: {
      padding: '0 10px',
    },
    info: { display: 'flex', alignItems: 'baseline', columnGap: 4 },
  }),
)

const getHeight = (height: MenuItemHeight): number => {
  switch (height) {
    case 'large':
      return 56
    case 'medium':
      return 40
    case 'small':
    default:
      return 30
  }
}

const getAvatarHeight = (height: MenuItemHeight): AppAvatarProps['size'] => {
  switch (height) {
    case 'large':
      return 34
    case 'medium':
      return 24
    case 'small':
    default:
      return 20
  }
}
