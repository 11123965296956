import type { ReactNode } from 'react'
import { forwardRef } from 'react'

import type { SelectSearchOptionValue } from '@src/component'

export interface TriggerProps<
  T extends SelectSearchOptionValue = SelectSearchOptionValue,
> {
  onClick: () => void
  children: ReactNode
  isOpen: boolean
  selectedValue: T | T[]
  disabled: boolean
}

const SelectSearchTrigger = forwardRef<HTMLElement, TriggerProps>(
  function SelectSearchTrigger({ children: _, ...props }, ref) {
    return (
      <div
        ref={ref as React.ForwardedRef<HTMLDivElement>}
        aria-hidden="true"
        {...props}
      />
    )
  },
)

export default SelectSearchTrigger
