import { makeAutoObservable, observable, when } from 'mobx'

import type AppStore from '@src/app/AppStore'
import type { BlockNumberCommandProps } from '@src/app/command/BlockNumberCommand'
import type { ConfirmDeleteAllContactsBySourceModalProps } from '@src/app/command/ConfirmDeleteAllContactsBySourceCommand'
import type { RenameGroupConversationCommandProps } from '@src/app/command/RenameGroupConversationCommand'
import type { SelectPhoneNumberCommandProps } from '@src/app/command/SelectPhoneNumberCommand'
import type { IndividualContactDeletionCommandProps } from '@src/app/command/contact-deletion'
import type { IndividualContactSharingCommandProps } from '@src/app/command/contact-sharing/IndividualContactSharingCommand'
import type {
  SendTestRequestCommandProps,
  TestRequestDetailsCommandProps,
} from '@src/app/command/send-test-request'

import type { AddCallerIdCommandProps } from './AddCallerIdCommand'
import type { AddConsentMethodCommandProps } from './AddConsentMethodCommand/AddConsentMethodCommand'
import type { BusinessNameConfirmationCommandProps } from './BusinessNameConfirmationCommand'
import type { BusinessPlanFeaturesCommandProps } from './BusinessPlanFeaturesCommand'
import type { CallDestinationCommandProps } from './CallDestinationCommand'
import type { DialerCommandProps } from './DialerCommand'
import type { EditCallerIdCommandProps } from './EditCallerIdCommand'
import type { EditGroupCommandProps } from './EditGroupCommand'
import type { EditSnippetCommandProps } from './EditSnippetCommand'
import type { EmailCodeCommandProps } from './EmailCodeCommand'
import type { ForwardAllCallsCommandProps } from './ForwardAllCallsCommand'
import type { ForwardCallsCommandProps } from './ForwardCallsCommand'
import type { MemberStatusCommandProps } from './MemberStatusCommand'
import type { MutePhoneNumberCommandProps } from './MutePhoneNumberCommand'
import type { PortExistingNumberCommandProps } from './PortExistingNumberCommand'
import type { RejectWithMessageCommandProps } from './RejectWithMessageCommand'
import type { SetGreetingCommandProps } from './SetGreetingCommand'
import type { SetPasswordCommandProps } from './SetPasswordCommand'
import type { SharePhoneNumberCommandProps } from './SharePhoneNumberCommand'
import type { SharedWithCommandProps } from './SharedWithCommand'
import type { TollFreeCustomerProfileCommandProps } from './TollFreeCustomerProfileCommand'
import type { UpdatePlanCommandProps } from './UpdatePlanCommand'
import type { UpgradePlanCommandProps } from './UpgradePlanCommand'
import type { UploadCommandProps } from './UploadCommand'
import type { VerifyDomainCommandProps } from './VerifyDomainCommand'
import type {
  PortExistingNumberFormCommandProps,
  PortRequestReceivedCommandProps,
} from './add-phone-number'
import type { BillingAutoChargeCommandProps } from './billing/AutoChargeCommand'
import type {
  EditScheduledMessageCommandProps,
  RescheduleMessageCommandProps,
  ScheduleMessageCommandProps,
} from './scheduled-message'

export type Command = (
  | { name: 'app' }
  | { name: 'add call tag' }
  | ({ name: 'business name confirmation' } & BusinessNameConfirmationCommandProps)
  | ({ name: 'billing auto charge' } & BillingAutoChargeCommandProps)
  | { name: 'billing cancel subscription' }
  | { name: 'billing credits' }
  | { name: 'business details help' }
  | ({ name: 'business plan features' } & BusinessPlanFeaturesCommandProps)
  | { name: 'business registration help' }
  | ({ name: 'change email code' } & EmailCodeCommandProps)
  | { name: 'change email' }
  | { name: 'change env' }
  | ({ name: 'dialer' } & DialerCommandProps)
  | { name: 'do not disturb' }
  | ({ name: 'edit shared with' } & SharedWithCommandProps)
  | ({ name: 'edit snippet' } & EditSnippetCommandProps)
  | ({ name: 'forward calls' } & ForwardCallsCommandProps)
  | ({ name: 'forward all calls' } & ForwardAllCallsCommandProps)
  | { name: 'invite member' }
  | { name: 'add domain' }
  | ({ name: 'verify domain' } & VerifyDomainCommandProps)
  | ({ name: 'member status' } & MemberStatusCommandProps)
  | ({ name: 'mute phone number' } & MutePhoneNumberCommandProps)
  | ({ name: 'reject call with message' } & RejectWithMessageCommandProps)
  | { name: 'search' }
  | ({ name: 'select call destination' } & CallDestinationCommandProps)
  | ({ name: 'set greeting' } & SetGreetingCommandProps)
  | ({ name: 'share phone number' } & SharePhoneNumberCommandProps)
  | ({ name: 'upload' } & UploadCommandProps)
  | { name: 'referral' }
  | ({ name: 'edit group' } & EditGroupCommandProps)
  | { name: 'create webhook' }
  | { name: 'create api key' }
  | ({ name: 'send test request' } & SendTestRequestCommandProps)
  | ({ name: 'test request details' } & TestRequestDetailsCommandProps)
  | ({ name: 'schedule message' } & ScheduleMessageCommandProps)
  | ({ name: 'reschedule message' } & RescheduleMessageCommandProps)
  | ({ name: 'edit scheduled message' } & EditScheduledMessageCommandProps)
  | ({ name: 'add caller id' } & AddCallerIdCommandProps)
  | ({ name: 'edit caller id' } & EditCallerIdCommandProps)
  | { name: 'default contact sharing' }
  | { name: 'change password' }
  | ({ name: 'set password' } & SetPasswordCommandProps)
  | ({ name: 'individual contact sharing' } & IndividualContactSharingCommandProps)
  | { name: 'bulk contact sharing' }
  | ({ name: 'port existing number' } & PortExistingNumberCommandProps)
  | ({ name: 'port existing number form' } & PortExistingNumberFormCommandProps)
  | ({ name: 'port request received' } & PortRequestReceivedCommandProps)
  | { name: 'porting drafts' }
  | ({ name: 'select phone numbers' } & SelectPhoneNumberCommandProps)
  | { name: 'export csv' }
  | ({ name: 'individual contact deletion' } & IndividualContactDeletionCommandProps)
  | { name: 'bulk contact deletion' }
  | { name: 'delete all contacts' }
  | ({ name: 'rename group conversation' } & RenameGroupConversationCommandProps)
  | { name: 'import csv contacts' }
  | ({ name: 'upgrade plan' } & UpgradePlanCommandProps)
  | ({ name: 'update plan modal' } & UpdatePlanCommandProps)
  | ({
      name: 'confirm-delete-all-contacts-by-source'
    } & ConfirmDeleteAllContactsBySourceModalProps)
  | ({ name: 'block number' } & BlockNumberCommandProps)
  | ({
      name: 'toll free registration customer profile'
    } & TollFreeCustomerProfileCommandProps)
  | { name: 'unpaid invoices' }
  | ({ name: 'add consent method' } & AddConsentMethodCommandProps)
) & { onClose?: () => void }

interface CommandOptions {
  centered?: boolean
}

export default class CommandUiStore {
  command: (Command & CommandOptions) | null = null

  constructor(private root: AppStore) {
    makeAutoObservable(this, {
      command: observable.ref,
    })
  }

  get open(): boolean {
    return Boolean(this.command)
  }

  present = (command: Command, options?: CommandOptions) => {
    this.command = { ...command, ...options }
    const onClose = this.command.onClose
    when(
      () => this.command?.name !== command.name,
      () => onClose?.(),
    )
  }

  hide = () => {
    this.command = null
  }
}
