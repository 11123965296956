import { forwardRef } from 'react'

import { useAppStore } from '@src/app/context'
import { isMacDesktop } from '@src/lib/device'

interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {
  href: string
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ href, target, children, onClick, disabled, ...rest }, ref) => {
    const { url, history } = useAppStore()

    const props: LinkProps = (() => {
      if (href.startsWith('http') || href.startsWith('mailto')) {
        if (target !== '_blank') {
          return {
            ...rest,
            href,
            onClick: (event) => {
              event.preventDefault()
              if (disabled) {
                return
              }

              url.open(href)
              onClick?.(event)
            },
          }
        }

        return {
          ...rest,
          target,
          href,
          onClick: (event) => {
            if (disabled) {
              event.preventDefault()
              return
            }

            onClick?.(event)
          },
        }
      }

      return {
        ...rest,
        target,
        href,
        onClick: (event) => {
          if (disabled) {
            event.preventDefault()
            return
          }

          if ((isMacDesktop() ? event.metaKey : event.ctrlKey) || event.shiftKey) {
            onClick?.(event)
            return
          }

          event.preventDefault()
          history.router.navigate(href)
          onClick?.(event)
        },
      }
    })()

    return (
      <a ref={ref} {...props}>
        {children}
      </a>
    )
  },
)

export default Link
