import type { Theme } from '@material-ui/core'

import useMetaTag from './useMetaTag'

/**
 * Maintains a `<meta name="theme-color">` tag in the document `<head>` based
 * on the app's current theme.
 */
export default function useMetaThemeColor(theme: Theme) {
  useMetaTag('theme-color', theme.palette.op.gray[6], [theme])
}
