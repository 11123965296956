import { action, computed, makeObservable, observable } from 'mobx'

import { parseDate } from '@src/lib'
import type { ActivityModel } from '@src/service/model'
import { Comment } from '@src/service/model/comment'

import type { BaseCodableReaction, BaseEncodableReaction } from './BaseReaction'
import BaseReaction from './BaseReaction'

export interface ActivityCodableReaction extends BaseCodableReaction {
  commentId: string | null
  activityId: string | null
}

export interface ActivityEncodableReaction extends BaseEncodableReaction {
  activityId: string | null
  commentId?: string
}

export interface ActivityDecodableReaction extends ActivityCodableReaction {
  conversationId?: string
  activityId: string | null
}

export function isActivityReaction(reaction: any): reaction is ActivityReaction {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
  return reaction.id && reaction.conversationId && reaction.activityId
}

class ActivityReaction extends BaseReaction implements ActivityCodableReaction {
  commentId: string | null = null
  activityId: string | null = null

  constructor(
    readonly object: ActivityModel | Comment,
    attrs: Partial<ActivityDecodableReaction> = {},
  ) {
    super()

    this.deserialize(attrs)

    makeObservable(this, {
      commentId: observable.ref,
      activityId: observable.ref,
      activity: computed,
      comment: computed,
      deserialize: action.bound,
      serialize: action.bound,
      toJSON: action.bound,
    })
  }

  get activity(): ActivityModel {
    return this.object instanceof Comment ? this.object.activity : this.object
  }

  get comment(): Comment | null {
    return this.object instanceof Comment ? this.object : null
  }

  deserialize(
    json?: Partial<ActivityCodableReaction> | Partial<ActivityDecodableReaction>,
  ) {
    if (json) {
      const { createdAt, updatedAt, ...attrs } = json
      Object.assign(this, attrs)
      this.createdAt = parseDate(createdAt || this.createdAt) as number
      this.updatedAt = parseDate(updatedAt || this.updatedAt) as number
    }
    return this
  }

  serialize(): ActivityCodableReaction {
    return {
      id: this.id,
      body: this.body,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      userId: this.userId,
      commentId: this.commentId,
      activityId: this.activityId,
    }
  }

  toJSON(): ActivityEncodableReaction {
    return {
      id: this.id,
      activityId: this.activity.id,
      commentId: this.comment?.id,
      body: this.body,
    }
  }
}

export default ActivityReaction
