import type { IReactionPublic, IReactionOptions } from 'mobx'
import { reaction } from 'mobx'
import { useEffect } from 'react'

export default function useReaction<T>(
  expression: (r: IReactionPublic) => T,
  effect: (arg: T, prev: T | undefined, r: IReactionPublic) => void,
  options: IReactionOptions<any, boolean> = {},
  deps: any[] = [],
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  useEffect(() => reaction(expression, effect, options), deps)
}
