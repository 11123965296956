import { Tooltip } from '@material-ui/core'
import cx from 'classnames'
import { forwardRef, useCallback, useEffect, useState } from 'react'

import type {
  RenderOptionProps,
  TriggerProps,
  SelectSearchOptionValue,
} from '@src/component'
import { SelectSearch } from '@src/component'
import type { ButtonProps } from '@src/component/ButtonV2'
import Button from '@src/component/ButtonV2'
import useIsOverflowing from '@src/lib/hooks/useIsOverflowing'
import isNonNull from '@src/lib/isNonNull'
import { fuzzySearch } from '@src/lib/search'
import Checkbox from '@ui/Checkbox'
import { useFormField } from '@ui/FormField'
import Typography from '@ui/Typography'
import { ChevronDownIcon } from '@ui/icons/tint/08'
import { CheckBoxTickIcon } from '@ui/icons/tint/16/general'
import { AddIcon } from '@ui/icons/tint/20/general'
import { theme } from '@ui/theme'

import * as styles from './SelectSearchControl.css'

export type SelectOptionType = SelectSearchOptionValue & { suffix?: string }

interface BaseSelectSearchControlProps {
  options: SelectOptionType[]
  triggerPlaceholder?: string
  searchPlaceholder?: string
  isDisabled?: boolean
  limitWidth?: boolean
  buttonClassName?: string
  dropdownClassName?: string
  height?: ButtonProps['height']
  onClose?(): void

  /**
   * Enabling this allows the user to select a custom option with the value of the typed text in the search input.
   */
  addTypedOption?: boolean

  /**
   * Hides the search input
   */
  hideSearch?: boolean

  /**
   * Render a custom footer component for the popover menu.
   */
  footer?: React.FunctionComponent
}

interface SingleSelectSearchControlProps extends BaseSelectSearchControlProps {
  defaultValue?: string
  value?: string
  onChange?: (value: string) => void
  multiple?: false

  /**
   * Adds a suffix after the trigger value. Example:
   *
   * > **5 seconds** per batch
   *
   * `triggerSuffix` would be `per batch`
   */
  triggerSuffix?: string
}

interface MultipleSelectSearchControlProps extends BaseSelectSearchControlProps {
  defaultValue?: string[]
  value?: string[]
  onChange?: (value: string[]) => void
  multiple: true
  triggerSuffix?: undefined
}

type SelectSearchControlProps =
  | SingleSelectSearchControlProps
  | MultipleSelectSearchControlProps

function SelectSearchControl({
  options,
  value,
  defaultValue,
  triggerPlaceholder = 'Select...',
  searchPlaceholder = 'Search...',
  onChange,
  isDisabled,
  limitWidth = true,
  buttonClassName,
  dropdownClassName,
  height,
  onClose,
  multiple = false,
  addTypedOption,
  triggerSuffix,
  hideSearch,
  footer,
}: SelectSearchControlProps) {
  const { isOverflowing, ref: closedValueRef } = useIsOverflowing<HTMLDivElement>()
  const [open, setOpen] = useState(false)

  const getSelectedValue = useCallback(
    (value: string | string[]) => {
      if (multiple) {
        let selectedOptions: SelectSearchOptionValue[] = []
        if (value) {
          const def = Array.isArray(value) ? value : [value]
          selectedOptions = options.filter((option) => def.includes(option.value))

          if (addTypedOption && !selectedOptions) {
            selectedOptions = def.map((v) => ({ value: v, name: v }))
          }
        }

        return selectedOptions
      }
      const def = Array.isArray(value) ? value[0] : value
      let option = options.find((option) => option.value === def)

      if (addTypedOption && def && !option) {
        option = { value: def, name: def }
      }

      return option
    },
    [addTypedOption, multiple, options],
  )

  const [selectedOption, setSelectedOption] = useState<
    SelectOptionType | SelectOptionType[] | undefined
  >(defaultValue ? () => getSelectedValue(defaultValue) : undefined)

  useEffect(() => {
    // FIXME: in this ticket UXP-2273
    if (isNonNull(value)) {
      setSelectedOption(getSelectedValue(value))
    }
  }, [getSelectedValue, value])

  function onSelectChange(option: SelectOptionType | SelectOptionType[]) {
    setSelectedOption(option)
    if (multiple) {
      const values = Array.isArray(option)
        ? option.map(({ value }) => value)
        : [option.value]
      onChange?.(values as string & string[])
    } else {
      const value = Array.isArray(option) ? option[0]?.value : option.value
      onChange?.(value as string & string[])
    }
  }

  const filterOptions = useCallback(
    (options: SelectSearchOptionValue[], { inputValue }: { inputValue: string }) => {
      if (!inputValue) {
        return options
      }

      const typedOption = options.find((option) => option.__isTyped)
      options = options.filter((option) => !option.__isTyped)
      const results = fuzzySearch(options, inputValue, ['name'])

      if (typedOption) {
        // Add the typed option at the end of the results
        results.push(typedOption)
      }

      return results
    },
    [],
  )

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  const { htmlProps } = useFormField()
  const tooltipTitle = Array.isArray(selectedOption)
    ? selectedOption.map(({ name }) => name).join(', ')
    : selectedOption?.name
  let title: React.ReactNode | undefined = undefined
  if (Array.isArray(selectedOption)) {
    if (selectedOption.length > 0) {
      if (selectedOption.length === 1) {
        title = selectedOption[0]?.name
      } else {
        title = (
          <>
            {selectedOption[0]?.name}{' '}
            <Typography color="placeholder" component="span">
              + {selectedOption.length - 1} more
            </Typography>
          </>
        )
      }
    }
  } else {
    title = selectedOption?.name
  }

  return (
    <>
      <Tooltip title={isOverflowing && tooltipTitle ? tooltipTitle : ''}>
        <div>
          <Button
            type="button"
            variant="outlined"
            color="textPrimary"
            align="start"
            onClick={() => setOpen(true)}
            endIcon={<ChevronDownIcon className={styles.buttonIcon} />}
            fullWidth={true}
            className={cx(styles.button, buttonClassName)}
            disabled={isDisabled}
            height={height}
            {...htmlProps}
          >
            <div ref={closedValueRef} className={styles.buttonContent}>
              {title ? title : triggerPlaceholder}
              {multiple === false && title && triggerSuffix ? (
                <Typography color="placeholder" component="span" fontWeight="regular">
                  {' '}
                  {triggerSuffix}
                </Typography>
              ) : null}
            </div>
          </Button>
        </div>
      </Tooltip>
      <SelectSearch
        open={open}
        onClose={handleClose}
        dropdownProps={{
          placeholder: searchPlaceholder,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          className: limitWidth
            ? cx(styles.popover, dropdownClassName)
            : dropdownClassName,
          footer,
        }}
        multiple={multiple}
        options={options}
        onChange={onSelectChange}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- FIXME: Fix this ESLint violation!
        // @ts-ignore make null a valid value
        value={selectedOption ? selectedOption : null}
        renderTrigger={SelectSearchTrigger}
        renderOption={multiple ? SelectSearchOptionMulti : SelectSearchOption}
        filterOptions={filterOptions}
        addTypedOption={addTypedOption}
        getTypedOption={toSelectOptionType}
        hideSearch={hideSearch}
      />
    </>
  )
}

export default SelectSearchControl

function SelectSearchOptionHoC(multiSelect: boolean) {
  const CheckMark: (props: { checked: boolean }) => JSX.Element | null = multiSelect
    ? ({ checked }) => (
        <Checkbox className={styles.checkmark} checked={checked} aria-hidden="true" />
      )
    : ({ checked }) =>
        checked ? <CheckBoxTickIcon aria-hidden="true" className={styles.tick} /> : null

  const Component = ({
    option,
    index,
    rootOptionProps,
  }: RenderOptionProps<SelectOptionType>) => {
    const isSelected = Boolean(rootOptionProps['aria-selected'])
    const { isOverflowing, ref: optionRef } = useIsOverflowing()

    let nameNode = (
      <Typography
        variant="footnote"
        nowrap
        ref={optionRef}
        color={isSelected ? `rgb(${theme.palette.primary.strong})` : undefined}
      >
        {option.name}
      </Typography>
    )
    let icon: React.ReactNode = null

    if (option.__isTyped && !isSelected) {
      icon = <AddIcon />
      nameNode = (
        <Typography component="span" variant="footnote" nowrap ref={optionRef}>
          Set to{' '}
          <Typography component="span" variant="inherit" fontWeight="medium">
            {option.name}
          </Typography>
        </Typography>
      )
    }

    return (
      <div
        {...rootOptionProps}
        key={index}
        className={cx(styles.option, { [styles.selected]: isSelected })}
      >
        <div className={styles.menuItemContent}>
          {icon}
          {isOverflowing ? <Tooltip title={option.name}>{nameNode}</Tooltip> : nameNode}
          <Typography
            variant="footnote"
            color={isSelected ? 'primary' : 'textSecondary'}
            fontWeight="regular"
            nowrap
            className={styles.optionRightLabel}
          >
            {option.suffix}
          </Typography>
          <CheckMark checked={isSelected} />
        </div>
      </div>
    )
  }
  Component.displayName = multiSelect ? 'SelectSearchOptionMulti' : 'SelectSearchOption'

  return Component
}

const SelectSearchOption = SelectSearchOptionHoC(false)
const SelectSearchOptionMulti = SelectSearchOptionHoC(true)

const SelectSearchTrigger = forwardRef<HTMLElement, TriggerProps>(
  function SelectSearchTrigger({ children: _children, ...props }, ref) {
    return (
      <div
        ref={ref as React.ForwardedRef<HTMLDivElement>}
        aria-hidden="true"
        {...props}
      />
    )
  },
)

export function toSelectOptionType(option: string): SelectOptionType {
  return {
    value: option,
    name: option,
  }
}
