import { makeAutoObservable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import objectId from '@src/lib/objectId'

import type BaseCodableContactSettings from './BaseCodableContactSettings'
import type { Model } from './base'

export interface CodableSharedContactSettings extends BaseCodableContactSettings {
  deletedAt: number | null
  deletedBy: string | null
  orgId: string | null
  defaultSharingIds: string[]
  suggestionsEnabled: boolean
}

class SharedContactSettingsModel implements CodableSharedContactSettings, Model {
  createdAt: number | null = null
  id: string = objectId()
  meta: { hasRefreshToken: boolean } | null = null
  updatedAt: number | null = null
  userId: string | null = null
  defaultSharingIds: string[] = []
  deletedAt: number | null = null
  deletedBy: string | null = null
  orgId: string | null = null
  suggestionsEnabled = false

  constructor(attributes: CodableSharedContactSettings) {
    makeAutoObservable(this, {})

    this.deserialize(attributes)
  }

  deserialize = ({
    updatedAt,
    createdAt,
    deletedAt,
    ...json
  }: CodableSharedContactSettings) => {
    Object.assign(this, json)

    this.updatedAt = updatedAt ? parseDate(updatedAt) : null

    this.createdAt = createdAt ? parseDate(createdAt) : null

    this.deletedAt = deletedAt ? parseDate(deletedAt) : null

    return this
  }

  serialize = (): CodableSharedContactSettings => {
    return {
      createdAt: this.createdAt,
      id: this.id,
      meta: toJS(this.meta),
      updatedAt: this.updatedAt,
      userId: this.userId,
      defaultSharingIds: toJS(this.defaultSharingIds),
      deletedAt: this.deletedAt,
      deletedBy: this.deletedBy,
      orgId: this.orgId,
      suggestionsEnabled: this.suggestionsEnabled,
    }
  }
}

export default SharedContactSettingsModel
