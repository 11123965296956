import Debug from 'debug'
import { action, observable, runInAction } from 'mobx'
import { Subject } from 'rxjs'

import { parseDate } from '@src/lib'
import DataResource from '@src/lib/DataResource'
import Deferred from '@src/lib/Deferred'
import assertNever from '@src/lib/assertNever'
import uuid from '@src/lib/uuid/uuid'
import type ActivityStore from '@src/service/ActivityStore/ActivityStore'
import Collection from '@src/service/collections/Collection'
import type ActivityModel from '@src/service/model/activity/ActivityModel'
import type { IActivity } from '@src/service/model/activity/ActivityModel'
import type Transport from '@src/service/transport'
import type { LegacyPageInfo } from '@src/service/transport/lib/LegacyPaginated'

interface RawConversationActivity {
  id: string
  createdAt: number
}

interface HoleData {
  /**
   * The type of the hole.
   *
   * Used to determine how to fetch the hole (before or after the activity).
   */
  type: 'before' | 'after'

  /**
   * The activity ID is the first activity that is missing from the hole.
   *
   * We can use it to fetch the page that contains the hole.
   */
  activityId: string

  /**
   * The sibling activity ID is the activity that is next to the hole.
   *
   * This is what the UI uses to check for holes as the user scrolls up or down.
   */
  siblingActivityId: string | null
}

interface ConversationHistoryPageInfo {
  /**
   * The ID of the first activity in the fetched page.
   *
   * We use this to check for holes at the start of the page.
   *
   * When we fetch extra activities at the start of the page and the backend indicates
   * that there is a previous page, we use this to check for holes.
   *
   * There will be a hole if the activity corresponding to this ID is not in the cache and {@link ConversationHistoryPageInfo.hasPreviousPage} is `true`.
   *
   * The value of `overfetchStartId` always matches the value of {@link LegacyPageInfo.startId} that we get from the backend.
   *
   * @see {@link ConversationHistoryPageInfo.startId}
   * @see {@link ConversationHistoryService.recalculateHoles}
   */
  overfetchStartId: string | null

  /**
   * The ID of the last activity in the fetched page.
   * It corresponds to the extra activity we fetch at the end of the page.
   *
   * We use this to check for holes at the end of the page.
   *
   * When we fetch extra activities at the end of the page and the backend indicates
   * that there is a next page, we use this to check for holes.
   *
   * There will be a hole if the activity corresponding to this ID is not in the cache and {@link ConversationHistoryPageInfo.hasNextPage} is `true`.
   *
   * The value of `overfetchEndId` always matches the value of {@link LegacyPageInfo.endId} that we get from the backend.
   *
   * @see {@link ConversationHistoryPageInfo.endId}
   * @see {@link ConversationHistoryService.recalculateHoles}
   */
  overfetchEndId: string | null

  /**
   * The ID of the first activity in the fetched page.
   * `startId` is the actual start of the page when you don't take into account the extra activities used to check for holes.
   *
   * We use this to check for holes at the start of the page.
   *
   * If there is a hole, we'll store the `startId` in the hole object as the `siblingActivityId`
   * so the UI can check for holes as the user scrolls.
   *
   * @see {@link ConversationHistoryPageInfo.overfetchStartId}
   * @see {@link ConversationHistoryService.recalculateHoles}
   */
  startId: string | null

  /**
   * The ID of the last activity in the fetched page.
   * `endId` is the actual end of the page when you don't take into account the extra activities used to check for holes.
   *
   * We use this to check for holes at the end of the page.
   *
   * If there is a hole, we'll store the `endId` in the hole object as the `siblingActivityId`
   * so the UI can check for holes as the user scrolls.
   *
   * @see {@link ConversationHistoryPageInfo.overfetchEndId}
   * @see {@link ConversationHistoryService.recalculateHoles}
   */
  endId: string | null

  /**
   * Whether there is a previous page.
   */
  hasPreviousPage: boolean

  /**
   * Whether there is a next page.
   */
  hasNextPage: boolean
}

interface ConversationHistoryCache {
  key: string
  conversationId: string
  allActivities: Collection<RawConversationActivity>
  activities: Collection<RawConversationActivity>
  holes: HoleData[]
  pageInfos: ConversationHistoryPageInfo[]
  initialFetch: {
    type: 'latest' | `around-${string}` | null
    resource: DataResource<unknown>
    status: 'loading' | 'finished' | 'failed'
  }
}

type PaginationParams = Parameters<Transport['communication']['activities']['list']>[0]

// Always include 1 extra item before and after the requested range to check for holes
const EXTRA_ITEMS = 1
const UNRESOLVED_RESOURCE = new DataResource(() => new Promise(() => null))
const RESOLVED_RESOURCE = new DataResource(() => Promise.resolve(null))

interface ConversationHistoryServiceConfig {
  itemsPerPage: number
}

export type ConversationHistoryCacheResult = ReturnType<
  InstanceType<typeof ConversationHistoryService>['getInMemoryActivitiesByConversationId']
>

export default class ConversationHistoryService {
  private readonly debug = Debug('app:service:conversation:history')
  private readonly cache = new Map<string, ConversationHistoryCache>()

  readonly cache$ = new Subject<{
    type: 'invalidate'
    conversationId: string
  }>()

  constructor(
    private readonly activityStore: ActivityStore,
    private readonly transport: Transport,
    private readonly config: ConversationHistoryServiceConfig,
  ) {
    this.subscribeToChanges()
  }

  getInMemoryActivitiesByConversationId(conversationId: string) {
    const cache = this.getOrCreateCache(conversationId)
    const activities = cache.activities

    return {
      collection: activities as Pick<typeof activities, 'get' | 'list'>,
      initialFetch: cache.initialFetch,
      key: cache.key,
    }
  }

  async fetchLatest(conversationId: string) {
    const fetchAndLoad = async () => {
      const { result, pageInfo } = await this.transport.communication.activities.list(
        this.createPaginationParams('last', conversationId),
      )

      const activities = await this.addToCache(cache, result, pageInfo)

      return activities
    }

    this.debug('fetchLatest:start', conversationId)

    const promise = fetchAndLoad()
    const resource = new DataResource(() => promise)
    resource.load()

    const cache = this.getOrCreateCache(conversationId, {
      type: 'latest',
      resource,
    })

    const activities = await promise

    this.debug('fetchLatest:end', conversationId, activities)

    return activities
  }

  async fetchAround(conversationId: string, activityId: string) {
    this.debug('fetchAround:start', conversationId, activityId)

    const deferred = new Deferred()
    const resource = new DataResource(() => deferred.promise)
    resource.load()

    const cache = this.getOrCreateCache(conversationId, {
      type: `around-${Date.now()}`,
      resource,
    })

    const promiseResults = await Promise.all([
      this.fetchBefore(conversationId, activityId, cache),
      this.fetchAfter(conversationId, activityId, cache),
    ])
      .then((results) => {
        deferred.resolve(results)
        return results
      })
      .catch((error) => {
        deferred.reject(error)
        throw error
      })

    const results = [...new Set(promiseResults.flat())]
    this.debug('fetchAround:end', conversationId, activityId, results)

    return results
  }

  async fetchBefore(
    conversationId: string,
    activityId: string,
    cache = this.getOrCreateCache(conversationId),
  ) {
    this.debug('fetchBefore:start', conversationId, activityId)

    const { result, pageInfo } = await this.transport.communication.activities.list(
      this.createPaginationParams('before', conversationId, activityId),
    )

    const activities = await this.addToCache(cache, result, pageInfo)

    this.debug('fetchBefore:end', conversationId, activityId, activities)

    return activities
  }

  async fetchAfter(
    conversationId: string,
    activityId: string,
    cache = this.getOrCreateCache(conversationId),
  ) {
    this.debug('fetchAfter:start', conversationId, activityId)

    const { result, pageInfo } = await this.transport.communication.activities.list(
      this.createPaginationParams('after', conversationId, activityId),
    )

    const activities = await this.addToCache(cache, result, pageInfo)

    this.debug('fetchAfter:end', conversationId, activityId, activities)

    return activities
  }

  getHolesAroundActivity(
    conversationId: string,
    activityId: string,
  ): readonly HoleData[] {
    const cache = this.cache.get(conversationId)

    if (!cache) {
      return []
    }

    const holes = cache.holes.filter((hole) => hole.siblingActivityId === activityId)

    return holes
  }

  fetchHole(conversationId: string, hole: HoleData): Promise<ActivityModel[]> {
    const id = getHoleId(hole)

    this.debug('fetchHole:start', conversationId, id)

    let promise: Promise<ActivityModel[]>

    switch (hole.type) {
      case 'before':
        promise = this.fetchBefore(conversationId, hole.activityId)
        break
      case 'after':
        promise = this.fetchAfter(conversationId, hole.activityId)
        break
      default:
        assertNever(hole.type, `Unknown hole type: ${hole.type}`)
    }

    promise.finally(() => {
      this.debug('fetchHole:end', conversationId, id)
    })

    return promise
  }

  addActivityToCache(
    activity: Pick<IActivity, 'id' | 'createdAt' | 'type' | 'conversationId' | 'status'>,
  ) {
    if (activity.type === 'loading' || activity.type === 'typing') {
      // Ignore loading and typing activities
      return
    }

    const conversationId = activity.conversationId

    if (!conversationId) {
      return
    }

    const isLocalNewActivity = activity.status === 'queued'

    const cache = isLocalNewActivity
      ? this.getOrCreateCache(conversationId, {
          type: 'latest',
          resource: RESOLVED_RESOURCE,
        })
      : this.cache.get(conversationId)

    if (!cache) {
      // If we don't have a cache for the conversation, we don't need to do anything
      // because we will create it when the user actually opens the conversation
      return
    }

    const exists = cache.allActivities.get(activity.id)

    if (exists) {
      // If the activity already exists, we don't need to do anything
      return
    }

    const activityId = activity.id
    const isStartOfHole = cache.holes.some((hole) => hole.activityId === activityId)

    if (isStartOfHole) {
      // If the activity is the start of a hole, we don't need to do anything
      // because we need to fetch the hole when the user scrolls to ensure we fill it
      return
    }

    const newestActivity = cache.allActivities.list[0]

    if (!isLocalNewActivity && !newestActivity) {
      // For activities that are not local and new, we need to have at least one activity in the cache:
      // If we don't have any activities avoid adding a new one to prevent unknown holes from being created
      return
    }

    const createdAt = activity.createdAt || 0
    const newestCreatedAt = newestActivity?.createdAt || 0

    if (createdAt < newestCreatedAt) {
      // If the new activity is older than the newest activity we have in the cache, we don't need to do anything
      // because we will fetch it when the user scrolls up
      return
    }

    const hasAfterHole = cache.holes.some((hole) => hole.type === 'after')

    if (hasAfterHole) {
      // If we have a hole at the bottom, we don't need to do anything
      // because we will fetch it when the user scrolls down
      return
    }

    const rawActivity = {
      id: activity.id,
      createdAt: date(activity.createdAt),
    }

    // If the new activity is newer than the newest activity we have in the cache, we need to add it
    // to the cache
    cache.allActivities.put(rawActivity)

    if (activity.type !== 'voicemail') {
      cache.activities.put(rawActivity)
    }

    // Create a PageInfo object to store information about the new activity
    // this will help us determine if we have holes when we fetch latest activities
    // after a period of inactivity
    const pageInfo: ConversationHistoryPageInfo = {
      hasPreviousPage: cache.activities.length > 1,
      hasNextPage: false,
      endId: rawActivity.id,
      overfetchEndId: rawActivity.id,
      startId: rawActivity.id,
      overfetchStartId: rawActivity.id,
    }

    cache.pageInfos.push(pageInfo)

    this.recalculateHoles(cache)
  }

  reset(conversationId: string) {
    this.debug('reset:start', conversationId)

    const cache = this.cache.get(conversationId)

    if (cache) {
      this.cache.delete(conversationId)

      this.cache$.next({
        type: 'invalidate',
        conversationId,
      })
    }

    this.debug('reset:end', conversationId)
  }

  private async addToCache(
    cache: ConversationHistoryCache,
    activities: Pick<IActivity, 'id' | 'createdAt' | 'type'>[],
    pageInfo: LegacyPageInfo,
  ): Promise<ActivityModel[]> {
    if (activities.length === 0) {
      return []
    }

    // Sort activities by createdAt, descending
    // We need to sort them because the API doesn't guarantee the order
    activities.sort(sortDesc)

    const hasExtraItems = activities.length > this.config.itemsPerPage

    if (pageInfo.hasPreviousPage && hasExtraItems) {
      // If we have a previous page and > ITEMS_PER_PAGE activities, we could have a hole
      // at the END of the page.
      // We check that later when we recalculate holes, but first we need to remove the last
      // activity
      activities.pop()
    }

    if (pageInfo.hasNextPage && hasExtraItems) {
      // If we have a next page and > ITEMS_PER_PAGE activities, we could have a hole
      // at the START of the page.
      // We check that later when we recalculate holes, but first we need to remove the first
      // activity
      activities.shift()
    }

    const regularActivities: RawConversationActivity[] = []
    const baseActivities = activities.map((activity) => {
      const baseActivity: RawConversationActivity = {
        id: activity.id,
        createdAt: date(activity.createdAt),
      }

      if (activity.type !== 'voicemail') {
        regularActivities.push(baseActivity)
      }

      return baseActivity
    })

    cache.allActivities.putBulk(baseActivities)
    cache.activities.putBulk(regularActivities)

    const realStartId = baseActivities.at(-1)?.id || null
    const realEndId = baseActivities.at(0)?.id || null

    cache.pageInfos.push({
      overfetchStartId: pageInfo.startId,
      overfetchEndId: pageInfo.endId,
      hasPreviousPage: pageInfo.hasPreviousPage,
      hasNextPage: pageInfo.hasNextPage,

      startId: realStartId,
      endId: realEndId,
    })

    this.recalculateHoles(cache)

    const loadActivities = () => this.activityStore.collection.load(activities)

    // After adding the activities to the cache and recalculating the holes
    // we need to check if there are any holes in the middle of the page.
    // If there are, since our current UI doesn't support loading holes in the middle of the page,
    // we need to solve this situation in one of the following ways:
    //
    // 1. If the hole was created when fetching the latest activities, we'll discard those activities
    //    and fetch the next page from the current endId. This will mutate the cache to the `around-${endId}` type.
    // 2. If the hole was created in a different way (which shouldn't be possible, but just in case), we'll discard
    //    the cache and refetch the conversation from the start. This will be a symptom of a bug implemented in the
    //    code that interacts with the ConversationHistoryService.
    const middleHoles = this.getMiddleHoles(cache)

    if (middleHoles.length === 0) {
      // If there are no holes in the middle of the page, we can safely load the activities and return them
      return await loadActivities()
    }

    // If there are holes in the middle of the page, we'll proceed with one of the two options mentioned above
    // Undo the changes we made to the cache
    cache.pageInfos.pop()
    cache.allActivities.deleteBulk(baseActivities)
    cache.activities.deleteBulk(regularActivities)
    this.recalculateHoles(cache)

    const hardResetCache = async () => {
      this.reset(cache.conversationId)
      return await this.fetchLatest(cache.conversationId)
    }

    // Find the last PageInfo object to determine if it's the latest page
    const lastPageInfo = cache.pageInfos.at(-1)

    if (!lastPageInfo) {
      // Not having a lastPageInfo is related to the option (2) mentioned above
      // A case that shouldn't happen, but we'll handle it to not break the conversation
      return await hardResetCache()
    }

    const isPreviousLatestPageInfo = lastPageInfo.overfetchEndId === lastPageInfo.endId

    if (isPreviousLatestPageInfo && lastPageInfo.endId) {
      // Option (1) mentioned above
      cache.initialFetch.type = `around-${lastPageInfo.endId}`
      const activities = await this.fetchAfter(
        cache.conversationId,
        lastPageInfo.endId,
        cache,
      )

      return activities
    }

    // Last resort, we'll hard reset the cache when we have a hole that we can't solve
    return await hardResetCache()
  }

  /**
   * Hole recalculation takes advantage of the PageInfo we generate when we fetch activities
   * to determine if there are holes in the cache.
   *
   * Since our backend doesn't give us information about where next and previous pages start and end,
   * we fetch extra activities to check for holes and save their information in our custom {@link ConversationHistoryPageInfo} object.
   *
   * The algorithm is as follows:
   *
   * For each {@link ConversationHistoryPageInfo}, we do the following checks:
   *    - **For checking holes at the start of the page:**
   *       1. There's a previous page and `overfetchStartId` exists.
   *       2. If `overfetchStartId` is not in the cache, we have a hole at the start of the page.
   *       3. Create the hole object with the type `before` and the activity ID. The sibling activity ID is the `startId`
   *    - **For checking holes at the end of the page:**
   *       1. There's a next page and `overfetchEndId` exists.
   *       2. If `overfetchEndId` is not in the cache, we have a hole at the end of the page.
   *       3. Create the hole object with the type `after` and the activity ID. The sibling activity ID is the `endId`
   */
  private recalculateHoles(cache: ConversationHistoryCache) {
    const newHoles: HoleData[] = []

    for (const pageInfo of cache.pageInfos) {
      if (
        pageInfo.hasPreviousPage &&
        pageInfo.overfetchStartId &&
        !cache.allActivities.get(pageInfo.overfetchStartId)
      ) {
        newHoles.push({
          type: 'before',
          activityId: pageInfo.overfetchStartId,
          siblingActivityId: pageInfo.startId || null,
        })
      }

      if (
        pageInfo.hasNextPage &&
        pageInfo.overfetchEndId &&
        !cache.allActivities.get(pageInfo.overfetchEndId)
      ) {
        newHoles.push({
          type: 'after',
          activityId: pageInfo.overfetchEndId,
          siblingActivityId: pageInfo.endId || null,
        })
      }
    }

    runInAction(() => {
      cache.holes = newHoles
    })
  }

  private getOrCreateCache(
    conversationId: string,
    fetch?: {
      type: NonNullable<ConversationHistoryCache['initialFetch']>['type']
      resource: DataResource<unknown>
    },
  ): ConversationHistoryCache {
    this.debug('getOrCreateCache:start', conversationId)
    let cache = this.cache.get(conversationId)

    if (cache && fetch) {
      if (fetch.type !== cache.initialFetch.type) {
        const canConvertToLatest = this.canConvertToLatest(cache)

        // If we can convert the cache to the latest type, do it to avoid showing
        // a pending state to the user
        if (canConvertToLatest) {
          this.debug('getOrCreateCache:mutateCache', conversationId)
          cache.initialFetch.type = 'latest'
          this.debug('getOrCreateCache:end', conversationId)
          return cache
        }

        // Reset the cache if the fetch type is different
        this.debug('getOrCreateCache:resetCache', conversationId)
        cache = undefined
      }
    }

    if (cache) {
      this.debug('getOrCreateCache:end', conversationId)

      return cache
    }

    this.debug('getOrCreateCache:createCache', conversationId)

    const activities = new Collection<RawConversationActivity>({
      compare: sortAsc,
    })

    const allActivities = new Collection<RawConversationActivity>({
      compare: sortAsc,
    })

    const newCache = observable<ConversationHistoryCache>({
      key: uuid(),
      conversationId,
      activities,
      allActivities,
      holes: [],
      pageInfos: [],
      initialFetch: observable({
        type: fetch?.type ?? null,
        resource: fetch?.resource ?? UNRESOLVED_RESOURCE,
        status: 'loading',
      } as const),
    })

    fetch?.resource
      ?.load()
      .then(
        action(() => {
          newCache.initialFetch.status = 'finished'
        }),
      )
      .catch(
        action(() => {
          newCache.initialFetch.status = 'failed'
        }),
      )

    this.debug('getOrCreateCache:set', conversationId)
    this.cache.set(conversationId, newCache)

    this.debug('getOrCreateCache:notifyInvalidation', conversationId)
    this.cache$.next({
      type: 'invalidate',
      conversationId,
    })

    this.debug('getOrCreateCache:end', conversationId)
    return newCache
  }

  private createPaginationParams(
    fetchType: 'last',
    conversationId: string,
  ): PaginationParams
  private createPaginationParams(
    fetchType: 'before' | 'after',
    conversationId: string,
    activityId: string,
  ): PaginationParams
  private createPaginationParams(
    fetchType: 'before' | 'after' | 'last',
    conversationId: string,
    activityId?: string,
  ): PaginationParams {
    switch (fetchType) {
      case 'last':
        return {
          id: conversationId,
          last: this.config.itemsPerPage + EXTRA_ITEMS,
        }
      case 'before':
        return {
          id: conversationId,
          last: this.config.itemsPerPage + EXTRA_ITEMS,
          next: EXTRA_ITEMS,
          before: activityId,
        }
      case 'after':
        return {
          id: conversationId,
          next: this.config.itemsPerPage + EXTRA_ITEMS,
          last: EXTRA_ITEMS,
          before: activityId,
        }
    }
  }

  private removeActivityFromCache(activity: ActivityModel) {
    const conversationId = activity.conversationId

    if (!conversationId) {
      return
    }

    const cache = this.cache.get(conversationId)

    if (!cache) {
      return
    }

    cache.allActivities.delete(activity.id)
    cache.activities.delete(activity.id)

    this.recalculateHoles(cache)
  }

  private subscribeToChanges() {
    this.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'activity-update': {
          this.addActivityToCache(data.activity)
          return
        }
      }
    })

    this.activityStore.collection.observe((change) => {
      if (change.type === 'delete') {
        for (const activity of change.objects) {
          this.removeActivityFromCache(activity)
        }
      }
    })
  }

  /**
   * As an optimization, we can convert cache to the latest type if there are no holes at the bottom.
   *
   * This is useful when we have a cache that was fetched around an activity and the user scrolls to the bottom.
   *
   * If there are no holes at the bottom, we can safely convert the cache to the latest type.
   */
  private canConvertToLatest(cache: ConversationHistoryCache) {
    if (!cache.initialFetch.type?.startsWith('around')) {
      return false
    }

    const lastActivityId = cache.activities.list.at(-1)?.id
    const holes = lastActivityId
      ? cache.holes.filter(
          (hole) => hole.siblingActivityId === lastActivityId && hole.type === 'after',
        )
      : []
    const hasNoBottomHole = holes.length === 0

    return hasNoBottomHole
  }

  private getMiddleHoles(cache: ConversationHistoryCache) {
    const firstActivityId = cache.activities.list.at(0)?.id
    const lastActivityId = cache.activities.list.at(-1)?.id

    const holes = cache.holes.filter(
      (hole) =>
        hole.siblingActivityId !== firstActivityId &&
        hole.siblingActivityId !== lastActivityId,
    )

    return holes
  }
}

function sortDesc(a: { createdAt: number | null }, b: { createdAt: number | null }) {
  return date(b.createdAt) - date(a.createdAt)
}

function sortAsc(a: { createdAt: number | null }, b: { createdAt: number | null }) {
  return date(a.createdAt) - date(b.createdAt)
}

function getHoleId(hole: HoleData) {
  return `${hole.type}:${hole.activityId}`
}

function date(input: string | number | null) {
  return input ? parseDate(input) || 0 : 0
}
