import type { LabelAria } from '@react-aria/label'
import { createContext } from 'react'

// IMPORTANT: Make sure to update the `useFormField.stories.mdx` file if you change
// something that requires a documentation change.

export interface FormFieldContextType {
  /**
   * HTML props to pass to the native input element.
   */
  htmlProps: LabelAria['fieldProps'] & {
    'aria-invalid'?: boolean
  }

  /**
   * Field related props that you can use to style the native or make other customizations.
   */
  fieldProps: {
    hasError?: boolean
  }
}

const FormFieldContext = createContext<FormFieldContextType>({
  htmlProps: {},
  fieldProps: {},
})

export default FormFieldContext
