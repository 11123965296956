import type { PriceId } from '@src/app/billing/pricing/constants'
import {
  ADDITIONAL_NUMBER_ANNUAL_PRICE_ID,
  ADDITIONAL_NUMBER_MONTHLY_PRICE_ID,
} from '@src/app/billing/pricing/constants'

export const isAddditionalNumberMonthly = (priceId: PriceId) =>
  priceId === ADDITIONAL_NUMBER_MONTHLY_PRICE_ID

export const isAddditionalNumberAnnual = (priceId: PriceId) =>
  priceId === ADDITIONAL_NUMBER_ANNUAL_PRICE_ID

const isAdditionalNumber = (priceId: PriceId) =>
  isAddditionalNumberMonthly(priceId) || isAddditionalNumberAnnual(priceId)

export default isAdditionalNumber
