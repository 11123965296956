import { makeObservable, observable } from 'mobx'

import uuid from '@src/lib/uuid'
import type { Model } from '@src/service/model'

export interface BaseCodableReaction {
  id: string
  body: string | null
  createdAt: number
  updatedAt: number
  userId: string | null
}

export interface BaseEncodableReaction {
  id: string
  body: string | null
}

export interface BaseDecodableReaction extends BaseCodableReaction {
  conversationId?: string
  activityId?: string
  commentId?: string
}

abstract class BaseReaction implements Model, BaseCodableReaction {
  id: string = `RN${uuid()}`.replace(/-/g, '')
  body: string | null = null
  createdAt: number = Date.now()
  updatedAt: number = Date.now()
  userId: string | null = null

  constructor() {
    makeObservable(this, {
      body: observable.ref,
      createdAt: observable.ref,
      updatedAt: observable.ref,
      userId: observable.ref,
    })
  }

  abstract deserialize(
    json?: Partial<BaseCodableReaction> | Partial<BaseDecodableReaction>,
  )

  abstract serialize(): BaseCodableReaction

  abstract toJSON(): BaseEncodableReaction
}

export default BaseReaction
