import { computed, makeObservable } from 'mobx'

import type Service from '@src/service'

import BaseAlertModel from './BaseAlertModel'

class ContactNoteMentionAlertModel extends BaseAlertModel<
  'mention-in-contact-note',
  { contactId: string; noteId: string; userId: string }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      user: computed,
      contact: computed,
      note: computed,
    })
  }

  get associatedObjectsLoaded() {
    return Boolean(this.note)
  }

  get user() {
    return this.service.member.get(this.data.userId)
  }

  get contact() {
    return this.service.contact.get(this.data.contactId)
  }

  get note() {
    return this.contact?.notes.find((note) => note.id === this.data.noteId)
  }
}

export default ContactNoteMentionAlertModel
