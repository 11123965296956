import cx from 'classnames'
import { observer } from 'mobx-react-lite'

import AppSkeleton from '@src/app/components/AppSkeleton'
import { useAppStore } from '@src/app/context'
import { isMac } from '@src/config'
import useIsMobileReferral from '@src/lib/hooks/useIsMobileReferral'
import useTransition from '@src/lib/hooks/useTransition'

import * as styles from './ConnectedAppSkeleton.css'
import ProgressContent from './ProgressContent'

const LoggedInSkeleton = function () {
  const { initializationStatus, needsOnboarding, isLoggedIn } = useAppStore()
  const state = useTransition(true, { enterDelay: 2000 })
  const hidden = state !== 'entered'
  const isMobileReferral = useIsMobileReferral()

  const showSkeleton =
    !isMobileReferral &&
    initializationStatus !== 'updating' &&
    initializationStatus !== 'failed' &&
    isLoggedIn &&
    needsOnboarding === false

  return (
    <>
      {showSkeleton ? <AppSkeleton /> : null}
      <div
        className={cx(styles.root, { [styles.hidden]: hidden })}
        style={{
          WebkitAppRegion: !hidden && isMac ? 'drag' : undefined,
        }}
      >
        <div className={styles.message} aria-atomic aria-live="assertive">
          <ProgressContent />
        </div>
      </div>
    </>
  )
}

export default observer(LoggedInSkeleton)
