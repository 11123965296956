import Key from '@ui/Key'
import Typography from '@ui/Typography'
import { fontWeights } from '@ui/theme/css'

import type { TooltipAlign } from './Tooltip'
import * as styles from './TooltipContent.css'

const mapPressedKeyToSpecialChar: { [key: string]: string } = {
  shift: '⇧',
  meta: '⌘',
  enter: '⏎',
  alt: '⌥',
  backspace: '⌫',
}

interface TooltipContentProps {
  title: string
  body?: string
  icon?: React.ReactNode
  shortcut?: string
  align: TooltipAlign
}

const TooltipContent = function ({
  title,
  body,
  icon,
  shortcut,
  align,
}: TooltipContentProps) {
  const keys = (shortcut?.split('+') || []).map(
    (key) => mapPressedKeyToSpecialChar[key.toLowerCase()] ?? key,
  )

  return (
    <div className={styles.content({ align, hasBody: !!body })}>
      <div
        className={styles.wrapper}
        style={{
          margin: body ? '12px 0' : '8px 0',
          fontWeight: body ? fontWeights.default.regular : 'inherit',
        }}
      >
        <Typography
          variant="caption1"
          color="inherit"
          fontWeight="medium"
          className={styles.title({ align })}
        >
          {icon} {title}
        </Typography>
        {body ? (
          <Typography variant="caption2" color="textSecondary">
            {body}
          </Typography>
        ) : null}
      </div>
      {shortcut && (
        <div className={styles.shortcut}>
          {keys.map((key) => (
            <Key key={key} label={key} variant="caption3" />
          ))}
        </div>
      )}
    </div>
  )
}

export default TooltipContent
