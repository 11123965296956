export function isElement(node: unknown): node is Element {
  return node instanceof Element
}

export function isHTMLElement(node: unknown): node is HTMLElement {
  return node instanceof HTMLElement
}

export interface TrueElementContentEditable extends ElementContentEditable {
  readonly isContentEditable: true
  value?: string
}

export type EditableElement =
  | HTMLInputElement
  | HTMLTextAreaElement
  | (HTMLElement & TrueElementContentEditable)

function isEditableInput(input: HTMLInputElement) {
  const editableTypes = ['text', 'url', 'email', 'search', 'password', 'tel', 'number']

  return editableTypes.includes(input.type)
}

export function isEditableElement(node: EventTarget): node is EditableElement {
  return (
    isHTMLElement(node) &&
    (node.isContentEditable ||
      (node instanceof HTMLInputElement && isEditableInput(node)) ||
      node instanceof HTMLTextAreaElement)
  )
}

/**
 * Determine if an element has any input from the user.
 *
 * For traditional HTML input and textarea elements, it uses the `value`
 * attribute. For elements with `contenteditable`, it uses the `textContent` to
 * determine if there is content within the editable element, stripping any
 * leading BOMs (mostly because of Slate).
 */
export function hasInputValue(node: EditableElement): boolean {
  return Boolean(
    node.value || (node.isContentEditable && node.textContent?.replace(/^\uFEFF/, '')),
  )
}
