import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import { useCallback, useEffect, useRef } from 'react'
import type { AriaDialogProps } from 'react-aria'
import {
  FocusScope,
  useDialog,
  useFocusManager,
  useModal,
  useOverlay,
  usePreventScroll,
  DismissButton,
} from 'react-aria'

import OverlayContainer from '@ui/OverlayContainer'
import VisuallyHidden from '@ui/VisuallyHidden'
import { LayerProvider, setBaseZIndex, useLayer, zIndexes } from '@ui/z-index'

import * as styles from './Modal.css'
import ModalContext from './ModalContext'

const modalZIndex = zIndexes.modal

export interface ModalProps extends AriaDialogProps {
  children?: React.ReactNode

  /**
   * Handler that is called when the overlay should close.
   */
  onClose?: () => void

  /**
   * Whether to close the overlay when the user interacts outside it.
   * @default false
   */
  isDismissable?: boolean

  /**
   * Whether pressing the escape key to close the overlay should be disabled.
   * @default false
   */
  isKeyboardDismissDisabled?: boolean

  /**
   * When user interacts with the argument element outside of the overlay ref,
   * return true if onClose should be called.  This gives you a chance to filter
   * out interaction with elements that should not dismiss the overlay.
   * By default, onClose will always be called on interaction outside the overlay ref.
   */
  shouldCloseOnInteractOutside?: (element: Element) => boolean

  /**
   * Accessibility title for the Modal. Should be set when not adding a title inside the Modal children.
   */
  accessibilityTitle?: string

  /**
   * Determines the HTML element to use for the accessibility title of the Modal (h1-h6).
   */
  accessibilityTitleLevel?: 1 | 2 | 3 | 4 | 5 | 6

  /**
   * Custom class name for the Modal.
   */
  className?: string

  /**
   * Custom class name for the Modal Underlay
   * Should be use to add spacing between the Modal and the edges of the viewport
   */
  underlayClassName?: string

  /**
   * Style overrides for the Modal.
   */
  style?: React.CSSProperties

  /**
   * Underlay color for the Modal.
   */
  underlayColor?: string
}

const Modal = ({
  children,
  accessibilityTitle,
  accessibilityTitleLevel,
  shouldCloseOnInteractOutside,
  onClose,
  className,
  underlayClassName,
  underlayColor = 'rgba(0, 0, 0, 0)',
  style,
  ...props
}: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const clickedTargetRef = useRef<HTMLElement | null>(null)

  const setClickedTargetRef = useCallback((e: PointerEvent) => {
    clickedTargetRef.current = e.target as HTMLElement
  }, [])

  useEffect(() => {
    window.addEventListener('pointerdown', setClickedTargetRef)

    return () => {
      window.removeEventListener('pointerdown', setClickedTargetRef)
    }
  }, [setClickedTargetRef])

  const { overlayProps, underlayProps } = useOverlay(
    {
      isOpen: true,
      onClose,
      shouldCloseOnInteractOutside: (target) => {
        let element: HTMLElement | null = target as HTMLElement

        if (shouldCloseOnInteractOutside?.(element)) {
          return true
        }

        // Issue: When a popover element (e.g., Combobox) is rendered in a Modal,
        // it closes both the modal and itself on pointer interaction if the interaction
        // occurs outside the modal bounds. This is due to the order of the events.
        // Any interaction with a DOM element on mousedown causes this issue.

        // Solution: Identify the clicked element (e.g., Combobox) and check if the target
        // element is still in the DOM. If the target element is no longer in the DOM,
        // we do not want to close the modal.
        if (clickedTargetRef.current && element !== clickedTargetRef.current) {
          return false
        }

        while (element) {
          // Close if the user clicks on the underlay
          if (element.dataset.underlay) {
            return true
          }

          // Don't close if the user clicks on an element with a superior z-index
          const elementStyle = window.getComputedStyle(element)
          const zIndex = Number(elementStyle.zIndex ?? '0')

          if (zIndex >= modalZIndex) {
            return false
          }

          element = element.parentElement
        }

        return false
      },
      ...props,
    },
    ref,
  )
  const { modalProps } = useModal()
  const { dialogProps, titleProps } = useDialog(props, ref)

  usePreventScroll()

  let Heading: React.ElementType | null = null

  if (accessibilityTitle) {
    Heading = 'h1'

    if (accessibilityTitleLevel) {
      Heading = `h${accessibilityTitleLevel}`
    }
  }

  const { zIndex } = useLayer()

  return (
    <OverlayContainer>
      <FocusScope contain={false} restoreFocus={true} autoFocus={true}>
        <div
          className={cx(styles.root, underlayClassName)}
          data-underlay="true"
          {...underlayProps}
          style={{
            backgroundColor: underlayColor,
            ...assignInlineVars({ ...setBaseZIndex(zIndex) }),
          }}
        >
          <FocusTrapNext />

          <LayerProvider layer="modal">
            <div
              ref={ref}
              {...overlayProps}
              {...dialogProps}
              {...modalProps}
              className={className}
              style={style}
            >
              {Heading ? (
                <VisuallyHidden>
                  <Heading {...titleProps}>{accessibilityTitle}</Heading>
                </VisuallyHidden>
              ) : null}

              <ModalContext.Provider value={{ elementRef: ref }}>
                {children}
              </ModalContext.Provider>

              <VisuallyHidden>
                <DismissButton onDismiss={onClose} />
              </VisuallyHidden>
            </div>
          </LayerProvider>

          <FocusTrapReset />
        </div>
      </FocusScope>
    </OverlayContainer>
  )
}

export default Modal

function FocusTrapReset() {
  const focusManager = useFocusManager()

  // TODO: maybe we could add another element at the beginning of the body to jump back to the modal

  return (
    <div
      tabIndex={0}
      onFocus={() => {
        focusManager.focusFirst()
      }}
    />
  )
}

function FocusTrapNext() {
  const focusManager = useFocusManager()

  return (
    <div
      tabIndex={0}
      onFocus={() => {
        focusManager.focusNext()
      }}
    />
  )
}
