import { useContext } from 'react'

import ComboboxTriggerContext from './ComboboxTriggerContext'

export default function useComboboxTrigger() {
  const context = useContext(ComboboxTriggerContext)

  if (!context) {
    throw new Error('useComboboxTrigger has to be used within a <Combobox /> component')
  }

  return context
}
