/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { action, makeAutoObservable, runInAction } from 'mobx'

import isNonNull from '@src/lib/isNonNull'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import ActivityMentionAlertModel from '@src/service/model/alert/ActivityMentionAlertModel'
import ActivityReactionAlertModel from '@src/service/model/alert/ActivityReactionAlertModel'
import ContactNoteMentionAlertModel from '@src/service/model/alert/ContactNoteMentionAlertModel'
import CsvImportCompletedAlertModel from '@src/service/model/alert/CsvImportCompletedAlertModel'
import OrganizationExportCompleteAlertModel from '@src/service/model/alert/OrganizationExportCompleteAlertModel'
import PortRequestCompletedAlertModel from '@src/service/model/alert/PortRequestCompletedAlertModel'
import ThreadCommentReactionAlertModel from '@src/service/model/alert/ThreadCommentReactionAlertModel'
import ThreadMentionAlertModel from '@src/service/model/alert/ThreadMentionAlertModel'
import ThreadReplyAlertModel from '@src/service/model/alert/ThreadReplyAlertModel'
import ThreadResolutionAlertModel from '@src/service/model/alert/ThreadResolutionAlertModel'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import type AlertAssociations from './model/alert/AlertAssociations'
import type AlertType from './model/alert/AlertType'
import type CodableAlert from './model/alert/CodableAlert'
import type DecodableAlert from './model/alert/DecodableAlert'
import type { LegacyPageInfo } from './transport/lib/LegacyPaginated'
import type { AlertDeleteMessage, AlertUpdateMessage } from './transport/websocket'
import type { AlertRepository } from './worker/repository'

export default class AlertStore {
  readonly collection: PersistedCollection<AlertType, AlertRepository>

  private pageInfo: LegacyPageInfo | null = null
  private lastFetchedAt: number | null = null

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('alert'),
      classConstructor: (json: CodableAlert) => {
        switch (json.type) {
          case 'comment-on-activity':
          case 'reply-in-mentioned-thread':
          case 'reply-in-thread':
            return new ThreadReplyAlertModel(this.root)
          case 'reaction-on-activity':
            return new ActivityReactionAlertModel(this.root)
          case 'mention-in-activity-comment':
            return new ThreadMentionAlertModel(this.root)
          case 'mention-in-activity':
            return new ActivityMentionAlertModel(this.root)
          case 'mention-in-contact-note':
            return new ContactNoteMentionAlertModel(this.root)
          case 'reaction-on-activity-comment':
            return new ThreadCommentReactionAlertModel(this.root)
          case 'activity-resolved':
          case 'activity-unresolved':
            return new ThreadResolutionAlertModel(this.root)
          case 'port-request-completed':
            return new PortRequestCompletedAlertModel(this.root)
          case 'contact-import-completed':
          case 'contact-import-errored':
            return new CsvImportCompletedAlertModel(this.root)
          case 'org-export-complete':
            return new OrganizationExportCompleteAlertModel(this.root)
          default:
            return null
        }
      },
    })

    makeAutoObservable(this, {}, { autoBind: true })

    makePersistable<this, 'pageInfo' | 'lastFetchedAt'>(this, 'AlertStore', {
      pageInfo: root.storage.sync(),
      lastFetchedAt: root.storage.sync(),
    })

    this.subscribeToWebhookEvents()
  }

  getAll() {
    this.collection.performQuery((repo) => repo.all())
  }

  private async fetchSince(timestamp: number) {
    const { result } = await this.root.transport.account.alert.since(new Date(timestamp))
    const alerts = (await this.collection.load(result)) ?? []
    runInAction(() => {
      this.lastFetchedAt = Math.max(
        ...alerts.map((c) => c.updatedAt).filter(isNonNull),
        timestamp + 1,
      )
    })
  }

  private async fetchPaginated() {
    const {
      pageInfo,
      result,
      associations: { activities, contacts, conversations },
    } = await this.root.transport.account.alert.paginated()

    const [alerts] = await Promise.all([
      this.load(result),
      this.root.activity.collection.load(activities),
      this.root.contact.load(contacts),
      this.root.conversation.collection.load(conversations),
    ])

    runInAction(() => {
      this.pageInfo = pageInfo
      this.lastFetchedAt = Math.max(
        ...alerts.map((alert) => alert.updatedAt ?? 0 + 1),
        (this.lastFetchedAt || Date.now()) + 1,
      )
    })
  }

  /**
   * If lastFetchedAt was older than a week ago, nuke the cache and try
   * fetching alerts again from scratch. This will avoid a scenario where
   * a user that has been inactive for more than a week and comes back
   * doesn't fetch since that date cause in active workspaces there could
   * be a lot of activities and the payload would be big, to avoid that
   * we just start fresh from the most recent.
   */
  private async clearCacheIfStaleData() {
    if (
      typeof this.lastFetchedAt !== 'number' ||
      this.lastFetchedAt > Date.now() - 3600 * 24 * 7 * 1000
    ) {
      return
    }

    await this.collection.deleteQuery((repo) => repo.all())
    runInAction(() => {
      this.lastFetchedAt = null
    })
  }

  async fetchMissing() {
    await this.clearCacheIfStaleData()

    if (this.lastFetchedAt) {
      await this.fetchSince(this.lastFetchedAt)
    } else {
      await this.fetchPaginated()
    }
  }

  async markRead(alerts: AlertType[]) {
    if (alerts.length === 0) {
      return
    }
    alerts.forEach(
      action((alert) => {
        alert.readAt = Date.now()
      }),
    )
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.read(ids)
  }

  async markUnread(alerts: AlertType[]) {
    if (alerts.length === 0) {
      return
    }
    alerts.forEach((alert) => {
      alert.readAt = null
    })
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.unread(ids)
  }

  async markOpened(alerts: AlertType[]) {
    if (alerts.length === 0) {
      return
    }
    alerts.map(
      action((alert) => {
        alert.openedAt = Date.now()
      }),
    )
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.open(ids)
  }

  async markUnopened(alerts: AlertType[]) {
    if (alerts.length === 0) {
      return
    }
    alerts.forEach((alert) => {
      alert.openedAt = null
    })
    this.collection.putBulk(alerts)
    const ids = alerts.map((a) => a.id)
    return this.root.transport.account.alert.unopen(ids)
  }

  delete(alerts: AlertType[]) {
    const ids = alerts.map((a) => a.id)
    this.collection.deleteBulk(alerts)
    return this.root.transport.account.alert.delete(ids)
  }

  private subscribeToWebhookEvents() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'alert-update':
          return this.handleAlertUpdate(data)
        case 'alert-delete':
          return this.handleAlertDelete(data)
      }
    })
  }

  private handleAlertUpdate(message: AlertUpdateMessage) {
    this.load(message.alert, message.associations)
  }

  private handleAlertDelete(message: AlertDeleteMessage) {
    this.collection.delete(message.alert.id)
  }

  private load(
    alerts: DecodableAlert | DecodableAlert[],
    { contacts = [], conversations = [], activities = [] }: AlertAssociations = {},
  ) {
    this.root.contact.load(contacts)
    this.root.conversation.load(conversations, activities)
    return this.collection.load(alerts)
  }
}
