import type { ListState } from '@react-stately/list'
import type { Key } from 'react'

function getNextValidItemKey<T>(
  state: ListState<T>,
  key: Key | null | undefined,
  direction: 'first' | 'last',
): Key | null {
  if (!key) {
    return null
  }

  const item = state.collection.getItem(key)

  if (!item) {
    return null
  }

  if (item.type === 'item' && !state.disabledKeys.has(key)) {
    return key
  }

  const nextKey = direction === 'first' ? item.nextKey : item.prevKey

  return getNextValidItemKey(state, nextKey, direction)
}

export function getFirstValidItemKey<T>(
  state: ListState<T>,
  key: Key | null | undefined,
): Key | null {
  return getNextValidItemKey(state, key, 'first')
}

export function getLastValidItemKey<T>(
  state: ListState<T>,
  key: Key | null | undefined,
): Key | null {
  return getNextValidItemKey(state, key, 'last')
}
