export function isMacDesktop(): boolean {
  return navigator.userAgent.includes('Macintosh')
}

export function isWindowsDesktop(): boolean {
  return navigator.userAgent.includes('Windows')
}

export function isFirefox(): boolean {
  return navigator.userAgent.includes('Firefox/')
}

export function isChrome(): boolean {
  return hasUABrand('Google Chrome')
}

function isEdge(): boolean {
  return hasUABrand('Microsoft Edge')
}

export function isBrave(): boolean {
  return !!navigator?.brave
}

export function isChromium(): boolean {
  return !isChrome() && !isEdge() && !isBrave() && hasUABrand('Chromium')
}

function isSafari(): boolean {
  return 'safari' in window
}

export function getBrowserName(): string | null {
  if (isFirefox()) {
    return 'Firefox'
  }
  if (isChrome()) {
    return 'Google Chrome'
  }
  if (isBrave()) {
    return 'Brave'
  }
  if (isEdge()) {
    return 'Microsoft Edge'
  }
  if (isSafari()) {
    return 'Safari'
  }
  if (isChromium()) {
    return 'Chromium'
  }
  return null
}

function hasUABrand(brand: string): boolean {
  if (!('userAgentData' in navigator)) {
    return false
  }
  return !!navigator.userAgentData?.brands.find((b) => b.brand === brand)
}

export function isMobile(): boolean {
  const userAgent = navigator.userAgent

  const isAndroid = userAgent.includes('Android')

  const isIOS =
    /iPad|iPhone/.test(userAgent) ||
    // With the introduction of iPadOS, some iPad models (like the iPad Air and iPad Pro) began to report
    // their user agent string as Macintosh, rather than iPad.
    (userAgent.includes('Mac') && 'ontouchend' in document)

  return isAndroid || isIOS
}

export function getDeviceName(): 'iPad' | 'iPhone' | 'Android' | undefined {
  const userAgent = navigator.userAgent

  if (isMobile()) {
    // With the introduction of iPadOS, some iPad models (like the iPad Air and iPad Pro) began to report
    // their user agent string as Macintosh, rather than iPad.
    if (/iPad|Macintosh/.test(userAgent)) {
      return 'iPad'
    }
    if (userAgent.includes('iPhone')) {
      return 'iPhone'
    }
    if (userAgent.includes('Android')) {
      return 'Android'
    }

    return undefined
  }
  return undefined
}

export function isPwa(): boolean {
  return window.matchMedia('(display-mode: standalone)').matches
}
