import { useHover } from '@react-aria/interactions'
import { mergeProps } from '@react-aria/utils'
import type { KeyboardEvent } from 'react'
import { useMenu as useDefaultMenu } from 'react-aria'

import { useMenuList } from '@ui/Menu/MenuList/context'
import { useIsVisible } from '@ui/Menu/MenuList/utils/useIsVisible'
import MenuListItem from '@ui/Menu/MenuListItem'
import MenuListSection from '@ui/Menu/MenuListSection'
import { useMenuTrigger } from '@ui/Menu/MenuProvider'
import MenuSeparator from '@ui/Menu/MenuSeparator'
import SubMenuProvider, { useMaybeSubMenu } from '@ui/Menu/SubMenuProvider'

import * as styles from './MenuListContent.css'

export default function MenuListContent() {
  const { props, state, setIsVisible, menuListRef } = useMenuList()
  const subMenu = useMaybeSubMenu()
  const {
    menuProps: menuPropsFromTrigger,
    state: { close },
  } = useMenuTrigger()

  const { hoverProps } = useHover({
    onHoverChange(isHovering) {
      if (!subMenu) {
        return
      }

      if (isHovering) {
        subMenu.parentMenu.subMenuMediator.onSubMenuHover()
      } else {
        subMenu.parentMenu.subMenuMediator.onSubMenuLeave(close)
      }
    },
  })

  const { menuProps } = useDefaultMenu(
    { ...props, ...menuPropsFromTrigger },
    state,
    menuListRef,
  )

  const onKeyDown = (e: KeyboardEvent) => {
    if (subMenu && e.key === 'ArrowLeft') {
      close()
    }
  }

  const mergedProps = mergeProps(menuProps, hoverProps, { onKeyDown })

  useIsVisible(menuListRef, setIsVisible)

  return (
    <ul {...mergedProps} ref={menuListRef} className={styles.root}>
      {[...state.collection].map((item) => {
        if (item.type === 'item') {
          if (item.props.isSubMenu) {
            return <SubMenuProvider key={item.key} item={item} />
          }

          return <MenuListItem item={item} key={item.key} />
        }

        if (item.type === 'separator') {
          return <MenuSeparator key={item.key} />
        }

        if (item.type === 'section') {
          return <MenuListSection section={item} key={item.key} />
        }
      })}
    </ul>
  )
}
