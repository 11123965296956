import type { Subscription } from 'rxjs'

export type Dispose = () => void
export type Disposable = Dispose | Subscription | null

export class DisposeBag {
  protected disposers: Dispose[] = []

  constructor(...disposables: Disposable[]) {
    this.add(...disposables)
  }

  add(...disposables: Disposable[]) {
    for (const disposable of disposables) {
      if (disposable) {
        if ('unsubscribe' in disposable) {
          this.disposers.push(() => disposable.unsubscribe())
        } else {
          this.disposers.push(disposable)
        }
      }
    }
  }

  addTimeout(handle: number) {
    this.disposers.push(() => window.clearTimeout(handle))
  }

  addInterval(handle: number) {
    this.disposers.push(() => window.clearInterval(handle))
  }

  dispose() {
    this.disposers.forEach((dispose) => dispose())
  }
}
