/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { type Session } from '@openphone/internal-api-client'
import type { JwtPayload } from 'jwt-decode'
import jwt_decode from 'jwt-decode'
import { makeAutoObservable } from 'mobx'

import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import type TransportClient from './transport'
import type { LoginResponse } from './transport/auth'

export default class AuthStore {
  session: Session | null = null

  constructor(private root: Service) {
    makeAutoObservable(this, {
      transport: false,
    })

    makePersistable(this, 'AuthStore', {
      session: root.storage.async(),
    })

    // Migrate from sync storage to async storage for session
    // TODO: UXP-2909 - Remove this migration code in 2025
    const legacySession = root.storage.sync().get('AuthStore.session') as Session | null
    if (legacySession && !this.root.authV2.isEnabled) {
      this.session = legacySession
      this.transport.setSession(this.session)
      root.storage.sync().delete('AuthStore.session')
    }

    /**
     * When the websocket indicates token needs to be refreshed, go ahead and do it
     */
    this.transport.onMessage.subscribe((message: any) => {
      if (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        message.className === 'AuthUser' &&
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        message.tokenStatus === 'expired' &&
        this.session?.refreshToken
      ) {
        this.refreshToken(this.session.refreshToken)
      }
    })
  }

  get transport(): TransportClient {
    return this.root.transport
  }

  get hasSession() {
    return Boolean(this.session?.idToken)
  }

  get isImpersonating() {
    return this.session?.idToken && !this.session.refreshToken
  }

  async init() {
    const data = await this.root.storage.async().get('AuthStore.session')

    const session = data as Session | null

    if (session) {
      this.session = session
      this.transport.setSession(session)
    }
  }

  isTokenExpired = (): boolean => {
    const tokenExpirationDate = getTokenExpirationDate(this.session?.idToken)

    if (tokenExpirationDate) {
      return tokenExpirationDate.valueOf() - new Date().valueOf() <= 0
    }

    return false
  }

  setSession = (session: Session) => {
    this.session = session
    this.transport.setSession(this.session)
    return this.root.storage.async().set(session, 'AuthStore.session')
  }

  setSessionFromResponse = async (res: LoginResponse): Promise<Session> => {
    const session = {
      idToken: res.id_token,
      refreshToken: res.refresh_token,
    }

    await this.setSession(session)

    return session
  }

  acceptReferral = (referralToken?: string) => (session: Session) => {
    if (referralToken) {
      this.root.referral.accept(referralToken)
    }
    return session
  }

  googleSignin = (accessToken: string, inviteToken?: string): Promise<Session> => {
    return this.transport.auth.signin
      .google(accessToken)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
  }

  googleRegister = (
    accessToken: string,
    inviteToken?: string,
    referralToken?: string,
  ): Promise<Session> => {
    return this.transport.auth.register
      .google(accessToken)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
      .then(this.acceptReferral(referralToken))
  }

  passwordSignin = (
    email: string,
    password: string,
    inviteToken?: string,
    recaptcha_token?: string,
  ): Promise<Session> => {
    return this.transport.auth.signin
      .password(email, password, recaptcha_token)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
  }

  forgotPassword = (email: string): Promise<any> => {
    return this.transport.auth.signin.forgotPassword(email)
  }

  startPasswordlessSignin = (email: string): Promise<void> => {
    return this.transport.auth.signin.sendCode(email)
  }

  startPasswordlessRegister = (email: string): Promise<void> => {
    return this.transport.auth.register.sendCode(email)
  }

  verifyPasswordlessSignin = (
    email: string,
    code: string,
    inviteToken?: string,
    recaptcha_token?: string,
  ): Promise<Session> => {
    return this.transport.auth.signin
      .verifyCode(email, code, recaptcha_token)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
  }

  verifyPasswordlessRegister = (
    email: string,
    code: string,
    inviteToken?: string,
    referralToken?: string,
    recaptcha_token?: string,
  ): Promise<Session> => {
    return this.transport.auth.register
      .verifyCode(email, code, recaptcha_token)
      .then(this.acceptInvite(inviteToken))
      .then(this.setSessionFromResponse)
      .then(this.acceptReferral(referralToken))
  }

  refreshToken = (refreshToken?: string): Promise<Session> => {
    refreshToken ??= this.session?.refreshToken
    return this.transport.auth.signin
      .refreshToken(refreshToken)
      .then(this.setSessionFromResponse)
  }

  changeEmail = (email: string) => {
    return this.transport.auth.changeEmail(email)
  }

  verifyChangeEmail(email: string, code: string) {
    return this.transport.auth.verifyChangeEmail(email, code)
  }

  private acceptInvite = (
    token?: string,
  ): ((session: LoginResponse) => Promise<LoginResponse>) => {
    if (token) {
      return (session: LoginResponse) => {
        this.root.transport.client.idToken = session.id_token
        return this.root.transport.account.invites.accept(token).then(() => {
          return this.transport.auth.signin.refreshToken(session.refresh_token)
        })
      }
    }
    return (session: LoginResponse) => Promise.resolve(session)
  }
}

const getTokenExpirationDate = (token?: string): Date | null => {
  if (!token) {
    return null
  }

  try {
    const decodedToken = jwt_decode<JwtPayload>(token)
    if (decodedToken.exp) {
      // converting milliseconds to seconds and checking if it's before the current date
      return new Date(decodedToken.exp * 1000)
    }
    // it can throw an error if the token is invalid
    // which should not be a case under the normal circumstances
    // eslint-disable-next-line no-empty
  } catch {}

  return null
}
