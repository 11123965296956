import { useListState } from '@react-stately/list'
import type { Selection } from '@react-types/shared'
import type { Key, ReactNode } from 'react'
import { useEffect, useRef, useCallback, useState } from 'react'

import type { ComboboxListProps, ComboboxStyleProps } from '@ui/Combobox/ComboboxList'
import {
  getFirstValidItemKey,
  getLastValidItemKey,
} from '@ui/Combobox/ComboboxList/getNextValidItemKey'
import useGridList from '@ui/Combobox/ComboboxList/useGridList'
import { useComboboxTrigger } from '@ui/Combobox/ComboboxProvider'
import { useSensor } from '@ui/SensorProvider'

import { ComboboxListContext } from '.'
import useFocusTarget from './useFocusTarget'
import useListFilter from './useListFilter'

type ComboboxListProviderProps<T> = Omit<
  ComboboxListProps<T>,
  keyof ComboboxStyleProps
> & {
  outerChildren: ComboboxListProps<T>['children']
  children: ReactNode
}

interface ComboboxListInternalProps {
  isInlined?: boolean
}

export default function ComboboxListProvider<T extends object>({
  children,
  outerChildren,
  items,
  selectedKeys,
  defaultSelectedKeys,
  selectionMode = 'single',
  isInlined = false,
  filterable,
  inputPlaceholder,
  onSelectionChange: outerOnSelectionChange,
  closeOnSelect,
  ...props
}: ComboboxListProviderProps<T> & ComboboxListInternalProps) {
  const comboboxListRef = useRef<HTMLUListElement>(null)
  const comboboxInputRef = useRef<HTMLInputElement>(null)
  const comboboxFocusTargetRef = useRef<HTMLDivElement>(null)

  const shouldAutoFocusRef = useRef(true)
  const [disabledKeys, setDisabledKeys] = useState(new Set<Key>())
  const [isVisible, setIsVisible] = useState(false)

  const { state: triggerState, triggeredWithSensorRef } = useComboboxTrigger()

  const onSelectionChange = useCallback(
    (selection: Selection) => {
      outerOnSelectionChange?.(selection)
      if (closeOnSelect) {
        triggerState.close()
      }
    },
    [outerOnSelectionChange, triggerState, closeOnSelect],
  )

  const state = useListState({
    items,
    children: outerChildren,
    disabledKeys,
    selectedKeys,
    defaultSelectedKeys,
    selectionMode,
    onSelectionChange,
  })

  const { inputProps, collection } = useListFilter(state, {
    enabled: !!filterable,
    inputPlaceholder,
  })

  const { focusTargetProps } = useFocusTarget(state)

  state.collection = collection

  const sensor = useSensor()

  useEffect(() => {
    if (!shouldAutoFocusRef.current || isInlined) {
      return
    }

    const focusOnKey = (key: Key) => {
      state.selectionManager.setFocused(true)
      state.selectionManager.setFocusedKey(key)
    }

    if (filterable) {
      // For filterable lists, always focus on the input by default
      // except when the combobox is opened by the ArrowUp key
      switch (triggerState.focusStrategy) {
        case 'last': {
          const lastKey = state.collection.getLastKey()
          const keyToFocus = getLastValidItemKey(state, lastKey)
          if (keyToFocus) {
            focusOnKey(keyToFocus)
            shouldAutoFocusRef.current = false
          }
          break
        }
        default: {
          comboboxInputRef.current?.focus()
          shouldAutoFocusRef.current = false
          break
        }
      }

      return
    }

    // If there's a selection, focus on the first selected key
    const firstSelectedKey = state.selectionManager.firstSelectedKey
    if (firstSelectedKey) {
      focusOnKey(firstSelectedKey)
      shouldAutoFocusRef.current = false
      return
    }

    // If the combobox is opened by ArrowDown or ArrowUp, focus on
    // the first or last key, respectively.
    // For all other triggers (i.e. pointer and other keys), focus
    // on the focusTarget.
    if (
      triggeredWithSensorRef.current?.type === 'keyboard' &&
      (triggeredWithSensorRef.current.key === 'ArrowDown' ||
        triggeredWithSensorRef.current.key === 'ArrowUp')
    ) {
      let itemKey: Key | null = null

      switch (triggerState.focusStrategy) {
        case 'first': {
          const firstKey = state.collection.getFirstKey()
          itemKey = getFirstValidItemKey(state, firstKey)
          break
        }
        case 'last': {
          const lastKey = state.collection.getLastKey()
          itemKey = getLastValidItemKey(state, lastKey)
          break
        }
      }

      if (itemKey) {
        focusOnKey(itemKey)
        shouldAutoFocusRef.current = false
      }
    } else {
      comboboxFocusTargetRef.current?.focus()
      shouldAutoFocusRef.current = false
    }
  }, [state, triggerState, isInlined, triggeredWithSensorRef, filterable])

  const { gridProps } = useGridList(
    {
      ...props,
      // FIXME: when we have virtualization in place enable it
      // isVirtualized: false,
    },
    state,
    comboboxListRef,
  )

  const setDisabledKey = useCallback((key: Key) => {
    setDisabledKeys((prev) => new Set([...prev, key]))
  }, [])

  return (
    <ComboboxListContext.Provider
      value={{
        comboboxListRef,
        comboboxInputRef,
        comboboxFocusTargetRef,
        state,
        listBoxProps: gridProps,
        inputProps,
        focusTargetProps,
        sensor,
        setDisabledKey,
        isVisible,
        setIsVisible,
      }}
    >
      {children}
    </ComboboxListContext.Provider>
  )
}
