import { observer } from 'mobx-react-lite'

import { useFlag } from '@src/app/feature-flags'
import Banner from '@src/component/Banner'
import Typography from '@ui/Typography'

import * as styles from './OutageBanner.css'

function OutageBanner() {
  const isEnabled = useFlag('outageBanner')

  if (!isEnabled) {
    return null
  }

  return (
    <Banner isDismissible={false} type="outage">
      <Typography variant="footnote" style={{ marginRight: 5 }}>
        🚨
      </Typography>
      <Typography className={styles.message} variant="footnote">
        We're currently investigating reports of a potential service interruption. Please
        check our{' '}
        <a href="https://status.openphone.co/" target="_blank">
          Status page
        </a>{' '}
        for updates.
      </Typography>
    </Banner>
  )
}

export default observer(OutageBanner)
