import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import type { MouseEvent, ReactNode } from 'react'

import { useBanner, useBannerCounter } from '@src/app/banner'
import type { BannerKey } from '@src/app/banner'
import DesktopDraggableArea from '@src/app/components/DesktopDraggableArea'
import { useAppStore } from '@src/app/context'
import IconButton from '@src/component/IconButton'
import { XIcon } from '@ui/icons/tint/20/general'

import * as styles from './Banner.css'

type ColorVariants = 'purple' | 'green' | 'blue' | 'yellow' | 'orange' | 'red'
export interface BannerProps {
  id?: BannerKey
  onClick?: () => void
  type: 'browser notifications' | 'outage' | 'impersonation' | ColorVariants
  isDismissible?: boolean
  className?: string
  children: ReactNode
  height?: number
}

function Banner({
  id,
  onClick,
  children,
  className,
  type,
  isDismissible = true,
  height = 32,
}: BannerProps) {
  const app = useAppStore()
  const { isDismissed, dismiss } = useBanner(id)
  const isClickable = Boolean(onClick)

  useBannerCounter(!isDismissed)

  const handleClose = (event: MouseEvent) => {
    event.stopPropagation()
    dismiss()
  }

  if (isDismissed) {
    return null
  }

  // the pointer events might be enabled again in the future
  // so we might not need to check for isElectron
  // https://github.com/electron/electron/pull/38208
  if (app.isElectron) {
    return (
      <DesktopDraggableArea
        style={{ ...assignInlineVars({ [styles.height]: `${height}px` }) }}
        className={cx(
          styles.root({
            type,
          }),
          className,
        )}
      >
        <div
          className={cx(styles.desktopContent, { [styles.action]: isClickable })}
          onClick={onClick}
        >
          {children}
        </div>
        {isDismissible ? (
          <IconButton
            rounded
            opaque
            variant="transparent"
            hoverColor="white"
            size={24}
            className={styles.close}
            icon={<XIcon />}
            onClick={handleClose}
          />
        ) : null}
      </DesktopDraggableArea>
    )
  }

  return (
    <div
      style={{ ...assignInlineVars({ [styles.height]: `${height}px` }) }}
      className={cx(
        styles.root({
          type,
        }),
        className,
        { [styles.action]: isClickable },
      )}
      onClick={onClick}
    >
      {children}
      {isDismissible ? (
        <IconButton
          rounded
          opaque
          variant="transparent"
          hoverColor="white"
          size={24}
          className={styles.close}
          icon={<XIcon />}
          onClick={handleClose}
        />
      ) : null}
    </div>
  )
}

export default observer(Banner)
