/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import type { CallQualityWizardFeedbackSchema } from '@src/app/voice/CallQualityWizard'
import config from '@src/config'
import type {
  VoiceRingOrderGetAllByPhoneNumberIdResponse,
  VoiceRingOrderUpdateRequest,
  VoiceRingOrderUpdateResponse,
} from '@src/service/dto'
import type VoiceCallFallbackConfigUpdateRequest from '@src/service/dto/request/VoiceCallFallbackConfigUpdateRequest'
import type VoiceCallFallbackConfigGetByPhoneNumberIdResponse from '@src/service/dto/response/VoiceCallFallbackConfigGetByPhoneNumberIdResponse'
import type VoiceRingOrderMaxDialAtOnceResponse from '@src/service/dto/response/VoiceRingOrderMaxDialAtOnceResponse'
import type { Room } from '@src/service/voice'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export type RoomInvitationType = 'added' | 'transferred' | 'called'
export type RoomInvitation = {
  type: RoomInvitationType
  message?: string
  fromParticipantId: string
}
export type RoomParticipantStatus =
  | 'active'
  | 'ringing'
  | 'no-answer'
  | 'completed'
  | 'busy'
  | 'failed'
  | 'queued'

export type RecordingStatus = 'in-progress' | 'paused' | 'stopped'

export type RecordingBody = { action: 'start' } | { action: 'pause' | 'resume' | 'stop' }

export interface RecordingResponse {
  recordingKey: string
  recordingStatus: RecordingStatus
  recordingUrl: string
}

export interface VoiceTarget {
  phoneNumber?: string
  phoneNumberId?: string
  directNumber?: string
  userId?: string
}

export interface ParticipantTarget extends VoiceTarget {
  message?: string
  participantId: string
  isListener?: boolean
}

export interface TransferParams {
  to: ParticipantTarget
  from: { participantId: string; phoneNumberId?: string }
}

export interface AddParticipantsParams {
  to: readonly ParticipantTarget[]
  from: { participantId: string; phoneNumberId?: string }
}

export interface UpdateParticipantsParams {
  isMuted?: boolean
  onHold?: boolean
  coaching?: string
}

export interface UpdateRoomParams {
  onHold?: boolean
  status?: 'ended'
}

export interface TokenResponse {
  expiry: string
  token: string
  version: number
}

export default class VoiceClient {
  constructor(private transport: Transport) {}

  recordings = {
    put: (
      roomId: string,
      participantId: string,
      body: RecordingBody,
    ): Promise<RecordingResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/record/${participantId}`,
          body,
        }),
      )
    },
    bulkDelete: (activityId: string, recordingIds: string[]) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.VOICE_SERVICE_URL}call/recording`,
          body: {
            activityId,
            recordingIds,
          },
        }),
      )
    },
    summarize: (recordingId: string) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}call/recording/summarize`,
          body: {
            recordingId,
          },
        }),
      )
    },
    transcribe: (recordingId: string) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.COMMUNICATION_SERVICE_URL}call/recording/transcribe`,
          body: {
            recordingId,
          },
        }),
      )
    },
  }

  token(): Promise<TokenResponse> {
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.VOICE_SERVICE_URL}token`,
        retry: true,
      }),
    )
  }

  room = {
    get: (roomId: string) => {
      return this.transport.queue(
        new HttpTransaction<Room>({
          method: 'get',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}`,
        }),
      )
    },

    transfer: (roomId: string, params: TransferParams) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'post',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/transfer`,
          body: params,
        }),
      )
    },

    update: (roomId: string, params: UpdateRoomParams) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}`,
          body: params,
        }),
      )
    },
  }

  participants = {
    add: (roomId: string, params: AddParticipantsParams) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/participant`,
          body: params,
        }),
      )
    },

    addAgain: (roomId: string, participantId: string) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'post',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/participant/${participantId}`,
        }),
      )
    },

    update: (roomId: string, participantId: string, params: UpdateParticipantsParams) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'put',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/participant/${participantId}`,
          body: params,
        }),
      )
    },

    remove: (roomId: string, participantId: string) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'delete',
          url: `${config.VOICE_SERVICE_URL}room/${roomId}/participant/${participantId}`,
        }),
      )
    },
  }

  ringOrders = {
    getAllByPhoneNumberId: (phoneNumberId: string) => {
      return this.transport.queue(
        new HttpTransaction<VoiceRingOrderGetAllByPhoneNumberIdResponse>({
          method: 'get',
          url: `${config.VOICE_SERVICE_URL}ringOrders?phoneNumberId=${phoneNumberId}`,
        }),
      )
    },
    update: (dto: VoiceRingOrderUpdateRequest) => {
      return this.transport.queue(
        new HttpTransaction<VoiceRingOrderUpdateResponse>({
          method: 'put',
          url: `${config.VOICE_SERVICE_URL}ringOrders/${dto.id}`,
          body: dto,
        }),
      )
    },
    delete: (id: string) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'delete',
          url: `${config.VOICE_SERVICE_URL}ringOrders/${id}`,
        }),
      )
    },
    maxDialAtOnce: () => {
      return this.transport.queue(
        new HttpTransaction<VoiceRingOrderMaxDialAtOnceResponse>({
          method: 'get',
          url: `${config.VOICE_SERVICE_URL}ringOrders/maxDialAtOnce`,
        }),
      )
    },
  }

  callFallbackConfig = {
    getByPhoneNumberId: (phoneNumberId: string) => {
      return this.transport.queue(
        new HttpTransaction<VoiceCallFallbackConfigGetByPhoneNumberIdResponse>({
          method: 'get',
          url: `${config.VOICE_SERVICE_URL}call-settings/phone-numbers/${phoneNumberId}/call-fallback-config`,
        }),
      )
    },
    update: (dto: VoiceCallFallbackConfigUpdateRequest) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'put',
          url: `${config.VOICE_SERVICE_URL}call-settings/phone-numbers/${dto.phoneNumberId}/call-fallback-config`,
          body: dto,
        }),
      )
    },
  }

  feedback = {
    submit: (feedback: CallQualityWizardFeedbackSchema) => {
      return this.transport.queue(
        new HttpTransaction<void>({
          method: 'post',
          url: `${config.ACCOUNT_SERVICE_URL}feedback`,
          body: feedback,
        }),
      )
    },
  }

  workflow = {
    optIn: (phoneNumberIds: string[]) => {
      return this.transport.queue(
        new HttpTransaction<null, null, { phoneNumberIds: string[] }>({
          method: 'POST',
          url: `${config.VOICE_SERVICE_URL}workflow/opt-in`,
          body: { phoneNumberIds },
        }),
      )
    },
    optOut: (phoneNumberIds: string[]) => {
      return this.transport.queue(
        new HttpTransaction<null, null, { phoneNumberIds: string[] }>({
          method: 'POST',
          url: `${config.VOICE_SERVICE_URL}workflow/opt-out`,
          body: { phoneNumberIds },
        }),
      )
    },
    enableDefinition: (phoneNumberId: string, workflowDefinitionId: string) => {
      return this.transport.queue(
        new HttpTransaction<null, null, { phoneNumberId: string; workflowId: string }>({
          method: 'POST',
          url: `${config.VOICE_SERVICE_URL}workflow/enable`,
          body: { phoneNumberId, workflowId: workflowDefinitionId },
        }),
      )
    },
  }
}
