import { stepNames } from '@src/app/settings/trust/constants'
import type { PhoneNumberModel, TrustRegistrationV2Model } from '@src/service/model'

import type Heap from './Heap'

type RegistrationType = 'personal-use' | TrustRegistrationV2Model['type']

interface TollFreeRegistrationError {
  errorMessage: string
  phoneNumberId: PhoneNumberModel['id']
}

type A2PValidationModal = 'business_name_confirmation'

export default class TrustAnalyticsStore {
  constructor(private readonly heap: Heap) {}

  /**
   * Heap event fires when a user is shown the toll-free message block
   */
  tollFreeMessageBlockViewed(
    messageBlockVariation: 'draft_or_rejected' | 'submitted',
  ): void {
    this.heap.track('viewed_toll_free_message_block', {
      messageBlockVariation,
    })
  }

  /**
   * Heap event fires when the user clicks the link that takes them to the registration flow  (ie. opens the typeform, eventually the in product registration flow)
   */
  tollFreeMessageRegistrationClicked(): void {
    this.heap.track('clicked_toll_free_message_registration_cta')
  }

  /**
   * Heap event fires when the user clicks a link that takes them to the help docs on any toll-free message block callout
   */
  tollFreeMessageBlockHelpClicked(): void {
    this.heap.track('clicked_toll_free_message_block_help_cta')
  }

  /**
   * Heap event fires when the user starts the tfn registration flow
   */
  tollFreeRegistrationFlowStarted(): void {
    this.heap.track('started_toll_free_registration_flow')
  }

  /**
   * Heap event fires when the user submits a toll-free registration
   */
  tollFreeRegistrationSubmitted(): void {
    this.heap.track('submitted_toll_free_registration')
  }

  /**
   * Heap event fires when the user submits a toll-free registration and there is an error
   */
  tollFreeRegistrationSubmittedError({
    errorMessage,
    phoneNumberId,
  }: TollFreeRegistrationError): void {
    this.heap.track('submitted_toll_free_registration_error', {
      phoneNumberId,
      errorMessage,
    })
  }

  /**
   * Heap event fires when the user saves a draft toll-free registration
   */
  tollFreeRegistrationSaveDraft(): void {
    this.heap.track('saved_draft_toll_free_registration')
  }

  /**
   * Heap event fires when the user saves a draft toll-free registration and there is an error
   */
  tollFreeRegistrationSaveDraftError({
    errorMessage,
    phoneNumberId,
  }: TollFreeRegistrationError): void {
    this.heap.track('saved_draft_toll_free_registration_error', {
      phoneNumberId,
      errorMessage,
    })
  }

  /**
   * Fires when a caller ID name has been set as the default
   */
  defaultCallerIdSet(): void {
    this.heap.track('default_caller_id_set')
  }

  /**
   * Fires when a caller ID name has been deleted
   */
  callerIdDeleted(): void {
    this.heap.track('caller_id_deleted')
  }

  /**
   * Fires when a caller ID name has been assigned to a phone number
   */
  callerIdAssigned(): void {
    this.heap.track('caller_id_assigned')
  }

  /**
   * Fires when a caller ID name is created
   */
  callerIdCreated(): void {
    this.heap.track('caller_id_created')
  }

  /**
   * Fires when the user starts to create a caller ID name
   */
  callerIdCreationStarted(): void {
    this.heap.track('caller_id_creation_started')
  }

  /**
   * Fires when the user starts to resubmit a caller ID name
   */
  callerIdResubmissionStarted(): void {
    this.heap.track('caller_id_resubmission_started')
  }

  /**
   * Fires when the user resubmits a caller ID name
   */
  callerIdResubmitted(): void {
    this.heap.track('caller_id_resubmitted')
  }

  /**
   * Fires when the user starts to edit a caller ID name
   */
  callerIdEditStarted(): void {
    this.heap.track('caller_id_edit_started')
  }

  /**
   * Fires when the user edits a caller ID name
   */
  callerIdEdited(): void {
    this.heap.track('caller_id_edited')
  }

  /**
   * Fires when the user fills in the Consent Collection Form
   */
  consentCollectionFormFilled({
    type,
    options,
  }: {
    type: string
    options: string[]
  }): void {
    this.heap.track('consent_collection_options_selected', {
      type,
      options: options.join(','),
    })
  }

  /**
   * Fires when the user clicks the link to register with US
   * carriers in the blocked messaging callout
   */
  blockedMessagingCalloutClicked(): void {
    this.heap.track('blocked_messaging_callout_clicked')
  }

  /**
   * Fires when the user starts the registration flow from
   * the trust center homepage
   */
  registrationStarted(): void {
    this.heap.track('a2p_registration_started')
  }

  /**
   * Fires when the user saves the registration draft
   *
   * @param type - Type of A2P registration
   * @param step - Step in the registration form
   */
  registrationDraftSaved({ type, step }: { type: RegistrationType; step: number }): void {
    this.heap.track('a2p_registration_draft_saved', { type, step })
  }

  /**
   * Fires when there's an error saving the registration draft
   *
   * @param type - Type of A2P registration
   * @param step - Step in the registration form
   **/
  registrationDraftSaveError({
    type,
    step,
  }: {
    type: RegistrationType
    step: number
  }): void {
    this.heap.track('a2p_registration_draft_save_error', { type, step })
  }

  /**
   * Fires when the user clicks the button to review the A2P LVS guide
   */
  reviewGuideClicked(): void {
    this.heap.track('a2p_review_guide_clicked')
  }

  /**
   * Fires when the user clicks the next step button in the registration flow
   * @param type - Type of A2P registration
   * @param step - Step in the registration form. This is the step the user is moving to.
   */
  registrationNextStepClicked({
    type,
    step,
  }: {
    type: RegistrationType
    step: number
  }): void {
    const name = (stepNames[type] as string[])?.[step - 2] ?? 'unkown-step'

    this.heap.track('a2p_registration_next_step_clicked', { type, step: name })
  }

  /**
   * Fires when the user submits the registration form
   *
   * @param type
   */
  registrationSubmitted({ type }: { type: RegistrationType }): void {
    this.heap.track('a2p_registration_submitted', { type })
  }

  /**
   * Fires when there's an error submitting the registration form
   *
   * @param type - Type of A2P registration
   */
  registrationSubmitError({ type }: { type: RegistrationType }): void {
    this.heap.track('a2p_registration_submit_error', { type })
  }

  /**
   * Fires when any of the A2P registration modals are presented
   *
   * @param modal - The name of the modal
   */
  validationModalPresented(modal: A2PValidationModal): void {
    this.heap.track('a2p_validation_modal_presented', { modal })
  }

  /**
   * Fires when the user clicks the confirmation button in the A2P validation modal
   *
   * @param modal - The name of the modal
   */
  validationModalConfirmClicked(modal: A2PValidationModal): void {
    this.heap.track('a2p_validation_modal_confirm_clicked', { modal })
  }

  /**
   * Fires when the user clicks the cancel button in the A2P validation modal
   *
   * @param modal - The name of the modal
   */
  validationModalCancelClicked(modal: A2PValidationModal): void {
    this.heap.track('a2p_validation_modal_cancel_clicked', { modal })
  }

  freeCallerRegistryButtonClicked(): void {
    this.heap.track('fcr_button_clicked')
  }
}
