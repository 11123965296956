import { mergeProps, mergeRefs } from '@react-aria/utils'
import type { Node } from '@react-types/shared'
import cx from 'classnames'
import type { ReactNode } from 'react'
import { useEffect, useRef } from 'react'
import { useHover, useMenuItem } from 'react-aria'

import { useMenuList } from '@ui/Menu/MenuList'
import MenuListItemBase, { type MenuListItemBaseProps } from '@ui/Menu/MenuListItemBase'
import { useSubMenuTrigger } from '@ui/Menu/SubMenuTrigger'
import { SelectedOption } from '@ui/Menu/SubMenuTrigger/SelectedOption'
import { removeUnusedHandlers } from '@ui/Menu/utils'
import Tooltip from '@ui/Tooltip'
import Typography from '@ui/Typography'
import VisuallyHidden from '@ui/VisuallyHidden'
import { CheckBoxTickIcon, ChevronRightIcon } from '@ui/icons/tint/16/general'

import * as styles from './MenuListItem.css'
import { Shortcut } from './Shortcut'

interface ItemNodeProps {
  /**
   * If true the item can't be selected, focused or activated.
   */
  disabled?: boolean

  /**
   * The variant to apply to the item:
   *
   * - `default` displays an ordinary item
   * - `danger` displays the item with more use of the red color to convey a desctuctive action. For example, it can be used in a "Delete" item.
   * - `selected` displays the item with a purpleish background
   *
   * @default `default`
   */
  variant?: MenuListItemBaseProps['variant']

  /**
   * Description of the list item.
   *
   * If specified a tooltip will be displayed on the side of the list item containing the value of this property. The placement of the tooltip is determined using the `descriptionPlacement` prop.
   * A visually hidden element is also rendered to provide this description to screen reader users that do not interact with the list item.
   *
   */
  description?: string

  /**
   * The placement of the tooltip shown if `description` is provided
   *
   * @default 'left'
   */
  descriptionPlacement?: 'left' | 'right'

  /**
   * The shortcut that is bound to the list item action.
   *
   * The shortcut does not necessarily activate the action when the menu is opened, it's more of a visual cue displayed on the right side of the list item.
   *
   * @example
   * ```tsx
   * <Menu.ListItem shortcut={['Alt', 'F4']}>
   *  Close window
   * </Menu.ListItem>
   * ```
   */
  shortcut?: string[]

  /**
   * The left icon to display on the item
   *
   * @example
   * ```tsx
   * <Menu.ListItem icon={<XIcon />}>
   *  Close window
   * </Menu.ListItem>
   * ```
   */
  icon?: ReactNode

  /**
   * If true the item width will adapt to the content width.
   *
   * @default false
   */
  fitContent?: boolean

  /**
   * Display content on the right of the menu item.
   * Content is only displayed with non-selectable Menus.
   *
   * @example
   * ```tsx
   * <Menu.ListItem rightContent={<Badge label="Badge" />}>
   *  item name
   * </Menu.ListItem>
   * ```
   */
  rightContent?: ReactNode
}

interface ItemNode<T> extends Node<T> {
  props?: ItemNodeProps
}

export interface MenuItemProps<T> extends ItemNodeProps {
  item: ItemNode<T>
}

const MenuListItem = <T extends object>({ item }: MenuItemProps<T>) => {
  const ref = useRef<HTMLLIElement>(null)
  const { state, sensor, setDisabledKey, isVisible, props } = useMenuList()
  const subMenuTrigger = useSubMenuTrigger()

  const mergedRefs = subMenuTrigger
    ? mergeRefs<HTMLLIElement>(ref, subMenuTrigger.subMenuTriggerProps.setNodeRef)
    : ref

  useEffect(() => {
    if (item.props?.disabled) {
      setDisabledKey(item.key)
    }
  }, [item.key, item.props?.disabled, setDisabledKey])

  const {
    menuItemProps,
    descriptionProps,
    keyboardShortcutProps,
    isFocused,
    isSelected,
    isDisabled,
  } = useMenuItem({ key: item.key }, state, ref)

  const tooltipTitle = item.props?.description
  const tooltipPlacement = item.props?.descriptionPlacement ?? 'left'
  const fitContent = item.props?.fitContent ?? false

  const { hoverProps, isHovered } = useHover({
    isDisabled: !tooltipTitle,
  })

  const subMenuTriggerProps = subMenuTrigger
    ? {
        ...subMenuTrigger.ariaProps,
        ...(!isDisabled
          ? {
              ...subMenuTrigger.subMenuTriggerProps.hoverProps,
              onKeyDown: subMenuTrigger.subMenuTriggerProps.onKeyDown,
              onClick: subMenuTrigger.subMenuTriggerProps.onClick,
            }
          : {}),
      }
    : null

  const mergedProps = subMenuTriggerProps
    ? mergeProps(removeUnusedHandlers(menuItemProps), subMenuTriggerProps)
    : mergeProps(menuItemProps, hoverProps)

  useEffect(() => {
    if (!isVisible || !ref.current || !isFocused) {
      return
    }
    if (sensor.type !== 'keyboard') {
      return
    }
    ref.current.scrollIntoView({ block: 'center' })
  }, [isFocused, isVisible, sensor])

  const hasRightSide = isSelected || item.props?.shortcut || item.props?.rightContent

  const variant: MenuListItemBaseProps['variant'] = (() => {
    if (isSelected) {
      return 'selected'
    }
    return item.props?.variant ?? 'default'
  })()

  const content = (
    <MenuListItemBase
      ref={mergedRefs}
      variant={variant}
      disabled={isDisabled}
      {...mergedProps}
    >
      {item.props?.icon ? (
        <div
          className={cx(styles.leftSide, {
            [styles.defaultLeftSide]: variant === 'default',
          })}
        >
          {item.props.icon}
        </div>
      ) : null}

      <Typography
        variant="footnote"
        component="div"
        className={styles.content({ fitContent })}
      >
        {item.rendered}
      </Typography>

      {hasRightSide || subMenuTrigger ? (
        <div className={styles.rightSide}>
          {subMenuTrigger ? (
            <>
              {subMenuTrigger.selectionManager.selectionMode !== 'none' ? (
                <SelectedOption
                  selection={subMenuTrigger.selectionManager.selectedKeys}
                />
              ) : null}
              <ChevronRightIcon className={styles.arrowIcon} />
            </>
          ) : (
            <>
              {item.props?.shortcut ? (
                <Shortcut id={keyboardShortcutProps.id} keys={item.props.shortcut} />
              ) : null}
              {isSelected ? <CheckBoxTickIcon className={styles.tickIcon} /> : null}
              {props.selectionMode ? null : item.props?.rightContent}
            </>
          )}
        </div>
      ) : null}

      {item.props?.description ? (
        <VisuallyHidden>
          <span id={descriptionProps.id}>{item.props.description}</span>
        </VisuallyHidden>
      ) : null}
    </MenuListItemBase>
  )

  if (tooltipTitle) {
    return (
      <Tooltip
        open={isHovered}
        title={tooltipTitle}
        placement={subMenuTrigger ? 'right' : tooltipPlacement}
        offset={10}
        className={styles.description}
      >
        {content}
      </Tooltip>
    )
  }

  return content
}

export default MenuListItem
