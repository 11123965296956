import type { MenuListProps } from '@ui/Menu/MenuList'
import { MenuListContent } from '@ui/Menu/MenuList/MenuListContent'
import { MenuListProvider } from '@ui/Menu/MenuList/context'

export default function InlinedMenuList<T extends object>({
  children,
  ...props
}: MenuListProps<T>) {
  return (
    <MenuListProvider outerChildren={children} {...props}>
      <MenuListContent />
    </MenuListProvider>
  )
}
