export function noop() {
  // noop
}

/**
 * TODO: make it work for functions with an argument
 */
export function memoize<R>(fn: () => R): () => R {
  let r: R | undefined
  return () => {
    if (typeof r === 'undefined') {
      r = fn()
    }

    return r
  }
}
