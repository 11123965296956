import Debug from 'debug'
import random from 'lodash/fp/random'
import { action, computed, makeObservable, observable, reaction, when } from 'mobx'
import { nanoid } from 'nanoid'
import { matchPath } from 'react-router-dom'
import { Workbox } from 'workbox-window'

import type AppStore from '@src/app/AppStore'
import Deferred from '@src/lib/Deferred'
import { markErrorAsIgnored } from '@src/lib/IgnoredError'
import { DisposeBag } from '@src/lib/dispose'
import log, { logError } from '@src/lib/log'
import ElectronUpdateController from '@src/updater/ElectronUpdateController'
import ServiceWorkerUpdateController from '@src/updater/ServiceWorkerUpdateController'
import type UpdateController from '@src/updater/UpdateController'

type InitialUpdateStatus = 'checking' | 'updating' | 'complete'

export default class AppUpdateController implements UpdateController {
  readonly id = nanoid()
  readonly debug = Debug(`op:app:update:UpdateController:${this.id}`)

  readonly serviceWorker: ServiceWorkerUpdateController | null
  readonly electron: ElectronUpdateController | null

  protected readonly disposeBag = new DisposeBag()
  protected readonly initialUpdateAvailable = new Deferred<boolean>({ timeout: 5000 })
  protected updateCheckInterval: number | null = null

  protected autoUpdateDelay = 0
  protected autoUpdateDelayTimeout: number | null = null
  protected isAutoUpdateDelayFinished = false

  constructor(protected app: AppStore) {
    makeObservable<
      this,
      'electronUpdateReady' | 'serviceWorkerUpdateReady' | 'isAutoUpdateDelayFinished'
    >(this, {
      initialized: computed,
      updateReady: computed,
      updateDownloading: computed,
      electronUpdateReady: computed,
      serviceWorkerUpdateReady: computed,
      initialUpdateStatus: computed,
      isAutoUpdateDelayFinished: observable.ref,
      shouldUpdate: computed,
      checkForUpdate: action,
      isUpdateAvailable: action,
      installAndRestart: action,
    })

    this.initialUpdateAvailable.promise.catch((error) => {
      markErrorAsIgnored(
        error,
        'Ignore the initial update check timeout error to not flood Sentry',
      )
      logError(error)
    })

    const workbox =
      'serviceWorker' in navigator && location.hostname !== 'localhost'
        ? new Workbox('/sw.js')
        : null

    this.serviceWorker = workbox ? new ServiceWorkerUpdateController(workbox) : null

    this.electron = this.app.electron
      ? new ElectronUpdateController(this.app.electron)
      : null

    this.disposeBag.add(
      reaction(
        () => this.updateReady,
        (updateAvailable, updateAvailablePreviously) => {
          if (updateAvailable && !updateAvailablePreviously) {
            if (this.autoUpdateDelayTimeout === null) {
              this.setAutoUpdateDelayTimeout()
            }

            log.info('Update available!', {
              electronUpdateAvailable: this.electronUpdateReady,
              serviceWorkerUpdateAvailable: this.serviceWorkerUpdateReady,
            })
          }
        },
      ),

      reaction(
        () => app.service.flags.getFlag('appAutoUpdateDelayMs'),
        (appAutoUpdateDelayMs) => {
          this.autoUpdateDelay = random(0, appAutoUpdateDelayMs)

          // Restart an on-going auto-update delay timeout when the delay changes.
          // This allows us to increase or decrease the delay on the fly using
          // LaunchDarkly to either spread out the auto-update occurances to
          // further reduce load on our system, or promote a new version of the
          // app to our users faster, respectively.
          if (this.autoUpdateDelayTimeout !== null) {
            this.setAutoUpdateDelayTimeout()
          }
        },
        { fireImmediately: true },
      ),
    )

    // When the app is initialized, start watching for when it can auto-update
    when(
      () => this.initialized,
      () => {
        this.disposeBag.add(
          reaction(
            () => [this.updateReady, this.shouldUpdate],
            ([updateReady, shouldUpdate]) => {
              if (updateReady && shouldUpdate) {
                this.installAndRestart()
              }
            },
          ),
        )
      },
    )

    this.updateCheckInterval = window.setInterval(() => {
      this.isUpdateAvailable()
    }, 900_000)

    this.disposeBag.add(() => {
      if (this.updateCheckInterval) {
        window.clearInterval(this.updateCheckInterval)
      }
    })

    if (this.electron) {
      this.disposeBag.add(
        this.electron.updateEvent$.subscribe((updateEvent) => {
          if (updateEvent.type === 'error') {
            logError(updateEvent.error, { type: 'electronUpdateError' })

            if (
              typeof updateEvent.error === 'object' &&
              updateEvent.error.message === 'net::ERR_CONNECTION_REFUSED'
            ) {
              this.app.toast.showError('Unable to connect to the update server.')
            } else {
              this.app.toast.showError('Something went wrong updating the app.')
            }
            this.initialUpdateAvailable.resolve(false)
          }
        }),
      )
    }

    this.debug('performing initial update check...')

    this.getTypeOfUpdateAvailable().then(
      action((updateAvailable) => {
        if (updateAvailable) {
          this.initialUpdateAvailable.clearTimeout()

          // wait a bit longer for the update to be ready
          when(() => this.updateReady, {
            timeout: updateAvailable === 'web' ? 30_000 : 60_000,
          })
            .then(
              action(() => {
                this.debug('update is ready, installing and restarting...')
                this.installAndRestart()

                // We usually don't need to resolve the initialUpdateAvailable promise
                // because we're going to install the update and restart the app.
                // However, something can go wrong with the Electron update which is
                // communicated via an event instead of being bubbled up to the initiator
                // so this timeout is to capture a potential error and simply move on
                // from the updating flow.
                setTimeout(() => {
                  this.initialUpdateAvailable.resolve(false)
                }, 10_000)
              }),
            )
            .catch(
              action((error) => {
                this.debug(
                  'timeout occurred while waiting for the update to be ready. Error: %o',
                  error,
                )
                this.app.toast.showError('Something went wrong updating the app.')
                this.initialUpdateAvailable.resolve(false)
              }),
            )
        } else {
          this.debug('no update available')
          this.initialUpdateAvailable.resolve(false)
        }
      }),
    )
  }

  /**
   * Whether or not the update controller has been initialized.
   *
   * This is switched to `true` once the initial update check has completed (or
   * timed out).
   */
  get initialized(): boolean {
    return this.initialUpdateAvailable.state !== 'pending'
  }

  get initialUpdateStatus(): InitialUpdateStatus {
    // we must account for `!this.initialized` here
    // otherwise the computed value will not get reevaluated
    if (this.initialUpdateAvailable.timeoutState === 'cleared' && !this.initialized) {
      return 'updating'
    } else if (this.initialized) {
      return 'complete'
    }

    return 'checking'
  }

  /**
   * Whether or not an update is ready to be installed.
   */
  get updateReady(): boolean {
    return this.serviceWorkerUpdateReady || this.electronUpdateReady
  }

  /**
   * Whether or not an update is currently downloading.
   */
  get updateDownloading(): boolean {
    return this.serviceWorkerUpdateDownloading || this.electronUpdateDownloading
  }

  /**
   * Whether or not the app should be updated.
   *
   * This is `true` when all of the following are true:
   * - The app is not visible
   * - The user is idle
   * - The random delay to ensure staggered auto-updates has finished
   * - The user is in an inbox
   * - The command modal is closed
   * - There are no active calls
   */
  get shouldUpdate(): boolean {
    const criteria = {
      isHidden: this.app.service.presence.visibilityState === 'hidden',
      isIdle: this.app.service.presence.activityState === 'idle',
      isAutoUpdateDelayFinished: this.isAutoUpdateDelayFinished,
      isInInbox: !!matchPath('/inbox/:inboxId/*', this.app.history.location.pathname),
      isCommandModalClosed: !this.app.command.command,
      isNotOnAnActiveCall: !this.app.service.voice.hasActiveCalls,
    }

    const shouldUpdate = Object.values(criteria).every((value) => value)

    this.debug('should update: %o (criteria: %o)', shouldUpdate, criteria)

    return shouldUpdate
  }

  protected get electronUpdateReady(): boolean {
    return !!this.electron?.updateReady
  }

  protected get serviceWorkerUpdateReady(): boolean {
    return !!this.serviceWorker?.updateReady
  }

  protected get electronUpdateDownloading(): boolean {
    return !!this.electron?.updateDownloading
  }

  protected get serviceWorkerUpdateDownloading(): boolean {
    return !!this.serviceWorker?.updateDownloading
  }

  checkForUpdate(): void {
    this.isUpdateAvailable()
  }

  /**
   * Clears an on-going auto-update delay timeout and starts
   * a new one if needed.
   */
  private setAutoUpdateDelayTimeout() {
    if (this.autoUpdateDelayTimeout !== null) {
      window.clearTimeout(this.autoUpdateDelayTimeout)
    }

    if (this.isAutoUpdateDelayFinished) {
      return
    }

    this.autoUpdateDelayTimeout = window.setTimeout(
      action(() => {
        this.isAutoUpdateDelayFinished = true
      }),
      this.autoUpdateDelay,
    )
  }

  private async getTypeOfUpdateAvailable(): Promise<'web' | 'electron' | null> {
    if (this.serviceWorkerUpdateReady) {
      return 'web'
    }

    if (this.electronUpdateReady) {
      return 'electron'
    }

    if (this.serviceWorker) {
      log.info('Checking for service worker update...')
      const available = await this.serviceWorker.isUpdateAvailable()

      if (available) {
        return 'web'
      }
    }

    if (this.electron) {
      log.info('Checking for Electron update...')
      const available = await this.electron.isUpdateAvailable()

      if (available) {
        return 'electron'
      }
    }

    return null
  }

  /**
   * Check for Electron and service worker updates.
   */
  async isUpdateAvailable(): Promise<boolean> {
    return !!(await this.getTypeOfUpdateAvailable())
  }

  /**
   * Install the update (if ready) and restart the app.
   */
  installAndRestart(): void {
    if (this.serviceWorker?.updateReady) {
      log.info('Installing service worker update...')
      this.serviceWorker.installAndRestart()
    } else if (this.electron?.updateReady) {
      log.info('Installing Electron update...')
      this.electron.installAndRestart()
    }
  }

  dispose(): void {
    this.serviceWorker?.dispose()
    this.electron?.dispose()
    this.disposeBag.dispose()
  }
}
