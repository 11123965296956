import { useField } from '@react-aria/label'
import cx from 'classnames'
import { useId } from 'react'

import Tooltip from '@ui/Tooltip'
import VisuallyHidden from '@ui/VisuallyHidden'
import { MiniInfo } from '@ui/icons/custom'

import * as styles from './FormField.css'
import type { FormFieldContextType } from './FormFieldContext'
import FormFieldContext from './FormFieldContext'

export interface FormFieldProps {
  /**
   * The label for the form field.
   */
  label: React.ReactNode

  /**
   * Short description of the form field. This is used to provide a hint to the user of the field's purpose.
   */
  description?: string

  /**
   * Id of the description element. This should be used when you need to place the description in a different
   * location than the default, for example, when the description is placed inside a Callout.
   */
  'aria-describedby'?: string

  /**
   * Detailed description of the form field. This is used to provide additional information about the field's purpose.
   */
  details?: string

  /**
   * Error message to display. This is used to provide feedback to the user about the field's state.
   */
  error?: string

  /**
   * Hides the label and the details tooltip trigger from the UI.
   */
  hideTop?: boolean

  /**
   * Hides the description and error message from the UI.
   */
  hideBottom?: boolean

  /**
   * Class name to apply to the form field.
   */
  className?: string

  /**
   * Children should be Form inputs, selects or other form elements.
   *
   * FormField provides a context for the children to access the form field's state,
   * and a set of html props to pass to the native html element to make it accessible.
   *
   * This prop also allows passing a function, which will receive the context and should return a `ReactNode`.
   *
   * @see FormFieldContext
   */
  children: React.ReactNode | ((context: FormFieldContextType) => React.ReactNode)
}

const FormField = ({
  label,
  description,
  details,
  error,
  children,
  hideTop,
  hideBottom,
  className,
  ['aria-describedby']: ariaDescribedBy,
}: FormFieldProps) => {
  const id = useId()
  const ariaDetailsId = `${id}-details`
  const { labelProps, fieldProps, descriptionProps, errorMessageProps } = useField({
    id,
    label,
    description,
    'aria-describedby': ariaDescribedBy,
    errorMessage: error,
    validationState: error ? 'invalid' : 'valid',
  })

  const contextValue: FormFieldContextType = {
    htmlProps: {
      ...fieldProps,
      'aria-invalid': !!error,
      'aria-details': ariaDetailsId,
    },
    fieldProps: {
      hasError: !!error,
    },
  }

  const bottomContent = (
    <>
      {description && !error ? (
        <div {...descriptionProps} className={cx(styles.bottom, styles.description)}>
          {description}
        </div>
      ) : null}
      {error ? (
        <div {...errorMessageProps} className={cx(styles.bottom, styles.error)}>
          {error}
        </div>
      ) : null}
    </>
  )

  return (
    <FormFieldContext.Provider value={contextValue}>
      <div className={className}>
        {hideTop ? (
          <VisuallyHidden>
            <label {...labelProps}>{label}</label>
          </VisuallyHidden>
        ) : (
          <div className={styles.top}>
            <label {...labelProps} className={styles.label({ error: !!error })}>
              {label}
            </label>

            {details ? (
              <Tooltip title={details} placement="right">
                <button
                  className={styles.detailsTrigger}
                  aria-label="Field Details"
                  type="button"
                >
                  <MiniInfo />
                </button>
              </Tooltip>
            ) : null}
          </div>
        )}

        {typeof children === 'function' ? children(contextValue) : children}

        {hideBottom ? <VisuallyHidden>{bottomContent}</VisuallyHidden> : bottomContent}

        <VisuallyHidden>
          <span id={ariaDetailsId}>{details}</span>
        </VisuallyHidden>
      </div>
    </FormFieldContext.Provider>
  )
}

export default FormField
