import type { CSSProperties } from '@vanilla-extract/css'

import { fontWeights } from '@ui/theme/css'

import fonts from './fonts'

export type TypographyVariant =
  | 'emoji'
  | 'largeTitle'
  | 'title1'
  | 'title2'
  | 'title3'
  | 'headline'
  | 'subheadline'
  | 'body'
  | 'callout'
  | 'footnote'
  | 'caption1'
  | 'caption2'
  | 'caption3'
  | 'code'
  | 'signature'

const typography: { [key in TypographyVariant]: CSSProperties } = {
  emoji: {
    fontSize: 20,
    lineHeight: '22px',
    fontFamily: fonts.emoji,
  },
  largeTitle: {
    fontSize: 35,
    lineHeight: '40px',
    fontFamily: fonts.header,
  },
  title1: {
    fontSize: 29,
    lineHeight: '32px',
    fontFamily: fonts.header,
  },
  title2: {
    fontSize: 25,
    lineHeight: '28px',
    fontFamily: fonts.header,
  },
  title3: {
    fontSize: 21,
    lineHeight: '24px',
    fontFamily: fonts.header,
  },
  headline: {
    fontSize: 17,
    lineHeight: '24px',
    fontWeight: fontWeights.default.regular,
  },
  subheadline: {
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: fontWeights.default.regular,
  },
  body: {
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: fontWeights.default.regular,
  },
  callout: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: fontWeights.default.regular,
  },
  footnote: {
    fontSize: 13,
    lineHeight: '16px',
    fontWeight: fontWeights.default.regular,
  },
  caption1: {
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: fontWeights.default.medium,
  },
  caption2: {
    fontSize: 11,
    lineHeight: '14px',
    fontWeight: fontWeights.default.regular,
  },
  caption3: {
    fontSize: 10,
    lineHeight: '11px',
    fontWeight: fontWeights.default.bold,
  },
  code: {
    fontSize: 13,
    lineHeight: '16px',
    fontFamily: fonts.mono,
  },
  signature: {
    fontSize: 28,
    lineHeight: '32px',
    fontFamily: fonts.signature,
    fontWeight: 700,
  },
}

export default typography
