import { action, computed, makeObservable, observable, toJS } from 'mobx'

import { parseDate } from '@src/lib'
import type Service from '@src/service'
import type AlertLevel from '@src/service/model/alert/AlertLevel'
import type CodableAlert from '@src/service/model/alert/CodableAlert'
import type DecodableAlert from '@src/service/model/alert/DecodableAlert'
import type { Model } from '@src/service/model/base'

abstract class BaseAlertModel<T extends string, D> implements Model, CodableAlert {
  id = ''
  type: T = null as unknown as T
  userId = ''
  data: D = null as unknown as D
  readAt: number | null = null
  openedAt: number | null = null
  deletedAt: number | null = null
  updatedAt: number | null = null
  createdAt: number = Date.now()
  level: AlertLevel = 'info'

  constructor(protected service: Service) {
    makeObservable(this, {
      userId: observable,
      type: observable,
      data: observable.ref,
      readAt: observable,
      openedAt: observable,
      deletedAt: observable,
      updatedAt: observable,
      createdAt: observable,
      isUnread: computed,
      associatedObjectsLoaded: computed,
      isUnopened: computed,
      isDeleted: computed,
      deserialize: action.bound,
    })
  }

  abstract get associatedObjectsLoaded(): boolean

  get isUnread() {
    return !this.readAt
  }

  get isUnopened() {
    return !this.openedAt
  }

  get isDeleted() {
    return Boolean(this.deletedAt)
  }

  serialize(): CodableAlert {
    return {
      id: this.id,
      type: this.type,
      userId: this.userId,
      data: toJS(this.data),
      readAt: this.readAt,
      openedAt: this.openedAt,
      deletedAt: this.deletedAt,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      level: this.level,
    }
  }

  deserialize({
    readAt,
    openedAt,
    deletedAt,
    createdAt,
    updatedAt,
    ...json
  }: Partial<CodableAlert> | Partial<DecodableAlert>) {
    Object.assign(this, json)

    this.readAt = readAt ? parseDate(readAt) : null

    this.openedAt = openedAt ? parseDate(openedAt) : null

    this.deletedAt = deletedAt ? parseDate(deletedAt) : null

    this.createdAt = parseDate(createdAt ?? 0) ?? 0

    this.updatedAt = updatedAt ? parseDate(updatedAt) : null

    return this
  }
}

export default BaseAlertModel
