import type { OverlayContainerProps as ReactAriaOverlayContainerProps } from 'react-aria'
import { OverlayContainer as ReactAriaOverlayContainer } from 'react-aria'

import { useTheme } from '@ui/theme'

export type OverlayContainerProps = ReactAriaOverlayContainerProps

const OverlayContainer = ({ children, ...props }: OverlayContainerProps) => {
  const theme = useTheme()

  return (
    <ReactAriaOverlayContainer {...props}>
      <div className={theme.themeClassName}>{children}</div>
    </ReactAriaOverlayContainer>
  )
}

export default OverlayContainer
