import type { ComboboxListProps } from '@ui/Combobox/ComboboxList'
import { ComboboxListContent } from '@ui/Combobox/ComboboxList/ComboboxListContent'
import { ComboboxListInput } from '@ui/Combobox/ComboboxList/ComboboxListInput'
import { ComboboxListProvider } from '@ui/Combobox/ComboboxList/context'
import { ComboboxListWrapper } from '@ui/Combobox/ComboboxListWrapper'

export default function InlinedComboboxList<T extends object>({
  children,
  width,
  filterable,
  ...props
}: ComboboxListProps<T>) {
  return (
    <ComboboxListProvider outerChildren={children} {...props} isInlined>
      <ComboboxListWrapper width={width}>
        {filterable ? <ComboboxListInput /> : null}
        <ComboboxListContent />
      </ComboboxListWrapper>
    </ComboboxListProvider>
  )
}
