/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable } from 'mobx'

import StatefulPromise from '@src/lib/StatefulPromise'
import PersistedCollection from '@src/service/collections/PersistedCollection'

import type Service from '.'
import { SnippetModel } from './model'
import type { SnippetRepository } from './worker/repository'

export default class SnippetsService {
  collection: PersistedCollection<SnippetModel, SnippetRepository>

  private fetchSnippetsPromise = new StatefulPromise(this.handleFetch.bind(this))

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('snippet'),
      classConstructor: () => new SnippetModel(root.snippet),
    })

    makeAutoObservable(this, {})

    this.subscribeToWebSocket()
    this.load()
  }

  private handleFetch() {
    return this.root.transport.snippets.list()
  }

  async fetch() {
    if (this.fetchStatus === 'idle') {
      const response = await this.fetchSnippetsPromise.run()
      this.collection.load(response, { deleteOthers: true })
    }
  }

  get fetchStatus() {
    return this.fetchSnippetsPromise.status
  }

  load = () => {
    return this.collection.performQuery((repo) => repo.all())
  }

  save = (snippet: SnippetModel) => {
    this.root.snippet.collection.put(snippet)
    return this.root.transport.snippets.put(snippet.serialize())
  }

  delete = (snippet: SnippetModel) => {
    this.collection.delete(snippet)
    return this.root.transport.snippets.delete(snippet)
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'canned-message-update':
          return this.collection.load(data.message)
        case 'canned-message-delete':
          return this.collection.delete(data.message.id)
      }
    })
  }
}
