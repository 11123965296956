import { computed, makeObservable } from 'mobx'

import type Service from '@src/service'

import ActivityAlertBaseModel from './ActivityAlertBaseModel'

class ActivityReactionAlertModel extends ActivityAlertBaseModel<
  'reaction-on-activity',
  {
    activityId: string
    body: string
    conversationId: string
    reactionId: string
    userId: string
  }
> {
  constructor(service: Service) {
    super(service)

    makeObservable(this, {
      reaction: computed,
    })
  }

  get reaction() {
    return this.activity?.reactions?.find(
      (reaction) => reaction.id === this.data.reactionId,
    )
  }
}

export default ActivityReactionAlertModel
