import type { CollectionChildren, SelectionMode } from '@react-types/shared'

import ComboboxFocusTarget from '@ui/Combobox/ComboboxList/ComboboxFocusTarget'
import type { ComboboxListWrapperProps } from '@ui/Combobox/ComboboxListWrapper'
import { ComboboxListWrapper } from '@ui/Combobox/ComboboxListWrapper'
import ComboboxPopover from '@ui/Combobox/ComboboxPopover'
import { useComboboxTrigger } from '@ui/Combobox/ComboboxProvider'
import type { PopoverProps } from '@ui/Popover'

import { ComboboxListContent } from './ComboboxListContent'
import { ComboboxListInput } from './ComboboxListInput'
import { ComboboxListProvider } from './context'
import type { GridProps } from './useGridList'
import useTriggerWidth from './useTriggerWidth'

interface ComboboxPopoverProps {
  /**
   * {@link PopoverProps.placement}
   * @default 'bottom left'
   */
  placement?: PopoverProps['placement']

  /**
   * {@link PopoverProps.offset}
   *
   * @default 6
   */
  offset?: PopoverProps['offset']

  /** {@link PopoverProps.crossOffset} */
  crossOffset?: PopoverProps['crossOffset']
}

export type ComboboxStyleProps = Pick<ComboboxListWrapperProps, 'width' | 'maxHeight'>

export interface ComboboxInputProps {
  inputPlaceholder?: string
}

export interface ComboboxListProps<T>
  extends Omit<GridProps<T>, 'onClose'>,
    ComboboxStyleProps,
    ComboboxPopoverProps,
    ComboboxInputProps {
  /**
   * A list of items to render. If specified a render function has to be specified as children.
   */
  items?: T[]

  /**
   * The children of the list which can be: Items, Sections, Separators, and Sub Menus.
   */
  children: CollectionChildren<T>

  selectionMode?: Exclude<SelectionMode, 'none'>

  /**
   * Whether to allow the combobox to be filtered
   */
  filterable?: boolean

  /**
   * Whether to close the combobox list after making a selection
   */
  closeOnSelect?: boolean
}

export default function ComboboxList<T extends object>({
  children,
  width,
  maxHeight,
  placement = 'bottom left',
  offset = 6,
  crossOffset,
  filterable,
  ...props
}: ComboboxListProps<T>) {
  const {
    ref,
    state: { close, isOpen },
  } = useComboboxTrigger()

  const wrapperWidth = useTriggerWidth({ ref, enabled: width === '100%' }) ?? width

  if (!isOpen) {
    return null
  }

  return (
    <ComboboxListProvider outerChildren={children} {...props} filterable={filterable}>
      <ComboboxPopover
        placement={placement}
        offset={offset}
        crossOffset={crossOffset}
        targetRef={ref}
        onClose={close}
      >
        <ComboboxListWrapper width={wrapperWidth} maxHeight={maxHeight}>
          {filterable ? <ComboboxListInput /> : <ComboboxFocusTarget />}
          <ComboboxListContent />
        </ComboboxListWrapper>
      </ComboboxPopover>
    </ComboboxListProvider>
  )
}
