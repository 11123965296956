import type React from 'react'

type Handler = (event: React.KeyboardEvent) => void

export default {
  onEnter: (handler: Handler) => {
    return (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        handler(event)
      }
    }
  },
  onEnterOrTab: (handler: Handler) => {
    return (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        event.preventDefault()
        handler(event)
      }
    }
  },
}
