import type {
  BusinessOrPersonalOptions,
  TaxIdOptions,
  AddressAndMobileOptions,
  MessagingFee,
  ConsentCollectionMethodKeys,
  ConsentMethodOption,
} from './trustTypes'

export const businessTypes = [
  'Sole Proprietorship',
  'Partnership',
  'Corporation',
  'Co-operative',
  'Limited Liability Corporation',
  'Non-profit Corporation',
] as const

export const stepNames = {
  'lv-standard': [
    'tax-id',
    'business-details',
    'additional-details',
    'point-of-contact',
    'texting-use-case',
    'message-samples',
    'privacy-policy-compliance',
    'consent-options',
    'consent-submission',
    'forbidden-message-topics',
  ],
  'sole-prop': [
    'your-details',
    'your-address',
    'texting-use-case',
    'message-samples',
    'privacy-policy-compliance',
    'consent-options',
    'consent-submission',
    'select-phone-number',
    'forbidden-message-topics',
  ],
  'personal-use': [
    'your-details',
    'your-address',
    'texting-use-case',
    'message-samples',
    'consent-options',
    'select-phone-number',
    'forbidden-message-topics',
    'registration-fees',
  ],
}

export const companyTypes = [
  {
    value: 'Private',
    name: 'Privately held',
  },
  {
    value: 'Public',
    name: 'Publicly traded',
  },
  {
    value: 'Non-profit',
    name: 'Non-profit',
  },
  {
    value: 'Government',
    name: 'Government',
  },
] as const

export const exchanges = [
  'NASDAQ',
  'NYSE',
  'AMEX',
  'AMX',
  'ASX',
  'B3',
  'BME',
  'BSE',
  'FRA',
  'ICEX',
  'JPX',
  'JSE',
  'KRX',
  'LON',
  'NSE',
  'OMX',
  'SEHK',
  'SGX',
  'SSE',
  'STO',
  'SWX',
  'SZSE',
  'TSX',
  'TWSE',
  'VSE',
  'Other',
] as const

export const businessIdTypes = [
  'USA: Employer Identification Number (EIN)',
  'Canada: Canadian Business Number (BN-9)',
  'Great Britain: Company Number',
  'Australia: Company Number from ASIC (ACN)',
  'India: Corporate Identity Number',
  'Estonia: VAT Number',
  'Romania: VAT Registration Number',
  'Israel: Registration Number',
  'Other',
] as const

export const industries = [
  'Agriculture',
  'Automotive',
  'Banking',
  'Consumer',
  'Education',
  'Electronics',
  'Energy',
  'Engineering',
  'Fast moving consumer goods',
  'Financial',
  'Fintech',
  'Food and beverage',
  'Government',
  'Healthcare',
  'Hospitality',
  'Insurance',
  'Jewelry',
  'Legal',
  'Manufacturing',
  'Media',
  'Not for profit',
  'Oil and gas',
  'Online',
  'Raw materials',
  'Real estate',
  'Religion',
  'Retail',
  'Technology',
  'Telecommunications',
  'Transportation',
  'Travel',
] as const

export const regionsOfOperation = [
  'Africa',
  'Asia',
  'Europe',
  'Latin America',
  'USA and Canada',
] as const

export const jobPositions = [
  'Other',
  'Director',
  'GM',
  'VP',
  'CEO',
  'CFO',
  'General Counsel',
] as const

export const optInTypes = [
  'VERBAL',
  'WEB_FORM',
  'PAPER_FORM',
  'VIA_TEXT',
  'MOBILE_QR_CODE',
] as const

export const popularUseCaseCategories = [
  'EVENTS',
  'CUSTOMER_CARE',
  'ACCOUNT_NOTIFICATIONS',
  'MARKETING',
  'DELIVERY_NOTIFICATIONS',
] as const

export const otherUseCaseCategories = [
  'HIGHER_EDUCATION',
  'K12',
  'SECURITY_ALERT',
  'CHARITY_NONPROFIT',
  'TWO_FACTOR_AUTHENTICATION',
  'POLLING_AND_VOTING_NON_POLITICAL',
  'POLITICAL_ELECTION_CAMPAIGNS',
  'PUBLIC_SERVICE_ANNOUNCEMENT',
] as const

export const allUseCaseCategories = [
  ...popularUseCaseCategories,
  ...otherUseCaseCategories,
] as const

export const messageVolume = [
  '10',
  '100',
  '1,000',
  '10,000',
  '100,000',
  '250,000',
  '500,000',
  '750,000',
  '1,000,000',
  '5,000,000',
  '10,000,000+',
] as const

export const A2P_CAMPAIGN_REGISTRATION_TIMELINE_MESSAGE =
  'Campaign registration typically takes 3-5 days, but may extend up to 20 days during high submission volumes. We will notify you as soon as this is complete.'

export const forbiddenMessages = [
  {
    icon: '🏦',
    title: 'High-risk financial services',
    descriptionItems: [
      'Includes payday loans, short term high-interest loans, new loan soliciting, third-party loans, student loans, cryptocurrency, and stocks and investing platforms.',
      '"Third-party" means originating from any party other than the one which will service the loan. Examples of third-party loans could include: auto, mortgage, personal, etc. First party loan content is acceptable if it is not promotional messaging, unless it is on an approved Short Code.',
      'Businesses that solely operate in stocks, investing, or cryptocurrency are only permitted to send SMS traffic that is 2FA and/or transactional messaging. If there is a mixed use case where that is a partial aspect of the business it may be approved based on the other use case content.',
    ],
  },
  {
    icon: '📲️',
    title: 'Third party lead generation',
    descriptionItems: [
      'Includes companies, NGOs or political campaigns that buy, sell, or share consumer information, affiliate lending, affiliate marketing and deceptive marketing',
      'Any third-party use cases are strictly forbidden. Consent must be obtained directly from end-users.',
      'Political use case customers sending SMS messages are not able to use voter registration databases to collect consent and outreach end-users.',
      'Any business with a terms of service or privacy policy that mentions sharing or selling consumer data/opt-in information is considered noncompliant.',
    ],
  },
  {
    icon: '💰️',
    title: 'Debt collection or forgiveness',
    descriptionItems: [
      'Includes, third-party debt collection, debt consolidation, debt reduction, debt relief and credit/debt repair',
      '"Third-party" means originating from any party other than the one who is owed the debt. For example, a hospital could send messages regarding bills for its own patients, assuming they provided opt-in to receive that messaging.',
      'While third party debt collection is not permitted, a debt collection business that has direct consent from end-users to send related content may do so.',
      'Debt consolidation, debt reduction and credit repair programs are prohibited regardless if there is first-party consent.',
    ],
  },
  {
    icon: '💊️',
    title: 'Prescription drugs',
    descriptionItems: [
      'Includes drugs that require a prescription',
      'Offers for drugs that cannot be sold over-the-counter in the US/Canada are forbidden regardless if the business is a licensed professional.',
    ],
  },
  {
    icon: '🪴',
    title: 'Illegal substances or articles',
    descriptionItems: [
      'Includes cannabis, CBD, Kratom, paraphernalia products, vape/e-cigs and fireworks',
      'Cannabis, CBD, Kratom, or drug paraphernalia product businesses are prohibited from utilizing SMS/MMS messaging on Twilio in the US and Canada, regardless of content. These restrictions apply regardless of the federal or state legality. All use cases for these are disallowed from sending SMS whether it contains cannabis content or not, even for 2FA purposes it is not permissible for such entities.',
    ],
  },
  {
    icon: '🎲',
    title: 'Gambling',
    descriptionItems: [
      'Includes casino apps, websites that offer gambling, sweepstakes, 50/50 raffles, contests and betting/sports picks',
      'Gambling traffic is prohibited in the US and Canada on all number types (Toll Free, Short Code, Long Code).',
      'Sweepstakes are allowed on Short Code but need to go through a special review.',
      'Bingo related messages are allowed on an approved Short Code. Community or local based Bingo related messaging are also permissible on carrier networks.',
    ],
  },
  {
    icon: '🔞',
    title: 'S.H.A.F.T.',
    descriptionItems: [
      'Includes Sex, Hate, Alcohol, Firearms, Tobacco, Vape/E-ciggs',
      'Alcohol traffic is allowed on Toll Free, Short Code, and Long Code in the US, as long as proper age gating procedures are in place. Age gating means that website users must input their date of birth. It cannot be a yes or no question.',
      'Firearms, Tobacco, Vape, and E-cigarettes are not allowed on Toll Free, Short Code, or Long Code regardless of age gating. Gun advocacy groups/messaging does not fall under Firearms.',
      'All age-gated content into Canada must be blocked across Toll Free, Short Code, and Long Code. The only way to send age-gated traffic into Canada (even with proper age-gating) is to receive a special carrier exemption. Allowed age gated content in Canada include: pocket knives, lighters, and non-alcoholic beverages',
    ],
  },
] as const

// eligibility survey questions
export const businessOrPersonalOptions: BusinessOrPersonalOptions = [
  {
    label: 'For business',
    description: 'Connecting with customers or prospects',
    value: 'business',
    icon: '💼',
  },
  {
    label: 'For personal use',
    description: 'Connecting with friends and family',
    value: 'personal',
    icon: '🙋',
  },
]

export const taxIdOptions: TaxIdOptions = [
  {
    label: 'Yes, I do',
    value: 'yes',
    icon: '👍',
  },
  {
    label: "No, I don't",
    value: 'no',
    icon: '👎',
  },
]

export const addressAndMobileOptions: AddressAndMobileOptions = [
  {
    label: 'Yes, I do',
    value: 'yes',
    icon: '👍',
  },
  {
    label: "No, I don't",
    value: 'no',
    icon: '👎',
  },
]

export const postalCodeRequiredError = 'postal_code_required'

export const messagingFee: MessagingFee = {
  'lv-standard': '$1.50/month',
  'sole-prop': '$2/month',
  standard: '$2/month',
} as const

export const MIN_MESSAGE_EXAMPLES = 2

export const MAX_MESSAGE_EXAMPLES = 5

export const msgConsentNoneSelectedErrorMessage =
  'At least one messaging consent method must be selected'

export const msgPrimaryConsentMethodErrorMessage = 'One consent method must be selected'

export const consentMethodOptions: Record<
  ConsentCollectionMethodKeys,
  ConsentMethodOption
> = {
  verbal: {
    title: 'Verbal consent',
    description: 'In-person or over the phone',
    icon: '🗣️',
    badge: 'Popular',
  },
  written: {
    title: 'Written consent',
    description: 'Email or paper form',
    icon: '📝',
  },
  digitalForm: {
    title: 'Digital consent',
    description: 'Online form, in-app form, point-of-sale, or QR code',
    icon: '💻',
  },
  textMessage: {
    title: 'Text to subscribe',
    description: 'Customers send SMS with a keyword to sign up',
    icon: '💬',
  },
} as const

const defaultLabels = {
  smsOptIn: {
    title: 'SMS opt in',
    label: 'Would you like to receive updates by text?',
  },
  textFrequency: {
    title: 'Text frequency',
    label: 'Message frequency varies',
  },
  dataRates: {
    title: 'Data rates',
    label: 'Message and data rates may apply',
  },
  optOutMethod: {
    title: 'Opt-out method',
    label: 'You can opt out at any time by replying “STOP',
  },
  privacyPolicy: {
    title: 'Link to your compliant Privacy Policy page',
    label: 'acme.com/privacy',
  },
}

export const consentExampleLabels = {
  verbal: {
    ...defaultLabels,
    privacyPolicy: {
      ...defaultLabels.privacyPolicy,
      label: 'Visit acme.com/privacy for more info',
    },
  },
  written: {
    ...defaultLabels,
  },
  digital: {
    ...defaultLabels,
    smsOptIn: {
      ...defaultLabels.smsOptIn,
      label: 'I would like to receive updates by text',
    },
  },
  textMessage: {
    ...defaultLabels,
    smsOptIn: {
      ...defaultLabels.smsOptIn,
      label:
        'Would you like to receive updates by text? Text ACME to 12345 to sign up for updates and information.',
    },
  },
}
export const privacyPolicyNoneSelectedErrorMessage =
  'You must select one option in order to proceed'

export const privacyPolicyClause =
  'All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties.'

export const copyByType = {
  'lv-standard': {
    submitSuccess: 'Low Volume Standard registration submitted successfully',
    submitError:
      'There was a problem submitting your Low Volume Standard registration. Please check your entries.',
    draftSuccess: 'Low Volume Standard registration saved successfully',
    draftError:
      'There was a problem saving your Low Volume Standard registration. Please check your entries.',
  },
  'sole-prop': {
    submitSuccess: 'Sole Proprietorship registration submitted successfully',
    submitError:
      'There was a problem submitting your Sole Proprietorship registration. Please check your entries.',
    draftSuccess: 'Sole Proprietorship registration saved successfully',
    draftError:
      'There was a problem saving your Sole Proprietorship registration. Please check your entries.',
  },
  'personal-use': {
    submitSuccess: 'Personal Use registration submitted successfully',
    submitError:
      'There was a problem submitting your Personal Use registration. Please check your entries.',
    draftSuccess: 'Personal Use registration saved successfully',
    draftError:
      'There was a problem saving your Personal Use registration. Please check your entries.',
  },
} as const
