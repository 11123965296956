import { LazyMotion } from 'framer-motion'
import { useLayoutEffect } from 'react'
import { OverlayProvider } from 'react-aria'

import SensorProvider from '@ui/SensorProvider'
import TooltipProvider from '@ui/TooltipProvider'
import {
  type ThemeKey,
  type TypographyThemeKey,
  useTheme,
  ThemeProvider,
} from '@ui/theme'

export interface DesignSystemProviderProps {
  children?: React.ReactNode
  themeKey?: ThemeKey
  typographyThemeKey?: TypographyThemeKey
}

const loadFeatures = async () =>
  (await import('@ui/animations/FramerLazyFeatures')).default

const DesignSystemProvider = ({
  children,
  themeKey = 'light',
  typographyThemeKey = 'default',
}: DesignSystemProviderProps) => {
  return (
    <ThemeProvider themeKey={themeKey} typographyThemeKey={typographyThemeKey}>
      <GlobalThemeUpdater />
      <SensorProvider>
        <OverlayProvider style={{ flex: 1, height: '100%' }}>
          <TooltipProvider>
            <LazyMotion strict features={loadFeatures}>
              {children}
            </LazyMotion>
          </TooltipProvider>
        </OverlayProvider>
      </SensorProvider>
    </ThemeProvider>
  )
}

export default DesignSystemProvider

function GlobalThemeUpdater() {
  const { themeClassName } = useTheme()

  useLayoutEffect(() => {
    const tokens = themeClassName.split(' ')
    document.body.classList.add(...tokens)

    return () => {
      document.body.classList.remove(...tokens)
    }
  }, [themeClassName])

  return null
}
