import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { useBillingSubscription } from '@src/app/billing/subscription'
import { useAppStore } from '@src/app/context'
import { Card } from '@src/app/onboarding/common'
import Illustration from '@src/component/illustrations'
import AccountBlocked from '@src/component/illustrations/AccountBlocked'
import { Link } from '@src/component/link'
import Callout from '@ui/Callout'
import { Spacer } from '@ui/layout/Spacer'

import VerificationLayout from './VerificationLayout'

const Flagged = () => {
  const { history, needsOnboarding } = useAppStore()
  const { isReviewApproved, isActive } = useBillingSubscription()

  useEffect(() => {
    if (isReviewApproved) {
      if (needsOnboarding) {
        history.push('/onboarding')
      } else if (isActive) {
        history.push('/')
      }
    }
  }, [history, isReviewApproved, needsOnboarding, isActive])

  if (isReviewApproved) {
    return null
  }

  return (
    <VerificationLayout>
      <Card
        illustration={
          <Illustration illustration={AccountBlocked} style={{ width: 200 }} />
        }
        title="Account blocked"
        description="Our team will email you within 48 hours with an update. Your 7-day trial has not started and you will not be charged."
      >
        <>
          <Spacer height={24} />
          <Callout icon="⚠️">
            Attempts to bypass this process using another email address will fail and may
            impact the status of your pending review.
          </Callout>
        </>
      </Card>
    </VerificationLayout>
  )
}

export default observer(Flagged)
