import { useCallback, useLayoutEffect, useState, useId } from 'react'

import { useTooltip } from './TooltipProvider'

const useSequentialHoverTooltip = (isOpen = false) => {
  const id = useId()
  const {
    currentlyOpenedTooltipRef,
    setCurrentlyOpenedTooltip: setOpenedTooltip,
    unsetCurrentlyOpenedTooltip,
  } = useTooltip()

  const setCurrentlyOpenedTooltip = useCallback(() => {
    setOpenedTooltip(id)
  }, [id, setOpenedTooltip])

  const [isSequentialHover, setIsSequentialHover] = useState(false)

  useLayoutEffect(() => {
    if (!isOpen) {
      return
    }

    setIsSequentialHover(
      currentlyOpenedTooltipRef.current !== null ||
        currentlyOpenedTooltipRef.current === id,
    )

    return () => {
      setIsSequentialHover(false)
    }
  }, [isOpen, id, currentlyOpenedTooltipRef])

  return {
    isSequentialHover,
    setCurrentlyOpenedTooltip,
    unsetCurrentlyOpenedTooltip,
  }
}

export default useSequentialHoverTooltip
