import { makeAutoObservable, action } from 'mobx'
import { nanoid } from 'nanoid'

import { parseDate } from '@src/lib'
import isNonNull from '@src/lib/isNonNull'
import type MemberStore from '@src/service/member-store'
import type { AvailabilityHours as IAvailabilityHours } from '@src/service/transport/account'

import type { Model } from '.'
import AvailabilityHours from './AvailabilityHours'

type PresenceStatus = 'online' | 'offline'

export interface CodablePresence {
  id: string
  userId?: string | null
  status?: PresenceStatus | null
  snoozed?: boolean | null
  onCall?: boolean
  snoozedUntil?: number | null
  offlineUntil?: number | null
  symbol?: string | null
  text?: string | null
  schedule?: AvailabilityHours
}

class PresenceModel implements CodablePresence, Model {
  userId?: string | null = null
  status?: PresenceStatus | null = null
  snoozed?: boolean | null = null
  onCall = false
  snoozedUntil: number | null = 0
  offlineUntil: number | null = 0
  symbol?: string | null = null
  text?: string | null = null
  _schedule?: IAvailabilityHours

  constructor(private memberStore: MemberStore) {
    makeAutoObservable(this, {})
  }

  get id() {
    return this.userId ?? nanoid()
  }

  get isOnline() {
    return this.status === 'online'
  }

  get isSnoozed() {
    return this.snoozed && this.snoozedUntil && this.snoozedUntil > Date.now()
  }

  get schedule(): AvailabilityHours | undefined {
    return this._schedule
      ? new AvailabilityHours().deserialize(this._schedule)
      : undefined
  }

  get hasStatus(): boolean {
    return Boolean(this.text || this.symbol)
  }

  get fullStatus(): string {
    return [this.symbol, this.text].filter(isNonNull).join(' ')
  }

  /**
   * The weight to be used when sorting members by presence.
   *
   * Members should be ordered like this:
   *  1. Online
   *  2. Offline
   *  3. On a call
   *  4. Snoozed
   */
  get sortWeight(): number {
    if (this.isSnoozed) {
      return 3
    }
    if (this.onCall) {
      return 2
    }
    if (!this.isOnline) {
      return 1
    }
    return 0
  }

  setStatus = (symbol: string | null, text: string | null) => {
    this.text = text
    this.symbol = symbol
    return this.memberStore.updatePresence(this)
  }

  setOnline = (online: boolean) => {
    if (online) {
      this.offlineUntil = null
    } else {
      this.offlineUntil = Date.now() + 3600000 * 24 * 365 * 100
    }
    this.status = online ? 'online' : 'offline'
    return this.memberStore.updatePresence(this)
  }

  snoozeNotifications(until: number) {
    this.snoozedUntil = until
    return this.memberStore
      .setDoNotDisturb(this)
      .then(action(() => (this.snoozed = true)))
  }

  unsnooze() {
    this.snoozedUntil = null
    return this.memberStore
      .setDoNotDisturb(this)
      .then(action(() => (this.snoozed = false)))
  }

  save = () => {
    return this.memberStore.updatePresence(this)
  }

  deserialize = ({ id: _id, schedule, ...json }: CodablePresence) => {
    Object.assign(this, json)

    if (typeof schedule !== 'undefined') {
      this._schedule = schedule
    }

    if (json.snoozedUntil) {
      this.snoozedUntil = parseDate(json.snoozedUntil)
    }

    if (json.offlineUntil) {
      this.offlineUntil = parseDate(json.offlineUntil)
    }

    return this
  }

  serialize = (): CodablePresence => {
    return {
      id: this.id,
      userId: this.userId,
      status: this.status,
      snoozed: this.snoozed,
      snoozedUntil: this.snoozedUntil,
      offlineUntil: this.offlineUntil,
      onCall: this.onCall,
      symbol: this.symbol,
      text: this.text,
    }
  }
}

export default PresenceModel
