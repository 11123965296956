import type { MenuTriggerState } from '@react-stately/menu'
import type React from 'react'
import type { MutableRefObject } from 'react'
import { createContext } from 'react'
import type { AriaButtonProps, AriaMenuOptions } from 'react-aria'

import type { Sensor } from '@ui/SensorProvider'

export interface ComboboxTriggerContextProps {
  state: MenuTriggerState
  defaultOpen?: boolean
  comboboxTriggerProps: AriaButtonProps
  comboboxProps: AriaMenuOptions<unknown>
  ref: React.MutableRefObject<HTMLElement | null>
  setNodeRef: (element: HTMLElement | null) => void
  triggeredWithSensorRef: MutableRefObject<Sensor | null>
  setTriggeredWith: (sensor: Sensor) => void
}

const ComboboxTriggerContext = createContext<ComboboxTriggerContextProps | null>(null)

export default ComboboxTriggerContext
