import cx from 'classnames'

import { ScrollView } from '@src/component/scrollview'
import { useMaybeSubMenu } from '@ui/Menu/SubMenuProvider'
import Popover from '@ui/Popover'
import type { PopoverProps } from '@ui/Popover'
import { LayerProvider } from '@ui/z-index'

import * as styles from './MenuPopover.css'

export default function MenuPopover({ children, className, ...props }: PopoverProps) {
  const subMenu = useMaybeSubMenu()
  return (
    <LayerProvider layer="menu">
      <Popover
        {...props}
        isDismissable
        className={cx(styles.popover, className)}
        shouldCloseOnBlur
        shouldCloseOnInteractOutside={(element) => {
          const isOutsideMenuAndTrigger = !props.targetRef.current?.contains(element)

          if (subMenu) {
            return (
              isOutsideMenuAndTrigger &&
              !subMenu.parentMenu.menuListRef.current?.contains(element)
            )
          }

          return isOutsideMenuAndTrigger
        }}
      >
        <ScrollView className={styles.scrollView}>{children}</ScrollView>
      </Popover>
    </LayerProvider>
  )
}
