import { ErrorFallback } from '@src/app/components/ErrorFallback'
import { useAppStore } from '@src/app/context'

import * as styles from './GlobalErrorView.css'

interface GlobalErrorViewProps {
  error: unknown
  reset: () => void
}

const GlobalErrorView = function ({ error, reset }: GlobalErrorViewProps) {
  const app = useAppStore()

  function reload() {
    if (app.update.updateReady) {
      app.update.installAndRestart()
    } else {
      reset()
    }
  }

  return (
    <div className={styles.root}>
      <ErrorFallback error={error} onReload={reload} />
    </div>
  )
}

export default GlobalErrorView
