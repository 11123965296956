import type { MutableRefObject } from 'react'
import { useEffect } from 'react'

/**
 * Trigger given callback when the ref element intersects the document
 */
export default function useIsVisible(
  ref: MutableRefObject<HTMLElement | null>,
  callback: (isVisible: boolean) => void,
) {
  useEffect(() => {
    if (!ref.current) {
      return
    }
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      callback(entry?.isIntersecting ?? false)
    })

    observer.observe(ref.current)

    return () => {
      callback(false)
      observer.disconnect()
    }
  }, [callback, ref])
}
