import { assignInlineVars } from '@vanilla-extract/dynamic'
import cx from 'classnames'

import * as styles from './DesktopDraggableArea.css'

interface DesktopDraggableAreaProps {
  children?: React.ReactNode
  height?: string
  className?: string
  style?: React.CSSProperties
}

/**
 * Enables dragging the window on macOS and Windows.
 * Note: Events will not be propagated to children,
 * So, if you need to handle events on children,
 * you need to explicitly apply `-webkit-app-region: 'no-drag' to each one
 *
 */
const DesktopDraggableArea = ({
  children,
  className,
  height = 'auto',
  style = {},
}: DesktopDraggableAreaProps) => {
  return (
    <div
      className={cx(styles.root, className)}
      style={{ ...style, ...assignInlineVars({ [styles.height]: height }) }}
    >
      {children}
    </div>
  )
}

export default DesktopDraggableArea
