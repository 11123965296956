import cx from 'classnames'

import Button from '@src/component/ButtonV2'
import Typography from '@ui/Typography'
import { XIcon } from '@ui/icons/tint/12/general'

import type { FeatureTooltipAction } from './FeatureTooltip'
import * as styles from './FeatureTooltipContent.css'

export interface FeatureTooltipContentProps {
  /**
   * The title of the tooltip.
   */
  title: string

  /**
   * The description of the tooltip.
   */
  description: React.ReactNode

  /**
   * The footer of the tooltip. It's rendered to the left of the action button(s).
   */
  footer?: string

  /**
   * Called when the tooltip is closed by the user.
   */
  onClose?: () => void

  /**
   * Variants to add action button(s)
   */
  actions?:
    | [FeatureTooltipAction<'primary'>]
    | [FeatureTooltipAction<'secondary'>, FeatureTooltipAction<'primary'>]
}

function FeatureTooltipContent({
  title,
  description,
  footer,
  onClose,
  actions,
}: FeatureTooltipContentProps) {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Typography variant="footnote" fontWeight="semibold">
          {title}
        </Typography>

        <button
          type="button"
          aria-label="Close"
          className={styles.close}
          onClick={onClose}
        >
          <XIcon aria-hidden="true" />
        </button>
      </div>

      <Typography variant="footnote" fontWeight="regular" className={styles.description}>
        {description}
      </Typography>

      <div className={styles.footerWrapper}>
        {footer ? (
          <Typography variant="caption1" fontWeight="regular" className={styles.footer}>
            {footer}
          </Typography>
        ) : null}
        {actions?.length ? (
          <div className={styles.actions}>
            {actions.map((action) => (
              <Button
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
                key={action.type}
                height={30}
                className={cx(
                  styles.actionButton,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
                  { [styles.primaryActionButton]: action.type === 'primary' },
                )}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
                  action.onAction?.()
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
                  if (action.isOnCloseTriggered) {
                    onClose?.()
                  }
                }}
              >
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation! */}
                {action.title ?? (action.type === 'primary' ? 'View Now' : 'Dismiss')}
              </Button>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default FeatureTooltipContent
