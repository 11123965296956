import type { ReactNode } from 'react'

import * as styles from './ComboboxListWrapper.css'

export interface ComboboxListWrapperProps {
  children: ReactNode

  /**
   * The width of the combobox
   */
  width: number | '100%'

  /**
   * The max height of the combobox
   */
  maxHeight?: number
}

export default function ComboboxListWrapper({
  children,
  width,
  maxHeight,
}: ComboboxListWrapperProps) {
  return (
    <div className={styles.root} style={{ width, maxHeight }}>
      {children}
    </div>
  )
}
